import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export const ADD_MAPPING = {
  id: "add-mapping",
  token: localizationKey("Add New Mapping"),
  position: "right",
}

export const EDIT_MAPPING = {
  id: "edit-mapping",
  token: localizationKey("Edit"),
}

export const DELETE_MAPPING = {
  id: "delete-mapping",
  token: localizationKey("Delete"),
}

export const COPY_MAPPING = {
  id: "copy-mapping",
  token: localizationKey("Copy"),
}
