import { Modal } from "@ninjaone/components"
import { AWSResourceImage } from "js/includes/ticketing/editor/shared/components"
import { Box } from "js/includes/components/Styled"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export const ImagePreviewModal = ({ unmount, onDownload, attachmentId, contentId, titleText }) => {
  return (
    <Modal
      size="lg"
      unmount={unmount}
      titleGroup={titleText ? { titleText } : { titleToken: localizationKey("Preview") }}
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Download"),
          disabled: !attachmentId && !contentId,
          onClick: () => {
            onDownload()
            unmount()
          },
        },
      ]}
    >
      <Box overflow="auto" maxHeight="calc(100vh - 300px)">
        <AWSResourceImage {...{ attachmentId, contentId }} />
      </Box>
    </Modal>
  )
}
