import { useEffect, useState } from "react"
import { matchPath } from "react-router-dom"
import { Heading, Text } from "@ninjaone/components"
import { HelpTooltipVideoTimestamp } from "@ninjaone/components/src/HelpHoverTooltip"
import { BulbOnIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { localizationKey, localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { ArticleCard, Markdown, allowedElements } from "../common"
import HelpDropdownAnchor from "../HelpDropdownAnchor"
import { suggestedArticlesByRoute } from "./common"

const getArticles = pages => {
  const activeRoute = window.location.hash.replace("#", "")

  return (
    pages
      .slice()
      .reverse()
      .find(({ route, exact = false }) => {
        const match = matchPath(activeRoute, {
          path: route,
          exact,
          strict: false,
        })

        return exact ? match?.isExact : !!match
      })?.articles ?? []
  )
}

const SuggestedArticles = () => {
  const [articles, setArticles] = useState(getArticles(suggestedArticlesByRoute))

  useEffect(() => {
    const handleSetArticles = () => {
      setArticles(getArticles(suggestedArticlesByRoute))
    }

    window.addEventListener("hashchange", handleSetArticles)
    window.addEventListener("backboneEditorTabChange", handleSetArticles)

    return () => {
      window.removeEventListener("hashchange", handleSetArticles)
      window.removeEventListener("backboneEditorTabChange", handleSetArticles)
    }
  }, [])

  const title = (
    <Flex gap={tokens.spacing[1]} alignItems="center" marginBottom={tokens.spacing[3]}>
      <BulbOnIcon color="colorTextWeak" />
      <Heading level={2} type="headingS" color="colorTextWeak">
        {localized("Suggested help articles")}
      </Heading>
    </Flex>
  )

  if (!articles.length) {
    return (
      <Box marginBottom={tokens.spacing[6]}>
        {title}
        <Text
          color="colorTextWeakest"
          token={localizationKey(
            "We're working on getting articles added for this page. In the meantime, try searching for articles in the search bar above.",
          )}
          size="sm"
          textWrap
        />
      </Box>
    )
  }

  return (
    <Flex flexDirection="column" height="auto" marginBottom={tokens.spacing[6]}>
      {title}
      <Flex id="fs-suggested-help-articles" flexDirection="column" gap={tokens.spacing[2]} overflowY="scroll">
        {articles.map(({ labelToken, href, videoTimestamp, descriptionToken }) => (
          <ArticleCard key={labelToken}>
            <Flex justifyContent="space-between" width="100%" gap={tokens.spacing[2]}>
              <HelpDropdownAnchor {...{ href, bold: true }}>{localized(labelToken)}</HelpDropdownAnchor>
              {!!videoTimestamp && <HelpTooltipVideoTimestamp timestamp={videoTimestamp} />}
            </Flex>
            <Markdown
              {...{
                skipHtml: true,
                allowedElements,
                linkTarget: "_blank",
              }}
            >
              {localized(descriptionToken)}
            </Markdown>
          </ArticleCard>
        ))}
      </Flex>
    </Flex>
  )
}

export default SuggestedArticles
