import { useMemo, useState, forwardRef } from "react"

import { CodeHighlightNode, CodeNode } from "@lexical/code"
import { HashtagNode } from "@lexical/hashtag"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { ListItemNode, ListNode } from "@lexical/list"
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary"
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode"
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin"
import { TablePlugin } from "@lexical/react/LexicalTablePlugin"
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table"

import {
  AutoLinkPlugin,
  AttachmentPlugin,
  CodeHighlightPlugin,
  ContentInjectorPlugin,
  EditorBlurPlugin,
  ExtendedTextNode,
  FloatingLinkEditorPlugin,
  FloatingTextFormatToolbarPlugin,
  HTMLParserPlugin,
  HTMLInjectorPlugin,
  ImageNode,
  LinkPlugin,
  ImagePlugin,
  DragDropPastePlugin,
  ListMaxIndentLevelPlugin,
  TableActionMenuPlugin,
  TableCellResizerPlugin,
  MentionNode,
  MentionPlugin,
  Placeholder,
  StyledEditor,
  //Styled Components
  StyledEditorContainer,
  TabFocusPlugin,
  ToolbarPlugin,
  theme,
} from "@ninjaone/components/src/WYSIWYG"

import { imageImport, spanImport } from "./importUtils"
import { htmlExport } from "./exportUtils"
import { TicketingMentionNode } from "./nodes"
import { TicketingMentionsTypeaheadMenuItem } from "./nodes/TicketingMentionsTypeaheadMenuItem"
import { StyledTicketingContentEditable, StyledTicketingEditorScroller, StyledTicketingEditorShell } from "./styled"

const getNodes = ({ imagePluginExcluded }) => [
  HeadingNode,
  QuoteNode,
  HashtagNode,
  LinkNode,
  CodeNode,
  CodeHighlightNode,
  HorizontalRuleNode,
  ListItemNode,
  ListNode,
  TableCellNode,
  TableNode,
  TableRowNode,
  AutoLinkNode,
  ...(imagePluginExcluded ? [] : [ImageNode]),
  TicketingMentionNode,
  {
    replace: MentionNode,
    with: node => {
      return new TicketingMentionNode(node.getId(), node.getLabel(), node.getMetadata())
    },
  },
  ExtendedTextNode,
]

export const WYSIWYGEditor = forwardRef(
  (
    {
      isPublicResponse,
      fullWidth,
      noPadding,
      noBorder,
      onBlur,
      ariaLabel,
      editorContentId,
      onChangePluginProps = {},
      htmlParserPluginProps = {},
      imagePluginProps = {},
      attachmentPluginProps = {},
      richTextPluginProps = {},
      autoLinkPluginProps = {},
      validationProps = {},
      mentionPluginProps = {},
      toolbarPluginProps = {},
      htmlInjectorPluginProps = {},
    },
    ref,
  ) => {
    const {
      excluded: imagePluginExcluded = false,
      initialInlineAttachments: inlineImageAttachments,
      onUploadImage,
      importImagesFromMemory,
    } = imagePluginProps
    const {
      excluded: attachmentPluginExcluded,
      initialAttachments,
      onRemoveAttachment,
      onUploadAttachment,
      onUploadAttachmentError,
    } = attachmentPluginProps
    const { matchers: lexicalAutoLinkMatchers } = autoLinkPluginProps
    const { onChange } = onChangePluginProps
    const { initialHTML, updateOnChange } = htmlParserPluginProps
    const { placeholderToken } = richTextPluginProps
    const { disableAutoLink } = autoLinkPluginProps
    const { initialData: initialMentionData, mentionFetch, onAddMention } = mentionPluginProps
    const { excluded: toolbarPluginExcluded } = toolbarPluginProps
    const { included: htmlInjectorPluginIncluded } = htmlInjectorPluginProps

    const [floatingAnchorElement, setFloatingAnchorElement] = useState(null)
    const [isLinkEditMode, setIsLinkEditMode] = useState(false)

    const onRef = element => {
      if (element !== null) {
        setFloatingAnchorElement(element)
      }
    }

    const nodes = useMemo(() => getNodes({ imagePluginExcluded }), [imagePluginExcluded])

    const initialConfig = useMemo(
      () => ({
        onError: error => {
          // TODO: Report error to Sentry
          throw error
        },
        html: {
          import: {
            ...((inlineImageAttachments || importImagesFromMemory) && {
              img: imageImport({ attachments: inlineImageAttachments, importImagesFromMemory }),
            }),
            ...(initialMentionData && { span: spanImport({ initialMentionData: initialMentionData }) }),
          },
          export: htmlExport,
        },
        theme,
      }),
      [inlineImageAttachments, initialMentionData, importImagesFromMemory],
    )

    return (
      <>
        <LexicalComposer
          initialConfig={{
            ...initialConfig,
            nodes,
          }}
        >
          <StyledTicketingEditorShell
            errorMessage={validationProps.errorMessage}
            noBorder={noBorder}
            fullWidth={fullWidth}
          >
            {!toolbarPluginExcluded && (
              <ToolbarPlugin
                sticky={false}
                cidKey="resourceId"
                onUploadImage={onUploadImage}
                onUploadAttachment={onUploadAttachment}
                onUploadAttachmentError={onUploadAttachmentError}
              />
            )}
            <StyledEditorContainer>
              <RichTextPlugin
                contentEditable={
                  <StyledTicketingEditorScroller id="editor-input" isPublicResponse={isPublicResponse}>
                    <StyledEditor ref={onRef}>
                      <StyledTicketingContentEditable
                        id={editorContentId}
                        ariaLabel={ariaLabel}
                        className="editor-root"
                        noPadding={noPadding}
                        placeholder={<Placeholder token={placeholderToken} />}
                      />
                    </StyledEditor>
                  </StyledTicketingEditorScroller>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HistoryPlugin />
              <ClearEditorPlugin />
              <ListPlugin />
              <LinkPlugin />
              {!imagePluginExcluded && (
                <>
                  <DragDropPastePlugin cidKey="resourceId" onUploadImage={onUploadImage} />
                  <ImagePlugin cidKey="resourceId" onUploadImage={onUploadImage} />
                </>
              )}
              {!disableAutoLink && <AutoLinkPlugin matchers={lexicalAutoLinkMatchers} />}
              <CodeHighlightPlugin />
              <HorizontalRulePlugin />
              <TablePlugin />
              <TableCellResizerPlugin />
              <HashtagPlugin />
              <ContentInjectorPlugin />
              <TabFocusPlugin />
              <TabIndentationPlugin />
              <OnChangePlugin onChange={onChange} />
              <ListMaxIndentLevelPlugin maxDepth={5} />
              <MentionPlugin
                idKey="id"
                labelKey="displayName"
                initialData={initialMentionData}
                mentionFetch={mentionFetch}
                onAddMention={onAddMention}
                MentionsTypeaheadMenuItemComponent={TicketingMentionsTypeaheadMenuItem}
              />
              <HTMLParserPlugin initialHTML={initialHTML} updateOnChange={updateOnChange} />
              {floatingAnchorElement && (
                <>
                  <FloatingTextFormatToolbarPlugin
                    anchorElem={floatingAnchorElement}
                    setIsLinkEditMode={setIsLinkEditMode}
                  />
                  <FloatingLinkEditorPlugin
                    anchorElem={floatingAnchorElement}
                    isLinkEditMode={isLinkEditMode}
                    setIsLinkEditMode={setIsLinkEditMode}
                  />
                  <TableActionMenuPlugin anchorElem={floatingAnchorElement} />
                </>
              )}
            </StyledEditorContainer>
            {!attachmentPluginExcluded && (
              <AttachmentPlugin
                cidKey="resourceId"
                initialAttachments={initialAttachments}
                onRemoveAttachment={onRemoveAttachment}
                onUploadAttachment={onUploadAttachment}
              />
            )}
          </StyledTicketingEditorShell>
          <EditorBlurPlugin onBlur={onBlur} />
          {ref && <EditorRefPlugin editorRef={ref} />}
          {htmlInjectorPluginIncluded && <HTMLInjectorPlugin />}
        </LexicalComposer>
      </>
    )
  },
)
