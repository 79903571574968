import PropTypes from "prop-types"
import { useMemo } from "react"

import { VerticalTabsModal } from "@ninjaone/components"

import { hasErrors, localized } from "js/includes/common/utils"
import { automationTypes } from "js/includes/ticketing/common/automations"
import { CREATE_ACTION, EDIT_ACTION } from "js/includes/ticketing/TriggerEditor/constants"
import {
  Actions,
  attributesType,
  Conditions,
  fieldsType,
  formOptionsType,
  General,
  notificationChannelsType,
  organizationsType,
  tagsType,
  ticketAttributesType,
  validationType,
} from "js/includes/ticketing/TriggerEditor/sections"
import { validateActions, validateConditions } from "js/includes/ticketing/TriggerEditor/utils"

import { ColumnsAndSorting } from "./ColumnsAndSorting"
import { getSectionsData, getTriggerModalLabels } from "./utils"

export const TriggerModal = ({
  action,
  isLoading,
  unmount,
  values,
  onChange,
  validation,
  isPersisting,
  handleSave,
  isFormSubmitted,
  type,
  getValidations,
  tags,
  onQueryChange,
  attributes,
  fields,
  organizations,
  formOptions,
  notificationChannels,
  handleClose,
  system,
  copy,
  ticketAttributes,
}) => {
  const { titleGroup, saveButtonToken } = useMemo(() => getTriggerModalLabels({ action, type, system }), [
    action,
    type,
    system,
  ])

  const sectionsData = useMemo(() => getSectionsData({ copy, system, type }), [copy, system, type])

  const areActionsValid = useMemo(() => {
    if (type === "BOARD") {
      return true
    }
    return validateActions(values.actions)
  }, [values.actions, type])

  const areConditionsValid = useMemo(() => {
    if (type === automationTypes.RESPONSE_TEMPLATE) {
      return true
    }
    return validateConditions(values.conditions)
  }, [type, values.conditions])

  const hasAnyError = hasErrors(validation.success)

  return (
    <VerticalTabsModal
      {...{
        closeAction: handleClose,
        minHeight: 586,
        tabsAriaLabel: localized("Editor tabs"),
        titleGroup,
        unmount,
        onPrimaryButtonClick: handleSave,
        primaryButtonDisabled: isLoading || isPersisting,
        primaryButtonLabel: saveButtonToken,
        cancelable: action === CREATE_ACTION,
        tabs: [
          {
            label: localized("General"),
            required: true,
            renderer: () => (
              <General
                {...{
                  isLoading,
                  values,
                  validation,
                  onChange,
                  ...sectionsData.general?.props,
                }}
              />
            ),
            hasError: hasAnyError(["name"]),
          },
          ...(sectionsData.conditions?.visible
            ? [
                {
                  label: localized("Conditions"),
                  required: true,
                  renderer: () => (
                    <Conditions
                      {...{
                        isLoading,
                        conditions: values.conditions,
                        onQueryChange,
                        attributes,
                        fields,
                        organizations,
                        type,
                        getValidations,
                        formOptions,
                        isFormSubmitted,
                        tags,
                        validationMessage: validation.message.conditions,
                      }}
                    />
                  ),
                  hasError: isFormSubmitted && (!areConditionsValid || hasAnyError(["conditions"])),
                },
              ]
            : []),
          ...(sectionsData.actions?.visible
            ? [
                {
                  label: localized("Actions"),
                  required: true,
                  renderer: () => (
                    <Actions
                      {...{
                        isLoading,
                        actions: values.actions,
                        onChange,
                        type,
                        getValidations,
                        tags,
                        isFormSubmitted,
                        notificationChannels,
                        validationMessage: validation.message.actions,
                      }}
                    />
                  ),
                  hasError: isFormSubmitted && (!areActionsValid || hasAnyError(["actions"])),
                },
              ]
            : []),
          ...(sectionsData.columnsAndSorting?.visible
            ? [
                {
                  label: localized("Columns and sorting"),
                  required: true,
                  renderer: () => (
                    <ColumnsAndSorting
                      {...{
                        isLoading,
                        columns: values.columns,
                        sortBy: values.sortBy,
                        columnsErrorMessage: validation.message.columns,
                        validation,
                        onChange,
                        ticketAttributes,
                      }}
                    />
                  ),
                  hasError: hasAnyError(["columns"]),
                },
              ]
            : []),
        ],
      }}
    />
  )
}

TriggerModal.propTypes = {
  action: PropTypes.oneOf([CREATE_ACTION, EDIT_ACTION]),
  isLoading: PropTypes.bool,
  isFormSubmitted: PropTypes.bool,
  unmount: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  validation: validationType.isRequired,
  isPersisting: PropTypes.bool,
  handleSave: PropTypes.func.isRequired,
  tags: tagsType.isRequired,
  type: PropTypes.oneOf([
    automationTypes.EVENT_BASED,
    automationTypes.TIME_BASED,
    automationTypes.RESPONSE_TEMPLATE,
    "BOARD",
  ]).isRequired,
  getValidations: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  attributes: attributesType.isRequired,
  fields: fieldsType.isRequired,
  organizations: organizationsType.isRequired,
  formOptions: formOptionsType.isRequired,
  notificationChannels: notificationChannelsType,
  system: PropTypes.bool,
  copy: PropTypes.bool,
  ticketAttributes: ticketAttributesType,
}
