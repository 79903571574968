import { useEffect, useMemo } from "react"

import { VerticalTabs } from "@ninjaone/components"
import { getNodeRoles, getSearches } from "js/includes/common/client"
import { useMountedState } from "js/includes/common/hooks"
import {
  hasErrors,
  localizationKey,
  localized,
  reportErrorAndShowMessage,
  sortByFieldNameCaseInsensitive,
} from "js/includes/common/utils"
import Loading from "js/includes/components/Loading"
import { getNodeRoleOptions } from "js/includes/configuration/psa/ConnectWise/settings/common"

import { contentPropTypes } from "./contentCommons"
import {
  AnyOrAllGroups,
  Billing,
  Cost,
  Description,
  Name,
  Price,
  Quantity,
  Taxable,
  Role,
  Group,
} from "./contentFields"
import ContentWrapper from "./ContentWrapper"

const ManagedDevicesProductContent = props => {
  const [isDataLoaded, setIsDataLoaded] = useMountedState(false)
  const [data, setData] = useMountedState({ searches: [], nodeRoles: [] })

  const hasAnyError = hasErrors(props.validation.success)

  const { nodeRoleOptions, nodeRolesMap } = useMemo(() => {
    const { nodeRoles, nodeRolesMap } = data.nodeRoles.reduce(
      (acc, nodeRole) => {
        const option = { value: nodeRole.id, labelText: nodeRole.name }

        acc.nodeRoles.push(option)
        acc.nodeRolesMap[nodeRole.id] = {
          option,
          nodeRole,
        }
        return acc
      },
      {
        nodeRoles: [],
        nodeRolesMap: {},
      },
    )

    return {
      nodeRoleOptions: sortByFieldNameCaseInsensitive("labelText", "ASC", nodeRoles),
      nodeRolesMap,
    }
  }, [data.nodeRoles])

  const { searchOptions, searchesMap } = useMemo(() => {
    const { searches, searchesMap } = data.searches.reduce(
      (acc, { search }) => {
        const option = { value: search.id, labelText: search.name }

        acc.searches.push(option)
        acc.searchesMap[search?.id] = {
          option,
          search,
        }
        return acc
      },
      {
        searches: [],
        searchesMap: {},
      },
    )

    return {
      searchOptions: sortByFieldNameCaseInsensitive("labelText", "ASC", searches),
      searchesMap,
    }
  }, [data.searches])

  useEffect(() => {
    if (isDataLoaded) return

    const loadData = async () => {
      try {
        const [searches, nodeRoles] = await Promise.all([getSearches(), getNodeRoles()])
        setIsDataLoaded(true)
        setData({ searches: searches || [], nodeRoles: getNodeRoleOptions(nodeRoles || []) })
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error fetching initial data"))
      }
    }
    loadData()
  }, [isDataLoaded, setIsDataLoaded, setData])

  return !isDataLoaded ? (
    <Loading />
  ) : (
    <VerticalTabs
      ariaLabel={props.ariaLabel || localized("Managed Product Form")}
      tabs={[
        {
          required: true,
          hasError: hasAnyError(["name", "description"]),
          label: localized("General"),
          renderer: () => (
            <ContentWrapper>
              <Name {...props} />
              <Description {...props} />
            </ContentWrapper>
          ),
        },
        {
          required: true,
          hasError: hasAnyError(["price", "cost"]),
          label: localized("Billing"),
          renderer: () => (
            <ContentWrapper>
              <Price {...props} />
              <Cost {...props} />
              <Taxable {...props} />
              <Billing {...props} />
            </ContentWrapper>
          ),
        },
        {
          required: true,
          hasError: hasAnyError(["nodeRoleId", "quantity"]),
          label: localized("Advanced"),
          renderer: () => (
            <ContentWrapper>
              <Role
                {...{
                  ...props,
                  options: nodeRoleOptions,
                  nodeRolesMap,
                }}
              />
              <Group
                {...{
                  ...props,
                  options: searchOptions,
                  searchesMap,
                }}
              />
              <AnyOrAllGroups {...props} />
              {props.useDynamicQuantity && <Quantity {...props} />}
            </ContentWrapper>
          ),
        },
      ]}
    />
  )
}

ManagedDevicesProductContent.propTypes = contentPropTypes

export default ManagedDevicesProductContent
