import { getApplicationLanguage, initialDefaultLanguage, isNilOrEmpty, lNumber } from "js/includes/common/utils"

/**
 * Removes trailing zeros from a localized number. It removes the decimal separator when there are only zeros after it.
 *
 * This function should be called only when value has a fractional part
 *
 * @param {string} value A localized decimal number.
 * @returns The value with trailing zeros removed.
 */
const removeTrailingZerosFromValue = value => {
  return value.replace(/[^0-9]?0+$/, "")
}

export const formatToDisplay = ({ value, language, decimalPlaces, removeTrailingZeros, currency }) => {
  if (isNilOrEmpty(value)) {
    return ""
  }

  let formattedValue = lNumber(value, {
    language,
    minRound: decimalPlaces,
    maxRound: decimalPlaces,
    ...(currency && { styleType: "currency", currency }),
  })
  if (formattedValue === "NaN") {
    return ""
  }

  if (removeTrailingZeros && decimalPlaces > 0 && formattedValue.endsWith("0")) {
    formattedValue = removeTrailingZerosFromValue(formattedValue)
  }
  return formattedValue
}

export const formatToEdit = ({ numericValue, decimalSeparator }) => {
  return decimalSeparator === "." ? numericValue : `${numericValue}`.replace(".", decimalSeparator)
}

export const formatToNumericValue = ({ targetValue, decimalSeparator }) => {
  // RegExp to remove any value that is different from a number, +, -, or the decimalSeparator.
  // For example, it will remove $ and , from $1,000.00 (when the decimal separator is a period)
  // This is useful when the value comes from an input autocomplete list managed by a browser.
  const regExp = new RegExp(`[^\\d|\\+|\\-|\\${decimalSeparator}]`, "g")
  const cleanValue = `${targetValue}`.replace(regExp, "")

  const numericValue = decimalSeparator === "." ? cleanValue : cleanValue.replace(decimalSeparator, ".")
  const changed = `${targetValue}` !== cleanValue
  return { numericValue, changed }
}

export const isValueForEmpty = ({ targetValue, decimalSeparator }) => {
  return (
    !targetValue.length ||
    targetValue === "-" ||
    targetValue === decimalSeparator ||
    targetValue === `-${decimalSeparator}`
  )
}

export const getNextValue = onBeforeInputEvent => {
  const {
    data,
    inputType,
    target: { selectionStart, selectionEnd, value },
  } = onBeforeInputEvent

  const backwardOffset = selectionStart === selectionEnd && inputType === "deleteContentBackward" ? 1 : 0
  const forwardOffset = selectionStart === selectionEnd && inputType === "deleteContentForward" ? 1 : 0
  return `${value.substring(0, selectionStart - backwardOffset)}${data || ""}${value.substring(
    selectionEnd + forwardOffset,
    value.length,
  )}`
}

export const getDecimalSeparator = language => {
  return new Intl.NumberFormat(language).formatToParts(1.2).find(part => part.type === "decimal").value
}

export const getValidLanguage = language => {
  const validLanguage = language || getApplicationLanguage() || initialDefaultLanguage

  try {
    getDecimalSeparator(validLanguage)
    return validLanguage
  } catch (error) {
    return initialDefaultLanguage
  }
}
