import { show, hide } from "js/state/actions/common/visibility"

export function showModal(title, mode, id) {
  return dispatch => {
    dispatch({ type: "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING_MODAL_EDITOR_TITLE", title })
    dispatch({ type: "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING_MODAL_EDITOR_MODE", mode })
    dispatch({ type: "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING_ID", mode, id })
    dispatch(show("CWNodeRoleConfigurationTypeMappingModal")())
  }
}

export function closeModal() {
  return dispatch => {
    dispatch({ type: "RESET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING" })
    dispatch(hide("CWNodeRoleConfigurationTypeMappingModal")())
  }
}

export function setCurrentMapping(mapping) {
  return dispatch => {
    dispatch({ type: "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING", mapping })
  }
}

export function addMapping(data) {
  return dispatch => {
    dispatch({ type: "ADD_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING", mapping: data })
  }
}

export function updateMapping(data) {
  return dispatch => {
    dispatch({ type: "EDIT_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING", mapping: data })
  }
}

export function removeMapping(id) {
  return dispatch => {
    dispatch({ type: "REMOVE_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING", id })
  }
}

export function setNodeRole(nodeRole) {
  return dispatch => {
    dispatch({ type: "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING_NODE_ROLE", nodeRole })
  }
}

export function setConfigurationType(configurationType) {
  return dispatch => {
    dispatch({ type: "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING_CONFIGURATION_TYPE", configurationType })
  }
}

export function setOnCreate(status) {
  return dispatch => {
    dispatch({ type: "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING_ON_CREATE_STATUS", status })
  }
}

export function setOnDelete(status) {
  return dispatch => {
    dispatch({ type: "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING_ON_DELETE_STATUS", status })
  }
}
