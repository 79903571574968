import React from "react"
import { localized } from "js/includes/common/utils"
import { Tab, Tabs } from "react-bootstrap"
import ProductMapping from "./ProductMapping"
import ProductAgreementSync from "./ProductAgreementSync"

export default function ConnectWiseProducts() {
  return (
    <Tabs animation={false} defaultActiveKey="mappings" id="cw-product-tabs" className="custom-nav-tabs p-l p-r">
      <Tab eventKey="mappings" title={localized("Mappings")}>
        <ProductMapping />
      </Tab>
      <Tab eventKey="sync" title={localized("Sync")}>
        <ProductAgreementSync />
      </Tab>
    </Tabs>
  )
}
