import { memo } from "react"
import styled from "@emotion/styled"
import { Text } from "@ninjaone/components"
import { ExternalLinkIconLight } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { Box, Flex } from "js/includes/components/Styled"
import Link from "js/includes/components/Link"

const StyledMenuItem = styled.button`
  border: none;
  background: none;
  padding: ${tokens.spacing[2]};
  color: ${({ theme }) => theme.colorTextStrong};
  border-radius: ${tokens.borderRadius[1]};
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }

  button:focus-visible & {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const StyledMenuItemDiv = StyledMenuItem.withComponent("div")

const RowItem = memo(({ icon, topic, zendeskUrl, isExternalLink, onClick, ...rest }) => {
  const ButtonElement = zendeskUrl ? StyledMenuItemDiv : StyledMenuItem

  const menuItem = (
    <ButtonElement
      {...{
        ...rest,
        ...(!zendeskUrl && {
          onClick,
        }),
      }}
    >
      <Text size="sm" color="colorTextStrong">
        <Flex alignItems="center" gap={tokens.spacing[2]}>
          {icon} {topic}
          {isExternalLink && (
            <Box marginLeft="auto">
              <ExternalLinkIconLight size="xs" color="colorTextWeakest" />
            </Box>
          )}
        </Flex>
      </Text>
    </ButtonElement>
  )

  return zendeskUrl ? (
    <Link href={zendeskUrl} textDecoration={false}>
      {menuItem}
    </Link>
  ) : (
    menuItem
  )
})

export default RowItem
