import { localizationKey } from "js/includes/common/utils"

export const dojoArticles = {
  "207436993": {
    labelToken: localizationKey("Dashboards"),
    descriptionToken: localizationKey("This guide goes over the various Dashboard screens in NinjaOne."),
    videoTimestamp: "3:11",
  },
  "22291610271757": {
    labelToken: localizationKey("Dashboards: Navigation"),
    descriptionToken: localizationKey("View all articles related to Dashboards"),
    isSection: true,
  },
  "16050515738765": {
    labelToken: localizationKey("Getting Started"),
    descriptionToken: localizationKey("View all articles related to Getting Started"),
    isSection: true,
  },
  "207436993#h_01ENKFJVN8N4Y7HDN9SY8H5SMH": {
    labelToken: localizationKey("Dashboards (Devices Tab)"),
    descriptionToken: localizationKey(
      "The Maintenance sub-tab lists all devices with currently configured maintenance windows.",
    ),
  },
  "18159591896717": {
    labelToken: localizationKey("Additional Filters for Device Search"),
    descriptionToken: localizationKey(
      "Access the Device Search grid by clicking Devices in the left navigation pane on your NinjaOne console.",
    ),
  },
  "360004879451": {
    labelToken: localizationKey("Dashboards: Software Inventory"),
    descriptionToken: localizationKey(
      "This guide provides information about how and when we pull information for the Software Inventory.",
    ),
  },
  "10496973792397": {
    labelToken: localizationKey("Dashboards: Patch Management"),
    descriptionToken: localizationKey(
      "The Patch Management Dashboard is a dashboard under the Patching tab of the system and organization dashboards.",
    ),
    videoTimestamp: "1:52",
  },
  "10496973792397#h_01GH0WXKVVWWS7PGCN7NT61BQE": {
    labelToken: localizationKey("Dashboards: Patch Management (OS)"),
    descriptionToken: localizationKey(
      "The OS Patches dashboard at the system level provides a holistic view of the OS patching data from devices across your entire NinjaOne environment.",
    ),
  },
  "360046961572": {
    labelToken: localizationKey("Dashboards: NinjaOne Backup"),
    descriptionToken: localizationKey("This guide goes over the different dashboards available with NinjaOne Backup."),
    videoTimestamp: "2:46",
  },
  "207436993#h_01ENKFMJ9P937W0HNZZPNHYCXA": {
    labelToken: localizationKey("Dashboards (Activities Tab)"),
    descriptionToken: localizationKey(
      "The Activities tab at the system level provides a holistic view of the activities from devices across your entire NinjaOne environment.",
    ),
  },
  "207436983": {
    labelToken: localizationKey("Device and System Activity Notification Feed"),
    descriptionToken: localizationKey(
      "This guide will walk you through the Activity Feed in NinjaOne and all the different areas it derives data from.",
    ),
  },
  "22291470699661": {
    labelToken: localizationKey("NinjaOne Remote Access and Tools"),
    descriptionToken: localizationKey("View all articles related to Dashboards"),
    isSection: true,
  },
  "6921324974861": {
    labelToken: localizationKey("NinjaOne Documentation: Knowledge Base (BETA)"),
    descriptionToken: localizationKey(
      "This section provides an overview of how you can interact with the Knowledge Base tab on the system dashboard.",
    ),
    videoTimestamp: "2:43",
  },
  "207436993#01H26M1DX19T64GKZ0A0MQ0DJK": {
    labelToken: localizationKey("Dashboards (Organization Dashboard Breakdown)"),
    descriptionToken: localizationKey(
      "The Overview tab on the organization dashboard provides a holistic view for all the devices under a specific customer/organization.",
    ),
  },
  "360016311651": {
    labelToken: localizationKey("Organizations: Locations"),
    descriptionToken: localizationKey(
      "Locations can be used as a way to further sort the devices under an organization—some examples include when an organization has more than one office location, department, floor, etc. where devices may be located.",
    ),
  },
  "15287965524109": {
    labelToken: localizationKey("Device Search Grid"),
    descriptionToken: localizationKey(
      "Access the Device Search grid by clicking Devices in the left navigation pane on your NinjaOne console.",
    ),
    videoTimestamp: "1:51",
  },
  "4405157737101": {
    labelToken: localizationKey("Managing Software with NinjaRMM"),
    descriptionToken: localizationKey(
      "This guide provides an overview of the software management functionality available in NinjaRMM and step-by-step instructions for the various methods to install and uninstall applications and enforce software compliance.",
    ),
  },
  "207436993#01H26RHS59YY6FTY12V2WNYH04": {
    labelToken: localizationKey("Dashboards (Activities Tab)"),
    descriptionToken: localizationKey(
      "The Activities tab is also available at the device level, which provides a view of the activities from just that specific device.",
    ),
  },
  "6344435230477": {
    labelToken: localizationKey("Device Overview and Actions"),
    descriptionToken: localizationKey(
      "This guide breaks down the different sections of the Overview tab on a device dashboard, as well as what you can expect to see in these sections.",
    ),
  },
  "360051670071": {
    labelToken: localizationKey("Feature / Application Availability by Device Type"),
    descriptionToken: localizationKey(
      "The following table outlines the integrations/features available between NinjaOne's Windows, Mac, and Linux agent devices.",
    ),
  },
  "360030662192": {
    labelToken: localizationKey("Remote Tools"),
    descriptionToken: localizationKey(
      "Remote tools allow you to access and manage different aspects of a device without having to access the machine itself or interrupt the end user.",
    ),
    videoTimestamp: "3:27",
  },
  "18601842971789": {
    labelToken: localizationKey("Custom Fields by Type and Functionality"),
    descriptionToken: localizationKey(
      "For instructions on how to set up Custom Fields, please see Custom Fields Configuration: Device Role Fields.",
    ),
  },
  "4402354243981": {
    labelToken: localizationKey("FAQs About Custom Fields"),
    descriptionToken: localizationKey("Is it possible to read data from a custom field attachment through a script?"),
  },
  "360056423691": {
    labelToken: localizationKey("NinjaOne Ticketing: Setup and Configuration"),
    descriptionToken: localizationKey(
      "NinjaOne Ticketing allows users of NinjaOne to be able to create, track, and resolve tickets without the need for third party software.",
    ),
    videoTimestamp: "2:17",
  },
  "4439200196237": {
    labelToken: localizationKey("NinjaOne Ticketing: FAQ"),
    descriptionToken: localizationKey(
      "If a ticket is created by a triggered condition, does it automatically close when the condition is no longer met?",
    ),
  },
  "360004175091": {
    labelToken: localizationKey("Administration"),
    descriptionToken: localizationKey("View all articles related to Administration"),
    isSection: true,
  },
  "213529286": {
    labelToken: localizationKey("Time Zone Settings"),
    descriptionToken: localizationKey("Click Administration in the left navigation pane and select General."),
    videoTimestamp: "1:46",
  },
  "360021397911": {
    labelToken: localizationKey("Branding: Website"),
    descriptionToken: localizationKey(
      "Configuring a branded website under the Branding tab allows you to create a custom-branded version of your NinjaOne portal for your NinjaOne technicians and end users.",
    ),
    videoTimestamp: "2:09",
  },
  "360021149472": {
    labelToken: localizationKey("FAQs About Branding"),
    descriptionToken: localizationKey(
      "What size and file type can my systray icon image be? Your systray icon image must be between 16x16 and 128x128 pixels, and it must be a square image.",
    ),
  },
  "18209364145293": {
    labelToken: localizationKey("Policies: Activities"),
    descriptionToken: localizationKey(
      "Policy activities allow you to enhance and create alerting, visibility, and safety options around specific endpoint events.",
    ),
  },
  "360060061732": {
    labelToken: localizationKey("Windows Patch Management: Approve, Reject, Uninstall, Update"),
    descriptionToken: localizationKey(
      "When using the Control mode of NinjaOne's Windows patch management, patches can be preemptively approved/rejected before being picked up in a scan.",
    ),
  },
  "17610755368845": {
    labelToken: localizationKey("Patch Management"),
    descriptionToken: localizationKey("View all articles related to Patch Management"),
    isSection: true,
  },
  "115003718266": {
    labelToken: localizationKey("Windows Third-Party Software Patch Management"),
    descriptionToken: localizationKey(
      "Navigate to policy editor (Administration > Policies) for the policy you would like to enable software patch management and open the Software section.",
    ),
    videoTimestamp: "3:11",
  },
  "4409384586637": {
    labelToken: localizationKey("User Roles and Permissions"),
    descriptionToken: localizationKey(
      "NinjaOne's User Permissions allow administrators to create numerous permission templates, or roles, that can be assigned to both new and existing NinjaOne technicians and/or end users to determine their level of access to different features and functionality within the app.",
    ),
  },
  "360003242791": {
    labelToken: localizationKey("Create a Technician"),
    descriptionToken: localizationKey(
      "When you create a new NinjaOne account, the information you input at the time of account creation will automatically be applied to creating your first NinjaOne technician.",
    ),
    videoTimestamp: "2:33",
  },
  "4424709904781": {
    labelToken: localizationKey("User Permissions: FAQ"),
    descriptionToken: localizationKey("What permissions does a new technician have by default?"),
  },
  "15650871262605": {
    labelToken: localizationKey("Single Sign-On and Multi-Factor Authentication (SSO & MFA)"),
    descriptionToken: localizationKey("View all articles related to Single Sign-On and Multi-Factor Authentication"),
    isSection: true,
  },
  "360059862912": {
    labelToken: localizationKey("SAML/SSO"),
    descriptionToken: localizationKey(
      "This guide goes over setting up SAML with NinjaOne with examples for Microsoft Azure, Okta, OneLogin, LastPass, Duo, and Google Workspace.",
    ),
    videoTimestamp: "3:47",
  },
  "4424787875213": {
    labelToken: localizationKey("User Permissions: Permission Options"),
    descriptionToken: localizationKey(
      "This article covers information about each of the Permission options within NinjaOne's User Permissions.",
    ),
  },
  "360059027052": {
    labelToken: localizationKey("Active Directory User Management"),
    descriptionToken: localizationKey(
      "Active Directory User Management allows technicians to manage users within AD domain controllers from directly within the NinjaOne app.",
    ),
    videoTimestamp: "1:24",
  },
  "360035549512": {
    labelToken: localizationKey("End User Sharing: FAQ"),
    descriptionToken: localizationKey(
      "What permission level is required in order to add or edit end users? All system administrators have the ability to configure and edit end users.",
    ),
  },
  "207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889": {
    labelToken: localizationKey("NinjaOne Onboarding Guide (Apps)"),
    descriptionToken: localizationKey(
      "The Apps tab allows you to set up any of the integrations that NinjaOne has to offer to increase your productivity and maximize your business potential.",
    ),
  },
  "360004175031": {
    labelToken: localizationKey("Integrations / Third-Party Apps"),
    descriptionToken: localizationKey("View all articles related to Integrations / Third-Party Apps"),
    isSection: true,
  },
  "4403617211277": {
    labelToken: localizationKey("API OAuth Token Configuration"),
    descriptionToken: localizationKey(
      "The NinjaRMM Public API uses OAuth2 to authenticate requests. OAuth 2.0 allows applications to obtain access to NinjaRMM resources (such as monitoring information) that are made available via the API.",
    ),
  },
  "214971106": {
    labelToken: localizationKey("RepairShopr: Integration Guide and API Key"),
    descriptionToken: localizationKey(
      "The following guide goes over how to set up the RepairShopr integration with NinjaOne. You must have an existing account with RepairShopr to proceed with the integration.",
    ),
  },
  "4567434594573": {
    labelToken: localizationKey("Ticketing"),
    descriptionToken: localizationKey("View all articles related to Ticketing"),
    isSection: true,
  },
  "360009961552": {
    labelToken: localizationKey("Backup (Data Protection)"),
    descriptionToken: localizationKey("View all articles related to Backup (Data Protection)"),
    isSection: true,
  },
  "360045232051": {
    labelToken: localizationKey("NinjaOne Backup: Setup Guide"),
    descriptionToken: localizationKey(
      "This guide walks you through the initial setup process for NinjaOne's Backup tool.",
    ),
    videoTimestamp: "1:41",
  },
  "17687466337677": {
    labelToken: localizationKey("NinjaOne Documentation Tool"),
    descriptionToken: localizationKey(
      "Document, standardize, and use contextual information about your managed IT environments directly in NinjaOne. Manage your global knowledge base documents",
    ),
    isSection: true,
  },
  "18030982761357": {
    labelToken: localizationKey("NinjaOne Documentation Tool Configuration"),
    descriptionToken: localizationKey(
      "This guide provides instruction for enabling and setting up the Documentation app in the NinjaOne console.",
    ),
  },
  "360056410972": {
    labelToken: localizationKey("NinjaOne Remote: Set up, Download and Install"),
    descriptionToken: localizationKey("Support your endpoints with NinjaOne's native remote access tool."),
    videoTimestamp: "2:32",
  },
  "33246900353037": {
    labelToken: localizationKey("Connecting in Background mode"),
    descriptionToken: localizationKey("Background mode allows technicians to connect to devices without disrupting the user."),
  },
  "17900463685389": {
    labelToken: localizationKey("Mobile Device Management (MDM) FAQ"),
    descriptionToken: localizationKey(
      "If I change the passcode policy, when will the user be prompted to update the password?",
    ),
  },
  "4406766167821": {
    labelToken: localizationKey("Notification Channels"),
    descriptionToken: localizationKey(
      "Notifications that are set up for the above channels and policy activities can be scheduled or canceled for devices or end users under an organization.",
    ),
    videoTimestamp: "3:15",
  },
  "360035953551": {
    labelToken: localizationKey("Device Roles"),
    descriptionToken: localizationKey(
      "Device roles allow you to create your own custom roles for devices, which then will work hand-in-hand with policy assignment.",
    ),
    videoTimestamp: "1:50",
  },
  "360016094512": {
    labelToken: localizationKey("Search and Grouping"),
    descriptionToken: localizationKey(
      "The search and grouping functionalities can be used by technicians with the appropriate permissions to filter your devices based on certain criteria.",
    ),
    videoTimestamp: "1:39",
  },
  "360050975492": {
    labelToken: localizationKey("Health Statuses"),
    descriptionToken: localizationKey(
      "NinjaOne offers the ability to customize the health statuses associated with a number of device events.",
    ),
    videoTimestamp: "1:25",
  },
  "30624563717133": {
    labelToken: localizationKey("Cache servers"),
    descriptionToken: localizationKey("Create and manage system wide cache servers"),
  },
  "207273133#h_01HDKN10W1G7WQ9CVQVAX9KCED": {
    labelToken: localizationKey("NinjaOne Onboarding Guide (Library)"),
    descriptionToken: localizationKey("View and use the download links for software within NinjaOne."),
  },
  "4409813444749": {
    labelToken: localizationKey("NinjaOne Backup: Image Restores"),
    descriptionToken: localizationKey(
      "The first step to perform an image restore is to download the Image Restore Manager.",
    ),
    videoTimestamp: "2:55",
  },
  "4426831669261": {
    labelToken: localizationKey("NMS: Custom SNMP"),
    descriptionToken: localizationKey(
      "Custom SNMP (Simple Network Monitoring Protocol) allows users to add custom OIDs (Object Identifiers) to use with NMS (Network Monitoring Services).",
    ),
  },
  "7982786688525": {
    labelToken: localizationKey("NMS: Troubleshooting Custom SNMP (BETA)"),
    descriptionToken: localizationKey(
      "Please see the following for some recommended troubleshooting steps in the case that you encounter an issue with Custom SNMP (BETA).",
    ),
  },
  "360016094492": {
    labelToken: localizationKey("Automation Library (Scripting)"),
    descriptionToken: localizationKey(
      "The Automation Library serves as a central script repository that is agnostic to policies.",
    ),
    videoTimestamp: "3:40",
  },
  "360004171232": {
    labelToken: localizationKey("Scripting/Automation"),
    descriptionToken: localizationKey("View all articles related to Scripting/Automation"),
    isSection: true,
  },
  "360051694271": {
    labelToken: localizationKey("Organizations: Creating and Editing Organizations"),
    descriptionToken: localizationKey(
      "Organizations allow you to create and configure the different customers/departments/etc. that you wish to onboard and manage with NinjaOne;",
    ),
    videoTimestamp: "1:56",
  },
  "360003478432": {
    labelToken: localizationKey("Policies: Create a New Policy"),
    descriptionToken: localizationKey(
      "A policy is a collection of conditions, actions, and various settings like Windows patch management and antivirus, which can be collectively applied to a group of devices that are members of that policy.",
    ),
    videoTimestamp: "1:44",
  },
  "360046348752": {
    labelToken: localizationKey("How to assign default policies to an organization"),
    descriptionToken: localizationKey(
      "To get started, navigate to the editor for an organization and select the Policies tab.",
    ),
    videoTimestamp: "0:54",
  },
  "115004434683": {
    labelToken: localizationKey("Policies: View Devices Under a Policy"),
    descriptionToken: localizationKey(
      "Navigate to the Devices tab in the left navigation pane and open the Additional Filters+. Select Policies.",
    ),
  },
  "206864826": {
    labelToken: localizationKey("Policies: Condition Configuration"),
    descriptionToken: localizationKey(
      "Conditions are set at the policy level and allow IT administrators to proactively monitor their systems with minimal configuration.",
    ),
    videoTimestamp: "2:25",
  },
  "360016094572": {
    labelToken: localizationKey("Scheduled Tasks"),
    descriptionToken: localizationKey(
      "Scheduled Tasks allow you to run automations on targeted organizations, devices, or groups on a specified schedule.",
    ),
    videoTimestamp: "1:58",
  },
  "360019817832": {
    labelToken: localizationKey("Scheduled Tasks: FAQ"),
    descriptionToken: localizationKey("What is the difference between Scheduled Scripts and Scheduled Tasks?"),
  },
  "9529843681549": {
    labelToken: localizationKey("NinjaOne Remote: QuickConnect (BETA)"),
    descriptionToken: localizationKey(
      "QuickConnect is a feature add-on for NinjaOne Remote designed for ad hoc support and rapid deployment.",
    ),
  },
  "16908567015565": {
    labelToken: localizationKey("QuickConnect FAQ (BETA)"),
    descriptionToken: localizationKey(
      "Will QuickConnect support headless system connections when 'ask for confirmation' is disabled?",
    ),
  },
  "360025675292": {
    labelToken: localizationKey("Reporting: Types of Reports"),
    descriptionToken: localizationKey(
      "The following provides an overview of each type of report that is available in NinjaOne.",
    ),
  },
  "360045092571": {
    labelToken: localizationKey("Reporting: FAQ"),
    descriptionToken: localizationKey(
      "Can reports be copied or duplicated? Yes—existing reports can be copied and/or duplicated. Here are instructions.",
    ),
  },
  "9782317575821": {
    labelToken: localizationKey("Report Library"),
    descriptionToken: localizationKey(
      "The new Report Library adds a Reporting tab to the left side navigation pane in the NinjaOne console.",
    ),
    videoTimestamp: "4:18",
  },
  "4436263017357": {
    labelToken: localizationKey("NinjaOne Ticketing: Create and Assign"),
    descriptionToken: localizationKey(
      "As a technician with appropriate permission levels, there are several places in the Ninja webapp where new tickets can be manually created, such as:",
    ),
    videoTimestamp: "2:33",
  },
  "10097536945805": {
    labelToken: localizationKey("NinjaOne Ticketing: Ticket Handling"),
    descriptionToken: localizationKey("Access your ticket boards from the left navigation pane under Administration."),
    videoTimestamp: "3:01",
  },
  "360051694271#01H1YHMDSMF05FRM0ZCZDKFGHE": {
    labelToken: localizationKey("Organizations: Creating and Editing Organizations (Configuring an Organization)"),
    descriptionToken: localizationKey(
      "In the organization editor, you'll notice at least 12 tabs in the left navigation pane (see table below).",
    ),
  },
  "360046961412": {
    labelToken: localizationKey("NinjaOne Backup: File/Folder Backup Plans"),
    descriptionToken: localizationKey(
      "This guide goes over the options for configuring file/folder backup plans for NinjaOne Backup. File/folder backup plans can be configured for both Windows and Mac devices.",
    ),
    videoTimestamp: "3:29",
  },
  "360061732472": {
    labelToken: localizationKey("NinjaOne Backup: Image Backup Plans"),
    descriptionToken: localizationKey(
      "In order to run image backups, NinjaOne Image Backup must be enabled per the steps in NinjaOne Backup: Setup Guide.",
    ),
    videoTimestamp: "2:17",
  },
  "360016094532": {
    labelToken: localizationKey("Credential Exchange"),
    descriptionToken: localizationKey(
      "Credential Exchange allows partners to securely pass admin credentials to devices for use in actions such as running scripts, patching, and RDP access.",
    ),
    videoTimestamp: "1:31",
  },
  "4409813341453": {
    labelToken: localizationKey("NinjaOne Backup: Network Storage"),
    descriptionToken: localizationKey("Network attached storage (NAS) can be configured to run local backups."),
    videoTimestamp: "1:49",
  },
  "360056174332": {
    labelToken: localizationKey("How to use patching with a WSUS server"),
    descriptionToken: localizationKey(
      "To get started, navigate to the editor for an organization, select Patching , and then select Edit next to WSUS Settings.",
    ),
    videoTimestamp: "1:01",
  },
  "4405173636237": {
    labelToken: localizationKey("Active Directory Discovery and Deployment"),
    descriptionToken: localizationKey(
      "Active Directory Discovery and Deployment allows technicians to use Active Directory to automatically deploy the NinjaOne agent to devices that are a part of a domain.",
    ),
    videoTimestamp: "4:07",
  },
  "360026622892": {
    labelToken: localizationKey("Node Approval"),
    descriptionToken: localizationKey(
      "Node approval gives you the option to require incoming devices be approved in order for them to be monitored and managed by the Ninja agent and its integrated components, as well as for them to have access to the secure credential store.",
    ),
    videoTimestamp: "1:25",
  },
  "360057099852": {
    labelToken: localizationKey("Hyper-V Monitoring"),
    descriptionToken: localizationKey(
      "Hyper-V Monitoring allows users to easily manage Hyper-V virtual machines with just a few configuration steps.",
    ),
    videoTimestamp: "1:40",
  },
  "360031007591": {
    labelToken: localizationKey("VMware Monitoring"),
    descriptionToken: localizationKey(
      "With VMware Monitoring, you can easily manage VMware virtual machines with just a few configuration steps.",
    ),
    videoTimestamp: "1:22",
  },
  "360003478432#h_01HD4SRW29HT6CPC5SEZNB8SNR": {
    labelToken: localizationKey("Policies: Create a New Policy (Overview of the Policy Management Table)"),
    descriptionToken: localizationKey(
      "Once you have a policy created the policy management table in Administration provides the following details:",
    ),
  },
  "209612533": {
    labelToken: localizationKey("Policies: Scheduled Automations"),
    descriptionToken: localizationKey(
      "Scheduled automations allow you to run actions from your Automation Library on the endpoints (i.e., devices) under a policy.",
    ),
    videoTimestamp: "1:35",
  },
  "15628524601485": {
    labelToken: localizationKey("Virtual Monitoring"),
    descriptionToken: localizationKey("View all articles related to Virtual Monitoring"),
    isSection: true,
  },
  "19075396746893": {
    labelToken: localizationKey("MDM: Android Policy Management"),
    descriptionToken: localizationKey(
      "Android policy functionality depends on how the device is enrolled at the time that it is added to the NinjaOne console",
    ),
  },
  "17916102408205": {
    labelToken: localizationKey("MDM: Android Mobile Device Enrollment"),
    descriptionToken: localizationKey(
      "Prior to adding the devices to NinjaOne and enrolling them, you must first enable this option in the Administration section using the instructions below.",
    ),
    videoTimestamp: "1:36",
  },
  "20070381996301": {
    labelToken: localizationKey("MDM: Adding an Android Mobile Device to NinjaOne"),
    descriptionToken: localizationKey(
      "After you enable enrollment for Android you may add the device to NinjaOne and complete the process on the physical device. You cannot complete this part of the process until you complete the device enrollment.",
    ),
    videoTimestamp: "1:19",
  },
  "19675912129165": {
    labelToken: localizationKey("MDM: Apple Policy Management"),
    descriptionToken: localizationKey("MDM Policies determine how users are able to use company owned devices."),
  },
  "20028476165389": {
    labelToken: localizationKey("MDM: Adding an Apple Mobile Device to NinjaOne"),
    descriptionToken: localizationKey(
      "After you enable enrollment for Apple APNs (the Apple Automated Device Enrollment [ADE] syncs automatically) you may add the device to NinjaOne and complete the enrollment process on the device itself.",
    ),
    videoTimestamp: "1:00",
  },
  "17899554040845": {
    labelToken: localizationKey("MDM: Apple Enrollment with APNs"),
    descriptionToken: localizationKey(
      "This guide provides the NinjaOne Mobile Device Management (MDM) enrollment process for Apple mobile devices, including iPads.",
    ),
    videoTimestamp: "1:47",
  },
  "20070760920077": {
    labelToken: localizationKey("MDM: Apple Enrollment Sync with ADE (Optional)"),
    descriptionToken: localizationKey(
      "Automated Device Enrollment (ADE) is an optional feature within the NinjaOne MDM product that uses Apple Business Manager (ABM) to automatically sync Apple devices with NinjaOne.",
    ),
    videoTimestamp: "2:03",
  },
  "5568986625165": {
    labelToken: localizationKey("How to use Roles to manage technician levels"),
    descriptionToken: localizationKey(
      "Technicians can be assigned to multiple roles. This allows for creating efficient methods of assigning unique permissions like technician levels.",
    ),
    videoTimestamp: "1:32",
  },
  "4436245047437": {
    labelToken: localizationKey("NinjaOne Ticketing: Technician and User Permissions"),
    descriptionToken: localizationKey(
      "NinjaOne Ticketing allows users of NinjaOne to be able to create, track, and resolve tickets without the need for third party software.",
    ),
  },
  "4416950739597": {
    labelToken: localizationKey("How to view what IP address a technician logged in from"),
    descriptionToken: localizationKey(
      "To get started, navigate to the Activities dashboard. Filter for Technician Logged In and review or export the displayed IP address information.",
    ),
    videoTimestamp: "0:36",
  },
  "360035894551": {
    labelToken: localizationKey("End User Sharing"),
    descriptionToken: localizationKey(
      "End user sharing allows you to easily grant end users access to their own machines through NinjaOne without having to create them a full NinjaOne account.",
    ),
    videoTimestamp: "3:55",
  },
  "360018921951": {
    labelToken: localizationKey("Branding: Environment Variables via Systray Icon"),
    descriptionToken: localizationKey(
      "The following is a list of environment variables that can be requested for Windows devices via the systray icon's help request form.",
    ),
  },
  "115001717823": {
    labelToken: localizationKey("Branding: Systray Icon"),
    descriptionToken: localizationKey(
      "NinjaOne allows you to create and deploy a fully customizable systray icon to all or some of your organizations.",
    ),
    videoTimestamp: "3:01",
  },
  "20708139639565": {
    labelToken: localizationKey("NinjaOne Public API Operations"),
    descriptionToken: localizationKey(
      "NinjaOne system administrators can download, upload, create, and edit any files that are stored in NinjaOne Documentation, either in custom fields, apps and services, or related items, to easily store and access stored information in NinjaOne Documentation.",
    ),
  },
  "207027356": {
    labelToken: localizationKey("Cloud Monitors"),
    descriptionToken: localizationKey("This guide walks you through the cloud monitors available in NinjaOne."),
    videoTimestamp: "1:20",
  },
  "207991486": {
    labelToken: localizationKey("What type of scripts does NinjaOne support?"),
    descriptionToken: localizationKey("NinjaOne currently supports the following in 32-bit and 64-bit:"),
  },
  "19027076144269": {
    labelToken: localizationKey("Automation Library: Template Scripts"),
    descriptionToken: localizationKey(
      "Refer to the table below for definitions of acronyms used in NinjaOne's template scripts.",
    ),
  },
  "22328250934669": {
    labelToken: localizationKey("Reading from or Writing to Custom Fields in Scripts"),
    descriptionToken: localizationKey(
      "For more detailed information about scripting and custom fields, please see Custom Fields and Documentation: CLI and Scripting.",
    ),
  },
  "17765447097357": {
    labelToken: localizationKey("Script Variable Types"),
    descriptionToken: localizationKey(
      "When creating scripts in the Automation Library, you have the option to add multiple types of script variables which affect the interactive form that is present when a script is run.",
    ),
  },
  "360031007611": {
    labelToken: localizationKey("NinjaOne Mobile App"),
    descriptionToken: localizationKey(
      "NinjaOne's mobile app allows you to access data from your NinjaOne environment as well as many integrated features straight from your iOS or Android device.",
    ),
    videoTimestamp: "3:58",
  },
  "11451699609101": {
    labelToken: localizationKey("What is Mobile Device Management (MDM)"),
    descriptionToken: localizationKey(
      "Mobile Device Management (MDM) is software that allows for the automation, control, and implementation of administrative policies on various mobile devices.",
    ),
  },
  "360030662132": {
    labelToken: localizationKey("VMware Monitoring: FAQ"),
    descriptionToken: localizationKey(
      "What datastore types are supported by VMware virtualization monitoring in NinjaOne?",
    ),
  },
  "207021723": {
    labelToken: localizationKey("NMS: Setup"),
    descriptionToken: localizationKey("Please review the NMS System Requirements prior to installing the NMS agent."),
    videoTimestamp: "2:46",
  },
  "360029544711": {
    labelToken: localizationKey("NMS: Device Roles and Policies"),
    descriptionToken: localizationKey(
      "Devices that are discovered by the NMS agent can be assigned a device role, which will dictate the policy that it is managed by.",
    ),
  },
  "360004175071": {
    labelToken: localizationKey("NMS and SNMP"),
    descriptionToken: localizationKey("View all articles related to NMS and SNMP"),
    isSection: true,
  },
  "207437163": {
    labelToken: localizationKey("ShadowProtect/ImageManager: Integration Guide"),
    descriptionToken: localizationKey(
      "This guide will walk you through the process of integrating StorageCraft (ShadowProtect and ImageManager) with NinjaOne.",
    ),
  },
  "16363919566477": {
    labelToken: localizationKey("How to restore a device from an image backup"),
    descriptionToken: localizationKey(
      "A device can be restored from an image backup once an image backup has been created and Ninja's Image Restore Manager has been downloaded on a device.",
    ),
    videoTimestamp: "2:11",
  },
  "207437143": {
    labelToken: localizationKey("Windows Patch Management: Setup"),
    descriptionToken: localizationKey(
      "This guide provides instruction for configuring Windows patch management settings within NinjaOne.",
    ),
    videoTimestamp: "3:23",
  },
  "212462043": {
    labelToken: localizationKey("Multi-Factor (2-Factor) Authentication"),
    descriptionToken: localizationKey(
      "Multi-factor authentication (MFA) serves as an extra layer of security for your NinjaOne account and is required for all NinjaOne users both at login and when performing administrative actions.",
    ),
  },
  "360003547072": {
    labelToken: localizationKey("Cloudberry: Integration Guide"),
    descriptionToken: localizationKey(
      "All Cloudberry backup plans currently run block-level backups by default. This lets you backup only modified parts of files instead of running a full backup every time a file is changed.",
    ),
  },
  "360048873071": {
    labelToken: localizationKey("ConnectWise Control (ScreenConnect): Integration Guide"),
    descriptionToken: localizationKey(
      "The ConnectWise Control (ScreenConnect) integration allows you to seamlessly initiate remote connections via your existing ConnectWise Control account without having to leave your NinjaOne console.",
    ),
    videoTimestamp: "1:20",
  },
  "360000696692": {
    labelToken: localizationKey("ConnectWise Manage: Integration Guide"),
    descriptionToken: localizationKey(
      "This guide provides instruction for setting up the ConnectWise Manage integration. ConnectWise Manage (also known as ConnectWise PSA), is a ticketing and project management software that is separate from ConnectWise Control (ScreenConnect), which NinjaOne also offers as an integrated tool for remote access and support.",
    ),
    videoTimestamp: "2:27",
  },
  "28793410000653": {
    labelToken: localizationKey("NinjaOne PSA: Setup Guide"),
    descriptionToken: localizationKey("This guide provides instructions for setting up and using NinjaOne PSA"),
  },
  "206623446": {
    labelToken: localizationKey("Autotask: Integration Guide"),
    descriptionToken: localizationKey(
      "This guide provides instruction for setting up the Autotask integration with NinjaOne.",
    ),
    videoTimestamp: "3:04",
  },
  "360004037291": {
    labelToken: localizationKey("Accelo: Setup with a new account"),
    descriptionToken: localizationKey(
      "This guide goes over how to set up the Accelo integration if you don't yet have an existing account with Accelo.",
    ),
    videoTimestamp: "1:18",
  },
  "360035555612": {
    labelToken: localizationKey("TeamViewer: Integration Guide (Automatic)"),
    descriptionToken: localizationKey(
      "The Automatic TeamViewer integration allows you to seamlessly initiate remote connections via TeamViewer without having to leave your NinjaOne console.",
    ),
    videoTimestamp: "1:47",
  },
  "115002744666": {
    labelToken: localizationKey("Splashtop: Integration Guide"),
    descriptionToken: localizationKey(
      "NinjaOne supports Splashtop version 3.6.6.0, which also includes support for FIPS-compliant cryptographic modules. For more information, please refer to the following Splashtop resource: Splashtop FIPS Compliance - Support.",
    ),
    videoTimestamp: "1:46",
  },
  "214971166": {
    labelToken: localizationKey("ITGlue: Setup Guide"),
    descriptionToken: localizationKey("This guide walks you through how to set up the ITGlue integration. "),
  },
  "115002716623": {
    labelToken: localizationKey("Webroot: Setup with a New Account"),
    descriptionToken: localizationKey(
      "This documentation covers setting up a brand-new trial and assumes that you do not currently have a Webroot account.",
    ),
    videoTimestamp: "1:58",
  },
  "10220618391565": {
    labelToken: localizationKey("SentinelOne: Integration Guide"),
    descriptionToken: localizationKey(
      "SentinelOne is a comprehensive enterprise security platform that provides virus/threat detection, hunting, and response features that enable organizations to discover vulnerabilities and protect IT operations.",
    ),
    videoTimestamp: "2:23",
  },
  "16288676347149": {
    labelToken: localizationKey("CrowdStrike: Integration Guide (BETA)"),
    descriptionToken: localizationKey(
      "NinjaOne is releasing an integration with CrowdStrike Falcon (FalconInsight XDR and Falcon Prevent), an integrated cloud-based endpoint detection and response (EDR) and endpoint protection platform (EPP) solution.",
    ),
  },
  "360060920631": {
    labelToken: localizationKey("Custom Fields Configuration: Device Role Fields"),
    descriptionToken: localizationKey(
      "For documentation providing examples for different use cases, please see Custom Field Use Cases.",
    ),
    videoTimestamp: "4:27",
  },
  "207273133": {
    labelToken: localizationKey("NinjaOne Onboarding Guide"),
    descriptionToken: localizationKey(
      "This guide is designed to make for a smooth and efficient onboarding process within NinjaOne.",
    ),
    videoTimestamp: "52:52",
  },
  "207077733": {
    labelToken: localizationKey("Mass Deployment of NinjaOne Installer"),
    descriptionToken: localizationKey(
      "Due to the fact that NinjaOne provides a MSI version of the installer, there are quite a few options to mass deploy the NinjaOne Agent for Windows devices. The following are a few popular methods.",
    ),
  },
  "23495246509325": {
    labelToken: localizationKey("Policies: Compound Conditions (BETA)"),
    descriptionToken: localizationKey(
      "Compound Conditions allow administrators to combine multiple conditions for more precise device targeting and monitoring.",
    ),
  },
  "207436993#01H26RF5NCTCYPMDE5KSPMGJZV": {
    labelToken: localizationKey("Dashboards (Activities Tab)"),
    descriptionToken: localizationKey(
      "The Activities tab at the organization level provides a view of the activities from devices across the specific organization in question.",
    ),
  },
  "23055075026701": {
    labelToken: localizationKey("ServiceNow Integration"),
    descriptionToken: localizationKey(
      "ServiceNow uses the ITSM (information technology service management) and SPM (service project management) technology platforms to enhance how work is done and has the ability to accelerate security operations utilizing their own security solution that provides device alerts and automation.",
    ),
  },
  "22965144750989": {
    labelToken: localizationKey("WinGet (Windows Package Manager) Integration Guide (BETA)"),
    descriptionToken: localizationKey(
      "WinGet is the Windows Package Manager command line tool. Within the NinjaOne console, the WinGet integration is used to install and upgrade applications on Windows 10+ devices and configure patch settings for selected software.",
    ),
  },
  "115003138691": {
    labelToken: localizationKey("Bitdefender GravityZone"),
    descriptionToken: localizationKey(
      "This documentation provides step to install Bitdefender GravityZone via the NinjaOne console.",
    ),
  },
  "4425077024653": {
    labelToken: localizationKey("Bitdefender GravityZone: Integration Guide"),
    descriptionToken: localizationKey(
      "Bitdefender is a state-of-the-art security solution that can protect your customer endpoints from multiple angles.",
    ),
  },
  "20537484651277": {
    labelToken: localizationKey("Bitdefender GravityZone: FAQ (BETA)"),
    descriptionToken: localizationKey("Are add-on features included in NinjaOne GravityZone Integration packages?"),
  },
  "360061218431": {
    labelToken: localizationKey("NinjaOne Documentation: Apps & Services"),
    descriptionToken: localizationKey(
      "This section shows you how to add documents to an organization using the templates created or imported to the Documentation app in Administration.",
    ),
  },
  "360051137451": {
    labelToken: localizationKey("Enabling maintenance mode"),
    descriptionToken: localizationKey(
      "Maintenance mode allows you to suppress selected features, such as patching and alerts, for a device or devices for a designated window of time.",
    ),
    videoTimestamp: "2:16",
  },
  "10498098542477": {
    labelToken: localizationKey("macOS Patch Management: Setup"),
    descriptionToken: localizationKey(
      "This guide provides instruction for configuring MacOS patch management settings within NinjaOne.",
    ),
  },
  "26331558954509": {
    labelToken: localizationKey("Vulnerability Importer: Setup"),
    descriptionToken: localizationKey("Import scan data from third-party vulnerability management software."),
  },
  "26331594477197": {
    labelToken: localizationKey("Rapid7: Setup"),
    descriptionToken: localizationKey(
      "Configure Rapid7 scan integration or import vulnerability CSV data into the NinjaOne console.",
    ),
  },
  "26331660240653": {
    labelToken: localizationKey("Qualys: Setup"),
    descriptionToken: localizationKey(
      "Configure Qualys scan integration or import vulnerability CSV data into the NinjaOne console.",
    ),
  },
  "26331671031053": {
    labelToken: localizationKey("Tenable: Setup"),
    descriptionToken: localizationKey(
      "Configure Tenable scan integration or import vulnerability CSV data into the NinjaOne console.",
    ),
  },
  "27380281424397": {
    labelToken: localizationKey("Patch Intelligence AI"),
    descriptionToken: localizationKey("AI-powered features to improve the NinjaOne technician experience."),
  },
  "27316499175309": {
    labelToken: localizationKey("Warranty Tracking notifications"),
    descriptionToken: localizationKey(
      "Automatically update Warranty information on devices for all supported vendors.",
    ),
  },
  "27491852723725": {
    labelToken: localizationKey("CrowdStrike Spotlight: Setup"),
    descriptionToken: localizationKey(
      "Configure CrowdStrike Spotlight scan integration or import vulnerability CSV data into the NinjaOne console.",
    ),
  },
}
