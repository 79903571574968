import React from "react"
import { connect } from "react-redux"
import { compose, map, reduce, assoc, toPairs } from "ramda"
import Tooltip from "js/includes/components/Tooltip"
import { localized, localizationKey, sortByFieldNameCaseInsensitive } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

const getMapFromList = reduce((acc, { id, name }) => assoc(id, name, acc), {})

const getListFromMap = compose(
  map(([id, name]) => ({ id: parseInt(id, 10), name })),
  toPairs,
)

const AutotaskAccountCriteria = ({ types, accountCriteria, setSelectedTypes }) => {
  return (
    <Box display="flex" margin={[0, "1px", 4, 4]} flexDirection="column">
      <h4>
        <span>{localized("Account Type Filter")}</span>
        &nbsp;
        <Tooltip
          token={localizationKey(
            "Please select your PSA account filters to limit the accounts that are available for mapping.",
          )}
        />
      </h4>

      <SearchableDropDown
        {...{
          width: "100%",
          minHeight: "32px",
          borderRadius: "2px",
          options: sortByFieldNameCaseInsensitive("name", "ASC", accountCriteria),
          value: getListFromMap(types),
          rowHeight: 30,
          isMulti: true,
          searchableKey: "name",
          valueSelectorKey: "id",
          openOnFocus: true,
          keepInView: false,
          onSelect: selection => {
            compose(setSelectedTypes, getMapFromList)(selection || [])
          },
        }}
      />
    </Box>
  )
}

export default connect(
  ({ psa }) => ({
    types: psa.Autotask.settings.companyFilterMap.types,
    accountCriteria: psa.Autotask.settings.accountCriteria,
  }),
  {
    setSelectedTypes: types => ({ type: "SET_AT_ACCOUNT_FILTER_TYPES", types }),
  },
)(AutotaskAccountCriteria)
