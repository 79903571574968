import { applySpec, identity, map } from "ramda"
import { useCallback, useMemo } from "react"

import styled from "@emotion/styled"
import { Body, Select, Stepper } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"

import { isNilOrEmpty, localized } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { DEFAULT_HOURS_SINCE_VALUE } from "js/includes/ticketing/TriggerEditor/constants"
import { getTimeSinceDisplayErrorData, MAX_TIME_SINCE_HOURS } from "js/includes/ticketing/TriggerEditor/utils"

const HOUR_INDEX = 0
const MINUTE_INDEX = 1

const minuteOptions = map(
  applySpec({
    value: identity,
    labelText: identity,
  }),
)(["0", "15", "30", "45"])

const MINUTES_SELECTOR_MIN_WIDTH = 90

const Container = styled.div`
  display: grid;
  gap: ${tokens.spacing[3]};
  grid-template-columns: minmax(0, 1fr) minmax(0, ${MINUTES_SELECTOR_MIN_WIDTH}px);
`

const FieldWrapper = ({ children, labelText }) => (
  <Box>
    {children}
    <Body color="colorTextWeakest" type="bodyXs" marginTop={tokens.spacing[1]}>
      {labelText}
    </Body>
  </Box>
)

export const TimeSinceEditor = ({ value, handleOnChange, errorMessage, ariaLabel }) => {
  const parsedValue = useMemo(() => value?.split(":") ?? DEFAULT_HOURS_SINCE_VALUE, [value])

  if (isNilOrEmpty(value)) {
    handleOnChange(DEFAULT_HOURS_SINCE_VALUE)
  }

  const handleChange = useCallback(
    (updatedValue, type) => {
      switch (type) {
        case "hour":
          handleOnChange([updatedValue, parsedValue[MINUTE_INDEX]].join(":"))
          break
        case "minute":
          handleOnChange([parsedValue[HOUR_INDEX], updatedValue].join(":"))
          break
        default:
          throw new Error(`Invalid type: "${type}"`)
      }
    },
    [parsedValue, handleOnChange],
  )

  const hoursText = localized("Hours")
  const minutesText = localized("Minutes")

  const displayErrorData = getTimeSinceDisplayErrorData(errorMessage)

  return (
    <Container aria-label={ariaLabel}>
      <FieldWrapper labelText={hoursText}>
        <Stepper
          ariaLabel={hoursText}
          value={+parsedValue[HOUR_INDEX]}
          min={0}
          max={MAX_TIME_SINCE_HOURS}
          onChange={hour => handleChange(hour, "hour")}
          {...(displayErrorData.hours && { error: errorMessage })}
        />
      </FieldWrapper>
      <FieldWrapper labelText={minutesText}>
        <Select
          triggerAriaLabel={minutesText}
          options={minuteOptions}
          value={parsedValue[MINUTE_INDEX]}
          onChange={minutes => handleChange(minutes, "minute")}
          triggerMinWidth={MINUTES_SELECTOR_MIN_WIDTH}
          listboxMinWidth={MINUTES_SELECTOR_MIN_WIDTH}
          {...(displayErrorData.minutes && { errorMessage })}
        />
      </FieldWrapper>
    </Container>
  )
}
