import { memo, useEffect, useMemo } from "react"
import { pluck } from "ramda"
import { connect } from "react-redux"

import tokens from "@ninjaone/tokens"
import { Select } from "@ninjaone/components"
import { Label } from "@ninjaone/components/src/Form/Label"
import { Box, Flex } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"
import {
  getRetriggerOptions,
  getSeverityOptions,
  getPriorityOptions,
  CREATE_NEW_TICKET_VALUE,
  APPEND_TO_EXISTENT_TICKET_VALUE,
} from "js/includes/ticketing/common/TicketEditorFields/options"
import TagsInput from "js/includes/ticketing/common/TagsInput"

import { mapStatusOptions, SelectLabelRenderer } from "./TicketCreate"

const ConditionRetrigger = memo(
  ({ readonly, validation, onChange, values, validateFormFields, tagsData, statusList }) => {
    const { retriggerStatus, retriggerTags, retriggerPriority, retriggerSeverity, createNewTicket } = values

    const { priorityOptions, severityOptions, statusOptions } = useMemo(() => {
      const useCurrent = { value: null, labelText: localized("Use Current") }

      return {
        statusOptions: mapStatusOptions(statusList),
        priorityOptions: [useCurrent, ...getPriorityOptions({ showNone: false, labelKey: "labelText" })],
        severityOptions: [useCurrent, ...getSeverityOptions({ showNone: false, labelKey: "labelText" })],
      }
    }, [statusList])

    useEffect(() => {
      createNewTicket && validateFormFields(["retriggerStatus"])
    }, [createNewTicket, validateFormFields])

    return (
      <Flex flexDirection="column" gap={tokens.spacing[2]} margin={`0 ${tokens.spacing[1]}`}>
        <Select
          labelId="condition-retrigger-action"
          labelText={localized("Action")}
          value={createNewTicket ? CREATE_NEW_TICKET_VALUE : APPEND_TO_EXISTENT_TICKET_VALUE}
          onChange={value => onChange("createNewTicket", value === CREATE_NEW_TICKET_VALUE)}
          options={getRetriggerOptions()}
          disabled={readonly}
        />

        {!createNewTicket && (
          <>
            <Select
              labelId="condition-retrigger-status"
              labelText={localized("Status")}
              value={retriggerStatus}
              onChange={value => onChange("retriggerStatus", value)}
              options={statusOptions}
              errorMessage={validation.message.retriggerStatus}
              required
              disabled={readonly}
            />

            <Box>
              <Label labelText={localized("Tags")} id="condition-retrigger-tags" forInputElement={false} />
              <TagsInput
                selected={pluck("value", retriggerTags || [])}
                disableEditor={readonly}
                onChange={selected => onChange("retriggerTags", selected)}
                tags={tagsData?.tags?.map(tag => ({ label: tag.name, value: tag.name }))}
                ariaInputAttributes={{ "aria-labelledby": "condition-retrigger-tags" }}
              />
            </Box>

            <Select
              labelId="condition-retrigger-priority"
              labelText={localized("Priority")}
              value={retriggerPriority}
              onChange={value => onChange("retriggerPriority", value)}
              options={priorityOptions}
              disabled={readonly}
              labelRenderer={() => <SelectLabelRenderer options={priorityOptions} value={retriggerPriority} />}
            />

            <Select
              labelId="condition-retrigger-severity"
              labelText={localized("Severity")}
              value={retriggerSeverity}
              onChange={value => onChange("retriggerSeverity", value)}
              options={severityOptions}
              disabled={readonly}
              labelRenderer={() => <SelectLabelRenderer options={severityOptions} value={retriggerSeverity} />}
            />
          </>
        )}
      </Flex>
    )
  },
)

export default connect(({ ticketing }) => ({ statusList: ticketing.status.list }))(ConditionRetrigger)
