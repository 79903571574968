import { memo, useState, useEffect } from "react"
import { localized, time } from "@ninjaone/webapp/src/js/includes/common/utils"
import Body from "../../Typography/Body"
import { getTimeDiffInMinutes } from "../utils"

const INTERVAL_TIME = 60000

export default memo(function LastUpdatedLabel({ isLoading, lastUpdated, onlyShowAfter5Minutes }) {
  const [timeDiffInMinutes, setTimeDiffInMinutes] = useState(null)
  const hidden = onlyShowAfter5Minutes && timeDiffInMinutes <= 5

  useEffect(() => {
    setTimeDiffInMinutes(getTimeDiffInMinutes(lastUpdated))

    const interval = setInterval(() => {
      setTimeDiffInMinutes(getTimeDiffInMinutes(lastUpdated))
    }, INTERVAL_TIME)

    return () => clearInterval(interval)
  }, [lastUpdated])

  return (
    !hidden && (
      <Body type="bodyXs" color={isLoading ? "colorTextDisabled" : "colorTextWeakest"}>
        {localized("Last refresh: {{time}}", { time: time(lastUpdated) })}
      </Body>
    )
  )
})
