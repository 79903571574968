import { TableNode } from "@lexical/table"
import { ImageNode } from "@ninjaone/components/src/WYSIWYG"

export const tableExport = [
  TableNode,
  editor => {
    const element = document.createElement("table")
    const className = editor._config.theme.table
    if (className) {
      element.className = className
    }
    element.style = "border-collapse: collapse; border-spacing: 0;"
    return { element }
  },
]

const imageExport = [
  ImageNode,
  (_, node) => {
    const element = document.createElement("img")

    element.setAttribute("data-cid", node.__cid)
    element.setAttribute("src", node.getSrc())
    element.setAttribute("width", node.getWidth().toString())
    element.setAttribute("height", node.getHeight().toString())
    element.setAttribute("max-width", "100%")

    return { element }
  },
]

export const htmlExport = new Map([tableExport, imageExport])
