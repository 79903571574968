import { showErrorMessage, localized } from "js/includes/common/utils"

const setDeviceSyncAttributes = attributes => ({
  type: "SET_CW_DEVICE_DATA_SYNC_ATTRIBUTES",
  attributes,
})

export default function deviceSyncAttributes(dispatch) {
  return {
    onDeviceSyncAttributesChange(attributes) {
      try {
        dispatch(setDeviceSyncAttributes(attributes))
      } catch (error) {
        showErrorMessage(localized("Error setting device sync attributes"))
        throw error
      }
    },
  }
}
