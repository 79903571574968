import React, { Component } from "react"
import { connect } from "react-redux"
import { prop, assoc, compose, evolve, map, toPairs, toString } from "ramda"
import Select from "react-select"
import Tooltip from "js/includes/components/Tooltip"
import { sortListByName } from "js/includes/configuration/psa/PsaSettingsEditor"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

class ConnectWiseAccountCriteria extends Component {
  prepareList(values = []) {
    return compose(sortListByName, map(evolve({ id: toString })))(values)
  }
  getListFromMap(values = []) {
    return map(([id, name]) => ({ id, name }), toPairs(values))
  }
  getMapFromList(values = []) {
    return values.reduce((acc, { id, name }) => assoc(id, name, acc), {})
  }
  render() {
    const {
      availableStatuses,
      availableTypes,
      selectedStatuses,
      selectedTypes,
      setSelectedStatuses,
      setSelectedTypes,
    } = this.props
    return (
      <div key="criteria" className="display-flex flex-column m-b">
        <h4 className="col-xs-12">
          {localized("Company Criteria")}
          &nbsp;
          <Tooltip
            token={localizationKey(
              "Please select your PSA company filters to limit the companies that are available for mapping.",
            )}
          />
        </h4>
        <div className="col-xs-12">
          <div className="row form-group display-flex">
            <label className="col-xs-2 display-flex align-items-center m-n">
              <span className="p-l">{localized("Types")}</span>
            </label>
            <div className="col-xs-10 no-padding">
              <Select
                isMulti
                closeMenuOnSelect={false}
                value={this.getListFromMap(selectedTypes)}
                options={this.prepareList(availableTypes)}
                onChange={selection => compose(setSelectedTypes, this.getMapFromList)(selection || [])}
                getOptionValue={prop("id")}
                getOptionLabel={prop("name")}
                placeholder={localized("Select Multiple...")}
              />
            </div>
          </div>
        </div>
        <div className="col-xs-12">
          <div className="row form-group display-flex m-b-n">
            <label className="col-xs-2 display-flex align-items-center m-n">
              <span className="p-l">{localized("Statuses")}</span>
            </label>
            <div className="col-xs-10 no-padding">
              <Select
                isMulti
                closeMenuOnSelect={false}
                value={this.getListFromMap(selectedStatuses)}
                options={this.prepareList(availableStatuses)}
                onChange={selection => compose(setSelectedStatuses, this.getMapFromList)(selection || [])}
                placeholder={localized("Select Multiple...")}
                getOptionValue={prop("id")}
                getOptionLabel={prop("name")}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ psa }) => ({
    availableStatuses: psa.ConnectWise.mappings.accounts.companyCriteria.companyStatusList,
    availableTypes: psa.ConnectWise.mappings.accounts.companyCriteria.companyTypeList,
    selectedTypes: psa.ConnectWise.mappings.accounts.companyFilterMap.types,
    selectedStatuses: psa.ConnectWise.mappings.accounts.companyFilterMap.statuses,
  }),
  {
    setSelectedTypes: types => ({ type: "SET_CW_COMPANY_FILTER_TYPES", types }),
    setSelectedStatuses: statuses => ({ type: "SET_CW_COMPANY_FILTER_STATUSES", statuses }),
  },
)(ConnectWiseAccountCriteria)
