import {
  always,
  applySpec,
  compose,
  concat,
  defaultTo,
  filter,
  find,
  flatten,
  gt,
  has,
  ifElse,
  isNil,
  lt,
  lte,
  map,
  prop,
  propEq,
  reduce,
  toString,
  values,
} from "ramda"

import { MAX_INTEGER_VALUE } from "js/includes/common/_constants"
import {
  arrayToMapWithKey,
  contains,
  getAsArray,
  isIntegerWithinRange,
  isNilOrEmpty,
  isNilOrEmptyOrBlank,
  isNinjaPSAEnabledFromSettings,
  isPositiveInteger,
  isUserAllowedToUseNinjaPSAAdministrativeActions,
  isValidNumber,
  isWholeNumber,
  localized,
  parseNumber,
  rejectInactive,
  sortByFieldNameCaseInsensitive,
  validations,
} from "js/includes/common/utils"
import { isTicketStatus } from "js/includes/configuration/integrations/ticketing/ticketCustomStatus/common"

export const getColumnOptions = ({ labelKey = "label" } = {}) =>
  sortByFieldNameCaseInsensitive(
    "label",
    "ASC",
  )([
    {
      value: "id",
      [labelKey]: "ID",
    },
    {
      value: "priority",
      [labelKey]: localized("Priority"),
    },
    {
      value: "severity",
      [labelKey]: localized("Severity"),
    },
    {
      value: "description",
      [labelKey]: localized("Description"),
    },
    {
      value: "status",
      [labelKey]: localized("Status"),
    },
    {
      value: "requester",
      [labelKey]: localized("Requester"),
    },
    {
      value: "summary",
      [labelKey]: localized("Subject"),
    },
    {
      value: "organization",
      [labelKey]: localized("Organization"),
    },
    {
      value: "location",
      [labelKey]: localized("Location"),
    },
    {
      value: "type",
      [labelKey]: localized("Type"),
    },
    {
      value: "source",
      [labelKey]: localized("Source"),
    },
    {
      value: "createTime",
      [labelKey]: localized("Create Time"),
    },
    {
      value: "totalTimeTracked",
      [labelKey]: localized("Total Time Tracked"),
    },
    {
      value: "totalTimeTrackedDecimal",
      [labelKey]: localized("Total Time Tracked Decimal"),
    },
    {
      value: "assignedAppUser",
      [labelKey]: localized("Assignee"),
    },
    {
      value: "solvedTime",
      [labelKey]: localized("Solved Time"),
    },
    {
      value: "ticketForm",
      [labelKey]: localized("Ticket Form"),
    },
    {
      value: "lastUpdatedTimeByAssignee",
      [labelKey]: localized("Last Updated Time by Assignee"),
    },
    {
      value: "lastUpdatedTimeByRequester",
      [labelKey]: localized("Last Updated Time by Requester"),
    },
    {
      value: "device",
      [labelKey]: localized("Device"),
    },
    {
      value: "lastUpdatedBy",
      [labelKey]: localized("Last Updated By"),
    },
    {
      value: "lastUpdated",
      [labelKey]: localized("Last Updated"),
    },
    ...(isNinjaPSAEnabledFromSettings() && isUserAllowedToUseNinjaPSAAdministrativeActions()
      ? [
          {
            value: "agreement",
            [labelKey]: localized("Agreement"),
          },
        ]
      : []),
  ])

export const triggerContainsHoursSinceCondition = conditions => {
  const { all } = conditions
  return all.some(({ operator }) =>
    contains(["hours_since:greater_or_equal_than", "hours_since:less_or_equal_than", "hours_since:is"], operator),
  )
}

export const getActiveAttributes = ({ attributes }) => {
  if (!attributes?.length) {
    return []
  }

  return compose(
    map(
      applySpec({
        value: compose(concat("attribute:"), toString, prop("id")),
        labelText: prop("name"),
        type: prop("attributeType"),
        content: prop("content"),
        groupName: always(localized("Fields")),
      }),
    ),
    rejectInactive,
  )(attributes)
}

export const getFields = ({ type, combinator, statusList, groupName }) => [
  ...(["BOARD", "TIME_BASED"].includes(type)
    ? combinator === "all"
      ? [
          { value: "ticket_created", labelText: localized("Time since ticket created"), groupName },
          { value: "ticket_changed", labelText: localized("Time since ticket changed"), groupName },
          { value: "assigned", labelText: localized("Time since assigned"), groupName },
          { value: "customer", labelText: localized("Time since customer responded"), groupName },
          { value: "technician", labelText: localized("Time since technician responded"), groupName },
          ...(statusList || []).map(status => ({
            value: status.id?.toString(),
            labelText: localized("Time since {{status}}", { status: status.displayName.toLocaleLowerCase() }),
            groupName,
          })),
        ]
      : []
    : [
        {
          value: "action",
          labelText: localized("Action"),
          groupName,
        },
      ]),
  ...(combinator === "all" && type !== "BOARD"
    ? [{ value: "business_hours", labelText: localized("Business hours"), groupName }]
    : []),
  { value: "type", labelText: localized("Type"), groupName },
  { value: "status", labelText: localized("Status"), groupName },
  { value: "source", labelText: localized("Source"), groupName },
  { value: "severity", labelText: localized("Severity"), groupName },
  { value: "priority", labelText: localized("Priority"), groupName },
  { value: "summary", labelText: localized("Subject"), groupName },
  { value: "description", labelText: localized("Description"), groupName },
  { value: "organization", labelText: localized("Organization"), groupName },
  { value: "requester", labelText: localized("Requester"), groupName },
  { value: "assignee", labelText: localized("Assignee"), groupName },
  { value: "tags", labelText: localized("Tags"), groupName },
  ...(["EVENT_BASED", "TIME_BASED", "BOARD"].includes(type)
    ? [
        { value: "form", labelText: localized("Form"), groupName },
        { value: "location", labelText: localized("Location"), groupName },
      ]
    : []),
  ...(type === "EVENT_BASED"
    ? [
        { value: "commentType", labelText: localized("Comment type"), groupName },
        { value: "updated_by", labelText: localized("Updated by"), groupName },
      ]
    : []),
]

const overrideValidation = () => ({ success: true })

export const MAX_TIME_SINCE_HOURS = 99999

const timeSinceValidationMessages = {
  getRequired: () => localized("Required"),
  minutes: {
    getWrongValue: () => localized("Values must be 0, 15, 30 or 45"),
  },
  hours: {
    getWrongValue: () =>
      localized("Value must be an integer between {{min}} and {{max}}", { min: 0, max: MAX_TIME_SINCE_HOURS }),
  },
}

const isTimeSinceSharedErrorMessage = errorMessage => {
  return errorMessage === timeSinceValidationMessages.getRequired()
}

const isTimeSinceHoursErrorMessage = errorMessage => {
  return errorMessage === timeSinceValidationMessages.hours.getWrongValue()
}

const isTimeSinceMinutesErrorMessage = errorMessage => {
  return errorMessage === timeSinceValidationMessages.minutes.getWrongValue()
}

export const getTimeSinceDisplayErrorData = errorMessage => {
  const data = {
    hours: false,
    minutes: false,
  }

  if (!errorMessage) {
    return data
  }

  const isShared = isTimeSinceSharedErrorMessage(errorMessage)
  data.hours = isShared || isTimeSinceHoursErrorMessage(errorMessage)
  data.minutes = isShared || isTimeSinceMinutesErrorMessage(errorMessage)

  return data
}

function validateTimeSince(value, values) {
  const requiredValidation = {
    success: false,
    message: timeSinceValidationMessages.getRequired(),
  }

  if (!value) {
    return requiredValidation
  }

  const [hour, minute] = value.split(":")
  const parsedHour = parseNumber(hour)
  const parsedMinute = parseNumber(minute)

  if (parsedHour + parsedMinute === 0) {
    return requiredValidation
  }

  if (lt(parsedHour, 0) || gt(parsedHour, MAX_TIME_SINCE_HOURS) || !isWholeNumber(hour)) {
    return {
      success: false,
      message: timeSinceValidationMessages.hours.getWrongValue(),
    }
  }

  if (!["0", "15", "30", "45"].includes(minute)) {
    return {
      success: false,
      message: timeSinceValidationMessages.minutes.getWrongValue(),
    }
  }

  return overrideValidation()
}

const findAttribute = (attributes, field) => attributes.find(({ value }) => value === field)

function validatePositiveInteger(value) {
  const parsedValue = parseNumber(value)
  const required = validations.required(parsedValue)

  const isNegativeInteger = !isPositiveInteger(parsedValue) && localized("Value must be a positive integer")

  const isNotLessThanOrEqualToMaxInt =
    !lte(parsedValue, MAX_INTEGER_VALUE) && `${localized("Value must be less than or equal to")} ${MAX_INTEGER_VALUE}`

  return {
    success: required.success && !isNegativeInteger && !isNotLessThanOrEqualToMaxInt,
    message: required.message || isNegativeInteger || isNotLessThanOrEqualToMaxInt,
  }
}

const getCustomValidations = (type, operator) => {
  if (["present", "not_present"].includes(operator)) return overrideValidation

  switch (type) {
    case "NUMERIC":
      return validatePositiveInteger
    default:
      return validations.required
  }
}

export const getSelectedValue = ({ value, isMulti, valuesMap, idKey = "id", labelKey = "name", valueMapper }) => {
  if (isNilOrEmptyOrBlank(value)) {
    return isMulti ? [] : null
  }

  const values = String(value).split(",")

  const selectedValues = map(id => {
    const metadataValue = valuesMap[id]
    const value = metadataValue ? metadataValue : { [idKey]: id, [labelKey]: localized("N/A") }
    return valueMapper ? valueMapper(value) : value
  }, values)

  return isMulti ? selectedValues : selectedValues[0]
}

const convertFormValueToArray = ({ value, valueToArrayConverter }) => {
  if (valueToArrayConverter) {
    return valueToArrayConverter(value)
  }
  const parsedValue = isNilOrEmptyOrBlank(value) ? null : `${value}`
  return filter(id => !!id, parsedValue?.split(",") || [])
}

const validateRequiredOrWrongValues = ({
  metaData,
  errorMessage,
  metadataIdKey = "id",
  checkWrongValue,
  valueToArrayConverter,
}) => {
  const metaDataArray = getAsArray(metaData)
  const metadataMap = arrayToMapWithKey(metadataIdKey, metaDataArray)

  return value => {
    const values = convertFormValueToArray({ value, valueToArrayConverter })
    if (!values.length) {
      return validations.required("")
    }
    if (values.some(id => checkWrongValue(metadataMap[id]))) {
      return { success: false, message: errorMessage }
    }
    return overrideValidation()
  }
}

export const getValuesMap = ({ options, metaData, metaDataMapper, idKey = "id" }) => {
  const metaDataArray = metaDataMapper ? getAsArray(metaData).map(metaDataMapper) : getAsArray(metaData)
  return arrayToMapWithKey(idKey, [...(options || []), ...metaDataArray])
}

export const getUsersDeletedValidation = ({ metaData, metadataIdKey, valueToArrayConverter }) => {
  return validateRequiredOrWrongValues({
    metaData,
    metadataIdKey,
    checkWrongValue: data => data?.deleted === true,
    errorMessage: localized("Must not contain deleted users"),
    valueToArrayConverter,
  })
}

export const getOrganizationsDeletedValidation = ({ metaData, valueToArrayConverter }) => {
  return validateRequiredOrWrongValues({
    metaData,
    checkWrongValue: data => data?.deleted === true,
    errorMessage: localized("Must not contain deleted organizations"),
    valueToArrayConverter,
  })
}

export const ticketLogEntryTimeTrackerValidation = value => {
  const requiredResult = validations.required(value)

  if (!requiredResult.success) {
    return requiredResult
  }

  const min = 1
  const max = 999999999
  const isValid = isValidNumber(value) && isIntegerWithinRange(value, min, max)

  return {
    success: isValid,
    message: isValid ? "" : localized("Value must be an integer between {{min}} and {{max}}", { min, max }),
  }
}

export const mapExistingIdValues = ({ objectList, idKey = "uid" }) =>
  reduce(
    (acc, data) => {
      const id = data[idKey]
      if (id) {
        acc.push(id)
      }
      return acc
    },
    [],
    objectList || [],
  )

export const buildGetValidations = attributes => (field, operator, metaData) => {
  if (isTicketStatus(field)) return validateTimeSince

  switch (field) {
    case "ticket_created":
    case "ticket_changed":
    case "assigned":
    case "customer":
    case "technician":
      return validateTimeSince
    case "organization":
    case "requester":
    case "assignee":
    case "tags":
    case "form":
    case "location":
      if (["present", "not_present"].includes(operator)) {
        return overrideValidation
      }
      if (["requester", "assignee"].includes(field)) {
        const idKey = field === "assignee" ? "id" : "uid"
        return getUsersDeletedValidation({
          metaData,
          metadataIdKey: idKey,
          valueToArrayConverter: value => mapExistingIdValues({ objectList: getAsArray(value), idKey }),
        })
      }
      if ("form" === field) {
        return validateRequiredOrWrongValues({
          metaData,
          checkWrongValue: data => data?.active === false,
          errorMessage: localized("Must not contain inactive forms"),
        })
      }
      if ("location" === field) {
        return validateRequiredOrWrongValues({
          metaData,
          checkWrongValue: data => data?.deleted === true,
          errorMessage: localized("Must not contain deleted locations"),
          valueToArrayConverter: value => mapExistingIdValues({ objectList: getAsArray(value), idKey: "id" }),
        })
      }
      if ("organization" === field) {
        return getOrganizationsDeletedValidation({
          metaData,
          valueToArrayConverter: value => mapExistingIdValues({ objectList: getAsArray(value), idKey: "id" }),
        })
      }

      return validations.required
    case "business_hours":
      return overrideValidation
    case "type":
    case "status":
    case "source":
    case "severity":
    case "priority":
      return operator === "changed" ? overrideValidation : validations.required

    // Validations for actions
    case "ticket":
      if (["requesterUid", "assignedAppUserId"].includes(operator)) {
        const idKey = operator === "assignedAppUserId" ? "id" : "uid"
        return getUsersDeletedValidation({
          metaData,
          metadataIdKey: idKey,
          valueToArrayConverter: value => mapExistingIdValues({ objectList: getAsArray(value), idKey }),
        })
      }
      if ("organization" === operator) {
        return getOrganizationsDeletedValidation({
          metaData,
          valueToArrayConverter: value => mapExistingIdValues({ objectList: getAsArray(value), idKey: "id" }),
        })
      }
      return validations.required
    case "SEND_EMAIL":
      return getUsersDeletedValidation({
        metadataIdKey: "uid",
        metaData: metaData?.toUsers,
        valueToArrayConverter: value => {
          if (isNilOrEmpty(value)) {
            return []
          }
          const { uids = [], keys = [], emails = [] } = value
          return mapExistingIdValues({ objectList: [...uids, ...keys, ...emails] })
        },
      })
    case "add_cc_list":
    case "set_cc_list":
      return getUsersDeletedValidation({
        metaData: metaData?.users,
        metadataIdKey: "uid",
        valueToArrayConverter: value => mapExistingIdValues({ objectList: value }),
      })
    case "TICKET_LOG_ENTRY_TIME_TRACKER": {
      return ticketLogEntryTimeTrackerValidation
    }
    default:
      const customAttribute = findAttribute(attributes, field)
      return !!customAttribute ? getCustomValidations(customAttribute.type, operator) : validations.required
  }
}

export const defineValidations = ({ getValidations, field, operator, metaData }) => {
  if (getValidations) {
    const val = getValidations(field, operator, metaData)
    if (val) return val
  }
  return validations.required
}

/**
 * Sets a validation field in every element in the list that does not have that field.
 *
 * @param {Object} data An object with the following keys
 * - list, an array of parsed conditions or actions (not the structure the server returns)
 * - getValidations, function that returns validations for specific actions or conditions
 * @returns a new list.
 */
export const setMissingValidations = ({ list, getValidations }) => {
  return (list || []).map(condition => {
    if (condition.validation) {
      return condition
    }

    const { field, operator, metaData, value } = condition
    return {
      ...condition,
      touched: true,
      validation: defineValidations({ getValidations, field, operator, metaData })(value),
    }
  })
}

const isValid = compose(
  isNil,
  find(compose(ifElse(has("success"), propEq("success", false), always(undefined)), prop("validation"))),
  defaultTo([]),
)

const flattenValues = compose(flatten, values)

/**
 * Validates every single action (if any) is valid. Basically it checks there are no validation errors.
 */
export const validateActions = isValid

/**
 * Validates every single condition (if any) is valid. Basically it checks there are no validation errors.
 */
export const validateConditions = conditions => {
  return isValid(flattenValues(conditions))
}
