import { show, hide } from "js/state/actions/common/visibility"

export function showModal(title, mode, id) {
  return dispatch => {
    dispatch({ type: "SET_CW_NODE_ROLE_PRODUCT_MAPPING_MODAL_EDITOR_TITLE", title })
    dispatch({ type: "SET_CW_NODE_ROLE_PRODUCT_MAPPING_MODAL_EDITOR_MODE", mode })
    dispatch({ type: "SET_CW_NODE_ROLE_PRODUCT_MAPPING_ID", id })
    dispatch(show("CWNodeRoleProductMappingModal")())
  }
}

export function closeModal() {
  return dispatch => {
    dispatch({ type: "RESET_CW_NODE_ROLE_PRODUCT_MAPPING" })
    dispatch(hide("CWNodeRoleProductMappingModal")())
  }
}

export function setCurrentMapping(mapping) {
  return dispatch => {
    dispatch({ type: "SET_CW_NODE_ROLE_PRODUCT_MAPPING", mapping })
  }
}

export function addMapping(data) {
  return dispatch => {
    dispatch({ type: "ADD_CW_PRODUCT_MAPPING_NODE_ROLE_PRODUCT_MAPPING", mapping: data })
  }
}

export function updateMapping(data) {
  return dispatch => {
    dispatch({ type: "EDIT_CW_PRODUCT_MAPPING_NODE_ROLE_PRODUCT_MAPPING", mapping: data })
  }
}

export function removeMapping(id) {
  return dispatch => {
    dispatch({ type: "REMOVE_CW_PRODUCT_MAPPING_NODE_ROLE_PRODUCT_MAPPING", id })
  }
}

export function setNodeRole(nodeRole) {
  return dispatch => {
    dispatch({ type: "SET_CW_NODE_ROLE_PRODUCT_MAPPING_NODE_ROLE", nodeRole })
  }
}

export function setProduct(product) {
  return dispatch => {
    dispatch({ type: "SET_CW_NODE_ROLE_PRODUCT_MAPPING_PRODUCT", product })
  }
}

export function setAgreementType(agreementType) {
  return dispatch => {
    dispatch({ type: "SET_CW_NODE_ROLE_PRODUCT_MAPPING_AGREEMENT_TYPE", agreementType })
  }
}

export function setGroups(groups) {
  return dispatch => {
    dispatch({ type: "SET_CW_NODE_ROLE_PRODUCT_MAPPING_GROUPS", groups })
  }
}

export function setGroupLogicalOperator(logicalOperator) {
  return dispatch => {
    dispatch({ type: "SET_CW_NODE_ROLE_PRODUCT_MAPPING_LOGICAL_OPERATOR", logicalOperator })
  }
}
