import { createSelector } from "reselect"
import tableSortSelector from "js/state/selectors/common/tableSort"

export const getProductMappingList = createSelector(
  createSelector(
    [
      mappings => mappings.products.productMappings,
      mappings => mappings.products.productMappingSort.sortBy,
      mappings => mappings.products.productMappingSort.sortDirection,
    ],
    (data, sortBy, sortDirection) => ({ ...{ data, sortBy, sortDirection } }),
  ),
  tableSortSelector,
)
