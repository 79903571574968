import { Highlighter } from "@ninjaone/components"

import { localized } from "js/includes/common/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { getSearchableDropdownTagRenderer } from "js/includes/ticketing/TriggerEditor/components/utils"
import { useSearchableDropdownValue } from "js/includes/ticketing/TriggerEditor/hooks"

const OPTION_ID_KEY = "id"
const OPTION_LABEL_KEY = "name"

export const LocationEditor = ({
  isMulti: _isMulti = true,
  value: _value,
  handleOnChange,
  errorMessage,
  ariaLabel,
}) => {
  const { value, selectionMap, isMulti } = useSearchableDropdownValue({ isMulti: _isMulti, value: _value })

  const handleOnSelect = selected => {
    handleOnChange(selected)
  }

  const getOptionLabel = ({ option }) => {
    const locationName = option?.[OPTION_LABEL_KEY] || localized("N/A")
    const deletedText = `${selectionMap[option[OPTION_ID_KEY]]?.deleted ? ` (${localized("Deleted")})` : ""}`
    const organizationName = option.organizationName || localized("N/A")
    return `${locationName} | ${organizationName}${deletedText}`
  }

  return (
    <SearchableDropDown
      isMulti={isMulti}
      keepDropdownInView
      keepInView={false}
      pageSize={50}
      endpoint="/query/locations"
      valueSelectorKey={OPTION_ID_KEY}
      searchableKey={OPTION_LABEL_KEY}
      value={value}
      onSelect={handleOnSelect}
      getOptionLabel={() => getOptionLabel({ option: value })}
      tagRenderer={getSearchableDropdownTagRenderer({ getOptionLabel })}
      rowRenderer={(rowData, inputValue, searchableKey) => {
        const label = rowData[searchableKey]
        const labelWithOrganization = `${label} (${rowData.organizationName})`
        return (
          <div className="text-ellipsis line-height-initial" title={labelWithOrganization}>
            {inputValue && label.toLowerCase().includes(inputValue.toLowerCase()) ? (
              <Highlighter text={labelWithOrganization} highlight={inputValue} />
            ) : (
              labelWithOrganization
            )}
          </div>
        )
      }}
      useSelectStyling
      {...{
        ariaAttributes: {
          "aria-label": ariaLabel,
        },
        ...(errorMessage && {
          errorMessage,
          validationState: "error",
        }),
      }}
    />
  )
}
