import { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { always, when, gt, compose } from "ramda"
import { Input } from "@ninjaone/components"
import {
  formatToDisplay,
  formatToEdit,
  formatToNumericValue,
  getDecimalSeparator,
  getNextValue,
  getValidLanguage,
  isValueForEmpty,
} from "js/includes/configuration/integrations/psa/common/components/NumericInput/utils"
import { MAX_MONETARY_VALUE_ALLOWED } from "js/includes/configuration/integrations/psa/psaProducts/productForm/formCommons"

const defaultToMaxAllowed = when(
  compose(
    value => gt(value, MAX_MONETARY_VALUE_ALLOWED),
    value => Number(value),
  ),
  always(MAX_MONETARY_VALUE_ALLOWED),
)

const getLanguageData = ({ _language, _decimalPlaces, isInteger }) => {
  const language = getValidLanguage(_language)
  const decimalSeparator = getDecimalSeparator(language)
  const decimalPlaces = isInteger ? 0 : _decimalPlaces
  const decimalPart = isInteger ? "" : `(\\${decimalSeparator}\\d{0,${decimalPlaces}})?`
  return {
    language,
    decimalPlaces,
    decimalSeparator,
    validNumberRegExp: new RegExp(`^-?([1-9]\\d*|0)${decimalPart}$`),
  }
}

const NumericInput = ({
  id,
  onChange,
  value,
  disabled = false,
  placeholder,
  className = "form-control",
  decimalPlaces: _decimalPlaces = 2,
  isInteger = false,
  language: _language,
  removeTrailingZeros = false,
  currency,
  labelToken,
  errorMessage,
  mandatory,
}) => {
  const [inputValue, setInputValue] = useState("")
  const [focused, setFocused] = useState(false)

  const { language, decimalSeparator, decimalPlaces, validNumberRegExp } = useMemo(
    () => getLanguageData({ _decimalPlaces, _language, isInteger }),
    [_language, _decimalPlaces, isInteger],
  )

  const onBlur = () => {
    setFocused(false)
  }

  const onFocus = () => {
    setFocused(true)
    setInputValue(formatToEdit({ numericValue: value, decimalSeparator }))
  }

  const onBeforeInput = event => {
    const nextValue = getNextValue(event)
    if (!isValueForEmpty({ targetValue: nextValue, decimalSeparator }) && !validNumberRegExp.test(nextValue)) {
      event.preventDefault()
    }
  }

  return (
    <Input
      {...{
        ...(id && { id }),
        ...(placeholder && { placeholder }),
        labelToken,
        errorMessage,
        className,
        onBeforeInput,
        disabled,
        onBlur,
        onFocus,
        required: mandatory,
        value: focused
          ? inputValue
          : formatToDisplay({ value, language, decimalPlaces, removeTrailingZeros, currency }),
        onChange: ({ target: { value: targetValue } }) => {
          if (disabled || !onChange) {
            return
          }

          if (isValueForEmpty({ targetValue, decimalSeparator })) {
            onChange("")
            setInputValue(targetValue)
            return
          }

          const { numericValue, changed } = formatToNumericValue({ targetValue, decimalSeparator })

          const newValue = defaultToMaxAllowed(numericValue)
          onChange(newValue)
          setInputValue(changed ? newValue : targetValue)
        },
      }}
    />
  )
}

NumericInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  language: PropTypes.string,
  isInteger: PropTypes.bool,
  decimalPlaces: PropTypes.number,
}

export default NumericInput
