/** @jsxImportSource @emotion/react */
import { useEffect, useMemo } from "react"
import { Form, FormGroup, Col, ControlLabel } from "react-bootstrap"
import { css } from "@emotion/react"
import { prop, find, propEq, compose, cond, T, always, evolve, pick, map } from "ramda"
import Modal from "js/includes/components/Modal"
import Loading from "js/includes/components/Loading"
import Select from "react-select"
import { localized, localizationKey, fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"
import { sortListByName } from "js/includes/configuration/psa/PsaSettingsEditor"
import { useForm, useMountedState } from "js/includes/common/hooks"
import Tooltip from "js/includes/components/Tooltip"

import { CLIENT_DEFAULT, SPECIFIED, NONE, NONE_CHOICE } from "./constants"

const labelStyle = css`
  text-align: left;
`

const setValue = (value, isDefault, isSetToNone) =>
  cond([
    [() => isDefault, always(null)],
    [() => isSetToNone, always(null)],
    [T, always(value)],
  ])()

const setMappingType = (isDefault, isSetToNone) =>
  cond([
    [() => isDefault, always(CLIENT_DEFAULT)],
    [() => isSetToNone, always(NONE)],
    [T, always(SPECIFIED)],
  ])()

const optionTransformer = {
  name: text => `${text} - ${localized("Default")}`,
}

const generateAgreementsOptions = map(pick(["id", "name"]))

const LocationSiteMappingModal = ({
  location,
  companyId,
  availableSites,
  updateLocationSiteMapping,
  setSite,
  hideModal,
  productMappings,
  configurationTypeMappings,
  mapping,
  setLocationProductMapping,
  setLocationConfigurationTypeMapping,
  setLocationAgreement,
}) => {
  const [agreements, setAgreements] = useMountedState([])
  const [fetchingAgreements, setFetchingAgreements] = useMountedState(true)

  const agreementOptions = useMemo(() => generateAgreementsOptions(agreements), [agreements])

  useEffect(() => {
    ;(async () => {
      try {
        const { financeAgreementList } = await fetchJson(`/psacw/v2/finance/agreements?companyId=${companyId}`)
        setAgreements(financeAgreementList)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error fetching Company agreements"))
      } finally {
        setFetchingAgreements(false)
      }
    })()
  }, [companyId, setAgreements, setFetchingAgreements])

  const {
    values: { site },
    validation,
    onChange,
  } = useForm({
    fields: {
      site: {
        id: location.psaSiteId,
        name: location.psaSiteName,
      },
      productMapping: {
        id: location.productMappingId,
        name: location.productMappingName,
      },
      configurationTypeMapping: {
        id: location.configurationTypeMappingId,
        name: location.configurationTypeMappingName,
      },
      agreement: {
        id: location.agreementId,
        name: location.agreementName,
      },
    },
  })

  function getProductMappingsValue() {
    const { productMappingType, productMappingId } = location
    const { productMappingId: clientProductMappingId } = mapping

    switch (productMappingType) {
      case CLIENT_DEFAULT:
        return compose(evolve(optionTransformer), find(propEq("id", clientProductMappingId)))(productMappings)
      case NONE:
        return NONE_CHOICE
      default:
        return find(propEq("id", productMappingId), productMappings)
    }
  }

  function getConfigurationTypeMappingsValue() {
    const { configurationTypeMappingType, configurationTypeMappingId } = location
    const { configurationTypeMappingId: clientConfigurationTypeMappingId } = mapping

    switch (configurationTypeMappingType) {
      case CLIENT_DEFAULT:
        return compose(
          evolve(optionTransformer),
          find(propEq("id", clientConfigurationTypeMappingId)),
        )(configurationTypeMappings)
      case NONE:
        return NONE_CHOICE
      default:
        return find(propEq("id", configurationTypeMappingId), configurationTypeMappings)
    }
  }

  function getAgreementValue() {
    const { agreementId } = location

    return agreementId ? find(propEq("id", agreementId), agreementOptions) : NONE_CHOICE
  }

  function handleSelectSite(site) {
    const { id, name } = site
    const isSetToNone = id === NONE
    setSite({
      psaSiteId: isSetToNone ? null : id,
      psaSiteName: isSetToNone ? null : name,
    })
    onChange({ site })
  }

  function handleSelectProductMapping(productMapping) {
    const { id, name } = productMapping
    const { productMappingId: clientProductMappingId } = mapping
    const isDefault = id === clientProductMappingId
    const isSetToNone = id === NONE
    setLocationProductMapping({
      productMappingName: setValue(name, isDefault, isSetToNone),
      productMappingId: setValue(id, isDefault, isSetToNone),
      productMappingType: setMappingType(isDefault, isSetToNone),
    })

    if (isSetToNone) {
      setLocationAgreement({
        agreementId: null,
        agreementName: null,
      })
    }

    onChange({
      productMapping,
      ...(isSetToNone && { agreement: NONE_CHOICE }),
    })
  }

  function handleSelectConfigurationTypeMapping(configurationTypeMapping) {
    const { id, name } = configurationTypeMapping
    const { configurationTypeMappingId: clientConfigurationTypeMappingId } = mapping
    const isDefault = id === clientConfigurationTypeMappingId
    const isSetToNone = id === NONE
    setLocationConfigurationTypeMapping({
      configurationTypeMappingName: setValue(name, isDefault, isSetToNone),
      configurationTypeMappingId: setValue(id, isDefault, isSetToNone),
      configurationTypeMappingType: setMappingType(isDefault, isSetToNone),
    })
    onChange({ configurationTypeMapping })
  }

  function handleAgreementChange(agreement) {
    const { id, name } = agreement
    const isNone = id === NONE

    setLocationAgreement({
      agreementId: setValue(id, false, isNone),
      agreementName: setValue(name, false, isNone),
    })
    onChange({ agreement })
  }

  function handleSave(e) {
    e.preventDefault()
    e.stopPropagation()
    updateLocationSiteMapping(location, companyId)
    hideModal()
  }

  return (
    <Form onSubmit={handleSave}>
      <Modal
        submittable
        overflow
        dataTitle={true}
        title={localized('"{{locationName}}" Ninja Location to CW Site', {
          locationName: location.locationName,
        })}
        close={hideModal}
        closeText={localizationKey("Cancel")}
        saveText={localizationKey("OK")}
        showCloseButton={false}
        dialogClassName="psa-modal"
        disabledSaveBtn={fetchingAgreements}
      >
        {fetchingAgreements ? (
          <Loading />
        ) : (
          <div className="display-flex flex-column">
            <FormGroup validationState={validation.message.site ? "error" : null}>
              <Col xs={3} componentClass={ControlLabel} css={labelStyle} className="m-b-none">
                <span>{localized("CW Site")}</span>
              </Col>
              <Col xs={9}>
                <Select
                  name="site"
                  className={validation.message.site ? "invalid-single-select" : ""}
                  value={find(option => option.id === site.id, availableSites || []) || NONE_CHOICE}
                  options={sortListByName([...availableSites, NONE_CHOICE])}
                  onChange={handleSelectSite}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                />
                {validation.message.site && <em className="invalid">{validation.message.site}</em>}
              </Col>
            </FormGroup>

            <FormGroup validationState={validation.message.productMapping ? "error" : null}>
              <Col xs={3} componentClass={ControlLabel} css={labelStyle} className="m-b-none">
                <span>{localized("Product Mapping")}</span>
              </Col>
              <Col xs={9}>
                <Select
                  name="productMapping"
                  className={validation.message.productMapping ? "invalid-single-select" : ""}
                  value={getProductMappingsValue()}
                  options={sortListByName([...productMappings, NONE_CHOICE])}
                  onChange={handleSelectProductMapping}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                />
                {validation.message.productMapping && <em className="invalid">{validation.message.productMapping}</em>}
              </Col>
            </FormGroup>

            <FormGroup validationState={validation.message.configurationTypeMapping ? "error" : null}>
              <Col xs={3} componentClass={ControlLabel} css={labelStyle} className="m-b-none">
                <span>{localized("Configuration Type Mapping")}</span>
              </Col>
              <Col xs={9}>
                <Select
                  name="configurationTypeMapping"
                  className={validation.message.configurationTypeMapping ? "invalid-single-select" : ""}
                  value={getConfigurationTypeMappingsValue()}
                  options={sortListByName([...configurationTypeMappings, NONE_CHOICE])}
                  onChange={handleSelectConfigurationTypeMapping}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                />
                {validation.message.configurationTypeMapping && (
                  <em className="invalid">{validation.message.configurationTypeMapping}</em>
                )}
              </Col>
            </FormGroup>

            <FormGroup>
              <Col xs={3} componentClass={ControlLabel} css={labelStyle} className="m-b-none">
                <span>{localized("Agreement")}</span>
                <Tooltip
                  token={localizationKey(
                    "Agreement is optional. If set, devices in this location will be mapped to it. Otherwise, devices will be mapped using the Agreement Type defined in the Product mapping.",
                  )}
                />
              </Col>
              <Col xs={9}>
                <Select
                  name="agreement"
                  value={getAgreementValue()}
                  options={sortListByName([...agreementOptions, NONE_CHOICE])}
                  onChange={handleAgreementChange}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                  isDisabled={!location.productMappingId && location.productMappingType !== CLIENT_DEFAULT}
                />
              </Col>
            </FormGroup>
          </div>
        )}
      </Modal>
    </Form>
  )
}

export default LocationSiteMappingModal
