import { curry, filter, includes } from "ramda"
import { localizationKey } from "js/includes/common/utils"
import { ValueEditor, ValueSelector, RuleGroup, CloseRuleButton } from "./components"
import ButtonLink from "../ButtonLink"

export const defaultTranslationTokens = {
  removeRule: {
    labelToken: localizationKey("Remove"),
  },
  addRule: {
    labelToken: localizationKey("Add"),
  },
}

export const filterOperators = curry(values => filter(o => includes(o.value, values), defaultOperators))

export const defaultOperators = [
  { value: "contains", labelToken: localizationKey("Contains") },
  { value: "does_not_contain", labelToken: localizationKey("Does not contain") },
  { value: "contains_any", labelToken: localizationKey("Contains any") },
  { value: "contains_none", labelToken: localizationKey("Contains none") },
  { value: "equals", labelToken: localizationKey("Equals") },
  { value: "not_equals", labelToken: localizationKey("Does not equal") },
  { value: "parent_equals", labelToken: localizationKey("Parent equals") },
  { value: "parent_not_equals", labelToken: localizationKey("Parent does not equal") },
  { value: "present", labelToken: localizationKey("Present") },
  { value: "not_present", labelToken: localizationKey("Not present") },
  { value: "hours_since:greater_or_equal_than", labelToken: localizationKey("Greater than") },
  { value: "hours_since:less_or_equal_than", labelToken: localizationKey("Less than") },
  { value: "hours_since:is", labelToken: localizationKey("Is") },
  { value: "add", labelToken: localizationKey("Add") },
  { value: "remove", labelToken: localizationKey("Remove") },
  { value: "current_user", labelToken: localizationKey("Current user") },
  { value: "requester", labelToken: localizationKey("Requester") },
  { value: "assignee", labelToken: localizationKey("Assignee") },
  { value: "changed", labelToken: localizationKey("Changed") },
  { value: "changed_to", labelToken: localizationKey("Changed to") },
  { value: "in", labelToken: localizationKey("Within") },
  { value: "not_in", labelToken: localizationKey("Outside") },
  { value: "greater_than", labelToken: localizationKey("Greater than") },
  { value: "less_than", labelToken: localizationKey("Less than") },
  { value: "is", labelToken: localizationKey("Is") },
  { value: "greater_or_equal_than", labelToken: localizationKey("Greater than or equal to") },
  { value: "less_or_equal_than", labelToken: localizationKey("Less than or equal to") },
]

export const defaultControlElements = {
  addRuleAction: ButtonLink,
  removeRuleAction: CloseRuleButton,
  fieldSelector: ValueSelector,
  operatorSelector: ValueSelector,
  valueEditor: ValueEditor,
  ruleGroup: RuleGroup,
}
