import { memo, useMemo } from "react"

import { localizationKey } from "js/includes/common/utils"
import {
  commonPropTypes,
  isFieldDisabled,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import DropdownField from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentFields/DropdownField"
import { autoRoundTimeEntryOptions } from "js/includes/configuration/integrations/psa/psaProducts/productForm/formCommons"

const AutoRoundTimeEntries = memo(({ onChange, values, validation, enabledFields }) => {
  const options = useMemo(
    () => autoRoundTimeEntryOptions.map(({ value, labelF }) => ({ value, labelText: labelF() })),
    [],
  )

  return (
    <DropdownField
      {...{
        onChange,
        values,
        validation,
        disabled: isFieldDisabled({ name: "autoRoundTimeEntries", enabledFields }),
        options,
        name: "autoRoundTimeEntries",
        nameToken: localizationKey("Auto Round Time Entries"),
      }}
    />
  )
})

AutoRoundTimeEntries.propTypes = commonPropTypes

export default AutoRoundTimeEntries
