import React from "react"
import { InlineWarning, Text } from "@ninjaone/components"
import Indicator from "js/includes/components/Indicator"
import { Box } from "js/includes/components/Styled"
import AutotaskAccountCriteria from "./AutotaskAccountCriteria"
import AutotaskAccountMapping from "./AutotaskAccountMapping"

export default function AutotaskAccountSettings(props) {
  return props.loading ? (
    <Indicator />
  ) : (
    <>
      {props.warning && (
        <Box margin={[0, "1px", 4, 4]}>
          <InlineWarning>
            <Text size="sm">{props.warning}</Text>
          </InlineWarning>
        </Box>
      )}
      <AutotaskAccountCriteria />
      <AutotaskAccountMapping {...props} />
    </>
  )
}
