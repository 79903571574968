import { $getRoot } from "lexical"
import PropTypes from "prop-types"
import { useCallback, useMemo, useRef } from "react"

import { $generateHtmlFromNodes } from "@lexical/html"
import { Modal, Select } from "@ninjaone/components"
import InputActions from "@ninjaone/components/src/Form/InputActions"
import { Label } from "@ninjaone/components/src/Form/Label"
import tokens from "@ninjaone/tokens"

import { useForm } from "js/includes/common/hooks"
import showModal from "js/includes/common/services/showModal"
import { isFeatureEnabled, localizationKey, localized, validations } from "js/includes/common/utils"
import { useEditor } from "js/includes/components/RichTextEditor/hooks/useEditor"
import { Box } from "js/includes/components/Styled"
import { WYSIWYGEditor as WYSIWYGEditorLegacy } from "js/includes/ticketing/editor/shared/components"
import { WYSIWYGEditor, WYSIWYGEditorReadOnly } from "js/includes/ticketing/editor/shared/components/WYSIWYGEditor"
import { Placeholders } from "js/includes/ticketing/TriggerEditor/components/Placeholders"
import { StyledEditorWrapper } from "js/includes/ticketing/TriggerEditor/components/Styled"

const PUBLIC_RESPONSE_VALUE = "true"

const getResponseTypeOptions = () => [
  {
    value: PUBLIC_RESPONSE_VALUE,
    labelText: localized("Public"),
  },
  {
    value: "false",
    labelText: localized("Private"),
  },
]

const EditorModal = ({
  value,
  handleOnChange,
  editorInsertHtml,
  withCurrentUserPlaceholders,
  isWYSIWYGV3FeatureEnabled,
  unmount,
}) => {
  const initialBodyRef = useRef(value?.body)

  const { values, validation, validateForm, onChange } = useForm({
    fields: {
      body: initialBodyRef.current ?? null,
      public: value?.public ?? true,
    },
    validate: {
      body: validations.required,
    },
  })

  function handleSave() {
    if (validateForm()) {
      editorInsertHtml(values.body)
      handleOnChange(values)
      unmount()
    }
  }

  const onChangeEvent = useCallback(
    (editorState, editor) => {
      editorState.read(() => {
        const root = $getRoot()
        const text = root.getTextContent()
        onChange("body", text.trim().length ? $generateHtmlFromNodes(editor) : null)
      })
    },
    [onChange],
  )

  const options = getResponseTypeOptions()

  const editorContentId = "body-editor"

  return (
    <Modal
      size="lg"
      titleGroup={{
        titleText: localized("Edit field"),
      }}
      unmount={unmount}
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Apply"),
          onClick: handleSave,
        },
      ]}
    >
      <Box marginBottom={tokens.spacing[2]}>
        <Label labelText={localized("Body text")} required labelFor={editorContentId} />
        {isWYSIWYGV3FeatureEnabled ? (
          <WYSIWYGEditor
            editorContentId={editorContentId}
            richTextPluginProps={{
              placeholderToken: localizationKey("Enter body text"),
            }}
            isPublicResponse={values.public}
            htmlParserPluginProps={{ initialHTML: initialBodyRef.current }}
            onChangePluginProps={{
              onChange: onChangeEvent,
            }}
            validationProps={{
              errorMessage: validation.message.body,
            }}
            imagePluginProps={{ excluded: true }}
            attachmentPluginProps={{ excluded: true }}
          />
        ) : (
          <WYSIWYGEditorLegacy
            isPublicResponse={values.public}
            editorData={{
              htmlBody: values.body,
            }}
            allowAttachments={false}
            allowInlineImages={false}
            hasError={validation.success.body === false}
            onChange={({ html, text }) => onChange("body", text.trim()?.length ? html : null)}
          />
        )}
      </Box>
      <Box marginBottom={tokens.spacing[2]}>
        <Select
          labelId="response"
          labelText={localized("Response")}
          required
          onChange={selected => onChange("public", selected === PUBLIC_RESPONSE_VALUE)}
          options={options}
          value={values.public.toString()}
        />
      </Box>
      <Placeholders {...{ withCurrentUserPlaceholders }} />
    </Modal>
  )
}

export const CommentEditor = props => {
  const { value, handleOnChange, withCurrentUserPlaceholders, errorMessage, ariaLabel } = props

  const containerId = ariaLabel

  const [editor, { editorInsertHtml }] = useEditor()

  const htmlBody = useMemo(() => props?.value?.body ?? "&nbsp;", [props?.value?.body])
  const isPublicResponse = useMemo(() => props?.value?.public, [props?.value?.public])
  const isWYSIWYGV3FeatureEnabled = isFeatureEnabled("wysiwyg_v3")

  const showEditorModal = () =>
    showModal(
      <EditorModal
        {...{
          value,
          handleOnChange,
          editorInsertHtml,
          withCurrentUserPlaceholders,
          isWYSIWYGV3FeatureEnabled,
        }}
      />,
    )

  return (
    <StyledEditorWrapper
      id={containerId}
      aria-label={ariaLabel}
      isPublicResponse={isPublicResponse}
      errorMessage={errorMessage}
      onClick={showEditorModal}
    >
      {isWYSIWYGV3FeatureEnabled ? (
        <WYSIWYGEditorReadOnly
          isPublicResponse={isPublicResponse}
          htmlParserPluginProps={{
            initialHTML: htmlBody,
            updateOnChange: true,
          }}
        />
      ) : (
        <WYSIWYGEditorLegacy isPublicResponse={isPublicResponse} readOnly editor={editor} editorData={{ htmlBody }} />
      )}
      <InputActions ariaErrorId={containerId} disableClear error={errorMessage} />
    </StyledEditorWrapper>
  )
}

CommentEditor.propTypes = {
  value: PropTypes.shape({
    body: PropTypes.string,
    public: PropTypes.bool,
  }),
  handleOnChange: PropTypes.func.isRequired,
  withCurrentUserPlaceholders: PropTypes.bool,
  errorMessage: PropTypes.string,
  ariaLabel: PropTypes.string,
}
