//@flow
import React, { Component } from "react"
import "@ninjamsp/ninja-switchery/switchery.min.css"
import Switchery from "@ninjamsp/ninja-switchery"
import { isNotNil } from "../common/utils"

type Props = {
  defaultChecked?: boolean,
  size?: string,
  toggleWarning?: Function,
  disabled?: boolean,
  onChange: Function,
}

export default class _Switchery extends Component<Props> {
  componentDidMount() {
    this.switchery = new Switchery(this.switch, {
      color: "#337ab7",
      size: this.props.size || "small",
      speed: "0.0s",
    })
    this.switch.onchange = this.handleChange.bind(this)
  }

  componentWillUnmount() {
    this.switch.onchange = null
    this.switchery.destroy()
  }

  componentDidUpdate(prevProps) {
    const checkedEval = this.props?.checked?.() ?? null
    if (isNotNil(checkedEval) && checkedEval !== prevProps?.checked?.() && this.switch) {
      this.switch.checked = checkedEval
      this.switch.dispatchEvent(new CustomEvent("change"))
    }
  }

  async handleChange(e) {
    if (this.props.toggleWarning) {
      const approved = await this.props.toggleWarning(!this.switch.checked)

      if (approved) {
        this.props.onChange(this.switch.checked)
      } else {
        // Force UI toggle to previous state
        this.switch.checked = !this.switch.checked
        this.switch.dispatchEvent(new CustomEvent("change"))
      }
    } else {
      this.props.onChange(this.switch.checked)
    }
  }

  render() {
    return (
      <input
        type="checkbox"
        defaultChecked={this.props.defaultChecked || false}
        disabled={this.props.disabled}
        ref={r => {
          this.switch = r
        }}
      />
    )
  }
}
