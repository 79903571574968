import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { compose, mapObjIndexed, values, map } from "ramda"
import fastDeepEqual from "fast-deep-equal"

import { noRowsRenderer, headerRenderer, localized, localizationKey, isFeatureEnabled } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import SelectableKeyboardShortcutsTable from "js/includes/components/SelectableKeyboardShortcutsTable"
import showModal from "js/includes/common/services/showModal"
import { saveAccountOrganizationMappings, saveLocationMappings } from "js/state/actions/psa/Autotask/settings"
import { updateByKey } from "js/includes/common/utils/ssrAndWebUtils"

import AutotaskAccountMappingModal from "./AutotaskAccountMappingModal"
import { AUTOMAP, CLEAR_MAPPING } from "./actions"

class AutotasksAccountMapping extends PureComponent {
  state = {
    sortBy: "clientName",
    sortDirection: "DESC",

    selected: {
      length: 0,
      rows: {},
    },
  }

  searchable = ["clientName"]

  columns = () => [
    {
      headerRenderer,
      dataKey: "clientName",
      width: 1,
      flexGrow: 1,
      label: localizationKey("Ninja Organization"),
    },
    {
      headerRenderer,
      dataKey: "accountName",
      width: 1,
      flexGrow: 1,
      label: localizationKey("Autotask Company"),
      cellRenderer: ({ cellData }) => cellData || "-",
    },
    {
      headerRenderer,
      dataKey: "installedProductCategoryMappingName",
      width: 1,
      flexGrow: 1,
      label: localizationKey("Ninja Device Role Mapping"),
      cellRenderer: ({ rowData }) =>
        this.props.nodeRoleMappings.find(m => m.id === rowData.installedProductCategoryMappingId)?.name || "-",
    },
  ]

  onRowClick = ({ rowData, index }) => {
    const {
      nodeRoleMappings,
      companyFilterMapTypes,
      accountOrganizationMappings,
      saveAccountOrganizationMappings,
      saveLocationMappings,
      locationMappings,
      setInlineWarning,
    } = this.props

    showModal(
      <AutotaskAccountMappingModal
        {...{
          nodeRoleMappings,
          mapping: rowData,
          companyFilterMapTypes: compose(
            values,
            mapObjIndexed((name, value) => ({ value, name })),
          )(companyFilterMapTypes),
          saveAccountOrganizationMapping: newAccountOrganizationMapping => {
            const updatedAccountOrganizationMappings = updateByKey(
              newAccountOrganizationMapping,
              accountOrganizationMappings,
              "clientId",
            )

            const updatedLocationMappings = map(locationMapping => {
              if (locationMapping.clientId === newAccountOrganizationMapping.clientId) {
                return {
                  ...locationMapping,
                  autotaskAccountId: newAccountOrganizationMapping.accountId,
                  autotaskAccountName: newAccountOrganizationMapping.accountName,
                  autotaskLocationId: null,
                  autotaskLocationName: null,
                }
              }
              return locationMapping
            }, locationMappings)

            const locationsChanged = !fastDeepEqual(updatedLocationMappings, locationMappings)

            saveAccountOrganizationMappings(updatedAccountOrganizationMappings)
            saveLocationMappings(updatedLocationMappings)

            if (locationsChanged) {
              setInlineWarning(
                localized(
                  "Because of your update to the account mappings we have also updated some of your location mappings, please take a look under Location Mapping.",
                ),
              )
            }
          },
        }}
      />,
      { withProvider: true },
    )
  }

  getActions = selected => {
    const autoMap = !isFeatureEnabled("autotask_big_data") ? [AUTOMAP] : []
    return selected.length ? [...autoMap, CLEAR_MAPPING] : autoMap
  }

  render() {
    const {
      props: { accountOrganizationMappings, saveAccountOrganizationMappings, saveLocationMappings, locationMappings },
      state: { sortBy, sortDirection, selected },
      columns,
      searchable,
      getActions,
      onRowClick,
    } = this

    return (
      <Box display="flex" flex={1} marginLeft={4} flexDirection="column">
        <h4>{localized("Account Mapping")}</h4>

        <Box display="flex" flex={1}>
          <SelectableKeyboardShortcutsTable
            {...{
              data: accountOrganizationMappings,
              columns,
              sortBy,
              sortDirection,
              searchable,
              onRowClick,
              dataKey: "clientId",
              rowHeight: 50,
              headerHeight: 45,
              actions: getActions(selected),
              noRowsRenderer: noRowsRenderer(localizationKey("No accounts available"), false, "no-padding"),
              sort: ({ sortBy, sortDirection }) => this.setState({ sortBy, sortDirection }),
              onCheck: ({ selected }) => {
                this.setState({ selected })
              },
              onAction: ({ action }, selected, { dispatchCallback }) => {
                action(
                  selected,
                  accountOrganizationMappings,
                  saveAccountOrganizationMappings,
                  saveLocationMappings,
                  locationMappings,
                  dispatchCallback,
                )
              },
            }}
          />
        </Box>
      </Box>
    )
  }
}

export default connect(
  ({ psa }) => ({
    nodeRoleMappings: psa.Autotask.settings.nodeRoleMappings,
    companyFilterMapTypes: psa.Autotask.settings.companyFilterMap.types,
    accountOrganizationMappings: psa.Autotask.settings.accountOrganizationMappings,
    locationMappings: psa.Autotask.settings.locationMappings,
  }),
  {
    saveAccountOrganizationMappings,
    saveLocationMappings,
  },
)(AutotasksAccountMapping)
