import React, { useState, useEffect } from "react"
import { StyledModal } from "js/includes/components/Styled/Form"
import {
  localized,
  localizationKey,
  fetchJson,
  reportErrorAndShowMessage,
  sortByFieldNameCaseInsensitive,
} from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { useMounted } from "js/includes/common/hooks"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

const selectCommonProps = {
  rowHeight: 30,
  width: "100%",
  minHeight: "32px",
  borderRadius: "2px",
  searchableKey: "name",
  valueSelectorKey: "id",
  openOnFocus: true,
  rowRenderer: option => option.name || localized("None"),
}

const mapAutotaskLocationToOption = mapping => ({
  name: mapping?.autotaskLocationName ?? null,
  id: mapping?.autotaskLocationId ?? null,
})

const AutotaskLocationMappingModal = ({ unmount, mapping, saveMapping }) => {
  const mounted = useMounted()
  const [loading, setLoading] = useState(false)
  const [autotaskLocations, setAutotaskLocations] = useState([])
  const [autotaskLocation, setAutotaskLocation] = useState(mapAutotaskLocationToOption(mapping))

  useEffect(() => {
    const getAutotaskLocations = async () => {
      setLoading(true)
      try {
        const autotaskLocations = await fetchJson(`/psaat/v3/locations/${mapping.autotaskAccountId}`)
        mounted.current && setAutotaskLocations(autotaskLocations)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        mounted.current && setLoading(false)
      }
    }

    getAutotaskLocations()
  }, [mounted, mapping])

  return (
    <StyledModal
      overflow="visible !important"
      title={localizationKey("Edit Mapping")}
      saveText={localizationKey("OK")}
      closeText={localizationKey("Cancel")}
      dialogClassName="psa-modal"
      close={() => {
        unmount()
      }}
      save={() => {
        const autotaskLocationMapping = {
          ...mapping,
          autotaskLocationId: autotaskLocation.id,
          autotaskLocationName: autotaskLocation.name,
        }

        saveMapping(autotaskLocationMapping, mapping)
        unmount()
      }}
    >
      <Box textAlign="left" display="flex" margin={[0, 4, 2, 4]} flexDirection="column">
        <Box>
          <h4>{localized("Location")}</h4>

          <SearchableDropDown
            {...{
              ...selectCommonProps,
              loading,
              options: [
                {
                  name: null,
                  id: null,
                },
                ...sortByFieldNameCaseInsensitive("name", "ASC", autotaskLocations),
              ],
              value: autotaskLocation,
              onSelect: selection => {
                setAutotaskLocation(selection)
              },
            }}
          />
        </Box>
      </Box>
    </StyledModal>
  )
}

AutotaskLocationMappingModal.defaultProps = {
  mappings: [],
}

export default AutotaskLocationMappingModal
