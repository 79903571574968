import { map } from "ramda"
import { memo, useMemo } from "react"

import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"

import { localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"

const ControlsRow = styled(Box)`
  display: grid;
  gap: ${tokens.spacing[3]};
  grid-template-columns: ${({ hasOperator }) => {
    if (hasOperator) {
      return "minmax(0, 212px) minmax(0, 150px) minmax(0, 1fr) 38px;"
    }
    return "minmax(0, 212px) minmax(0, 1fr) 38px;"
  }}
  margin-bottom: ${tokens.spacing[3]};
`

const Rule = memo(
  ({
    rowIndex,
    id,
    field,
    operator,
    value,
    translationTokens,
    validation,
    combinator,
    metaData,
    schema: {
      controls,
      fields,
      fieldsMap,
      getInputType,
      getOperators,
      getValueEditorType,
      getValues,
      onPropChange,
      onRuleRemove,
      excludeOptions,
    },
    displayErrors,
  }) => {
    const operatorOptions = useMemo(
      () => (operator ? map(op => ({ ...op, labelText: localized(op.labelToken) }), getOperators(field) || []) : []),
      [operator, field, getOperators],
    )

    const onElementChanged = (property, value) => {
      onPropChange(property, value, id)
    }

    const onFieldChanged = value => {
      onElementChanged("field", value)
    }

    const onOperatorChanged = value => {
      onElementChanged("operator", value)
    }

    const onValueChanged = value => {
      onElementChanged("value", value)
    }

    const removeRule = event => {
      event.preventDefault()
      event.stopPropagation()

      onRuleRemove(id)
    }

    const fieldData = fieldsMap[field] || { labelText: field }

    const hasOperator = !!operator

    const rowId = `row-rule-${combinator}-${rowIndex}`

    return (
      <>
        <ControlsRow id={rowId} hasOperator={hasOperator} data-testid={rowId}>
          <Box id={`column-rule-${combinator}-1`}>
            <controls.fieldSelector
              ariaLabel={localized("Field selector for row {{rowIndex}}", { rowIndex })}
              options={fields}
              excludeOptions={excludeOptions}
              value={field}
              operator={operator}
              handleOnChange={onFieldChanged}
              fieldGroupKey="groupName"
            />
          </Box>

          {hasOperator && (
            <Box id={`column-rule-${combinator}-2`}>
              <controls.operatorSelector
                ariaLabel={localized("{{field}} operator", { field: fieldData.labelText })}
                field={field}
                options={operatorOptions}
                value={operator}
                handleOnChange={onOperatorChanged}
                popoverWidth="200px"
              />
            </Box>
          )}

          <Box id={`column-rule-${combinator}-3`}>
            <controls.valueEditor
              ariaLabel={localized("Field editor for row {{rowIndex}}", { rowIndex })}
              field={field}
              operator={operator}
              value={value}
              metaData={metaData}
              type={getValueEditorType(field, operator)}
              inputType={getInputType(field, operator)}
              values={getValues(field, operator)}
              handleOnChange={onValueChanged}
              {...(displayErrors &&
                validation?.message && {
                  errorMessage: validation.message,
                })}
            />
          </Box>

          <Flex id={`column-rule-${combinator}-4`} alignItems="flex-start">
            <controls.removeRuleAction onClick={removeRule} />
          </Flex>
        </ControlsRow>
      </>
    )
  },
)

Rule.defaultProps = {
  id: null,
  field: null,
  operator: null,
  value: null,
  schema: null,
  displayErrors: false,
}

export default Rule
