import { memo } from "react"
import { Text, Checkbox } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { Box, Flex } from "js/includes/components/Styled"

export default memo(function ConnectWiseSyncSelectItem({ titleText, checked, onChange, disabled }) {
  return (
    <Flex>
      <Box>
        <Checkbox disabled={disabled} checked={checked} onChange={({ isChecked }) => onChange(isChecked)} />
      </Box>
      <Box marginLeft={sizer(3)}>
        <Text color="colorTextStrong" lineHeight={1.2} size="sm">
          {titleText}
        </Text>
      </Box>
    </Flex>
  )
})
