/** @jsxImportSource @emotion/react */
import { PureComponent } from "react"
import { connect } from "react-redux"
import { AutoSizer } from "react-virtualized"
import { css } from "@emotion/react"
import EditorPortalModal from "js/includes/components/EditorPortalModal"
import { localized } from "js/includes/common/utils"
import { sort as _sort } from "js/state/actions/common/tableSorting"
import { hide as _hide } from "js/state/actions/common/visibility"
import Loading from "js/includes/components/Loading"
import { colors } from "js/includes/common/theme"
import {
  openLocationSiteMappingModal as _openLocationSiteMappingModal,
  updateLocationSiteMapping as _updateLocationSiteMapping,
  setSite as _setSite,
  setLocationProductMapping as _setLocationProductMapping,
  setLocationConfigurationTypeMapping as _setLocationConfigurationTypeMapping,
  autoMapLocations as _autoMapLocations,
  clearLocationMappings as _clearLocationMappings,
  refreshCompanySites as _refreshCompanySites,
  setLocationAgreement as _setLocationAgreement,
} from "js/state/actions/psa/ConnectWise/mappings/accounts/locations"
import { getLocationSiteMappingList } from "js/state/selectors/psa/ConnectWise/mappings/accounts"
import LocationSiteMappingsTable from "./LocationSiteMappingsTable"
import LocationSiteMappingModal from "./LocationSiteMappingModal"
import LocationsSiteAutoMapModal from "./LocationsSiteAutoMapModal"
import showModal from "js/includes/common/services/showModal"

const mappingsStyle = css`
  display: flex;
  flex-flow: column;
  flex: 1;
  height: 340px;
  margin-top: -15px;
`

const tableHeaderStyle = css`
  display: flex;
  justify-content: flex-end;
  margin-top: -9px;
`

const tableContainerStyle = css`
  background-color: ${colors.white};
  border: 1px solid ${colors.ninjaLight};
  margin-top: 20px;
`

class LocationSiteMappings extends PureComponent {
  onAuto = e => {
    e.stopPropagation()
    e.preventDefault()
    const { autoMapLocations, companyId } = this.props
    autoMapLocations && autoMapLocations(companyId)
    showModal(<LocationsSiteAutoMapModal />, { withProvider: true })
  }

  onClear = e => {
    e.stopPropagation()
    e.preventDefault()
    const { clearLocationMappings } = this.props
    clearLocationMappings && clearLocationMappings()
  }

  onRefresh = e => {
    e.stopPropagation()
    e.preventDefault()
    const { refreshCompanySites, companyId } = this.props
    refreshCompanySites && refreshCompanySites(companyId)
  }

  render() {
    const {
      modalVisibility,
      locations,
      companyId,
      openLocationSiteMappingModal,
      sort,
      sortBy,
      sortDirection,
      location,
      availableSites,
      updateLocationSiteMapping,
      setSite,
      hideModal,
      productMappings,
      configurationTypeMappings,
      accountOrganizationModal: { mapping },
      setLocationProductMapping,
      setLocationConfigurationTypeMapping,
      loading,
      setLocationAgreement,
    } = this.props
    return loading ? (
      <Loading />
    ) : (
      <div css={mappingsStyle}>
        <div css={tableHeaderStyle}>
          <button className="btn-link action m-r" onClick={this.onAuto}>
            {localized("Auto")}
          </button>
          <button className="btn-link action m-r" onClick={this.onClear}>
            {localized("Clear")}
          </button>
          <button className="btn-link action m-r" onClick={this.onRefresh}>
            {localized("Refresh")}
          </button>
        </div>
        <div className="display-flex flex-full overflow-hidden" css={tableContainerStyle}>
          <AutoSizer>
            {({ height, width }) => (
              <LocationSiteMappingsTable
                {...{
                  height,
                  width,
                  sort,
                  sortBy,
                  sortDirection,
                  locations,
                  companyId,
                  openLocationSiteMappingModal,
                  mapping,
                }}
              />
            )}
          </AutoSizer>
        </div>
        <EditorPortalModal {...{ show: modalVisibility, className: "inmodal" }}>
          <LocationSiteMappingModal
            {...{
              location,
              companyId,
              availableSites,
              updateLocationSiteMapping,
              setSite,
              hideModal,
              productMappings,
              configurationTypeMappings,
              mapping,
              setLocationProductMapping,
              setLocationConfigurationTypeMapping,
              setLocationAgreement,
            }}
          />
        </EditorPortalModal>
      </div>
    )
  }
}

export default connect(
  ({ psa }) => ({
    modalVisibility: psa.ConnectWise.mappings.accounts.locationSiteMappingModalVisibility,
    locations: getLocationSiteMappingList(psa.ConnectWise),
    companyId: psa.ConnectWise.mappings.accounts.accountOrganizationModal.mapping.companyId,
    sortBy: psa.ConnectWise.mappings.accounts.locationSiteMappingSort.sortBy,
    sortDirection: psa.ConnectWise.mappings.accounts.locationSiteMappingSort.sortDirection,
    location: psa.ConnectWise.mappings.accounts.accountOrganizationModal.locationSiteModal.location,
    availableSites: psa.ConnectWise.mappings.accounts.accountOrganizationModal.availableSites,
    productMappings: psa.ConnectWise.mappings.products.productMappings,
    configurationTypeMappings: psa.ConnectWise.mappings.configurations.configurationTypeMappings,
    accountOrganizationModal: psa.ConnectWise.mappings.accounts.accountOrganizationModal,
    loading: psa.ConnectWise.mappings.accounts.locationSiteLoading,
    possibleLocationMappings: psa.ConnectWise.mappings.accounts.accountOrganizationModal.possibleLocationMappings,
    possibleLocationMappingsModalLoading: psa.ConnectWise.mappings.accounts.possibleLocationMappingsModalLoading,
  }),
  {
    sort: _sort("CWLocationSiteMappingSort"),
    openLocationSiteMappingModal: _openLocationSiteMappingModal,
    hideModal: _hide("CWLocationSiteMappingModal"),
    updateLocationSiteMapping: _updateLocationSiteMapping,
    setSite: _setSite,
    setLocationProductMapping: _setLocationProductMapping,
    setLocationConfigurationTypeMapping: _setLocationConfigurationTypeMapping,
    autoMapLocations: _autoMapLocations,
    clearLocationMappings: _clearLocationMappings,
    refreshCompanySites: _refreshCompanySites,
    setLocationAgreement: _setLocationAgreement,
  },
)(LocationSiteMappings)
