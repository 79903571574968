import { memo } from "react"
import tokens from "@ninjaone/tokens"
import { localizationKey, localized } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import NumericInput from "js/includes/configuration/integrations/psa/common/components/NumericInput"
import {
  commonPropTypes,
  isFieldDisabled,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import { multiplierPrecision } from "js/includes/configuration/integrations/psa/psaProducts/productForm/formCommons"

const NumberInput = ({ onChange, values, validation, disabled = false, name, nameToken: labelToken }) => {
  const inputId = `psa-product-${name}`
  const errorMessage = validation.message[name]
  const handleChange = value => {
    onChange(name, value)
  }

  return (
    <NumericInput
      {...{
        errorMessage,
        mandatory: true,
        id: inputId,
        onChange: handleChange,
        value: values[name],
        disabled,
        decimalPlaces: multiplierPrecision,
        labelToken: localized(labelToken),
        placeholder: localized(labelToken),
        removeTrailingZeros: true,
      }}
    />
  )
}

const multipliers = [
  { name: "onSiteMultiplier", nameToken: localizationKey("Onsite multiplier") },
  { name: "remoteMultiplier", nameToken: localizationKey("Remote multiplier") },
  { name: "normalHoursMultiplier", nameToken: localizationKey("Normal hours multiplier") },
  { name: "offHoursMultiplier", nameToken: localizationKey("Off-hours multiplier") },
]

const Multiplier = memo(({ onChange, values, validation, enabledFields }) => {
  return multipliers.map(({ name, nameToken }) => (
    <Box key={name} marginTop={tokens.spacing[3]}>
      <NumberInput
        {...{
          onChange,
          values,
          validation,
          disabled: isFieldDisabled({ name, enabledFields }),
          name,
          nameToken,
        }}
      />
    </Box>
  ))
})

Multiplier.propTypes = commonPropTypes

export default Multiplier
