import React, { PureComponent } from "react"
import { noRowsRenderer, headerRenderer } from "js/includes/common/utils/react-virtualized"
import {
  localized,
  localizationKey,
  ninjaReportError,
  showErrorMessage,
  showSuccessMessage,
} from "js/includes/common/utils"
import { getMappingName } from "js/includes/configuration/psa/ConnectWise/settings/common"
import { map, keys } from "ramda"
import LabelsWithArrow from "js/includes/configuration/psa/ConnectWise/common/LabelsWithArrow"
import SelectableKeyboardShortcutsTable from "js/includes/components/SelectableKeyboardShortcutsTable"
import { AUTO_MAP, CLEAR_MAPPING, REFETCH_CW_ACCOUNT_OPTIONS, REFETCH_CW_ACCOUNT_OPTIONS_LOADING } from "./actions"
import {
  getConfigurationTypeList,
  getConfigurationStatusList,
} from "js/state/actions/psa/ConnectWise/mappings/configurations/configurations"
import { clearCWAccountOrganizationSites as _clearCWAccountOrganizationSites } from "js/state/actions/psa/ConnectWise/mappings/accounts/locations"
import { getAgreementTypes, getProducts } from "js/state/actions/psa/ConnectWise/mappings/products/products"
import { connect } from "react-redux"

class AccountMappingTable extends PureComponent {
  state = {
    refreshingEntities: false,
    selected: 0,
  }

  columns = () => [
    {
      headerRenderer,
      dataKey: "clientName",
      label: () => <LabelsWithArrow leftText={localized("Ninja Organization")} rightText={localized("CW Company")} />,
      width: 1,
      flexGrow: 1,
      cellRenderer: ({ rowData, rowIndex }) => {
        const { clientName, companyName, hideFirstColumn } = rowData

        return (
          <span className="full-width">
            {!hideFirstColumn && (
              <LabelsWithArrow leftText={clientName} rightText={companyName || localized("Not Mapped")} />
            )}
          </span>
        )
      },
    },
    {
      headerRenderer,
      dataKey: "companyName",
      disableSort: true,
      label: () => <LabelsWithArrow leftText={localized("Location")} rightText={localized("CW Site")} />,
      width: 1,
      flexGrow: 1,
      cellRenderer: ({ rowData, rowIndex }) => {
        const { companyId, locationName, psaSiteName } = rowData

        return (
          <span className="display-flex flex-full text-wrap">
            {companyId && <LabelsWithArrow leftText={locationName} rightText={psaSiteName || localized("None")} />}
          </span>
        )
      },
    },
    {
      headerRenderer,
      dataKey: "productMappingName",
      disableSort: true,
      label: () => localized("Products"),
      width: 1,
      flexGrow: 1,
      cellRenderer: ({ rowData, rowIndex }) => {
        const { productMappingName, productMappingType, areLocationsMapped, orgProductMappingName } = rowData

        return (
          <span className="display-flex flex-full text-wrap">
            {!areLocationsMapped
              ? productMappingName
              : getMappingName(productMappingName || orgProductMappingName, productMappingType)}
          </span>
        )
      },
    },
    {
      headerRenderer,
      dataKey: "configurationTypeMappingName",
      disableSort: true,
      label: () => localized("Configuration Types"),
      width: 1,
      flexGrow: 1,
      cellRenderer: ({ rowData, rowIndex }) => {
        const {
          configurationTypeMappingName,
          configurationTypeMappingType,
          areLocationsMapped,
          orgConfigurationTypeMappingName,
        } = rowData

        return (
          <span className="display-flex flex-full text-wrap">
            {!areLocationsMapped
              ? configurationTypeMappingName
              : getMappingName(
                  configurationTypeMappingName || orgConfigurationTypeMappingName,
                  configurationTypeMappingType,
                )}
          </span>
        )
      },
    },
  ]

  refreshConnectWiseEntities = async () => {
    const {
      refetchCWAccountOptions,
      getConfigurationTypeList,
      getConfigurationStatusList,
      getAgreementTypes,
      getProducts,
      clearCWAccountOrganizationSites,
    } = this.props

    try {
      this.setState({ refreshingEntities: true })
      clearCWAccountOrganizationSites()

      await Promise.all([
        refetchCWAccountOptions(),
        getConfigurationTypeList(),
        getConfigurationStatusList(),
        getAgreementTypes(),
        getProducts(),
      ])
      showSuccessMessage(localized("Successfully updated ConnectWise entities"))
    } catch (error) {
      showErrorMessage(localized("Error while refreshing ConnectWise entities"))
      ninjaReportError(error)
    } finally {
      this.setState({ refreshingEntities: false })
    }
  }

  getFetchAccountOption = () => {
    const { refreshingEntities } = this.state
    return refreshingEntities ? REFETCH_CW_ACCOUNT_OPTIONS_LOADING : REFETCH_CW_ACCOUNT_OPTIONS
  }

  getActions = () => {
    const { selected } = this.state
    const fetchAccountOptionAction = this.getFetchAccountOption()
    return selected ? [AUTO_MAP, CLEAR_MAPPING, fetchAccountOptionAction] : [AUTO_MAP, fetchAccountOptionAction]
  }

  render() {
    const {
      organizationData,
      sort,
      sortBy,
      sortDirection,
      setSelectedAccountMappings,
      autoMap,
      confirmResetMapping,
    } = this.props

    return (
      <div className="display-flex flex-full p-l">
        <SelectableKeyboardShortcutsTable
          {...{
            id: "psa-account-mapping-table",
            sort,
            sortBy,
            sortDirection,
            columns: this.columns,
            dataKey: "clientId",
            data: organizationData,
            rowHeight: 45,
            headerHeight: 45,
            noRowsRenderer: noRowsRenderer(localizationKey("No Organizations")),
            onCheck: ({ row, selected, checked }) => {
              this.setState({ selected: selected.length })
              setSelectedAccountMappings(map(row => parseInt(row, 10), keys(selected.rows)))
            },
            onRowClick: ({ rowData: { data } }) => this.props.openAccountOrganizationModal(data),
            actions: this.getActions(),
            onAction: ({ id }) => {
              switch (id) {
                case AUTO_MAP.id:
                  autoMap()
                  break
                case CLEAR_MAPPING.id:
                  confirmResetMapping()
                  break
                case REFETCH_CW_ACCOUNT_OPTIONS.id:
                  this.refreshConnectWiseEntities()
                  break
                default:
                  throw new Error("Invalid action")
              }
            },
          }}
        />
      </div>
    )
  }
}

export default connect(null, {
  getConfigurationTypeList,
  getConfigurationStatusList,
  getAgreementTypes,
  getProducts,
  clearCWAccountOrganizationSites: _clearCWAccountOrganizationSites,
})(AccountMappingTable)
