import React, { PureComponent } from "react"
import { Table, Column } from "react-virtualized"
import { noRowsRenderer, headerRenderer } from "js/includes/common/utils/react-virtualized"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { getMappingName } from "js/includes/configuration/psa/ConnectWise/settings/common"
import { EllipsisWithTooltip } from "js/includes/components/EllipsisWithTooltip"
import { Flex, StyledSpan } from "js/includes/components/Styled"

export default class LocationSiteMappingsTable extends PureComponent {
  onEdit = (e, data) => {
    e.stopPropagation()
    e.preventDefault()
    const { companyId, openLocationSiteMappingModal } = this.props
    openLocationSiteMappingModal(data, companyId)
  }

  rowRenderer = ({ index, key, rowData, style }) => {
    const {
      mapping: {
        productMappingName: orgProductMappingName,
        configurationTypeMappingName: orgConfigurationTypeMappingName,
      },
    } = this.props

    const {
      productMappingName,
      productMappingType,
      configurationTypeMappingName,
      configurationTypeMappingType,
    } = rowData

    const _productMappingName = getMappingName(productMappingName || orgProductMappingName, productMappingType)
    const _configurationTypeMappingName = getMappingName(
      configurationTypeMappingName || orgConfigurationTypeMappingName,
      configurationTypeMappingType,
    )

    return (
      <Flex
        {...{
          key,
          style,
          className: "list-group-item horizontal-tile flex-tile cursor-default",
        }}
        onClick={e => {
          this.onEdit(e, rowData)
        }}
        alignItems="center"
      >
        <StyledSpan className="col-xs-6 text-ellipsis" textAlign="left">
          <EllipsisWithTooltip text={rowData.locationName} />
        </StyledSpan>
        <StyledSpan className="col-xs-6" textAlign="left">
          {rowData.psaSiteName || localized("None")}
        </StyledSpan>
        <StyledSpan className="col-xs-6" textAlign="left">
          {_productMappingName}
        </StyledSpan>
        <StyledSpan className="col-xs-6" textAlign="left">
          {_configurationTypeMappingName}
        </StyledSpan>
        <StyledSpan className="col-xs-6" textAlign="left">
          {rowData.agreementName || localized("None")}
        </StyledSpan>
      </Flex>
    )
  }

  render() {
    const { height, width, locations, sort, sortBy, sortDirection } = this.props
    return (
      <Table
        {...{
          sort,
          sortBy,
          sortDirection,
          height,
          width,
          ref: el => {
            this.Table = el
          },
          headerHeight: 35,
          rowHeight: 60,
          rowRenderer: this.rowRenderer,
          rowCount: locations.length,
          rowGetter: ({ index }) => locations[index],
          noRowsRenderer: noRowsRenderer(localizationKey("No Mappings"), false, "text-align-left col-xs-3"),
          className: "horizontal-tiles list-group",
        }}
      >
        <Column
          {...{
            headerRenderer,
            dataKey: "locationName",
            label: localized("Ninja Location"),
            width: 1,
            flexGrow: 1,
            headerClassName: "col-xs-6",
          }}
        />
        <Column
          {...{
            headerRenderer,
            dataKey: "psaSiteName",
            label: localized("CW Site"),
            width: 1,
            flexGrow: 1,
            headerClassName: "col-xs-6",
          }}
        />
        <Column
          {...{
            headerRenderer,
            dataKey: "productMappingName",
            label: localized("Product Mapping"),
            width: 1,
            flexGrow: 1,
            headerClassName: "col-xs-6",
          }}
        />
        <Column
          {...{
            headerRenderer,
            dataKey: "configurationTypeMappingName",
            label: localized("Configuration Type Mapping"),
            width: 1,
            flexGrow: 1,
            headerClassName: "col-xs-6",
          }}
        />
        <Column
          {...{
            headerRenderer,
            dataKey: "psaAgreementName",
            label: localized("Agreement"),
            width: 1,
            flexGrow: 1,
            headerClassName: "col-xs-6",
          }}
        />
      </Table>
    )
  }
}
