import { memo } from "react"

import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { isFieldDisabled } from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"

import MoneyField from "./MoneyField"

const Price = memo(({ onChange, values, validation, enabledFields, currency }) => {
  return (
    <MoneyField
      {...{
        onChange,
        values,
        validation,
        disabled: isFieldDisabled({ name: "price", enabledFields }),
        name: "price",
        nameToken: localizationKey("Price"),
        placeholderToken: localizationKey("Enter price"),
        mandatory: true,
        currency,
      }}
    />
  )
})

export default Price
