import { createSelector } from "reselect"
import tableSortSelector from "js/state/selectors/common/tableSort"

const getLocationSites = ConnectWise =>
  ConnectWise.mappings.accounts.accountOrganizationModal.mapping.locationSiteMappings

export const getLocationSiteMappingList = createSelector(
  createSelector(
    [
      getLocationSites,
      ConnectWise => ConnectWise.mappings.accounts.locationSiteMappingSort.sortBy,
      ConnectWise => ConnectWise.mappings.accounts.locationSiteMappingSort.sortDirection,
    ],
    (data, sortBy, sortDirection) => ({ ...{ data, sortBy, sortDirection } }),
  ),
  tableSortSelector,
)
