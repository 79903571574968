import React from "react"
import { connect } from "react-redux"
import { Box } from "js/includes/components/Styled"
import { Checkbox } from "js/includes/components/NinjaReactICheck"
import { StyledSpan } from "js/includes/components/Styled"
import { setHelpdeskOptions } from "js/state/actions/psa/Autotask/connection"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"

const AutotaskHelpdesk = ({ helpdeskSettings, setHelpdeskOptions }) => {
  return (
    <Box marginLeft={4}>
      <Box marginBottom={4}>
        <h4>
          {localized(
            "Options for the customization of Autotask ticket creation from help desk submit from the Systray icon",
          )}
        </h4>
      </Box>

      <Box marginBottom={4}>
        <Checkbox
          checkboxClass="icheckbox_square-blue"
          checked={helpdeskSettings.createContact}
          onChange={() => {
            setHelpdeskOptions({
              ...helpdeskSettings,
              createContact: !helpdeskSettings.createContact,
            })
          }}
        />

        <StyledSpan marginLeft={2}>
          {localized("Create contact for email addresses not already in Autotask")}
        </StyledSpan>
      </Box>

      <Box marginBottom={4}>
        <Checkbox
          checkboxClass="icheckbox_square-blue"
          checked={helpdeskSettings.embedHelpRequestImagesInTickets}
          onChange={() => {
            setHelpdeskOptions({
              ...helpdeskSettings,
              embedHelpRequestImagesInTickets: !helpdeskSettings.embedHelpRequestImagesInTickets,
            })
          }}
        />

        <StyledSpan marginLeft={2}>
          {localized("Embed images from a helpdesk request instead of providing a downlink")}
        </StyledSpan>
      </Box>
    </Box>
  )
}

export default connect(
  ({ psa }) => ({
    helpdeskSettings: psa.Autotask.helpdeskSettings,
  }),
  {
    setHelpdeskOptions,
  },
)(AutotaskHelpdesk)
