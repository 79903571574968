import {
  NONE,
  CLIENT_DEFAULT,
} from "js/includes/configuration/psa/ConnectWise/settings/ConnectWiseAccountSettings/ConnectWiseAccountMapping/LocationSiteMappings/constants"
import { cond, T, always, equals, map, compose, find, defaultTo } from "ramda"
import { localized, localizeMappingName } from "js/includes/common/utils"
import { sortListByName } from "js/includes/configuration/psa/PsaSettingsEditor"

export const getMappingName = (name, type) =>
  cond([
    [equals(CLIENT_DEFAULT), always(`${name || localized("None")} (${localized("Default")})`)],
    [equals(NONE), always(localized("None"))],
    [T, always(name)],
  ])(type)

export const localizeSingleRole = role => {
  if (!role) return null
  const { custom, name } = role
  return custom ? role : { ...role, name: localizeMappingName(name) }
}

export const localizeRoles = map(localizeSingleRole)

export const getNodeRoleValue = (nodeRoleList, nodeRoleId) =>
  compose(
    localizeSingleRole,
    find(option => option.id === nodeRoleId),
  )(nodeRoleList)

export const getNodeRoleOptions = nodeRoleList => compose(sortListByName, localizeRoles)(nodeRoleList)

export const defaultToNameNotAvailable = defaultTo(localized("general.nameNotAvailable"))
