/** @jsxImportSource @emotion/react */
import { memo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { css } from "@emotion/react"
import { EllipsisWithTooltip } from "js/includes/components/EllipsisWithTooltip"

const labelsStyle = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export default memo(({ leftText, rightText }) => (
  <span css={labelsStyle}>
    {leftText && <EllipsisWithTooltip text={leftText} />}
    {leftText && <FontAwesomeIcon icon={faArrowRight} size="sm" className="m-l-xs m-r-xs" />}
    {rightText && <EllipsisWithTooltip text={rightText} />}
  </span>
))
