import { memo } from "react"
import { Checkbox } from "@ninjaone/components"
import { contentFieldPropTypes } from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import { localized } from "js/includes/common/utils"

const CheckBoxField = memo(({ onChange, values, disabled = false, name, nameToken: labelToken, mandatory }) => {
  const checkboxId = `psa-product-${name}`
  const onCheckboxChange = () => onChange(name, !values[name])

  return (
    <Checkbox
      id={checkboxId}
      checked={values[name]}
      disabled={disabled}
      onChange={onCheckboxChange}
      label={localized(labelToken)}
      ariaLabel={localized(labelToken)}
    />
  )
})

CheckBoxField.propTypes = contentFieldPropTypes

export default CheckBoxField
