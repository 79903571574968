import { memo } from "react"

import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import {
  commonPropTypes,
  isFieldDisabled,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"

import CheckBoxField from "./CheckBoxField"

const Taxable = memo(({ enabledFields, ...props }) => (
  <CheckBoxField
    {...{
      ...props,
      name: "taxable",
      nameToken: localizationKey("Taxable"),
      disabled: isFieldDisabled({ name: "taxable", enabledFields }),
    }}
  />
))

Taxable.propTypes = commonPropTypes

export default Taxable
