import { memo } from "react"

import {
  commonPropTypes,
  isFieldDisabled,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

import MoneyField from "./MoneyField"

const RetailPrice = memo(({ onChange, values, validation, enabledFields, currency }) => {
  return (
    <MoneyField
      {...{
        onChange,
        values,
        validation,
        disabled: isFieldDisabled({ name: "price", enabledFields }),
        name: "price",
        nameToken: localizationKey("Retail price"),
        placeholderToken: localizationKey("Enter retail price"),
        mandatory: true,
        currency,
      }}
    />
  )
})

RetailPrice.propTypes = commonPropTypes

export default RetailPrice
