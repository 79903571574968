import { memo } from "react"
import { Input } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Box } from "js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils"
import {
  commonPropTypes,
  isFieldDisabled,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"

const inputId = "psa-product-name"
const Name = memo(({ onChange, values, validation, enabledFields, fieldLabelTokens }) => {
  const errorMessage = validation.message.name
  const labelToken = fieldLabelTokens?.name ?? localizationKey("Name")

  return (
    <Box marginBottom={tokens.spacing[3]}>
      <Input
        required
        id={inputId}
        type="text"
        errorMessage={errorMessage}
        labelToken={labelToken}
        ariaLabel={localized(labelToken)}
        placeholder={localized("Enter name")}
        value={values.name}
        onChange={e => onChange({ name: e.target.value })}
        maxLength={50}
        disabled={isFieldDisabled({ name: "name", enabledFields })}
      />
    </Box>
  )
})

Name.propTypes = commonPropTypes

export default Name
