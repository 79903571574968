import { combineReducers } from "redux"
import patchingVulnerabilities from "js/state/reducers/vulnerabilities/patchingVulnerabilities"
import systemVulnerabilities from "js/state/reducers/vulnerabilities/systemVulnerabilities"
import vulnerabilityFilters from "js/state/reducers/vulnerabilities/vulnerabilityFilters"

export default combineReducers({
  systemVulnerabilities,
  vulnerabilityFilters,
  patchingVulnerabilities,
})
