import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import Modal from "js/includes/components/Modal"
import {
  localized,
  localizationKey,
  capitalizeFirstLetter,
  validations,
  fetchJson,
  reportErrorAndShowMessage,
  sortByFieldNameCaseInsensitive,
  isFeatureEnabled,
} from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { useForm, useMounted } from "js/includes/common/hooks"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { compose, filter, values, map, isNil, pluck } from "ramda"
import { getReadableNodeRole } from "js/includes/common/_enums"

const selectCommonProps = {
  width: "100%",
  minHeight: "32px",
  borderRadius: "2px",
  rowHeight: 30,
  searchableKey: "name",
  valueSelectorKey: "id",
  openOnFocus: true,
}

export const activeAndInactiveOptions = () => [
  {
    name: capitalizeFirstLetter(localized("active")),
    id: "ACTIVE",
  },
  {
    name: capitalizeFirstLetter(localized("inactive")),
    id: "INACTIVE",
  },
]

const mapRoleToOption = role => ({
  name: getReadableNodeRole(role.name),
  id: role.id,
})

const mapConfigurationTypeToOption = mapping => ({
  name: mapping.installedProductCategoryName,
  id: mapping.installedProductCategoryId,
})

const mapAutotaskProductToOption = mapping => ({
  name: mapping.productName,
  id: mapping.productId,
})

const AutotaskDeviceRoleMappingModal = ({ unmount, mapping, mappings, nodeRoleMap, productList, saveMappings }) => {
  const mounted = useMounted()
  const nodeRoleSet = new Set(pluck("nodeRoleId", mappings))
  const [nodeRoleOptions, setNodeRoleOptions] = useState([])
  const [installedProductCategories, setInstalledProductCategories] = useState([])
  const {
    values: { nodeRole, configurationType, autotaskProduct },
    onChange,
    validation,
    validateForm,
  } = useForm({
    fields: {
      nodeRole: nodeRoleMap[mapping?.nodeRoleId] ? mapRoleToOption(nodeRoleMap[mapping?.nodeRoleId]) : {},
      configurationType: !isNil(mapping?.installedProductCategoryId) ? mapConfigurationTypeToOption(mapping) : {},
      autotaskProduct: !isNil(mapping?.productId) ? mapAutotaskProductToOption(mapping) : {},
    },
    validate: {
      nodeRole: validations.required,
      configurationType: validations.required,
      autotaskProduct: validations.required,
    },
  })

  useEffect(() => {
    setNodeRoleOptions(compose(map(mapRoleToOption), values)(nodeRoleMap))

    const getInstalledProductCategoriesList = async () => {
      try {
        const installedProductCategories = await fetchJson("/psaat/v3/installedProductCategoriesList")
        mounted.current && setInstalledProductCategories(installedProductCategories)
      } catch (error) {
        reportErrorAndShowMessage(error)
      }
    }

    getInstalledProductCategoriesList()
  }, [mounted, nodeRoleMap])

  return (
    <Modal
      overflow
      title={localizationKey("Edit Mapping")}
      saveText={localizationKey("OK")}
      closeText={localizationKey("Cancel")}
      dialogClassName="psa-modal"
      close={() => {
        unmount()
      }}
      save={() => {
        if (validateForm()) {
          const lastId = mappings.reduce((acc, { id }) => (acc > id ? acc : id), 0)

          const updatedNodeRoleCategoryMapping = {
            id: isNil(mapping?.id) ? lastId + 1 : mapping?.id,
            nodeRoleId: nodeRole.id,
            productId: autotaskProduct.id,
            productName: autotaskProduct.name,
            installedProductCategoryId: configurationType.id,
            installedProductCategoryName: configurationType.name,
          }

          saveMappings(updatedNodeRoleCategoryMapping, mapping)
          unmount()
        }
      }}
    >
      <Box textAlign="left" display="flex" margin={[0, 4, 4, 4]} flexDirection="column">
        <Box>
          <h4>{localized("Device Role")}*</h4>

          <SearchableDropDown
            {...{
              ...selectCommonProps,
              value: nodeRole,
              validationState: validation.success.nodeRole === false ? "error" : null,
              options: compose(
                sortByFieldNameCaseInsensitive("name", "ASC"),
                filter(n => !nodeRoleSet.has(n.id)),
              )(nodeRoleOptions),
              onSelect: selection => {
                onChange("nodeRole", selection)
              },
            }}
          />
        </Box>

        <Box paddingTop="20px">
          <h4>{localized("Configuration Type")}*</h4>

          <SearchableDropDown
            {...{
              ...selectCommonProps,
              options: sortByFieldNameCaseInsensitive("name", "ASC", installedProductCategories),
              value: configurationType,
              validationState: validation.success.configurationType === false ? "error" : null,
              onSelect: selection => {
                onChange("configurationType", selection)
              },
            }}
          />
        </Box>

        <Box paddingTop="20px">
          <h4>{localized("Autotask Product")}*</h4>

          <SearchableDropDown
            {...{
              ...selectCommonProps,
              value: autotaskProduct,
              validationState: validation.success.autotaskProduct === false ? "error" : null,
              onSelect: selection => {
                onChange("autotaskProduct", selection)
              },
              ...(isFeatureEnabled("autotask_big_data")
                ? {
                    pageSize: 500,
                    endpoint: "/psaat/v2/productsPaginated",
                    searchParams: ({ query }) => ({
                      ...(query && { productNameParam: query }),
                    }),
                    lastItemKey: "id",
                    responseParser: payload => payload?.productList ?? [],
                  }
                : {
                    options: sortByFieldNameCaseInsensitive("name", "ASC", productList),
                  }),
            }}
          />
        </Box>
      </Box>
    </Modal>
  )
}

AutotaskDeviceRoleMappingModal.defaultProps = {
  mappings: [],
}

export default connect(({ psa }) => ({
  productList: psa.Autotask.settings.productList,
}))(AutotaskDeviceRoleMappingModal)
