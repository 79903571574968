import React from "react"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { faSyncAlt } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const AUTO_MAP = {
  id: "cw-auto-map",
  token: localizationKey("Auto Map"),
}

export const CLEAR_MAPPING = {
  id: "cw-clear-mapping",
  token: localizationKey("Clear Mapping"),
}

export const REFETCH_CW_ACCOUNT_OPTIONS = {
  id: "cw-refetch-account-options",
  token: localizationKey("Refresh ConnectWise Data"),
}

export const REFETCH_CW_ACCOUNT_OPTIONS_LOADING = {
  id: "cw-refetch-account-options",
  label: () => (
    <span>
      {localized("Refresh ConnectWise Data")}
      <span className="m-l-sm">
        <FontAwesomeIcon icon={faSyncAlt} spin />
      </span>
    </span>
  ),
}
