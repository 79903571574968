import React from "react"
import { Tabs, Tab } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import ConnectWiseAccountSettings from "js/includes/configuration/psa/ConnectWise/settings/ConnectWiseAccountSettings"
import ConnectwiseHelpDesk from "js/includes/configuration/psa/ConnectWise/settings/ConnectWiseAccountSettings/ConnectwiseHelpDesk"

export default function ConnectWiseSettingsCompaniesTab({ loading }) {
  return (
    <div className="m-l-md display-flex flex-full">
      <Tabs
        animation={false}
        defaultActiveKey="sync"
        id="connectwise-settingscompanies-tabs"
        className="custom-nav-tabs"
      >
        <Tab eventKey="sync" title={localized("Sync")}>
          <div className="display-flex flex-full">
            <ConnectWiseAccountSettings {...{ loading }} />
          </div>
        </Tab>
        <Tab eventKey="helpdesk" title={localized("Help Desk")}>
          <div className="display-flex flex-full">
            <ConnectwiseHelpDesk />
          </div>
        </Tab>
      </Tabs>
    </div>
  )
}
