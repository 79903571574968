import { reject, whereEq } from "ramda"

export default function oidConfigsFromPolicy(state = [], action) {
  switch (action.type) {
    case "SET_OIDS_FROM_POLICY":
      return action.oids
    case "ADD_OIDS_TO_POLICY":
      return [...state, ...action.oids]
    case "REMOVE_OID_FROM_POLICY":
      return reject(whereEq({ oid: action.oid, mib: action.mib }), state)
    default:
      return state
  }
}
