import { memo, useState, useEffect } from "react"
import { RadioGroup } from "@ninjaone/components"
import { localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils"
import {
  anyRoleValue,
  specificRoleValue,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/formCommons"
import { isFieldDisabled } from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import { Box } from "js/includes/components/Styled"

import DropdownField from "./DropdownField"

const name = "nodeRoleId"

const Role = memo(({ onChange, values, validation, enabledFields, options, validateFormFields }) => {
  const [dropdownKey, setDropdownKey] = useState(values[name])

  useEffect(() => {
    if (values.anyRole) validateFormFields([name])
  }, [values.anyRole, validateFormFields])

  return (
    <Box>
      <RadioGroup
        value={values.anyRole ? anyRoleValue : specificRoleValue}
        labelText={localized("Role")}
        options={[
          {
            label: localized("All roles"),
            value: anyRoleValue,
            disabled: isFieldDisabled({ name, enabledFields }),
          },
          {
            label: localized("Specific role"),
            value: specificRoleValue,
            disabled: isFieldDisabled({ name, enabledFields }),
          },
        ]}
        onValueChange={value => {
          onChange({
            anyRole: value === anyRoleValue,
          })
          setDropdownKey(value)
        }}
      />
      <DropdownField
        {...{
          key: dropdownKey,
          name,
          options,
          onChange,
          values,
          validation,
          disabled: isFieldDisabled({ name, enabledFields }) || values.anyRole,
          nameToken: localizationKey("Roles"),
          mandatory: true,
        }}
      />
    </Box>
  )
})

export default Role
