import { fetchJson, localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"
import { getPlansActions } from "./actions"
import { getPlansColumns } from "./columns"
import { isAnyImagePlan, planTypesMap } from "js/includes/common/backup"

const fetchData = async ({ node }) => {
  //TODO: Add pagination result on response
  const response = await fetchJson(`/backup/lockhart/nodes/${node.id}/plans`)

  const plans = response.map(
    ({
      uid: planId,
      deleted: planDeleted,
      enabled: planEnabled,
      name,
      schedule,
      type,
      destination,
      backupPermissions,
      lastSuccess,
      storage,
    }) => {
      return {
        name,
        node,
        type: planTypesMap[type] ?? type,
        planId,
        schedule,
        planDeleted,
        planEnabled,
        plan: true,
        path: planId,
        folder: true,
        folders: true,
        imagePlan: isAnyImagePlan(type),
        destination,
        backupPermissions,
        lastSuccess,
        storage,
      }
    },
  )

  return {
    data: plans,
  }
}

const onError = error =>
  reportErrorAndShowMessage(error, localizationKey("There was an error fetching backup plans for current device"))

export const getPlansHandler = () => ({
  columns: getPlansColumns(),
  actions: getPlansActions(),
  fetchData,
  onError,
})
