import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { values } from "ramda"
import { noRowsRenderer, headerRenderer } from "js/includes/common/utils/react-virtualized"
import {
  setCurrentProductMapping as _setCurrentProductMapping,
  showProductMappingModal as _showProductMappingModal,
  removeProductMapping as _removeProductMapping,
  copyProductMapping as _copyProductMapping,
} from "js/state/actions/psa/ConnectWise/mappings/products/products"
import { getProductMappingList } from "js/state/selectors/psa/ConnectWise/mappings/products"
import { sort as _sort } from "js/state/actions/common/tableSorting"
import { faTrash } from "@fortawesome/pro-solid-svg-icons"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import SelectableKeyboardShortcutsTable from "js/includes/components/SelectableKeyboardShortcutsTable"
import { getTempId } from "js/state/actions/psa/ConnectWise/mappings/common"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

import { ADD_MAPPING, EDIT_MAPPING, DELETE_MAPPING, COPY_MAPPING } from "./actions"

const columns = () => [
  {
    headerRenderer,
    dataKey: "name",
    label: localizationKey("Name"),
    headerClassName: "col-xs-4",
    width: 1,
    flexGrow: 1,
  },
]

class ProductMappingTable extends PureComponent {
  state = {
    selectedRows: [],
  }

  DEFAULT_HEIGHT = 35

  onEdit = data => {
    this.props.setCurrentProductMapping(data)
    this.props.showProductMappingModal("EDIT", data.id)
  }

  onDelete = async id => {
    const buttonPressed = await ShowMessageDialog({
      icon: { icon: faTrash, type: "critical" },
      title: localizationKey("Delete Product mapping"),
      message: localizationKey("Are you sure you want to delete this product mapping?"),
      buttons: [
        { id: "YES", label: localizationKey("Yes"), type: "critical" },
        { id: "NO", label: localizationKey("No") },
      ],
    })
    if (buttonPressed === "YES") {
      await this.props.removeProductMapping(id)
    }
  }

  onCopy = data => {
    const { copyProductMapping, productMappings, setCurrentProductMapping } = this.props
    const payload = { ...data, name: `${data.name} (${localized("Copy")})`, id: getTempId(productMappings) }

    setCurrentProductMapping(payload)
    copyProductMapping(payload)
  }

  getActions = () => {
    const { selectedRows } = this.state

    return [ADD_MAPPING, ...(selectedRows.length === 1 ? [EDIT_MAPPING, DELETE_MAPPING, COPY_MAPPING] : [])]
  }

  onAction = action => {
    const { selectedRows } = this.state
    const { id } = action

    const [selectedRow] = values(selectedRows.rows)

    switch (id) {
      case ADD_MAPPING.id:
        return this.props.addNewMapping()
      case EDIT_MAPPING.id:
        return this.onEdit(selectedRow)
      case DELETE_MAPPING.id:
        return this.onDelete(selectedRow.id)
      case COPY_MAPPING.id:
        return this.onCopy(selectedRow)
      default:
        throw new Error(`Invalid action: ${action.id}`)
    }
  }

  render() {
    const { productMappings, sort, sortBy, sortDirection } = this.props
    return (
      <SelectableKeyboardShortcutsTable
        {...{
          sort,
          sortBy,
          sortDirection,
          columns,
          data: productMappings,
          searchable: ["name"],
          actions: this.getActions(),
          onAction: this.onAction,
          headerHeight: this.DEFAULT_HEIGHT,
          rowRenderer: this.rowRenderer,
          rowHeight: this.DEFAULT_HEIGHT,
          onCheck: ({ selected }) => this.setState({ selectedRows: selected }),
          noRowsRenderer: noRowsRenderer(localizationKey("No Mappings")),
          onRowClick: ({ rowData }) => {
            this.onEdit(rowData)
          },
        }}
      />
    )
  }
}

export default connect(
  ({ psa }) => ({
    productMappings: getProductMappingList(psa.ConnectWise.mappings),
    sortBy: psa.ConnectWise.mappings.products.productMappingSort.sortBy,
    sortDirection: psa.ConnectWise.mappings.products.productMappingSort.sortDirection,
  }),
  {
    sort: _sort("CWProductMappingSort"),
    setCurrentProductMapping: _setCurrentProductMapping,
    showProductMappingModal: _showProductMappingModal,
    removeProductMapping: _removeProductMapping,
    copyProductMapping: _copyProductMapping,
  },
)(ProductMappingTable)
