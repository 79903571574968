import { memo } from "react"
import { Input } from "@ninjaone/components"
import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils"
import {
  commonPropTypes,
  isFieldDisabled,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import { Box } from "js/includes/components/Styled"

const StyledTextArea = styled(Input)`
  height: 120px;
  resize: none;
`

const inputId = "psa-product-description"
const Description = memo(({ onChange, values, validation, enabledFields, fieldTooltipInfoTokens }) => {
  const errorMessage = validation.message.description
  const labelToken = localizationKey("Description")
  const infoTooltipToken = fieldTooltipInfoTokens?.description
  const placeholder = localized("Enter description")

  return (
    <Box marginBottom={tokens.spacing[3]}>
      <StyledTextArea
        required
        _isTextArea
        id={inputId}
        resize="vertical"
        ariaLabel={labelToken}
        labelToken={labelToken}
        placeholder={placeholder}
        errorMessage={errorMessage}
        value={values.description}
        onChange={e => onChange({ description: e.target.value })}
        maxLength={250}
        disabled={isFieldDisabled({ name: "description", enabledFields })}
        tooltipText={localized(infoTooltipToken)}
      />
    </Box>
  )
})

Description.propTypes = commonPropTypes

export default Description
