import { useMemo } from "react"
import PropTypes from "prop-types"

import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import { Content, Portal, Provider, Root, Trigger } from "@radix-ui/react-tooltip"

import { getTextSize, sizer, useMountedState } from "@ninjaone/utils"

const StyledContent = styled(Content, {
  shouldForwardProp: prop => isPropValid(prop) || prop !== "contentZIndex",
})`
  font-size: ${getTextSize("xs")};
  padding: ${sizer(1, 2)};
  color: ${({ theme }) => theme.colorTextHighContrast};
  border-radius: ${sizer(1)};
  max-width: 400px;
  line-height: 1.5;
  z-index: ${({ contentZIndex }) => contentZIndex || 9999};
  white-space: pre-line;
  overflow-wrap: break-word;

  ${({ theme, variant }) => getStylesByVariant({ theme, variant })}
`

export const VARIANTS = {
  DANGER: "danger",
  LIGHT: "light",
}

export const TOOLTIP_DELAY = 100

const getStylesByVariant = ({ theme, variant }) => {
  switch (variant) {
    case VARIANTS.DANGER:
      return `
        background-color: ${theme.colorBackgroundCtaDanger};
        color: ${theme.colorTextHighContrast};
        border-color: ${theme.colorBackgroundCtaDanger};
      `
    case VARIANTS.LIGHT:
      return `
        background-color: ${theme.color.white["100"]};
        color: ${theme.color.black["100"]};
        border-color: ${theme.color.black["015"]};
        box-shadow: 0 2px 4px ${theme.color.black["005"]};
      `
    default:
      return `
        background-color: ${theme.colorBackgroundAccentNeutralDarker};
        color: ${theme.colorTextHighContrast};
        border-color: ${theme.colorBackgroundAccentNeutralDarker};
      `
  }
}
export default function Tooltip({
  label,
  labelRenderer,
  variant,
  children,
  ariaLabel,
  className,
  customPosition,
  hideTooltip,
  isErrorMessage,
  sideOffset = 8,
  align = "start",
  triggerClassName,
  delayToOpen = TOOLTIP_DELAY,
  position = "bottom",
  // When hideOnTriggerClick is false, the hideWhenDetached prop doesn't work.
  hideOnTriggerClick = true,
  onEscapeKeyDown,
  portal = true,
  contentZIndex,
  asChild,
  triggerAsChild = true,
}) {
  const [tooltipOpenState, setTooltipOpenState] = useMountedState(false)

  const tooltipOpen = useMemo(() => {
    if (typeof hideTooltip === "boolean" && hideTooltip) return false
    if (!hideOnTriggerClick) return tooltipOpenState

    return
  }, [hideTooltip, hideOnTriggerClick, tooltipOpenState])

  const hideOnTriggerClickFunctions = !hideOnTriggerClick && {
    onMouseOver: () => setTooltipOpenState(true),
    onFocus: () => setTooltipOpenState(true),
    onMouseOut: () => setTooltipOpenState(false),
    onPointerUp: () => setTooltipOpenState(false),
    onBlur: () => setTooltipOpenState(false),
  }

  const content = (
    <StyledContent
      {...{
        align,
        sideOffset,
        side: position,
        "aria-label": ariaLabel,
        variant: isErrorMessage ? VARIANTS.DANGER : variant,
        onEscapeKeyDown,
        className,
        "data-ninja-tooltip-content": "",
        contentZIndex,
      }}
    >
      {labelRenderer ? labelRenderer() : label}
    </StyledContent>
  )

  return (
    <Provider delayDuration={delayToOpen}>
      <Root {...{ open: tooltipOpen }}>
        <Trigger
          data-testid="data-ninja-tooltip-trigger"
          className={triggerClassName}
          data-ninja-tooltip-trigger=""
          asChild={triggerAsChild}
          type="button"
          {...hideOnTriggerClickFunctions}
        >
          {asChild ? children : <span>{children}</span>}
        </Trigger>

        {portal ? <Portal>{content}</Portal> : content}
      </Root>
    </Provider>
  )
}

Tooltip.propTypes = {
  label: PropTypes.node,
  labelRenderer: PropTypes.func,
  children: PropTypes.node,
  ariaLabel: PropTypes.string,
  hideTooltip: PropTypes.bool,
  sideOffset: PropTypes.number,
  delayToOpen: PropTypes.number,
  isErrorMessage: PropTypes.bool,
  triggerClassName: PropTypes.string,
  triggerAsChild: PropTypes.bool,
  hideOnTriggerClick: PropTypes.bool,
  portal: PropTypes.bool,
  asChild: PropTypes.bool,
  align: PropTypes.oneOf(["start", "center", "end"]),
  variant: PropTypes.oneOf([VARIANTS.DANGER, VARIANTS.LIGHT]),
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  contentZIndex: PropTypes.number,
}
