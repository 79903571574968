import React, { useEffect, useState, memo, useCallback } from "react"
import { nanoid } from "nanoid"
import { clone, append, reject, propEq } from "ramda"
import { getFirstValue } from "js/includes/common/utils"
import { defaultTranslationTokens, defaultControlElements, defaultOperators } from "./defaults"
import { defineValidations } from "js/includes/ticketing/TriggerEditor/utils"
import { DEFAULT_HOURS_SINCE_VALUE } from "js/includes/ticketing/TriggerEditor/constants"

const RuleEditor = memo(props => {
  const {
    query,
    combinator,
    onQueryChange,
    controlElements,
    fields: { options: fields, optionsMap: fieldsMap },
    oneTimeFields,
    resetOnFieldChange,
    getValueEditorType,
    getValidations: _getValidations,
    displayErrors,
  } = props

  const getValidations = useCallback(
    (field, operator, metaData) => {
      return defineValidations({ getValidations: _getValidations, field, operator, metaData })
    },
    [_getValidations],
  )

  const getInitialQuery = useCallback(() => {
    return query || []
  }, [query])

  const createRule = () => {
    const field = getFirstValue(fields)
    const operator = getFirstValue(getOperators(field))
    return {
      id: nanoid(10),
      field,
      value: "",
      operator,
      touched: false,
      validation: getValidations(field, operator)(""),
    }
  }

  const _getValueEditorType = (field, operator) => {
    if (getValueEditorType) {
      const vet = getValueEditorType(field, operator)
      if (vet) return vet
    }
    return "text"
  }

  const getInputType = (field, operator) => {
    if (props.getInputType) {
      const inputType = props.getInputType(field, operator)
      if (inputType) return inputType
    }
    return "text"
  }

  const getValues = (field, operator) => {
    if (props.getValues) {
      const vals = props.getValues(field, operator)
      if (vals) return vals
    }
    return []
  }

  const getOperators = field => {
    if (props.getOperators) {
      const ops = props.getOperators(field)
      if (ops) return ops
    }
    return props.operators
  }

  const getRuleDefaultValue = rule => {
    const values = getValues(rule.field, rule.operator)
    const editorType = _getValueEditorType(rule.field, rule.operator)

    if (rule.operator?.startsWith("hours_since:")) return DEFAULT_HOURS_SINCE_VALUE
    if (["present", "not_present", "changed"].includes(rule.operator)) return null
    if (editorType === "multi-select") return ""
    if (editorType === "multi-creatable-select") return []
    if (typeof editorType === "function") return null
    if (values.length) return getFirstValue(values)
    if (editorType === "checkbox") return false

    return ""
  }

  const onRuleAdd = rule => {
    const defaultValue = getRuleDefaultValue(rule)
    const newRoot = append(
      { ...rule, value: defaultValue, touched: false, validation: getValidations(rule.field)(defaultValue) },
      root,
    )

    setRoot(newRoot)
    _notifyQueryChange(newRoot)
  }

  const onPropChange = (prop, value, ruleId) => {
    const newRoot = root.map(rule => (rule.id === ruleId ? updateRuleValue(prop, value, rule) : rule))
    setRoot(newRoot)
    _notifyQueryChange(newRoot)
  }

  const updateRuleValue = (prop, value, rule) => {
    const updatedRule = {
      ...rule,
      touched: true,
      validation: getValidations(rule.field, rule.operator, rule.metaData)(prop === "operator" ? rule.value : value),
      [prop]: value,
    }
    if (resetOnFieldChange && prop === "field") {
      const updatedOperator = getFirstValue(getOperators(updatedRule.field))

      const defaultValue = getRuleDefaultValue({
        ...updatedRule,
        operator: updatedOperator,
      })

      return {
        ...updatedRule,
        operator: updatedOperator,
        value: defaultValue,
        touched: false,
        validation: getValidations(updatedRule.field, updatedOperator, updatedRule.metaData)(defaultValue),
      }
    }

    if (prop === "operator") {
      const defaultValue = getRuleDefaultValue(updatedRule)
      return {
        ...updatedRule,
        validation: getValidations(updatedRule.field, updatedRule.operator, updatedRule.metaData)(defaultValue),
        value: defaultValue,
        touched: false,
      }
    }

    return updatedRule
  }

  const onRuleRemove = ruleId => {
    const newRoot = reject(propEq("id", ruleId), root)
    setRoot(newRoot)
    _notifyQueryChange(newRoot)
  }

  const _notifyQueryChange = useCallback(
    newRoot => {
      if (onQueryChange) {
        const query = clone(newRoot)
        onQueryChange(query)
      }
    },
    [onQueryChange],
  )

  const [root, setRoot] = useState(getInitialQuery())

  const schema = {
    fields,
    fieldsMap,
    excludeOptions: root.map(({ field }) => field).filter(field => oneTimeFields.includes(field)),
    combinator,
    createRule,
    onRuleAdd,
    onRuleRemove,
    onPropChange,
    controls: { ...defaultControlElements, ...controlElements },
    getOperators,
    getValueEditorType: _getValueEditorType,
    getInputType,
    getValues,
  }

  useEffect(() => {
    setRoot(getInitialQuery())
  }, [getInitialQuery])

  return (
    <schema.controls.ruleGroup
      translationTokens={{ ...defaultTranslationTokens, ...props.translationTokens }}
      rules={root}
      combinator={combinator}
      schema={schema}
      displayErrors={displayErrors}
    />
  )
})

RuleEditor.defaultProps = {
  query: [],
  fields: {
    options: [],
    optionsMap: {},
  },
  oneTimeFields: [],
  operators: defaultOperators,
  combinator: "all",
  translationsTokens: defaultTranslationTokens,
  controlElements: null,
  getOperators: null,
  getValidations: null,
  getValueEditorType: null,
  getInputType: null,
  getValues: null,
  onQueryChange: null,
  resetOnFieldChange: true,
  displayErrors: false,
}

export default RuleEditor
