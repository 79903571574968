import PropTypes from "prop-types"

import Loading from "js/includes/components/Loading"

export const LoadingWrapper = ({ isLoading, children }) => {
  return isLoading ? <Loading /> : children
}

LoadingWrapper.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
