import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { noRowsRenderer, headerRenderer, localized, localizationKey } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import Table from "js/includes/components/Table"
import showModal from "js/includes/common/services/showModal"
import AutotaskProductConfigurationMappingModal from "./AutotaskProductConfigurationMappingModal"
import { saveNodeRoleMappings } from "js/state/actions/psa/Autotask/settings"
import { reject } from "ramda"

class AutotaskDeviceRoles extends PureComponent {
  state = {
    sortBy: "configurationTypeName",
    sortDirection: "DESC",
  }

  columns = [
    {
      headerRenderer,
      dataKey: "name",
      width: 1,
      flexGrow: 2,
      label: localizationKey("Name"),
    },
    {
      dataKey: "actions",
      width: 1,
      flexGrow: 0.5,
      label: () => null,
      headerRenderer: () => {
        const { nodeRoleMappings } = this
        return (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <button
              className="btn-link"
              type="button"
              onClick={() =>
                showModal(<AutotaskProductConfigurationMappingModal {...{ nodeRoleMappings }} />, {
                  withProvider: true,
                })
              }
            >
              {localized("Add")}
            </button>
          </Box>
        )
      },
      cellRenderer: ({ rowData, index }) => (
        <span className="action-links">
          <button
            type="button"
            className="btn-link text-danger"
            onClick={e => {
              e.stopPropagation()
              const { nodeRoleMappings, saveNodeRoleMappings } = this.props

              const updatedNodeRoleMappings = reject(mapping => mapping.id === rowData.id, nodeRoleMappings)

              saveNodeRoleMappings(updatedNodeRoleMappings)
            }}
          >
            {localized("Delete")}
          </button>
        </span>
      ),
    },
  ]

  render() {
    const {
      props: { nodeRoleMappings },
      state: { sortBy, sortDirection },
      columns,
    } = this

    return (
      <Box display="flex" flex={1} marginLeft={3}>
        <Table
          {...{
            columns,
            sortBy,
            sortDirection,
            list: nodeRoleMappings,
            rowHeight: 50,
            headerHeight: 45,
            noRowsRenderer: noRowsRenderer(localizationKey("No Mappings"), false, "no-padding m-l-xs"),
            sort: ({ sortBy, sortDirection }) => this.setState({ sortBy, sortDirection }),
            onRowClick: ({ rowData, index }) => {
              showModal(
                <AutotaskProductConfigurationMappingModal
                  index={index}
                  nodeRoleMappings={this.props.nodeRoleMappings}
                  mapping={rowData}
                />,
                { withProvider: true },
              )
            },
          }}
        />
      </Box>
    )
  }
}

export default connect(
  ({ psa }) => ({
    nodeRoleMappings: psa.Autotask.settings.nodeRoleMappings,
  }),
  {
    saveNodeRoleMappings,
  },
)(AutotaskDeviceRoles)
