import PropTypes from "prop-types"
import { map } from "ramda"
import { memo } from "react"

import { localizationKey, localized } from "js/includes/common/utils"
import {
  commonPropTypes,
  isFieldDisabled,
  optionsType,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import DropdownField from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentFields/DropdownField"

const UserRoles = memo(({ onChange: _onChange, values, validation, enabledFields, userRolesData }) => {
  const onChange = (fieldName, values) => {
    _onChange(
      fieldName,
      map(value => userRolesData.optionsMap[value].role, values),
    )
  }

  return (
    <DropdownField
      {...{
        onChange,
        values,
        validation,
        disabled: isFieldDisabled({ name: "userRoles", enabledFields }),
        options: userRolesData.options,
        isMulti: true,
        name: "userRoles",
        nameToken: localizationKey("Application user roles"),
        getValue: () =>
          map(role => {
            const roleId = role.id || role.type
            return userRolesData.optionsMap[roleId]?.option || { labelText: localized("N/A"), value: roleId }
          }, values.userRoles),
      }}
    />
  )
})

UserRoles.propTypes = {
  ...commonPropTypes,
  userRolesData: PropTypes.shape({
    options: optionsType.isRequired,
    optionsMap: PropTypes.shape({
      option: PropTypes.object,
      role: PropTypes.object,
    }).isRequired,
  }),
}

export default UserRoles
