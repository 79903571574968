import { useEffect, useRef } from "react"
import { nanoid } from "nanoid"
import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { mergeRegister } from "@lexical/utils"
import { COMMAND_PRIORITY_CRITICAL } from "lexical"

import {
  HorizontalRuleLightIcon,
  ImageLightIcon,
  PaperclipLightIcon,
  PlusLightIcon,
  TableIconLight,
} from "@ninjaone/icons"
import {
  acceptedAttachmentFiles,
  acceptedAttachmentFileMimeTypes,
  acceptedImageFileMimeTypes,
  ATTACHMENT_FILE_EXTENSIONS,
  ATTACHMENT_FILE_MIME_TYPES,
  IMAGE_FILE_MIME_TYPES,
  localizationKey,
  localized,
  joinByComma,
} from "@ninjaone/webapp/src/js/includes/common/utils"
import showModal from "@ninjaone/webapp/src/js/includes/common/services/showModal"

import Text from "../../../../Text"

import {
  Dropdown,
  DROPDOWN_ICON_COLOR,
  DROPDOWN_TEXT_COLOR,
  DropdownItem,
  InsertTableDialog,
} from "../../../Components"
import { mediaFileReader, isValidAttachmentFile, isValidImageFile } from "../../../utils"
import { StyledHiddenInput } from "../../../styled"
import { ALLOW_IMAGE_PLUGIN_COMMAND, INSERT_IMAGE_COMMAND } from "../../ImagePlugin"
import {
  ALLOW_ATTACHMENT_PLUGIN_COMMAND,
  INSERT_ATTACHMENT_BEGAN_COMMAND,
  INSERT_ATTACHMENT_COMPLETED_COMMAND,
  REMOVE_ATTACHMENT_COMMAND,
} from "../../AttachmentPlugin"
import { StyledToolbarItem } from "../styled"

const acceptedAttachments = joinByComma([acceptedAttachmentFiles, acceptedAttachmentFileMimeTypes])

export const InsertSection = ({
  activeEditor,
  isDisabled,
  cidKey,
  tableModalBackgroundClassName,
  dropdownClassName,
  onUploadImage,
  onUploadImageError,
  onUploadAttachment,
  onUploadAttachmentError,
  allowImages,
  setAllowImages,
  allowAttachments,
  setAllowAttachments,
}) => {
  const [editor] = useLexicalComposerContext()
  const imageInputRef = useRef(null)
  const attachmentInputRef = useRef(null)

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        ALLOW_IMAGE_PLUGIN_COMMAND,
        payload => {
          setAllowImages(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      editor.registerCommand(
        ALLOW_ATTACHMENT_PLUGIN_COMMAND,
        payload => {
          setAllowAttachments(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    )
  }, [editor, setAllowImages, setAllowAttachments])

  async function handleUpload(files, acceptableMimeTypes, acceptableExtensions) {
    return mediaFileReader(files, acceptableMimeTypes, acceptableExtensions)
  }

  async function handleImageChange(event) {
    const filesResult = await handleUpload(event.target.files, IMAGE_FILE_MIME_TYPES)
    event.target.value = ""
    for (const { file, result } of filesResult) {
      if (isValidImageFile(file)) {
        try {
          const response = await onUploadImage(file)
          const src = result
          const cid = response[cidKey]
          activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, { src, cid, response })
        } catch (error) {
          onUploadImageError?.(error)
        }
      }
    }
  }

  async function handleAttachmentChange(event) {
    const filesResult = await handleUpload(event.target.files, ATTACHMENT_FILE_MIME_TYPES, ATTACHMENT_FILE_EXTENSIONS)
    event.target.value = ""
    for (const { file } of filesResult) {
      if (isValidAttachmentFile(file)) {
        const id = nanoid()
        try {
          activeEditor.dispatchCommand(INSERT_ATTACHMENT_BEGAN_COMMAND, { id, file })
          const response = await onUploadAttachment(file)
          activeEditor.dispatchCommand(INSERT_ATTACHMENT_COMPLETED_COMMAND, { id, file, response })
        } catch (error) {
          activeEditor.dispatchCommand(REMOVE_ATTACHMENT_COMMAND, { id, file })
          onUploadAttachmentError?.(error)
        }
      }
    }
  }

  return (
    <>
      <Dropdown
        dropdownClassName={dropdownClassName}
        disabled={isDisabled}
        ButtonComponent={StyledToolbarItem}
        buttonAriaLabel={localized("Formatting options for additional text styles")}
        ButtonIcon={PlusLightIcon}
      >
        {allowAttachments && (
          <DropdownItem
            onClick={event => {
              event.preventDefault()
              attachmentInputRef.current.click()
            }}
            title={localized("Insert attachment")}
            aria-label={localized("Insert attachment")}
          >
            <PaperclipLightIcon color={DROPDOWN_ICON_COLOR} />
            <Text size="sm" color={DROPDOWN_TEXT_COLOR} token={localizationKey("Attachment")} />
          </DropdownItem>
        )}
        <DropdownItem
          onClick={() => activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined)}
          title={localized("Insert horizontal rule")}
          aria-label={localized("Insert horizontal rule")}
        >
          <HorizontalRuleLightIcon color={DROPDOWN_ICON_COLOR} />
          <Text size="sm" color={DROPDOWN_TEXT_COLOR} token={localizationKey("Horizontal Rule")} />
        </DropdownItem>
        {allowImages && (
          <DropdownItem
            onClick={event => {
              event.preventDefault()
              imageInputRef.current.click()
            }}
            title={localized("Insert image")}
            aria-label={localized("Insert image")}
          >
            <ImageLightIcon color={DROPDOWN_ICON_COLOR} />
            <Text size="sm" color={DROPDOWN_TEXT_COLOR} token={localizationKey("Image")} />
          </DropdownItem>
        )}
        <DropdownItem
          onClick={() =>
            showModal(<InsertTableDialog {...{ activeEditor, backgroundClassName: tableModalBackgroundClassName }} />)
          }
          title={localized("Insert table")}
          aria-label={localized("Insert table")}
        >
          <TableIconLight color={DROPDOWN_ICON_COLOR} />
          <Text size="sm" color={DROPDOWN_TEXT_COLOR} token={localizationKey("Table")} />
        </DropdownItem>
      </Dropdown>
      {allowAttachments && (
        <StyledHiddenInput
          ref={attachmentInputRef}
          type="file"
          accept={acceptedAttachments}
          onChange={handleAttachmentChange}
        />
      )}
      {allowImages && (
        <StyledHiddenInput
          ref={imageInputRef}
          type="file"
          accept={acceptedImageFileMimeTypes}
          onChange={handleImageChange}
        />
      )}
    </>
  )
}
