import { reduce } from "ramda"
import React, { memo, useMemo } from "react"

import { Text } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"

import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { localized, sortByFieldNameCaseInsensitive } from "js/includes/common/utils"
import StyledDraggableList from "js/includes/components/Styled/DraggableList/StyledDraggableList"
import { Box } from "js/includes/components/Styled"
import { StyledDraggableListHeader } from "js/includes/components/Styled/Draggable"
import { productsPropType, productTypes } from "js/includes/configuration/integrations/psa/psaProducts/productCommons"
import {
  commonPropTypes,
  isFieldDisabled,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"

const allowedTypes = new Set([productTypes.HARDWARE, productTypes.SOFTWARE, productTypes.LABOR_BILLED])

const ProductsGroup = memo(({ onChange, values, validation, enabledFields, products = [] }) => {
  const name = "productIds"

  const { groupProductOptions, optionsMap } = useMemo(() => {
    const optionsMap = {}
    const groupProductOptions = sortByFieldNameCaseInsensitive(
      "label",
      "ASC",
      reduce(
        (acc, p) => {
          if (allowedTypes.has(p.type)) {
            const option = { value: p.id, label: p.name, isActive: p.isActive }
            acc.push(option)
            optionsMap[option.value] = option
          }
          return acc
        },
        [],
        products,
      ),
    )
    return {
      groupProductOptions,
      optionsMap,
    }
  }, [products])

  const optionIds = values[name]
  const selectedOptionIdsSet = new Set(optionIds)

  const selectedOptions = optionIds.map(pId => optionsMap[pId] || { value: pId, label: localized("Deleted") })

  const availableOptions = groupProductOptions.filter(p => p.isActive && !selectedOptionIdsSet.has(p.value))

  const onOptionSelected = option => onChange(name, [...optionIds, option.value])

  const onOptionRemoved = optionId =>
    onChange(
      name,
      optionIds.filter(pId => pId !== optionId),
    )

  const onOptionMoved = options =>
    onChange(
      name,
      options.map(o => o.value),
    )

  const disableField = isFieldDisabled({ name, enabledFields })
  const validationState = validation.message[name] ? "error" : null

  return (
    <Box>
      <Text color="colorTextStrong" type="body">
        {localized("Products")}
        <span aria-hidden="true">*</span>
      </Text>
      {!disableField && (
        <Box marginTop={tokens.spacing[1]} padding="0 2px 0 2px">
          <SearchableDropDown
            useSelectStyling
            width="100%"
            value={null}
            ariaAttributes={{
              "aria-label": localized("Products"),
            }}
            searchPlaceholderToken={localized("Add product to group...")}
            valueRenderer={() => (
              <Text color="colorTextWeakest" type="body">
                {localized("Add product to group...")}
              </Text>
            )}
            options={availableOptions}
            onSelect={onOptionSelected}
            validationState={validationState}
            errorMessage={validation.message[name]}
          />
        </Box>
      )}
      <Box marginTop={tokens.spacing[1]}>
        {!disableField && (
          <Box marginTop={tokens.spacing[2]} marginBottom={tokens.spacing[2]}>
            <StyledDraggableListHeader>{localized("Drag products to arrange order")}</StyledDraggableListHeader>
          </Box>
        )}
        <StyledDraggableList
          keyProp="value"
          elements={selectedOptions}
          onChange={onOptionMoved}
          onRemoveElement={onOptionRemoved}
          renderElement={section => <span>{section.label}</span>}
          isDisabled={disableField}
          height="370px"
          fadeAtBottom
        />
      </Box>
    </Box>
  )
})

ProductsGroup.propTypes = {
  ...commonPropTypes,
  products: productsPropType,
}

export default ProductsGroup
