/** @jsxImportSource @emotion/react */
import { connect } from "react-redux"
import { Form, FormGroup, Col, ControlLabel, FormControl } from "react-bootstrap"
import { css } from "@emotion/react"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import {
  addConfigurationTypeMapping as _addConfigurationTypeMapping,
  updateConfigurationTypeMapping as _updateConfigurationTypeMapping,
  closeConfigurationTypeMappingModal as _closeConfigurationTypeMappingModal,
  setConfigurationTypeMappingName as _setConfigurationTypeMappingName,
} from "js/state/actions/psa/ConnectWise/mappings/configurations/configurations"
import { useForm } from "js/includes/common/hooks"
import { StyledModal } from "js/includes/components/Styled/Form"

import NodeRoleConfigurationTypeMappings from "./NodeRoleConfigurationTypeMappings"

const labelStyle = css`
  text-align: left;
`

const ConfigurationTypeMappingModal = ({
  currentConfigurationTypeMapping,
  configurationTypeMappings,
  setConfigurationTypeMappingName,
  addConfigurationTypeMapping,
  updateConfigurationTypeMapping,
  closeConfigurationTypeMappingModal,
  mode,
}) => {
  const {
    values: { name },
    validation,
    onChange,
    validateForm,
  } = useForm({
    fields: {
      name: currentConfigurationTypeMapping.name,
    },
    validate: {
      name: validateName,
    },
  })

  function handleInput(event) {
    setConfigurationTypeMappingName(event.target.value)
    onChange("name", event.target.value)
  }

  async function handleSave(e) {
    e.preventDefault()
    e.stopPropagation()
    if (validateForm()) {
      if (mode === "ADD") {
        await addConfigurationTypeMapping(currentConfigurationTypeMapping)
      } else {
        await updateConfigurationTypeMapping(currentConfigurationTypeMapping)
      }
      closeConfigurationTypeMappingModal()
    }
  }

  function validateName(name) {
    const notPresent = !name.trim() && localized("Required")
    const tooLong = name.length > 50 && localized("Max length for name is 50 chars")
    const taken =
      nameTaken(configurationTypeMappings, name.trim(), currentConfigurationTypeMapping.id) &&
      localized("Configuration type mapping name already taken")
    return {
      success: !notPresent && !tooLong && !taken,
      message: notPresent || tooLong || taken,
    }
  }

  function nameTaken(configurationTypeMappings, name, id) {
    const ctm = configurationTypeMappings.find(ctm => ctm.name === name)
    return ctm && ctm.id !== id
  }

  return (
    <Form onSubmit={handleSave}>
      <StyledModal
        submittable
        title={localizationKey("Configuration Type Mapping")}
        close={closeConfigurationTypeMappingModal}
        closeText={localizationKey("Close")}
        saveText={localizationKey("OK")}
        showCloseButton={false}
        dialogClassName="widest-modal psa-modal"
        height="580px !important"
        maxHeight="580px !important"
      >
        <div className="display-flex flex-column">
          <FormGroup validationState={validation.message.name ? "error" : null}>
            <Col componentClass={ControlLabel} xs={4} css={labelStyle}>
              <span>{localized("Name")}</span>
              <sup> *</sup>
            </Col>
            <Col xs={8}>
              <FormControl
                autoFocus={true}
                type="text"
                placeholder={localized("Name")}
                value={name}
                onChange={handleInput}
                maxLength="50"
              />
              {validation.message.name && <em className="invalid">{validation.message.name}</em>}
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} xs={12} css={labelStyle}>
              {localized("Mappings")}
            </Col>
            <Col xs={12}>
              <NodeRoleConfigurationTypeMappings />
            </Col>
          </FormGroup>
        </div>
      </StyledModal>
    </Form>
  )
}

export default connect(
  ({ psa }) => ({
    mode: psa.ConnectWise.mappings.configurations.configurationTypeModalEditorMode,
    configurationTypeMappings: psa.ConnectWise.mappings.configurations.configurationTypeMappings,
    currentConfigurationTypeMapping: psa.ConnectWise.mappings.configurations.currentConfigurationTypeMapping,
  }),
  {
    addConfigurationTypeMapping: _addConfigurationTypeMapping,
    updateConfigurationTypeMapping: _updateConfigurationTypeMapping,
    closeConfigurationTypeMappingModal: _closeConfigurationTypeMappingModal,
    setConfigurationTypeMappingName: _setConfigurationTypeMappingName,
  },
)(ConfigurationTypeMappingModal)
