import React from "react"
import { noRowsRenderer, headerRenderer } from "js/includes/common/utils/react-virtualized"
import Table from "js/includes/components/Table"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export default function LocationsSiteAutoMapTable({ possibleLocationMappings, sort, sortBy, sortDirection }) {
  return (
    <Table
      {...{
        sort,
        sortBy,
        sortDirection,
        noRowsRenderer: noRowsRenderer(localizationKey("There are no sites to be mapped")),
        className: "horizontal-tiles list-group",
        list: possibleLocationMappings,
        columns: [
          {
            headerRenderer,
            dataKey: "clientName",
            label: localizationKey("Ninja Location"),
            width: 1,
            flexGrow: 1,
            cellRenderer: ({ rowData }) => {
              const { mapping } = rowData
              return <h4>{mapping.locationName}</h4>
            },
          },
          {
            headerRenderer,
            dataKey: "companyName",
            disableSort: true,
            label: localizationKey("CW Site"),
            width: 1,
            flexGrow: 1,
            cellRenderer: ({ rowData }) => {
              const { site } = rowData
              return <span>{site.name}</span>
            },
          },
        ],
      }}
    />
  )
}
