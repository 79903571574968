import { memo, useMemo } from "react"
import { pluck, propEq } from "ramda"
import { connect } from "react-redux"

import { Checkbox, Select, Text } from "@ninjaone/components"
import { Label } from "@ninjaone/components/src/Form/Label"
import tokens from "@ninjaone/tokens"
import { localized } from "js/includes/common/utils"
import { getPriorityOptions, getSeverityOptions } from "js/includes/ticketing/common/TicketEditorFields/options"
import { getStatusOptions } from "js/includes/ticketing/shared/utils/options"
import TagsInput from "js/includes/ticketing/common/TagsInput"
import { statusRowRenderer } from "js/includes/ticketing/editor/tabHeaderActions/general"
import { Box, Flex } from "js/includes/components/Styled"

export const mapStatusOptions = statusList =>
  getStatusOptions(statusList, { hideClose: true }).map(option => ({
    ...option,
    id: option.value,
    LabelComponent: ({ id, labelText }) => statusRowRenderer({ value: id, labelText }),
  }))

export const SelectLabelRenderer = ({ options, value }) => (
  <Text type="body" color="colorTextStrong">
    {options.find(propEq("value", value))?.labelText}
  </Text>
)

const TicketCreate = memo(({ readonly, onChange, tagsData, values, validation, ticketFormsOptions, statusList }) => {
  const { ticketFormId, tags, priority, severity, status, useOwnerAsRequester } = values

  const { priorityOptions, severityOptions, statusOptions } = useMemo(() => {
    const usePolicy = { value: null, labelText: localized("Use Policy") }
    return {
      priorityOptions: [usePolicy, ...getPriorityOptions({ showNone: false, labelKey: "labelText" })],
      severityOptions: [usePolicy, ...getSeverityOptions({ showNone: false, labelKey: "labelText" })],
      statusOptions: mapStatusOptions(statusList),
    }
  }, [statusList])

  return (
    <Flex flexDirection="column" gap={tokens.spacing[2]} margin={`0 ${tokens.spacing[1]}`}>
      <Select
        labelId="ticket-create-form"
        labelText={localized("Form")}
        value={ticketFormId}
        onChange={value => onChange("ticketFormId", value)}
        options={ticketFormsOptions}
        errorMessage={validation.message.ticketFormId}
        required
        disabled={readonly}
      />

      <Box>
        <Label labelText={localized("Tags")} id="ticket-create-tags" forInputElement={false} />
        <TagsInput
          selected={pluck("value", tags || [])}
          disableEditor={readonly}
          onChange={selected => onChange("tags", selected)}
          tags={tagsData?.tags?.map(tag => ({ label: tag.name, value: tag.name }))}
          ariaInputAttributes={{ "aria-labelledby": "ticket-create-tags" }}
        />
      </Box>

      <Select
        labelId="ticket-create-status"
        labelText={localized("Status")}
        value={status}
        onChange={value => onChange("status", value)}
        options={statusOptions}
        errorMessage={validation.message.status}
        required
        disabled={readonly}
      />

      <Select
        labelId="ticket-create-priority"
        labelText={localized("Priority")}
        value={priority}
        onChange={value => onChange("priority", value)}
        options={priorityOptions}
        disabled={readonly}
        labelRenderer={() => <SelectLabelRenderer options={priorityOptions} value={priority} />}
      />

      <Select
        labelId="ticket-create-severity"
        labelText={localized("Severity")}
        value={severity}
        onChange={value => onChange("severity", value)}
        options={severityOptions}
        disabled={readonly}
        labelRenderer={() => <SelectLabelRenderer options={severityOptions} value={severity} />}
      />

      <Checkbox
        label={localized("Use the device's user as requester")}
        ariaLabel={localized("Use the device's user as requester")}
        checked={useOwnerAsRequester}
        disabled={readonly}
        onChange={() => onChange("useOwnerAsRequester", !useOwnerAsRequester)}
      />
    </Flex>
  )
})

export default connect(({ ticketing }) => ({ statusList: ticketing.status.list }))(TicketCreate)
