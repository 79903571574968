import { memo } from "react"
import PropTypes from "prop-types"
import { Input } from "@ninjaone/components"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"
import { Box } from "js/includes/components/Styled"
import { commonPropTypes } from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import tokens from "@ninjaone/tokens"

const TextInput = memo(
  ({
    id,
    nameToken: labelToken,
    name,
    onChange,
    values,
    validation,
    mandatory = false,
    disabled = false,
    maxLength = 50,
  }) => {
    const errorMessage = validation.message[name]
    return (
      <Box marginTop={tokens.spacing[3]}>
        <Input
          id={id}
          required={mandatory}
          labelToken={labelToken}
          placeholder={localized(labelToken)}
          value={values[name]}
          onChange={e => onChange({ [name]: e.target.value })}
          maxLength={maxLength}
          disabled={disabled}
          errorMessage={errorMessage}
        />
      </Box>
    )
  },
)

TextInput.propTypes = {
  ...commonPropTypes,
  id: PropTypes.string.isRequired,
  nameToken: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  maxLength: PropTypes.number,
}

export default TextInput
