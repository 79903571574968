import { applySpec, compose, curry, filter, map, prop, propEq } from "ramda"
import { memo } from "react"
import { connect } from "react-redux"

import { arrayToMapWithKey, localizationKey, localized } from "js/includes/common/utils"
import RuleEditor from "js/includes/components/RuleEditor"
import { getTagValues } from "js/includes/configuration/integrations/ticketing/utils"
import { getPriorityOptions, getSeverityOptions } from "js/includes/ticketing/common/TicketEditorFields/options"
import { getStatusOptions } from "js/includes/ticketing/shared/utils/options"

import {
  CommentEditor,
  EmailEditor,
  OrganizationEditor,
  StatusEditor,
  TagsEditor,
  TicketSubjectEditor,
  UserEditor,
  UserEmailEditor,
} from "./components"

function getOperators(field) {
  switch (field) {
    case "add_tags":
    case "remove_tags":
    case "set_tags":
    case "add_cc_list":
    case "set_cc_list":
    case "ADD_TICKET_COMMENT":
    case "SEND_EMAIL":
      return []
    case "SEND_NOTIFICATION":
      return [
        { value: "EMAIL", labelToken: localizationKey("Email") },
        { value: "SMS", labelToken: "SMS" },
        { value: "SLACK", labelToken: "Slack" },
      ]
    case "ticket":
      return [
        { value: "organization", labelToken: localizationKey("Assign organization") },
        { value: "assignedAppUserId", labelToken: localizationKey("Assign to") },
        { value: "requesterUid", labelToken: localizationKey("Requester") },
        { value: "status", labelToken: localizationKey("Status") },
        { value: "severity", labelToken: localizationKey("Severity") },
        { value: "priority", labelToken: localizationKey("Priority") },
        { value: "summary", labelToken: localizationKey("Subject") },
      ]
    case "TICKET_LOG_ENTRY_TIME_TRACKER":
      return [
        { value: "MINUTES", labelToken: localizationKey("Minutes") },
        { value: "HOURS", labelToken: localizationKey("Hours") },
        { value: "DAYS", labelToken: localizationKey("Days") },
      ]
    default:
      return null
  }
}

function customTagRenderer({ value, values, handleOnChange, errorMessage, ariaLabel }) {
  return (
    <TagsEditor
      tags={values}
      selected={value}
      onChange={handleOnChange}
      errorMessage={errorMessage}
      ariaLabel={ariaLabel}
    />
  )
}

function customCommentEditorRenderer(type) {
  return function({ value, handleOnChange, errorMessage, ariaLabel }) {
    return (
      <CommentEditor
        {...{
          value,
          handleOnChange,
          withCurrentUserPlaceholders: type !== "TIME_BASED",
          errorMessage,
          ariaLabel,
        }}
      />
    )
  }
}

function customEmailEditorRenderer(type) {
  return function({ value, values, handleOnChange, metaData, errorMessage, ariaLabel }) {
    return (
      <EmailEditor
        {...{
          value,
          values,
          handleOnChange,
          metaData,
          showCurrentUserOption: type !== "TIME_BASED",
          errorMessage,
          ariaLabel,
        }}
      />
    )
  }
}

function customTicketSubjectEditorRenderer({ value, handleOnChange, errorMessage, ariaLabel }) {
  return <TicketSubjectEditor {...{ value, handleOnChange, errorMessage, ariaLabel }} />
}

function customUserEditorRenderer({ valueSelectorKey, ...options }) {
  return function({ value, handleOnChange, metaData, errorMessage, ariaLabel }) {
    return (
      <UserEditor {...{ value, handleOnChange, metaData, valueSelectorKey, errorMessage, ariaLabel, ...options }} />
    )
  }
}

function customOrganizationEditorRenderer({ value, handleOnChange, metaData, errorMessage, ariaLabel }) {
  return <OrganizationEditor {...{ value, handleOnChange, metaData, isMulti: false, errorMessage, ariaLabel }} />
}

function customStatusEditorRenderer({ value, handleOnChange, errorMessage, ariaLabel }) {
  return <StatusEditor {...{ value, handleOnChange, hideClose: true, errorMessage, ariaLabel }} />
}

function customUserEmailEditorRenderer({ value, handleOnChange, metaData, errorMessage, ariaLabel }) {
  return <UserEmailEditor {...{ value, handleOnChange, metaData, errorMessage, ariaLabel }} />
}

const getInputType = field => {
  switch (field) {
    case "TICKET_LOG_ENTRY_TIME_TRACKER":
      return "number"
    default:
      return "text"
  }
}

function getTicketValues(operator, statusList) {
  switch (operator) {
    case "status":
      return getStatusOptions(statusList, { hideClose: true })
    case "priority":
      return getPriorityOptions({ labelKey: "labelText" })
    case "severity":
      return getSeverityOptions({ labelKey: "labelText" })
    case "summary":
      return ""
    default:
      return []
  }
}

const getChannelValues = curry((operator, list) =>
  compose(
    map(
      applySpec({
        value: prop("id"),
        labelText: prop("name"),
      }),
    ),
    filter(propEq("type", operator)),
  )(list),
)

export const Actions = memo(
  ({
    query = [],
    onQueryChange,
    type,
    statusList,
    getValidations,
    tags,
    displayErrors,
    translationTokens,
    notificationChannels,
  }) => {
    const fields = [
      { value: "add_tags", labelText: localized("Add tags") },
      { value: "set_tags", labelText: localized("Set tags") },
      { value: "remove_tags", labelText: localized("Remove tags") },
      { value: "ticket", labelText: localized("Update ticket") },
      { value: "add_cc_list", labelText: localized("Add CC") },
      { value: "set_cc_list", labelText: localized("Set CC") },
      { value: "ADD_TICKET_COMMENT", labelText: localized("Add comment") },
      { value: "TICKET_LOG_ENTRY_TIME_TRACKER", labelText: localized("Add time") },
      ...(type === "RESPONSE_TEMPLATE"
        ? []
        : [
            { value: "SEND_NOTIFICATION", labelText: localized("Send notification") },
            { value: "SEND_EMAIL", labelText: localized("Email") },
          ]),
    ]
    const oneTimeFields = ["EVENT_BASED", "RESPONSE_TEMPLATE", "TIME_BASED"].includes(type)
      ? ["add_cc_list", "set_cc_list", "ADD_TICKET_COMMENT", "TICKET_LOG_ENTRY_TIME_TRACKER"]
      : []

    function getValues(field, operator) {
      switch (field) {
        case "add_tags":
        case "remove_tags":
        case "set_tags":
          return getTagValues(tags)
        case "ticket":
          return getTicketValues(operator, statusList)
        case "SEND_NOTIFICATION":
          return getChannelValues(operator, notificationChannels)
        case "add_cc_list":
        case "set_cc_list":
          return []
        default:
          return ""
      }
    }

    function getValueEditorType(field, operator) {
      switch (field) {
        case "add_tags":
        case "set_tags":
        case "remove_tags":
          return customTagRenderer
        case "SEND_EMAIL":
          return customEmailEditorRenderer(type)
        case "ADD_TICKET_COMMENT":
          return customCommentEditorRenderer(type)
        case "add_cc_list":
        case "set_cc_list":
          return customUserEmailEditorRenderer
        case "ticket":
          switch (operator) {
            case "organization":
              return customOrganizationEditorRenderer
            case "summary":
              return customTicketSubjectEditorRenderer
            case "assignedAppUserId":
              return customUserEditorRenderer({ valueSelectorKey: "id", userType: "TECHNICIAN" })
            case "requesterUid":
              return customUserEditorRenderer({ valueSelectorKey: "uid" })
            case "status":
              return customStatusEditorRenderer
            default:
              return "select"
          }
        case "SEND_NOTIFICATION":
          return "select"
        case "TICKET_LOG_ENTRY_TIME_TRACKER":
          return "number"
        default:
          return "text"
      }
    }

    return (
      <RuleEditor
        {...{
          query,
          fields: {
            options: fields,
            optionsMap: arrayToMapWithKey("value", fields),
          },
          oneTimeFields,
          getOperators,
          getValues,
          getValueEditorType,
          getInputType,
          onQueryChange,
          combinator: "actions",
          getValidations,
          displayErrors,
          translationTokens,
        }}
      />
    )
  },
)

export default connect(({ ticketing }) => ({ statusList: ticketing.status.list }))(Actions)
