/** @jsxImportSource @emotion/react */
import { PureComponent } from "react"
import { connect } from "react-redux"
import { css } from "@emotion/react"
import EditorPortalModal from "js/includes/components/EditorPortalModal"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { showModal as _showModal } from "js/state/actions/psa/ConnectWise/mappings/configurations/nodeRoleConfigurationTypeMappings"
import NodeRoleConfigurationTypeMappingsTable from "./NodeRoleConfigurationTypeMappingsTable"
import NodeRoleConfigurationTypeMappingModal from "./NodeRoleConfigurationTypeMappingModal"
import { colors } from "js/includes/common/theme"
import { getTempId } from "js/state/actions/psa/ConnectWise/mappings/common"

const mappingsStyle = css`
  display: flex;
  flex-flow: column;
  height: 465px;
  margin-top: -15px;
`

const tableHeaderStyle = css`
  display: flex;
  flex-flow: row-reverse;
  margin-top: -9px;
`

const tableContainerStyle = css`
  background-color: ${colors.white};
  border: 1px solid ${colors.ninjaLight};
  margin-top: 20px;
`

class NodeRoleConfigurationTypeMappings extends PureComponent {
  onAdd = e => {
    e.stopPropagation()
    e.preventDefault()
    const { showModal, mappings } = this.props
    showModal(localizationKey("Add Mapping"), "ADD", getTempId(mappings))
  }

  render() {
    const { modalVisibility } = this.props
    return (
      <div css={mappingsStyle}>
        <div css={tableHeaderStyle}>
          <button className="btn-link action m-r" onClick={this.onAdd}>
            {localized("Add Mapping")}
          </button>
        </div>
        <div className="display-flex flex-full overflow-hidden" css={tableContainerStyle}>
          <NodeRoleConfigurationTypeMappingsTable />
        </div>
        <EditorPortalModal {...{ show: modalVisibility, className: "inmodal" }}>
          <NodeRoleConfigurationTypeMappingModal />
        </EditorPortalModal>
      </div>
    )
  }
}

export default connect(
  ({ psa }) => ({
    modalVisibility: psa.ConnectWise.mappings.configurations.nodeRoleConfigurationTypeMappingModalVisibility,
    mappings: psa.ConnectWise.mappings.configurations.currentConfigurationTypeMapping.nodeRoleConfigurationTypeMappings,
  }),
  {
    showModal: _showModal,
  },
)(NodeRoleConfigurationTypeMappings)
