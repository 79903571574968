import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import Switchery from "js/includes/components/Switchery"
import { Col } from "react-bootstrap"
import {
  setEnabled as _setEnabled,
  setSchedule as _setSchedule,
} from "js/state/actions/psa/ConnectWise/mappings/products/sync"
import Schedule from "js/includes/components/Schedule/serverBased/Schedule"
import Indicator from "js/includes/components/Indicator"
import Tooltip from "js/includes/components/Tooltip"

class ProductAgreementSync extends PureComponent {
  onScheduleUpdate = schedule => {
    this.props.setSchedule(schedule)
  }

  render() {
    const {
      loading,
      syncData: { enabled, schedule },
      setEnabled,
    } = this.props

    return loading ? (
      <Indicator />
    ) : (
      <>
        <Col xs={6} className="m-b">
          <Col xs={3} className="flex-row">
            <h3 className="m-l-n">{localized("Enabled")}</h3>
            <div className="m-t-xs">
              <Tooltip
                token={localizationKey("Enabling this turns on product agreements synchronization on schedule.")}
              />
            </div>
          </Col>
          <Col xs={9}>
            <Switchery defaultChecked={enabled} onChange={checked => setEnabled(checked)} />
          </Col>
        </Col>
        <Col xs={6}>
          <Schedule
            {...{
              schedule,
              onScheduleUpdate: this.onScheduleUpdate,
              repeatsOptions: [
                {
                  value: "MONTHLY",
                  text: () => localized("Monthly"),
                  maxRecurrence: 12,
                  recurrenceText: () => localized("month(s)"),
                },
              ],
              repeatsDisabled: true,
              visibleRecurEvery: false,
            }}
          />
        </Col>
      </>
    )
  }
}

export default connect(
  ({ psa }) => ({
    syncData: psa.ConnectWise.mappings.products.productAgreementSync,
    loading: psa.editorLoading,
  }),
  {
    setEnabled: _setEnabled,
    setSchedule: _setSchedule,
  },
)(ProductAgreementSync)
