import { useMemo } from "react"

import { Label } from "@ninjaone/components/src/Form/Label"
import tokens from "@ninjaone/tokens"

import { localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"

import Rule from "./Rule"

const RuleGroup = ({ combinator, rules, translationTokens, schema, displayErrors }) => {
  const { controls, createRule, onRuleAdd } = schema

  const labelMapper = useMemo(
    () => ({
      all: localized("Meets all conditions"),
      any: localized("Meets any conditions"),
      actions: localized("Actions to perform"),
    }),
    [],
  )

  const addRule = event => {
    event.preventDefault()
    event.stopPropagation()

    const newRule = createRule()
    onRuleAdd(newRule)
  }

  const containerId = `row-rule-group-${combinator}`
  return (
    <Flex id={containerId} data-testid={containerId} marginBottom={tokens.spacing[2]} flexDirection="column">
      <Label required labelText={labelMapper[combinator]} />
      <Flex marginTop={tokens.spacing[2]} flexDirection="column">
        {rules.map(
          ({ id, field, value, operator, validation, metaData }, index) =>
            field !== "deleted" && (
              <Rule
                {...{
                  rowIndex: index,
                  key: id,
                  id,
                  field,
                  value,
                  operator,
                  schema,
                  translationTokens,
                  validation,
                  combinator,
                  metaData,
                  displayErrors,
                }}
              />
            ),
        )}
      </Flex>
      <Box id={`column-add-${combinator}-container`}>
        <controls.addRuleAction
          label={localized(translationTokens.addRule.labelToken)}
          onClick={addRule}
          rules={rules}
        />
      </Box>
    </Flex>
  )
}

RuleGroup.defaultProps = {
  rules: [],
  combinator: "all",
  schema: {},
  displayErrors: false,
}

export default RuleGroup
