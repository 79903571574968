export function getQuantityValue(quantityType, quantity) {
  switch (quantityType) {
    case "STATIC":
      return quantity
    case "QUANTITY_ONE":
      return 1
    default:
      return null
  }
}

export function getShouldDisableQuantity(quantityType) {
  return ["DYNAMIC", "QUANTITY_ONE"].includes(quantityType)
}
