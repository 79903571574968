import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { localized, localizationKey, headerRenderer, noRowsRenderer } from "js/includes/common/utils"
import Modal from "js/includes/components/Modal"
import Table from "js/includes/components/Table"
import { Box } from "js/includes/components/Styled"
import { saveAccountOrganizationMappings } from "js/state/actions/psa/Autotask/settings"

const columns = [
  {
    headerRenderer,
    dataKey: "clientName",
    label: localizationKey("Ninja Organization"),
    width: 1,
    flexGrow: 1,
  },
  {
    headerRenderer,
    dataKey: "accountName",
    disableSort: true,
    label: localizationKey("Autotask Company"),
    width: 1,
    flexGrow: 1,
    cellRenderer: ({ cellData }) => cellData || "-",
  },
]

const formatName = name => name.trim().toLowerCase()

const AutotaskAccountAutoMapModal = ({
  unmount,
  accountList,
  accountMapModalLoading,
  accountOrganizationMappings,
  saveAccountOrganizationMappings,
}) => {
  const [accountMap, setAccountMap] = useState({})

  const { autoMapped, notMapped } = accountOrganizationMappings.reduce(
    (acc, accOrgMap) => {
      const account = accountMap[formatName(accOrgMap.clientName)]

      if (account) {
        acc.autoMapped.push({
          ...accOrgMap,
          ...account,
        })
      } else {
        acc.notMapped.push(accOrgMap)
      }

      return acc
    },
    { autoMapped: [], notMapped: [] },
  )

  useEffect(() => {
    const accountMap = accountList.reduce((acc, account) => {
      const { id, name } = account

      acc[formatName(name)] = {
        accountId: id,
        accountName: name,
      }

      return acc
    }, {})

    setAccountMap(accountMap)
  }, [accountList])

  return (
    <Modal
      title={localizationKey("Auto Map")}
      saveText={localizationKey("Auto Map")}
      closeText={localizationKey("Cancel")}
      dialogClassName="psa-modal"
      save={() => {
        saveAccountOrganizationMappings([...autoMapped, ...notMapped])
        unmount()
      }}
      close={() => {
        unmount()
      }}
    >
      <Box padding={[0, 3]}>
        <Box textAlign="left" color="ninjaMedium" paddingBottom={2}>
          <span>{autoMapped.length}</span> {localized("organization(s) will be mapped.")}
        </Box>

        <Box height="282px" display="flex" className="panel-bordered">
          <Table
            {...{
              columns,
              list: autoMapped,
              className: "horizontal-tiles list-group",
              noRowsRenderer: noRowsRenderer(localizationKey("No Mappings"), false, "text-left m-l-xs"),
            }}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default connect(
  ({ psa }) => ({
    accountList: psa.Autotask.settings.accountList,
    accountOrganizationMappings: psa.Autotask.settings.accountOrganizationMappings,
  }),
  {
    saveAccountOrganizationMappings,
  },
)(AutotaskAccountAutoMapModal)
