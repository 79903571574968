import React from "react"
import { noRowsRenderer, headerRenderer } from "js/includes/common/utils/react-virtualized"
import Table from "js/includes/components/Table"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export default function AccountAutoMapTable({ organizations, sort, sortBy, sortDirection }) {
  return (
    <Table
      {...{
        sort,
        sortBy,
        sortDirection,
        noRowsRenderer: noRowsRenderer(localizationKey("No Organizations")),
        className: "horizontal-tiles list-group",
        list: organizations,
        columns: [
          {
            headerRenderer,
            dataKey: "clientName",
            label: localizationKey("Ninja Organization"),
            width: 1,
            flexGrow: 1,
            cellRenderer: ({ rowData }) => {
              return <h4>{rowData.clientName}</h4>
            },
          },
          {
            headerRenderer,
            dataKey: "companyName",
            disableSort: true,
            label: localizationKey("ConnectWise Company"),
            width: 1,
            flexGrow: 1,
          },
        ],
      }}
    />
  )
}
