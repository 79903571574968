import { reduce } from "ramda"
import { useCallback, useEffect } from "react"
import { VerticalTabs } from "@ninjaone/components"
import { useMountedState } from "js/includes/common/hooks"
import {
  localizationKey,
  reportErrorAndShowMessage,
  sortByFieldNameCaseInsensitive,
  localized,
  hasErrors,
} from "js/includes/common/utils"
import Loading from "js/includes/components/Loading"
import { getNinjaPSAUserRoles } from "js/includes/common/client"
import { contentPropTypes } from "./contentCommons"
import {
  AutoRoundTimeEntries,
  Billing,
  Cost,
  Description,
  Multiplier,
  Name,
  Price,
  Quantity,
  Taxable,
  UserRoles,
  //LaborCodes,
} from "./contentFields"
import ContentWrapper from "./ContentWrapper"

const LaborTicketTimeEntryProductContent = props => {
  const [userRolesData, setUserRolesData] = useMountedState({ options: [], optionsMap: {} })
  const [fetchingUserRoles, setFetchingUserRoles] = useMountedState(true)

  const fetchUserRoles = useCallback(async () => {
    try {
      const roles = await getNinjaPSAUserRoles()
      const { options, optionsMap } = reduce(
        (acc, role) => {
          const option = { labelText: role.name, value: role.id || role.type }
          acc.options.push(option)
          acc.optionsMap[option.value] = {
            option,
            role,
          }
          return acc
        },
        { options: [], optionsMap: {} },
        roles,
      )

      setUserRolesData({
        options: sortByFieldNameCaseInsensitive("name", "ASC", options),
        optionsMap,
      })
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error fetching user roles"))
    } finally {
      setFetchingUserRoles(false)
    }
  }, [setUserRolesData, setFetchingUserRoles])

  useEffect(() => {
    fetchUserRoles()
  }, [fetchUserRoles])

  const hasAnyError = hasErrors(props.validation.success)

  if (fetchingUserRoles) return <Loading />

  return (
    <VerticalTabs
      ariaLabel={props.ariaLabel || localized("Product Form")}
      tabs={[
        {
          required: true,
          hasError: hasAnyError(["name", "description"]),
          label: localized("General"),
          renderer: () => (
            <ContentWrapper>
              <Name {...props} />
              <Description {...props} />
              <UserRoles {...{ ...props, userRolesData }} />
            </ContentWrapper>
          ),
        },
        {
          required: true,
          hasError: hasAnyError([
            "price",
            "cost",
            "onSiteMultiplier",
            "remoteMultiplier",
            "normalHoursMultiplier",
            "offHoursMultiplier",
          ]),
          label: localized("Billing"),
          renderer: () => (
            <ContentWrapper>
              {/* <LaborCodes {...props} /> */}
              <Price {...props} />
              <Cost {...props} />
              <Taxable {...props} />
              <Billing {...props} />
              <Multiplier {...props} />
            </ContentWrapper>
          ),
        },
        {
          label: localized("Advanced"),
          renderer: () => (
            <ContentWrapper>
              <AutoRoundTimeEntries {...props} />
              {props.useDynamicQuantity && <Quantity {...props} />}
            </ContentWrapper>
          ),
        },
      ]}
    />
  )
}

LaborTicketTimeEntryProductContent.propTypes = contentPropTypes

export default LaborTicketTimeEntryProductContent
