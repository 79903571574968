import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export const automationTypes = {
  ACTIVITY_RULE: "ACTIVITY_RULE",
  CONDITION_RULE: "CONDITION_RULE",
  EVENT_BASED: "EVENT_BASED",
  RESPONSE_TEMPLATE: "RESPONSE_TEMPLATE",
  SCRIPT_RULE: "SCRIPT_RULE",
  TIME_BASED: "TIME_BASED",
}

export const automationTypeNameTokensMapper = {
  [automationTypes.ACTIVITY_RULE]: localizationKey("Activity Rule"),
  [automationTypes.CONDITION_RULE]: localizationKey("Condition Rule"),
  [automationTypes.EVENT_BASED]: localizationKey("Event Based"),
  [automationTypes.RESPONSE_TEMPLATE]: localizationKey("Response Template"),
  [automationTypes.SCRIPT_RULE]: localizationKey("Script Rule"),
  [automationTypes.TIME_BASED]: localizationKey("Time Based"),
}
