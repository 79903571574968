/** @jsxImportSource @emotion/react */
import { connect } from "react-redux"
import { Form, FormGroup, Col, ControlLabel } from "react-bootstrap"
import { css } from "@emotion/react"
import { prop, propOr } from "ramda"
import Select from "react-select"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { hide as _hide } from "js/state/actions/common/visibility"
import {
  updateAccountOrganizationModalMapping as _updateAccountOrganizationModalMapping,
  updateAccountOrganizationMapping as _updateAccountOrganizationMapping,
} from "js/state/actions/psa/ConnectWise/mappings/accounts"
import Loading from "js/includes/components/Loading"
import LocationSiteMappings from "./LocationSiteMappings"
import { sortListByName } from "js/includes/configuration/psa/PsaSettingsEditor"
import { useForm } from "js/includes/common/hooks"
import { StyledModal } from "js/includes/components/Styled/Form"

const labelStyle = css`
  text-align: left;
`

const mappingsFormGroupStyle = css`
  margin-top: 5px;
`

const AccountMappingModal = ({
  accountOrganizationModal: { mapping },
  loading,
  productMappings,
  configurationTypeMappings,
  updateAccountOrganizationModalMapping,
  updateAccountOrganizationMapping,
  hideAccountModal,
  companies,
}) => {
  const {
    values: { company },
    validation,
    onChange,
  } = useForm({
    fields: {
      company: { id: mapping.companyId, name: mapping.companyName },
    },
    validate: {
      company: validateCompany,
    },
  })

  function handleSelectCompany(company) {
    updateAccountOrganizationModalMapping({
      companyId: propOr(null, "id", company),
      companyName: propOr(null, "name", company),
    })
    onChange("company", company)
  }

  function handleSelectProductMapping(productMapping) {
    updateAccountOrganizationModalMapping({
      productMappingId: propOr(null, "id", productMapping),
      productMappingName: propOr(null, "name", productMapping),
    })
  }

  function handleSelectConfigurationTypeMapping(configurationTypeMapping) {
    updateAccountOrganizationModalMapping({
      configurationTypeMappingId: propOr(null, "id", configurationTypeMapping),
      configurationTypeMappingName: propOr(null, "name", configurationTypeMapping),
    })
  }

  function handleSave(e) {
    e.preventDefault()
    e.stopPropagation()
    if (validateCompany(company).success) {
      updateAccountOrganizationMapping(mapping)
      hideAccountModal()
    }
  }

  function validateCompany(company) {
    return {
      success: company.id,
      message: !company.id && localized("Required"),
    }
  }

  return (
    <Form onSubmit={handleSave}>
      <StyledModal
        submittable
        overflow
        dataTitle={true}
        title={`"${mapping.clientName}" ${localized("Ninja Organization to CW Company Mapping")}`}
        close={hideAccountModal}
        closeText={localizationKey("Cancel")}
        saveText={localizationKey("OK")}
        showCloseButton={false}
        dialogClassName="wide-modal psa-modal"
        disabledSaveBtn={!company.id}
        {...(company.id && {
          height: "580px !important",
          maxHeight: "580px !important",
        })}
      >
        {loading ? (
          <Loading />
        ) : (
          <div className="display-flex flex-column">
            <FormGroup validationState={validation.message.company ? "error" : null}>
              <Col xs={4} componentClass={ControlLabel} css={labelStyle}>
                <span>{localized("CW Company")}</span>
                <sup> *</sup>
              </Col>
              <Col xs={8}>
                <Select
                  name="company"
                  className={validation.message.company ? "invalid-single-select" : ""}
                  value={companies.find(option => option.id === company.id)}
                  options={sortListByName(companies)}
                  onChange={handleSelectCompany}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                />
                {validation.message.company && <em className="invalid">{validation.message.company}</em>}
              </Col>
            </FormGroup>
            <FormGroup className="m-b-md">
              <Col xs={4} componentClass={ControlLabel} css={labelStyle}>
                <span>{localized("Product Mapping")}</span>
              </Col>
              <Col xs={8}>
                <Select
                  name="productMapping"
                  value={productMappings.find(option => option.id === mapping.productMappingId)}
                  options={sortListByName(productMappings)}
                  onChange={handleSelectProductMapping}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                />
              </Col>
            </FormGroup>
            <FormGroup className="m-b-md">
              <Col xs={4} componentClass={ControlLabel} css={labelStyle}>
                <span>{localized("Configuration Type Mapping")}</span>
              </Col>
              <Col xs={8}>
                <Select
                  name="configurationTypeMapping"
                  value={configurationTypeMappings.find(option => option.id === mapping.configurationTypeMappingId)}
                  options={sortListByName(configurationTypeMappings)}
                  onChange={handleSelectConfigurationTypeMapping}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                />
              </Col>
            </FormGroup>
            {company.id && (
              <FormGroup css={mappingsFormGroupStyle}>
                <Col xs={12} componentClass={ControlLabel} css={labelStyle}>
                  {localized("Ninja Locations to CW Sites")}
                </Col>
                <Col xs={12}>
                  <LocationSiteMappings />
                </Col>
              </FormGroup>
            )}
          </div>
        )}
      </StyledModal>
    </Form>
  )
}

export default connect(
  ({ psa }) => ({
    accountOrganizationModal: psa.ConnectWise.mappings.accounts.accountOrganizationModal,
    loading: psa.ConnectWise.mappings.accounts.accountOrganizationMappingModalLoading,
    productMappings: psa.ConnectWise.mappings.products.productMappings,
    configurationTypeMappings: psa.ConnectWise.mappings.configurations.configurationTypeMappings,
    companies: psa.ConnectWise.mappings.accounts.companies,
  }),
  {
    updateAccountOrganizationModalMapping: _updateAccountOrganizationModalMapping,
    updateAccountOrganizationMapping: _updateAccountOrganizationMapping,
    hideAccountModal: _hide("CWAccountOrganizationMappingModal"),
  },
)(AccountMappingModal)
