/** @jsxImportSource @emotion/react */
import { Component } from "react"
import { connect } from "react-redux"
import { prop, compose, map, filter, reject, propEq, any, defaultTo, pluck } from "ramda"
import { Form, FormGroup, Col, ControlLabel } from "react-bootstrap"
import Select from "react-select"
import { css } from "@emotion/react"
import fastDeepEqual from "fast-deep-equal"
import { Radio, RadioGroup } from "js/includes/components/NinjaReactICheck"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import Modal from "js/includes/components/Modal"
import {
  addMapping as _addMapping,
  updateMapping as _updateMapping,
  closeModal as _closeModal,
  setNodeRole as _setNodeRole,
  setProduct as _setProduct,
  setAgreementType as _setAgreementType,
  setGroups as _setGroups,
  setGroupLogicalOperator as _setGroupLogicalOperator,
} from "js/state/actions/psa/ConnectWise/mappings/products/nodeRoleProductMappings"
import { sortListByName } from "js/includes/configuration/psa/PsaSettingsEditor"
import {
  getNodeRoleValue,
  getNodeRoleOptions,
  defaultToNameNotAvailable,
} from "js/includes/configuration/psa/ConnectWise/settings/common"
import { requestGetSearchesAndCriteria as _requestGetSearchesAndCriteria } from "js/state/actions/deviceSearch/search"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

const styledSection = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 0 15px;
  text-align: left;
`

const styledLabel = css`
  text-align: left;
`

class NodeRoleProductMappingModal extends Component {
  state = {
    productRequiredError: "",
    agreementTypeRequiredError: "",
    nodeRoleRequiredError: "",
    invalidMappingError: "",
  }

  componentDidMount() {
    this.props.requestGetSearchesAndCriteria()
  }

  validate = () => {
    const { currentMapping } = this.props
    const productRequiredError = !currentMapping.productId && localized("Required")
    const agreementTypeRequiredError = !currentMapping.agreementTypeId && localized("Required")
    const nodeRoleRequiredError = !currentMapping.nodeRoleId && localized("Required")
    const invalidMappingError =
      this.isMappingRedudant() && localized("Already exists a mapping with the same role and groups")

    if (productRequiredError || agreementTypeRequiredError || nodeRoleRequiredError || invalidMappingError) {
      this.setState({
        productRequiredError,
        agreementTypeRequiredError,
        nodeRoleRequiredError,
        invalidMappingError,
      })
      return false
    }
    return true
  }

  isMappingRedudant = () => {
    const {
      currentMapping: { id, nodeRoleId, groups },
      allMappings,
    } = this.props

    const sortedGroupsCurrentMapping = groups.sort()
    return compose(
      any(m => m.nodeRoleId === nodeRoleId && fastDeepEqual(m.groups.sort(), sortedGroupsCurrentMapping)),
      reject(propEq("id", id)),
    )(allMappings)
  }

  onSubmit = async e => {
    e.preventDefault()
    e.stopPropagation()

    const { mode, addMapping, updateMapping, closeModal, currentMapping } = this.props
    if (this.validate()) {
      if (mode === "ADD") {
        await addMapping(currentMapping)
      } else {
        await updateMapping(currentMapping)
      }
      closeModal()
    }
  }

  prepareSelectedGroups(selectedGroups, groupList) {
    return compose(
      map(g => ({ id: g.id, name: g.name })),
      filter(g => selectedGroups.includes(g.id)),
    )(groupList)
  }

  onChangeProduct = e => {
    this.setState({ productRequiredError: "" })
    this.props.setProduct(e)
  }

  onChangeAgreementType = e => {
    this.setState({ agreementTypeRequiredError: "" })
    this.props.setAgreementType(e)
  }

  onChangeNodeRole = e => {
    this.setState({ nodeRoleRequiredError: "" })
    this.props.setNodeRole(e)
  }

  onChangeGroupLogicalOperator = e => {
    const { setGroupLogicalOperator } = this.props
    setGroupLogicalOperator(e.target.value)
  }

  render() {
    const {
      closeModal,
      title,
      currentMapping,
      nodeRoleList,
      groupList,
      productList,
      agreementTypeList,
      setGroups,
      selectedGroups,
    } = this.props
    const { productRequiredError, agreementTypeRequiredError, nodeRoleRequiredError, invalidMappingError } = this.state
    const preparedGroupList = sortListByName(groupList)
    const preparedSelectedGroups = this.prepareSelectedGroups(selectedGroups, preparedGroupList)

    return (
      <Form onSubmit={this.onSubmit}>
        <Modal
          submittable
          title={title}
          close={closeModal}
          closeText={localizationKey("Close")}
          saveText={localizationKey("OK")}
          showCloseButton={false}
          dialogClassName="wide-modal psa-modal"
        >
          <div css={styledSection}>
            <h3 className="text-align-left m-b-md">{localized("Map the CW Product/Agreement Type:")}</h3>
            <FormGroup validationState={productRequiredError ? "error" : null} className="row">
              <Col componentClass={ControlLabel} xs={3} css={styledLabel}>
                <span>{localized("Product")}</span>
                <sup> *</sup>
              </Col>
              <Col xs={9}>
                <Select
                  name="productName"
                  className={productRequiredError ? "invalid-single-select" : ""}
                  value={productList.find(option => option.id === currentMapping.productId)}
                  options={sortListByName(productList)}
                  onChange={this.onChangeProduct}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                  formatOptionLabel={option => defaultToNameNotAvailable(option.name)}
                />
                {productRequiredError && <em className="invalid">{productRequiredError}</em>}
              </Col>
            </FormGroup>
            <FormGroup validationState={agreementTypeRequiredError ? "error" : null} className="row">
              <Col componentClass={ControlLabel} xs={3} css={styledLabel}>
                <span>{localized("Agreement Type")}</span>
                <sup> *</sup>
              </Col>
              <Col xs={9}>
                <Select
                  name="agreementTypeName"
                  className={agreementTypeRequiredError ? "invalid-single-select" : ""}
                  value={agreementTypeList.find(option => option.id === currentMapping.agreementTypeId)}
                  options={sortListByName(agreementTypeList)}
                  onChange={this.onChangeAgreementType}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                />
                {agreementTypeRequiredError && <em className="invalid">{agreementTypeRequiredError}</em>}
              </Col>
            </FormGroup>
          </div>
          <div css={styledSection}>
            <h3 className="text-align-left">{localized("To Devices with:")}</h3>
            <FormGroup
              validationState={nodeRoleRequiredError || invalidMappingError ? "error" : null}
              className="row m-b-none"
            >
              <Col componentClass={ControlLabel} xs={3} css={styledLabel}>
                <span>{localized("Role")}</span>
                <sup> *</sup>
              </Col>
              <Col xs={9}>
                <Select
                  name="nodeRoleName"
                  className={nodeRoleRequiredError ? "invalid-single-select" : ""}
                  value={getNodeRoleValue(nodeRoleList, currentMapping.nodeRoleId)}
                  options={getNodeRoleOptions(nodeRoleList)}
                  onChange={this.onChangeNodeRole}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                />
                {(nodeRoleRequiredError || invalidMappingError) && (
                  <em className="invalid">{nodeRoleRequiredError || invalidMappingError}</em>
                )}
              </Col>
            </FormGroup>
            <FormGroup className="row m-b-none">
              <Col componentClass={ControlLabel} xs={3} css={styledLabel}>
                <span>{localized("and").toUpperCase()}</span>
              </Col>
            </FormGroup>
            <FormGroup className="row m-t-md">
              <Col componentClass={ControlLabel} xs={3} css={styledLabel}>
                {localized("Group(s)")}
              </Col>
              <Col xs={9}>
                <SearchableDropDown
                  isMulti
                  keepDropdownInView
                  openOnFocus
                  width="100%"
                  options={sortListByName(preparedGroupList)}
                  value={preparedSelectedGroups}
                  onSelect={compose(setGroups, pluck("id"), defaultTo([]))}
                  valueSelectorKey="id"
                  searchableKey="name"
                  placeholder={localized("Select Multiple...")}
                />
                <div>
                  <RadioGroup
                    className="display-flex align-items-start m-t-sm"
                    name="Options"
                    value={currentMapping.groupsLogicOperator}
                    onChange={this.onChangeGroupLogicalOperator}
                  >
                    <Radio
                      value="OR"
                      radioClass="iradio_square-blue"
                      increaseArea="20%"
                      label={<span className="m-l-xs m-r-md">{localized("Any")}</span>}
                    />
                    <Radio
                      value="AND"
                      radioClass="iradio_square-blue"
                      increaseArea="20%"
                      label={<span className="m-l-xs">{localized("All Groups")}</span>}
                    />
                  </RadioGroup>
                </div>
              </Col>
            </FormGroup>
          </div>
        </Modal>
      </Form>
    )
  }
}

export default connect(
  ({ psa, deviceSearch }) => ({
    title: psa.ConnectWise.mappings.products.nodeRoleProductMappingModalEditorTitle,
    mode: psa.ConnectWise.mappings.products.nodeRoleProductMappingModalEditorMode,
    currentMapping: psa.ConnectWise.mappings.products.currentNodeRoleProductMapping,
    nodeRoleList: psa.ConnectWise.mappings.nodeRoleList,
    groupList: deviceSearch.groups,
    productList: psa.ConnectWise.mappings.products.productList,
    agreementTypeList: psa.ConnectWise.mappings.products.agreementTypeList,
    selectedGroups: psa.ConnectWise.mappings.products.currentNodeRoleProductMapping.groups,
    allMappings: psa.ConnectWise.mappings.products.currentProductMapping.nodeRoleProductMappings,
  }),
  {
    addMapping: _addMapping,
    updateMapping: _updateMapping,
    closeModal: _closeModal,
    setNodeRole: _setNodeRole,
    setProduct: _setProduct,
    setAgreementType: _setAgreementType,
    setGroups: _setGroups,
    setGroupLogicalOperator: _setGroupLogicalOperator,
    requestGetSearchesAndCriteria: _requestGetSearchesAndCriteria,
  },
)(NodeRoleProductMappingModal)
