/** @jsxImportSource @emotion/react */
import { PureComponent } from "react"
import { connect } from "react-redux"
import { css } from "@emotion/react"
import EditorPortalModal from "js/includes/components/EditorPortalModal"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { showModal as _showModal } from "js/state/actions/psa/ConnectWise/mappings/products/nodeRoleProductMappings"
import {
  moveMappingRankUp as _moveMappingRankUp,
  moveMappingRankDown as _moveMappingRankDown,
} from "js/state/actions/psa/ConnectWise/mappings/products/products"
import NodeRoleProductMappingsTable from "./NodeRoleProductMappingsTable"
import NodeRoleProductMappingModal from "./NodeRoleProductMappingModal"
import { colors } from "js/includes/common/theme"
import { getTempId } from "js/state/actions/psa/ConnectWise/mappings/common"

const mappingsStyle = css`
  display: flex;
  flex-flow: column;
  height: 405px;
  margin-top: -15px;
`

const tableHeaderStyle = css`
  display: flex;
  flex-flow: row-reverse;
  margin-top: -9px;
`

const tableContainerStyle = css`
  background-color: ${colors.white};
  border: 1px solid ${colors.ninjaLight};
  margin-top: 20px;
`

class NodeProductMappings extends PureComponent {
  state = {
    selectedMapping: null,
  }

  onAdd = e => {
    e.stopPropagation()
    e.preventDefault()
    const { showModal, mappings } = this.props
    showModal(localizationKey("Add Mapping"), "ADD", getTempId(mappings))
  }

  selectMappingRowId = id => {
    this.setState({ selectedMapping: id || null })
  }

  moveRankUp = () => {
    const { moveMappingRankUp } = this.props
    const { selectedMapping } = this.state
    moveMappingRankUp(selectedMapping)
  }

  moveRankDown = () => {
    const { moveMappingRankDown } = this.props
    const { selectedMapping } = this.state
    moveMappingRankDown(selectedMapping)
  }

  render() {
    const { modalVisibility } = this.props
    const { selectedMapping } = this.state
    const { selectMappingRowId, moveRankUp, moveRankDown } = this

    return (
      <div css={mappingsStyle}>
        <div css={tableHeaderStyle}>
          <button className="btn-link action m-r" onClick={this.onAdd}>
            {localized("Add Mapping")}
          </button>
        </div>
        <div className="display-flex flex-full overflow-hidden" css={tableContainerStyle}>
          <NodeRoleProductMappingsTable
            {...{
              selectedMapping,
              selectMappingRowId,
              moveRankUp,
              moveRankDown,
            }}
          />
        </div>
        <EditorPortalModal {...{ show: modalVisibility, className: "inmodal" }}>
          <NodeRoleProductMappingModal />
        </EditorPortalModal>
      </div>
    )
  }
}

export default connect(
  ({ psa }) => ({
    modalVisibility: psa.ConnectWise.mappings.products.nodeRoleProductMappingModalVisibility,
    mappings: psa.ConnectWise.mappings.products.currentProductMapping.nodeRoleProductMappings,
  }),
  {
    showModal: _showModal,
    moveMappingRankUp: _moveMappingRankUp,
    moveMappingRankDown: _moveMappingRankDown,
  },
)(NodeProductMappings)
