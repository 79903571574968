import { VerticalTabs } from "@ninjaone/components"
import { hasErrors, localized } from "js/includes/common/utils"
import { contentPropTypes } from "./contentCommons"
import { Billing, Cost, Description, Name, Price, Taxable, Quantity } from "./contentFields"
import ContentWrapper from "./ContentWrapper"

const UserProductContent = props => {
  const hasAnyError = hasErrors(props.validation.success)

  return (
    <VerticalTabs
      ariaLabel={props.ariaLabel || localized("Product Form")}
      tabs={[
        {
          required: true,
          hasError: hasAnyError(["name", "description"]),
          label: localized("General"),
          renderer: () => (
            <ContentWrapper>
              <Name {...props} />
              <Description {...props} />
            </ContentWrapper>
          ),
        },
        {
          required: true,
          hasError: hasAnyError(["price", "cost", "quantity"]),
          label: localized("Billing"),
          renderer: () => (
            <ContentWrapper>
              {props.showMoneyFields && (
                <>
                  <Price {...props} />
                  <Cost {...props} />
                </>
              )}
              <Taxable {...props} />
              <Billing {...props} />
              <Quantity {...props} />
            </ContentWrapper>
          ),
        },
      ]}
    />
  )
}

UserProductContent.propTypes = contentPropTypes

export default UserProductContent
