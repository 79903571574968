import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { isNil, reject } from "ramda"
import { Form } from "react-bootstrap"
import {
  noRowsRenderer,
  headerRenderer,
  localized,
  localizationKey,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"
import { getNodeRoles } from "js/includes/common/client"
import { getReadableNodeRole } from "js/includes/common/_enums"
import { Box } from "js/includes/components/Styled"
import { StyledFormGroup, StyledFormControl } from "js/includes/components/Styled/Form"
import { useForm, useMounted } from "js/includes/common/hooks"
import Table from "js/includes/components/Table"
import showModal from "js/includes/common/services/showModal"
import AutotaskDeviceRoleMappingModal from "./AutotaskDeviceRoleMappingModal"
import { Checkbox } from "js/includes/components/NinjaReactICheck"
import { StyledButton } from "js/includes/components/Styled/Form"
import { colors } from "js/includes/common/theme"
import { saveNodeRoleMappings } from "js/state/actions/psa/Autotask/settings"
import { StyledModal } from "js/includes/components/Styled/Form"
import { updateByKey } from "js/includes/common/utils/ssrAndWebUtils"

const formatName = name => name.trim().toLowerCase()

const AutotaskProductConfigurationMapping = ({ unmount, mapping, nodeRoleMappings, saveNodeRoleMappings }) => {
  const mounted = useMounted()
  const [loading, setLoading] = useState(false)
  const [nodeRoleInstalledProductCategoryMappings, setNodeRoleInstalledProductCategoryMappings] = useState(
    mapping?.nodeRoleInstalledProductCategoryMappings ?? [],
  )
  const [nodeRoleMap, setNodeRoleMap] = useState({})
  const [sortBy, setSortBy] = useState("deviceRole")
  const [sortDirection, setSortDirection] = useState("ASC")

  const {
    values: { name, disableInstalledProductOnNodeDelete, overrideProductMapping },
    onChange,
    validation,
    validateForm,
  } = useForm({
    fields: {
      name: mapping?.name ?? "",
      disableInstalledProductOnNodeDelete: mapping?.disableInstalledProductOnNodeDelete ?? false,
      overrideProductMapping: mapping?.overrideProductMapping ?? true,
    },
    validate: {
      name: name => {
        const formattedName = formatName(name)
        const isEmptyName = !formattedName
        const isDuplicateName = nodeRoleMappings.some(m => formatName(m.name) === formattedName && m.id !== mapping?.id)
        const success = !isEmptyName && !isDuplicateName
        const message = success ? "" : localized("Invalid")
        return { success, message }
      },
    },
  })

  const columns = [
    {
      headerRenderer,
      dataKey: "nodeRoleName",
      width: 1,
      flexGrow: 2,
      label: localizationKey("Device Role"),
      cellDataGetter: ({ rowData }) => {
        const nodeRole = nodeRoleMap[rowData.nodeRoleId]

        if (nodeRole) {
          return nodeRole?.custom ? nodeRole?.name : getReadableNodeRole(nodeRole?.name)
        }

        return ""
      },
    },
    {
      headerRenderer,
      dataKey: "productName",
      width: 1,
      flexGrow: 2,
      label: localizationKey("Product"),
      cellRenderer: ({ cellData }) => cellData || "-",
    },
    {
      headerRenderer,
      dataKey: "installedProductCategoryName",
      width: 1,
      flexGrow: 2,
      label: localizationKey("Configuration Item Category"),
      cellRenderer: ({ cellData }) => cellData || "-",
    },
    {
      headerRenderer,
      dataKey: "actions",
      width: 40,
      label: () => null,
      cellRenderer: ({ rowData }) => (
        <span className="action-links">
          <button
            type="button"
            className="btn-link text-danger"
            onClick={e => {
              e.stopPropagation()
              deleteNodeRoleCategoryMapping(rowData.nodeRoleId)
            }}
          >
            <FontAwesomeIcon className="action" icon={faTimes} />
          </button>
        </span>
      ),
    },
  ]

  function saveNodeRoleCategoryMappings(updatedNodeRoleCategoryMapping, oldMapping) {
    const updatedNodeRoleCategoryMappings = isNil(oldMapping?.id)
      ? nodeRoleInstalledProductCategoryMappings.concat([updatedNodeRoleCategoryMapping])
      : updateByKey(updatedNodeRoleCategoryMapping, nodeRoleInstalledProductCategoryMappings)

    setNodeRoleInstalledProductCategoryMappings(updatedNodeRoleCategoryMappings)
  }

  function deleteNodeRoleCategoryMapping(nodeRoleId) {
    const updatedNodeRoleCategoryMappings = reject(
      mapping => mapping.nodeRoleId === nodeRoleId,
      nodeRoleInstalledProductCategoryMappings,
    )

    setNodeRoleInstalledProductCategoryMappings(updatedNodeRoleCategoryMappings)
  }

  function saveProductConfigurationMapping(e) {
    e.stopPropagation()
    e.preventDefault()

    if (validateForm()) {
      const lastId = nodeRoleMappings.reduce((acc, { id }) => (acc > id ? acc : id), 0)

      const updatedProductConfigurationMapping = {
        id: isNil(mapping?.id) ? lastId + 1 : mapping?.id,
        name,
        disableInstalledProductOnNodeDelete,
        nodeRoleInstalledProductCategoryMappings,
        overrideProductMapping,
      }

      const updatedNodeRoleMappings = isNil(mapping?.id)
        ? nodeRoleMappings.concat([updatedProductConfigurationMapping])
        : updateByKey(updatedProductConfigurationMapping, nodeRoleMappings)

      saveNodeRoleMappings(updatedNodeRoleMappings)
      unmount()
    }
  }

  useEffect(() => {
    const fetchNodeRoles = async () => {
      try {
        setLoading(true)
        const nodeRoleList = await getNodeRoles()
        mounted.current &&
          setNodeRoleMap(
            nodeRoleList.reduce((acc, nodeRole) => {
              acc[nodeRole.id] = nodeRole
              return acc
            }, {}),
          )
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        mounted.current && setLoading(false)
      }
    }

    fetchNodeRoles()
  }, [mounted])

  return (
    <Form onSubmit={saveProductConfigurationMapping}>
      <StyledModal
        submittable
        maxHeight="480px !important"
        title={localizationKey("Ninja Device Role Mapping")}
        saveText={localizationKey("OK")}
        closeText={localizationKey("Cancel")}
        dialogClassName="wide-modal"
        close={unmount}
      >
        <Box textAlign="left" display="flex" flexDirection="column" overflow="hidden">
          <StyledFormGroup validationState={validation.success.name === false ? "error" : null}>
            <h4>{localized("Name")}*</h4>
            <StyledFormControl autoFocus={true} type="text" name="name" value={name} {...{ onChange }} />
          </StyledFormGroup>

          <Box margin={[4, 0, 0, 0]} display="flex" alignItems="center">
            <Checkbox
              checkboxClass="icheckbox_square-blue"
              onChange={() => onChange("disableInstalledProductOnNodeDelete", !disableInstalledProductOnNodeDelete)}
              checked={disableInstalledProductOnNodeDelete}
            />
            <Box marginLeft={2} display="inline-block">
              {localized("Mark configuration inactive if corresponding ninja device is deleted")}
            </Box>
          </Box>
          <Box margin={[4, 0, 4, 0]} display="flex" alignItems="center">
            <Checkbox
              checkboxClass="icheckbox_square-blue"
              onChange={() => onChange("overrideProductMapping", !overrideProductMapping)}
              checked={overrideProductMapping}
            />
            <Box marginLeft={2} display="inline-block">
              {localized("Overrides product")}
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <h4>{localized("Ninja Device Role Mapping")}</h4>

            <StyledButton
              type="button"
              onClick={() => {
                showModal(
                  <AutotaskDeviceRoleMappingModal
                    mappings={nodeRoleInstalledProductCategoryMappings}
                    nodeRoleMap={nodeRoleMap}
                    saveMappings={saveNodeRoleCategoryMappings}
                  />,
                  { withProvider: true },
                )
              }}
            >
              {localized("Add")}
            </StyledButton>
          </Box>

          <Box
            width="100%"
            height="270px"
            display="flex"
            borderRadius="3px"
            backgroundColor="white"
            border={`1px solid ${colors.ninjaLight}`}
          >
            <Table
              {...{
                columns,
                sortBy,
                sortDirection,
                rowHeight: 45,
                headerHeight: 45,
                list: loading ? [] : nodeRoleInstalledProductCategoryMappings,
                noRowsRenderer: noRowsRenderer(
                  localizationKey("No role mappings available"),
                  loading,
                  "no-padding m-l-xs",
                ),
                onRowClick: ({ rowData }) => {
                  showModal(
                    <AutotaskDeviceRoleMappingModal
                      mapping={rowData}
                      mappings={nodeRoleInstalledProductCategoryMappings}
                      nodeRoleMap={nodeRoleMap}
                      saveMappings={saveNodeRoleCategoryMappings}
                    />,
                    { withProvider: true },
                  )
                },
                sort: ({ sortBy, sortDirection }) => {
                  setSortBy(sortBy)
                  setSortDirection(sortDirection)
                },
              }}
            />
          </Box>
        </Box>
      </StyledModal>
    </Form>
  )
}

export default connect(
  ({ psa }) => ({
    nodeRoleMappings: psa.Autotask.settings.nodeRoleMappings,
  }),
  {
    saveNodeRoleMappings,
  },
)(AutotaskProductConfigurationMapping)
