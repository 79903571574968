import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import fastDeepEqual from "fast-deep-equal"
import {
  append,
  assoc,
  compose,
  flatten,
  indexBy,
  map,
  mapObjIndexed,
  modify,
  modifyPath,
  prop,
  reduce,
  values,
  find,
} from "ramda"
import { useMountedState, usePrevious } from "@ninjaone/webapp/src/js/includes/common/hooks"
import { isNilOrEmpty, PubSub, reportErrorAndShowMessage } from "@ninjaone/webapp/src/js/includes/common/utils"

import { filterOptions, runAfterDataTableRenders } from "./utils"
import { dataTablePubSubActions } from "./constants"

const getFilterProperties = compose(
  indexBy(prop("name")),
  flatten,
  values,
  mapObjIndexed((filters, filterCategory) => filters.map(assoc("category", filterCategory))),
)

const mapFilterValues = map(filter => assoc("value", filter.initialValue ?? filter.defaultValue, filter))
const mapFilterDefaultValues = map(filter => assoc("value", filter.defaultValue, filter))

const getUnselectedRowIdsObject = ({ rowIsUnselected, checked, id, rowIdsObject }) => {
  if (checked && rowIsUnselected) {
    delete rowIdsObject[id]
  } else if (!checked) {
    rowIdsObject[id] = true
  }

  return rowIdsObject
}

export const useRemoteData = (fetchData, filters, setLastUpdated, storeTableColumnsWidths) => {
  const [remoteData, setRemoteData] = useMountedState([])
  const [pageCount, setPageCount] = useMountedState(null)
  const [totalCount, setTotalCount] = useMountedState(null)

  const [paginationProps, setPaginationProps] = useMountedState(null)

  const [isLoading, setIsLoading] = useMountedState(false)
  const [isError, setIsError] = useMountedState(false)

  const timeoutRef = useRef()
  const fetchDataLastRequestTimeRef = useRef()

  const debouncedFetchData = useCallback(
    fetchProps =>
      new Promise((resolve, reject) => {
        clearTimeout(timeoutRef.current)

        timeoutRef.current = setTimeout(async () => {
          const requestTime = Date.now()
          fetchDataLastRequestTimeRef.current = requestTime

          const response = await fetchData(fetchProps).catch(reject)
          resolve({ response, requestTime })
        }, 300)
      }),
    [fetchData],
  )

  // Using a string to compare filters dependency by value instead of reference
  const filtersString = useMemo(
    () =>
      compose(
        JSON.stringify,
        map(({ name, value, type }) => ({ name, value, type })),
      )(filters),
    [filters],
  )

  const updateRemoteData = useCallback(
    async (showLoading = true) => {
      if (!fetchData || !paginationProps) return
      setIsError(false)
      setIsLoading(isLoading => {
        if (!isLoading) {
          storeTableColumnsWidths()
        }
        return showLoading
      })

      let isLastRequest = true

      try {
        const {
          response: { data, pageCount, totalCount },
          requestTime,
        } = await debouncedFetchData({ ...paginationProps, filters: JSON.parse(filtersString) })

        if (requestTime < fetchDataLastRequestTimeRef.current) {
          isLastRequest = false
          // At this point a new request was made so it stops here to avoid setting outdated data from previous requests.
          return
        }

        setLastUpdated(new Date())
        setRemoteData(data)
        setPageCount(pageCount)
        setTotalCount(totalCount)
      } catch (error) {
        setIsError(error)
        reportErrorAndShowMessage(error)
      } finally {
        isLastRequest && setIsLoading(false)
      }
    },
    [
      setIsError,
      fetchData,
      paginationProps,
      filtersString,
      setIsLoading,
      setRemoteData,
      setPageCount,
      setTotalCount,
      setLastUpdated,
      debouncedFetchData,
      storeTableColumnsWidths,
    ],
  )

  useEffect(() => {
    updateRemoteData()
  }, [updateRemoteData])

  return {
    remoteData,
    isLoading,
    setIsLoading,
    setPaginationProps,
    pageCount,
    totalCount,
    updateRemoteData,
    isError,
  }
}

export const useCustomFilters = (filtersSpec = {}, onClearFilters, clearFiltersOnReset) => {
  const initialFilterProperties = useRef(getFilterProperties(filtersSpec))
  const [filters, setFilters] = useMountedState(mapFilterValues(initialFilterProperties.current))

  const filtersArray = useMemo(() => values(filters), [filters])

  const prevFiltersSpec = usePrevious(filtersSpec)

  useEffect(() => {
    if (!fastDeepEqual(filtersSpec, prevFiltersSpec)) {
      const newFiltersSpec = getFilterProperties(filtersSpec)
      initialFilterProperties.current = newFiltersSpec

      setFilters(filters => {
        const mappedFilterValues = map(item => {
          return assoc(
            "value",
            item?.shouldResetValue
              ? item.defaultValue ?? item.initialValue
              : newFiltersSpec[item.name]?.value ?? filters[item.name]?.value,
            item,
          )
        })(newFiltersSpec)

        return mappedFilterValues
      })
    }
  }, [filtersSpec, prevFiltersSpec, setFilters])

  const filterFunctionList = useMemo(
    () =>
      reduce(
        (filterFuncListAcc, { name, type, filter: customFilterFunc, value, componentProps }) => {
          if (isNilOrEmpty(value)) return filterFuncListAcc
          const filterFunc = customFilterFunc ?? filterOptions[type].filter
          return append(
            row =>
              filterFunc({
                name,
                row,
                value,
                componentProps,
                allFilters: filters,
              }),
            filterFuncListAcc,
          )
        },
        [],
        filtersArray,
      ),
    // TECH DEBT:
    // - We intentionally disabled ESLint here since @ninjaone/components is now integrated into our build pipeline & linting warnings/errors will trigger a failure.
    // - We decided not to fix the warning (missing dependency: 'filters') for now as that could lead to unexpected bugs.
    // - If adding any new code here do it with caution & make sure to add missing dependencies for your code only.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersArray],
  )

  const updateFilterProps = useCallback(
    (filterName, updateFunc) => {
      setFilters(currentFilters => modify(filterName, updateFunc, currentFilters))
    },
    [setFilters],
  )

  const updateFilter = useCallback(
    (name, newValue) => {
      setFilters(currentFilters => modifyPath([name, "value"], () => newValue, currentFilters))
      initialFilterProperties.current[name]?.onFilterChange?.({ name, value: newValue, updateFilterProps })
    },
    [setFilters, updateFilterProps],
  )

  const clearFilters = useCallback(() => {
    if (clearFiltersOnReset) {
      onClearFilters?.()
      setFilters(initialFilterProperties.current)
    } else {
      setFilters(mapFilterDefaultValues(initialFilterProperties.current))
      onClearFilters?.()
    }
  }, [clearFiltersOnReset, onClearFilters, setFilters])

  return { customFilters: filtersArray, filterFunctionList, updateFilter, clearFilters }
}

//TODO comment about the hook name - is used also when working with fetchData
export const useLazyRowLoading = ({
  tableData,
  onLoadMore,
  requestPageSize,
  pageSizeLimit,
  totalRowCount,
  getRowId,
  omitDisabledInSelection,
  getCustomRowProps,
}) => {
  const temporarilyDisableNext = useRef(null)
  const disableAutoResetPage = useRef(null)
  const nextPageIndex = useRef(null)
  const [isInSelectAllMode, setIsInSelectAllMode] = useState(false)
  const [unselectedRowIds, setUnselectedRowIds] = useState({})

  const initialSelectedRowIds = useMemo(
    () =>
      isInSelectAllMode
        ? tableData.reduce((selectedRowIds, currentItem) => {
            // we want to check if current item being looped is in unselectedRowIds
            const id = getRowId(currentItem)

            if (!unselectedRowIds?.[id] && !(omitDisabledInSelection && getCustomRowProps(currentItem).disabled)) {
              selectedRowIds[id] = true
            }

            return selectedRowIds
          }, {})
        : null,
    [tableData, isInSelectAllMode, unselectedRowIds, getRowId, omitDisabledInSelection, getCustomRowProps],
  )

  const getRowChecked = ({ checked, id }) => checked || initialSelectedRowIds?.[id]

  const getNewUnselectedRowIdsFromPage = useCallback(
    ({ unselectedRowIds, checked, page }) =>
      page.reduce(
        (selectedRowIds, { id }) =>
          getUnselectedRowIdsObject({
            rowIsUnselected: unselectedRowIds?.[id],
            checked,
            rowIdsObject: { ...selectedRowIds },
            id,
          }),
        {
          ...unselectedRowIds,
        },
      ),
    [],
  )

  const getNewUnselectedRowIds = useCallback(
    ({ unselectedRowIds, checked, rowId }) =>
      getUnselectedRowIdsObject({
        rowIsUnselected: unselectedRowIds?.[rowId],
        checked,
        rowIdsObject: { ...unselectedRowIds },
        id: rowId,
      }),
    [],
  )

  const handleToggleRow = useCallback(
    (rowId, checked) => {
      setUnselectedRowIds(unselectedRowIds => getNewUnselectedRowIds({ unselectedRowIds, checked, rowId }))
    },
    [getNewUnselectedRowIds],
  )

  const handleTogglePageRows = useCallback(
    (checked, page) => {
      setUnselectedRowIds(unselectedRowIds => getNewUnselectedRowIdsFromPage({ unselectedRowIds, checked, page }))
    },
    [getNewUnselectedRowIdsFromPage],
  )

  const handleToggleAllRows = useCallback(checked => {
    setUnselectedRowIds({})
    setIsInSelectAllMode(checked)
  }, [])

  const canLoadMoreRows = ({ pageIndex, rowsLength, pageSize }) => {
    const requestEveryXPages = Math.ceil(requestPageSize / pageSizeLimit)
    const indexIsDivisible = (pageIndex + 1) % requestEveryXPages === 0
    const alreadyLoadedDataForIndex = rowsLength <= pageSize * (pageIndex + 1)

    return indexIsDivisible && alreadyLoadedDataForIndex && rowsLength < totalRowCount
  }

  const handleLazyLoadRowsAndNext = async ({ nextPage, pageIndex, rowsLength, pageSize }) => {
    if (temporarilyDisableNext.current) return

    temporarilyDisableNext.current = true

    if (canLoadMoreRows({ pageIndex, rowsLength, pageSize })) {
      disableAutoResetPage.current = true
      nextPageIndex.current = pageIndex + 1
      await onLoadMore()

      runAfterDataTableRenders(() => {
        disableAutoResetPage.current = false
      })
    } else {
      nextPage()
    }

    runAfterDataTableRenders(() => {
      temporarilyDisableNext.current = false
      nextPageIndex.current = null
    })
  }

  return {
    initialSelectedRowIds,
    handleTogglePageRows,
    handleToggleRow,
    handleToggleAllRows,
    getRowChecked,
    handleLazyLoadRowsAndNext,
    disableAutoResetPage: disableAutoResetPage.current,
    nextPageIndex: nextPageIndex.current,
    canLoadMoreRows,
    isInSelectAllMode,
    unselectedRowIds,
  }
}

const getColumnsData = columns => {
  const [firstColumn, ...remainingColumns] = columns
  const selectionEnabled = firstColumn.id === "selection"
  return {
    selectionEnabled,
    firstColumn,
    remainingColumns,
  }
}

export const useStatusIndicatorRenderer = ({
  getStatusIndicatorColor,
  getStatusIndicatorBorderColor,
  theme,
  isLoading,
}) => {
  return hooks => {
    if (getStatusIndicatorColor && !isLoading) {
      hooks.visibleColumns.push(columns => {
        const { firstColumn, remainingColumns, selectionEnabled } = getColumnsData(columns)
        const statusColumn = {
          id: "status-indicator",
          disableSortBy: true,
          fallbackColor: theme.color.black["010"],
          getStatusIndicatorColor,
          getStatusIndicatorBorderColor,
        }

        return selectionEnabled ? [firstColumn, statusColumn, ...remainingColumns] : [statusColumn, ...columns]
      })
    }
  }
}

export const useRowStateRenderer = ({ hasRowStateIcons, isLoading }) => {
  return hooks => {
    if (hasRowStateIcons && !isLoading) {
      hooks.visibleColumns.push(columns => {
        const { firstColumn, remainingColumns, selectionEnabled } = getColumnsData(columns)
        const disabledColumn = {
          id: "row-state-icon",
          disableSortBy: true,
        }

        return selectionEnabled ? [firstColumn, disabledColumn, ...remainingColumns] : [disabledColumn, ...columns]
      })
    }
  }
}

export const useDragAndDropRenderer = ({ onDrop, columnHeader }) => {
  return hooks => {
    if (onDrop) {
      hooks.visibleColumns.push(columns => {
        const { firstColumn, remainingColumns, selectionEnabled } = getColumnsData(columns)
        const dragAndDropColumn = {
          id: "drag-and-drop",
          disableSortBy: true,
          Header: columnHeader || (() => null),
        }
        return selectionEnabled
          ? [firstColumn, dragAndDropColumn, ...remainingColumns]
          : [dragAndDropColumn, ...columns]
      })
    }
  }
}

export const useAutoRefreshPolling = ({ pollingTime, resetData, pollingEnabled }) => {
  const pollingInterval = useRef()

  useEffect(() => {
    if (pollingEnabled) {
      pollingInterval.current = setInterval(() => {
        resetData({ keepCurrentPage: true, showLoading: false })
      }, pollingTime)
    }

    return () => clearInterval(pollingInterval.current)
  }, [pollingTime, pollingEnabled, resetData])
}

export const useAutoRefreshPubSub = ({ pubsubChannel, autoRefreshEnabled, resetData }) => {
  const subscriptionRef = useRef(null)

  useEffect(() => {
    if (pubsubChannel && !subscriptionRef.current && autoRefreshEnabled) {
      subscriptionRef.current = PubSub.subscribe(pubsubChannel, (_, data) => {
        const { type, options = {} } = data
        switch (type) {
          case dataTablePubSubActions.RELOAD_TABLE:
            const { keepCurrentPage = true, showLoading = false } = options
            resetData({ keepCurrentPage, showLoading })
            break
          default:
            break
        }
      })
    }

    return () => {
      if (subscriptionRef.current) {
        PubSub.unsubscribe(subscriptionRef.current)
        subscriptionRef.current = null
      }
    }
  }, [pubsubChannel, resetData, autoRefreshEnabled])
}

export const useCustomRowProps = getCustomRowProps => {
  return hooks => {
    if (!getCustomRowProps) {
      return
    }
    hooks.getRowProps.push((prevProps, { row }) => {
      return {
        ...prevProps,
        customProps: getCustomRowProps(row.original),
      }
    })
  }
}

export const useKeyboardActions = ({
  rows,
  tableId,
  selected,
  hasRowSelection,
  handleToggleRow,
  toggleRowSelected,
  getCustomRowProps,
  enableLazyLoadRows,
  hideCheckboxes,
}) => {
  const tbodyRef = useRef(null)

  const [lastCellFocused, setLastCellFocused] = useState(() => {
    return { rowId: null, id: null }
  })

  const handleKeyDown = useCallback(
    ({ event, row, enableRowAction, rowAction, fetchData }) => {
      const isTryingToCopyCellText = event.key === "c" && (event.metaKey || event.ctrlKey)

      if (isTryingToCopyCellText) return

      event.preventDefault() // prevent native scrolling, scrolling will be done via .focus()

      if (!lastCellFocused.rowId) {
        setLastCellFocused(prevState => ({ ...prevState, rowId: row.id }))
      }

      const currentRow = tbodyRef.current?.children.namedItem(`${tableId}-${row.id}`)

      const previousRowEl = currentRow?.previousElementSibling
      const nextRowEl = currentRow?.nextElementSibling

      const previousRowId = previousRowEl ? previousRowEl.getAttribute("data-table-row-id") : null
      const previousRowItem = find(_row => _row.id === previousRowId, rows)

      const nextRowId = nextRowEl ? nextRowEl.getAttribute("data-table-row-id") : null
      const nextRowItem = find(_row => _row.id === nextRowId, rows)

      const lastCellIndexFocusedNumber = lastCellFocused.id || 0

      switch (event.key) {
        case "ArrowUp":
          setLastCellFocused({ rowId: previousRowId, id: null })
          if (previousRowEl) {
            previousRowEl.focus()

            if (hideCheckboxes) break

            const isDisabled = getCustomRowProps?.(previousRowItem?.original)?.disabled

            if (hasRowSelection && event.shiftKey && previousRowItem) {
              if (enableLazyLoadRows || fetchData) {
                !isDisabled && handleToggleRow(previousRowId, true)

                if (row.isSelected && previousRowItem.isSelected) {
                  handleToggleRow(row.id, false)
                }
              }

              !isDisabled && toggleRowSelected(previousRowId, true)

              if (row.isSelected && previousRowItem.isSelected) {
                toggleRowSelected(row.id, false)
              }
            } else {
              if (selected?.length > 1) return

              if (enableLazyLoadRows || fetchData) {
                handleToggleRow(previousRowId, true)
                handleToggleRow(row.id, false)
              }
              !isDisabled && toggleRowSelected(previousRowId, true)
              toggleRowSelected(row.id, false)
            }
          }
          break

        case "ArrowDown":
          setLastCellFocused({ rowId: nextRowId, id: null })
          if (nextRowEl) {
            nextRowEl.focus()

            if (hideCheckboxes) break

            const isDisabled = getCustomRowProps?.(nextRowItem?.original)?.disabled

            if (hasRowSelection && event.shiftKey && nextRowItem) {
              if (enableLazyLoadRows || fetchData) {
                !isDisabled && handleToggleRow(nextRowId, true)

                if (row.isSelected && nextRowItem.isSelected) {
                  handleToggleRow(row.id, false)
                }
              }
              !isDisabled && toggleRowSelected(nextRowId, true)

              if (row.isSelected && nextRowItem.isSelected) {
                toggleRowSelected(row.id, false)
              }
            } else {
              if (selected?.length > 1) return

              if (enableLazyLoadRows || fetchData) {
                handleToggleRow(nextRowId, true)
                handleToggleRow(row.id, false)
              }
              !isDisabled && toggleRowSelected(nextRowId, true)
              toggleRowSelected(row.id, false)
            }
          }
          break

        case "Tab":
          if (event.shiftKey) {
            const previousCellIndex =
              (lastCellIndexFocusedNumber - 1 + currentRow.children.length) % currentRow.children.length
            currentRow.children[lastCellIndexFocusedNumber].blur()

            setLastCellFocused(prevState => ({
              ...prevState,
              id: previousCellIndex,
            }))

            currentRow.children[previousCellIndex].focus()
          } else {
            const nextCellIndex = (lastCellIndexFocusedNumber + 1) % currentRow.children.length
            currentRow.children[lastCellIndexFocusedNumber].blur()

            setLastCellFocused(prevState => ({
              ...prevState,
              id: nextCellIndex,
            }))

            currentRow.children[nextCellIndex].focus()
          }

          event.preventDefault()
          break

        case "Enter":
          const currentCell = currentRow?.children[lastCellIndexFocusedNumber]
          const interactiveCell = currentCell?.querySelector(".data-table-interactive-cell")

          if (enableRowAction && !interactiveCell) {
            rowAction(row.original, event)
          }

          if (interactiveCell) {
            interactiveCell?.click()
          }

          event.preventDefault()
          break

        case " ":
          if (!hideCheckboxes && hasRowSelection) {
            const isDisabled = getCustomRowProps?.(row.original).disabled

            if (!isDisabled) {
              toggleRowSelected(row.id, !row.isSelected)
            }
          }
          break

        default:
          break
      }
    },
    [
      hasRowSelection,
      tableId,
      handleToggleRow,
      toggleRowSelected,
      enableLazyLoadRows,
      rows,
      selected,
      lastCellFocused,
      getCustomRowProps,
      hideCheckboxes,
    ],
  )

  return { handleKeyDown, tbodyRef, lastCellFocused }
}

export const useShiftPressed = () => {
  const [isShiftPressed, setIsShiftPressed] = useState(false)

  const handleKeyDown = useCallback(event => {
    if (event.key === "Shift") {
      setIsShiftPressed(true)
    }
  }, [])

  const handleKeyUp = useCallback(event => {
    if (event.key === "Shift") {
      setIsShiftPressed(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
    window.addEventListener("keyup", handleKeyUp)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
      window.removeEventListener("keyup", handleKeyUp)
    }
  }, [handleKeyDown, handleKeyUp])

  return isShiftPressed
}

export const useShowSelected = ({
  isShowSelectedEnabled,
  _selectedRowIds,
  getCustomRowProps,
  rowsData,
  paginationContainerRef,
  onSelectRows,
  getRowId,
}) => {
  const _selectedRowIdsObject = Object.fromEntries(_selectedRowIds.map(val => [val, true]))
  const [showSelectedRowIds, setShowSelectedRowIds] = useState(_selectedRowIdsObject || {})
  const [showSelected, setShowSelected] = useState(false)
  const [paginationContainerWidth, setPaginationContainerWidth] = useState(null)

  const toggleShowSelected = callback => {
    if (!isShowSelectedEnabled) return
    setShowSelected(oldState => !oldState)

    if (showSelected) {
      setPaginationContainerWidth(null)
    } else {
      setPaginationContainerWidth(paginationContainerRef.current?.offsetWidth)
    }

    callback(!showSelected)
  }

  const showSelectedToggleRowSelected = (rowId, checked) => {
    if (!isShowSelectedEnabled) return

    if (checked) {
      setShowSelectedRowIds(newSelected => ({ ...newSelected, [rowId]: true }))
    } else {
      setShowSelectedRowIds(newSelected => {
        const selected = { ...newSelected }
        delete selected[rowId]
        return selected
      })
    }
  }

  const showSelectedToggleAllPageRowsSelected = (page, checked) => {
    if (!isShowSelectedEnabled) return

    if (checked) {
      const pageSelectedIds = page.reduce((acc, row) => {
        const { disabled } = getCustomRowProps?.(row.original) ?? {}

        if (!disabled) {
          acc[row.id] = true
        }
        return acc
      }, {})

      setShowSelectedRowIds(newSelected => ({ ...newSelected, ...pageSelectedIds }))
    } else {
      const selected = { ...showSelectedRowIds }
      page.forEach(pageRow => !delete selected[pageRow.id])

      setShowSelectedRowIds(selected)
    }
  }

  const showSelectedToggleAllRowsSelected = (rows, checked) => {
    if (!isShowSelectedEnabled) return

    if (checked) {
      const pageSelectedIds = rows.reduce((acc, row) => {
        const { disabled } = getCustomRowProps?.(row.original) ?? {}

        if (!disabled) {
          acc[row.id] = true
        }

        return acc
      }, {})

      setShowSelectedRowIds(oldSelectedRowIds => ({
        ...oldSelectedRowIds,
        ...pageSelectedIds,
      }))
    } else {
      setShowSelectedRowIds(oldSelectedRowIds => {
        const selected = { ...oldSelectedRowIds }
        rows.forEach(row => !delete selected[row.id])
        return selected
      })
    }
  }

  const showSelectedTableData = useMemo(() => {
    if (!isShowSelectedEnabled) return

    let rowsById = {}
    let rows = []

    rowsData.forEach((row, index) => {
      const rowId = getRowId(row, index)
      let _row = rowsById[rowId]

      if (!_row && !!showSelectedRowIds[rowId]) {
        rowsById[rowId] = row
        rows.push(row)
      }
    })

    return rows
  }, [getRowId, isShowSelectedEnabled, rowsData, showSelectedRowIds])

  const prevSelectedTableData = usePrevious(showSelectedTableData)

  if (isShowSelectedEnabled) {
    // if we delete all selected rows while in show selected
    if (showSelected && !showSelectedTableData.length) {
      setShowSelected(false)
      setShowSelectedRowIds([])
    }

    // if we delete any selected rows and we have a filter on and there is nothing else to show
    const rowsMatch =
      Object.keys(showSelectedRowIds).length === showSelectedTableData.length &&
      showSelectedTableData.every((row, index) => {
        const rowId = getRowId(row, index)
        return showSelectedRowIds[rowId]
      })

    if (!rowsMatch) {
      const pageSelectedIds = showSelectedTableData.reduce((acc, row) => {
        acc[row.id] = true
        return acc
      }, {})
      setShowSelectedRowIds(pageSelectedIds)
    }
  }

  // handle onSelectRows when selection changes
  useEffect(() => {
    if (!isShowSelectedEnabled) return

    if (onSelectRows && prevSelectedTableData && showSelectedTableData.length !== prevSelectedTableData.length) {
      runAfterDataTableRenders(() => {
        onSelectRows(showSelectedTableData, [])
      })
    }
  }, [isShowSelectedEnabled, onSelectRows, prevSelectedTableData, showSelectedTableData])

  return {
    showSelected,
    showSelectedRowIds,
    toggleShowSelected,
    paginationContainerWidth,
    showSelectedToggleRowSelected,
    showSelectedToggleAllPageRowsSelected,
    showSelectedToggleAllRowsSelected,
    showSelectedTableData,
  }
}
