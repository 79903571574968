import { nanoid } from "nanoid"
import {
  always,
  applySpec,
  compose,
  filter,
  find,
  flatten,
  has,
  identity,
  ifElse,
  includes,
  isEmpty,
  map,
  omit,
  pluck,
  prop,
  propEq,
  reduce,
  reject,
  sortBy,
  T,
} from "ramda"

import {
  arrayToMapWithKey,
  fullNameOrEmail,
  getAsArray,
  isNilOrEmpty,
  isNumber,
  joinByComma,
  localized,
  mappedIndex,
  splitByComma,
} from "js/includes/common/utils"
import {
  getCurrentUserOption,
  searchableKey,
  valueSelectorKey as usersDropdownSelectorKey,
} from "js/includes/ticketing/shared/components/AppUsersAndContactsDropdown"

export const parseServerRules = rules => rules.map(rule => ({ id: nanoid(10), ...rule }))

const isNew = prop("isNew")

const pluckUid = pluck("uid")

const getUids = compose(pluckUid, reject(isNew))

const getEmails = compose(pluckUid, filter(isNew))

const omitRuleFieldsForServer = omit(["id", "touched", "validation", "metaData"])
const sanitizeRules = map(omitRuleFieldsForServer)

const trimIfDefined = str => str?.trim?.()

const splitCommaSeparatedString = compose(ifElse(isNilOrEmpty, always([]), splitByComma), trimIfDefined)

export const flattenOptions = compose(flatten, map(ifElse(has("options"), prop("options"), identity)))

const transformServerEmailValues = map(
  applySpec({
    uid: identity,
    displayName: identity,
    isNew: T,
  }),
)

const transformServerUidValues = map(
  applySpec({
    uid: prop("uid"),
    displayName: user =>
      user?.deleted ? localized("{{fullName}} (Deleted)", { fullName: fullNameOrEmail(user) }) : fullNameOrEmail(user),
    userType: prop("userType"),
    deleted: prop("deleted"),
  }),
)

const mapOmittingOrder = map(omit(["order"]))

const sortByOrder = sortBy(prop("order"))

export const additionalEmailDefaultOptions = () => [
  { isDefaultOption: true, uid: "REQUESTER", displayName: localized("general.requester") },
  {
    isDefaultOption: true,
    uid: "REQUESTER_CC",
    displayName: `${localized("general.requester")} ${localized("general.cc")}`,
  },
  { isDefaultOption: true, uid: "ASSIGNEE", displayName: localized("general.assignee") },
]

const mapUser = ({ user, valueSelectorKey }) => ({
  id: user.id,
  [valueSelectorKey]: user[valueSelectorKey],
  [usersDropdownSelectorKey]: user[usersDropdownSelectorKey],
  [searchableKey]: user.deleted
    ? localized("{{fullName}} (Deleted)", { fullName: fullNameOrEmail(user) })
    : fullNameOrEmail(user),
  email: user.email,
  userType: user.entityType || user.userType,
})

const buildEntityNotFoundValue = ({ valueKey = "id", idValue, field, naText: _naText }) => {
  const naText = _naText || localized("N/A")
  // requester and assignee are for conditions
  // assignedAppUserId and requesterUid fare for actions
  if (["requester", "assignee", "assignedAppUserId", "requesterUid"].includes(field)) {
    return mapUser({ valueSelectorKey: valueKey, user: { [valueKey]: idValue, firstName: naText } })
  }

  return {
    id: idValue,
    name: naText,
    ...(field === "location" && { organizationName: naText }),
  }
}

const getUserValue = ({ operator, metaData, valueKey, value, field }) => {
  const isMulti = ["contains_any", "contains_none"].includes(operator)
  const isSingle = ["equals", "not_equals"].includes(operator)
  if (isMulti || isSingle) {
    const metadataMap = {
      ...reduce(
        (acc, userMetadata) => {
          acc[userMetadata[valueKey]] = mapUser({ user: userMetadata, valueSelectorKey: valueKey })
          return acc
        },
        {},
        getAsArray(metaData),
      ),
      current_user: {
        ...getCurrentUserOption(),
        [valueKey]: "current_user",
      },
    }
    if (isMulti) {
      return map(
        idValue => metadataMap[idValue] || buildEntityNotFoundValue({ valueKey, idValue, field }),
        value.split(",") || [],
      )
    }
    if (isSingle) {
      return metadataMap[value] || buildEntityNotFoundValue({ valueKey, idValue: value, field })
    }
  }
}

const buildUserValue = ({ field, value, operator, metaData, valueKey = "uid" }) => {
  if (value) {
    return getUserValue({ operator, metaData, valueKey, value, field }) || value
  }
  return value
}

const buildLocationOrOrganizationValue = ({ value, operator, metaData, field }) => {
  if (value && ["contains_any", "contains_none"].includes(operator)) {
    const metadataMap = arrayToMapWithKey("id", getAsArray(metaData))
    const naText = localized("N/A")
    return map(
      id => metadataMap[id] || buildEntityNotFoundValue({ field, idValue: id, naText }),
      value.split(",") || [],
    )
  }
  return value
}

const mapServerConditionRule = rule => {
  const { field, value: _value, operator, metaData } = rule

  let value = _value
  if (value) {
    if (["requester", "assignee"].includes(field)) {
      value = buildUserValue({ value, field, operator, metaData, ...("assignee" === field && { valueKey: "id" }) })
    } else if (["location", "organization"].includes(field)) {
      value = buildLocationOrOrganizationValue({ value, operator, metaData, field })
    }
  }

  return {
    id: nanoid(10),
    ...rule,
    value,
  }
}

export const parseServerConditionRules = rules => map(mapServerConditionRule, rules || [])

const getServerActionRuleValue = ({ rule, property }) => {
  const { value, property: propertyName, organization, user } = property
  const { field } = rule
  if (value && field === "ticket" && ["assignedAppUserId", "organization", "requesterUid"].includes(propertyName)) {
    const valueSelectorKey = propertyName === "requesterUid" ? "uid" : "id"
    if (["assignedAppUserId", "requesterUid"].includes(propertyName)) {
      return user
        ? mapUser({ user, valueSelectorKey })
        : buildEntityNotFoundValue({ field, idValue: value, valueKey: valueSelectorKey })
    }
    return organization || buildEntityNotFoundValue({ field, idValue: value })
  }
  return value
}

export const parseServerActionRules = (rules, uploads) => {
  const parsedActions = reduce((acc, actions) => {
    if (actions.type === "SEND_EMAIL") {
      const additionalEmailOptions = [...additionalEmailDefaultOptions(), getCurrentUserOption()]
      acc.push({
        id: nanoid(10),
        field: "SEND_EMAIL",
        uid: actions?.uid,
        order: actions?.order,
        value: {
          subject: actions?.params?.subject,
          body: actions?.params?.body,
          keys: actions?.params?.to.keys?.map(key => {
            return typeof key === "string"
              ? additionalEmailOptions.find(({ uid }) => uid.toLocaleLowerCase() === key.toLocaleLowerCase())
              : key
          }),
          emails: transformServerEmailValues(actions?.params?.to.emails),
          uids: transformServerUidValues(actions?.params?.to.users),
          allowResponse: actions?.params?.allowResponse,
          uploads,
        },
        metaData: {
          toUsers: actions?.params?.to.users,
        },
      })
    } else if (actions.type === "ADD_TICKET_COMMENT") {
      acc.push({
        id: nanoid(10),
        field: "ADD_TICKET_COMMENT",
        uid: actions?.uid,
        order: actions?.order,
        value: {
          body: actions?.params?.body,
          public: actions?.params?.public,
        },
      })
    } else if (actions.type === "TICKET_LOG_ENTRY_TIME_TRACKER") {
      acc.push({
        id: nanoid(10),
        field: "TICKET_LOG_ENTRY_TIME_TRACKER",
        uid: actions?.uid,
        order: actions?.order,
        value: actions?.params?.value,
        operator: actions?.params?.unit,
      })
    } else if (actions.type === "SEND_NOTIFICATION") {
      actions.params.channels.map(channel =>
        acc.push({
          id: nanoid(10),
          field: actions.type,
          uid: actions?.uid,
          order: channel.order,
          value: channel.channelId,
          operator: channel.channelType,
        }),
      )
    } else {
      map(rule => {
        if (includes(rule.field, ["ticket", "attributes"])) {
          map(property => {
            acc.push({
              id: nanoid(10),
              field: rule.field,
              uid: actions?.uid,
              order: rule.order,
              operator: property.property,
              value: getServerActionRuleValue({ rule, property }),
              metaData: property?.user || property?.organization,
            })
          }, rule?.properties)
        } else if (includes(rule.field, ["add_cc_list", "set_cc_list"])) {
          const [emails, uids] = rule.properties
          acc.push({
            id: nanoid(10),
            field: rule.field,
            uid: actions?.uid,
            order: rule.order,
            value: [...transformServerEmailValues(emails.value), ...transformServerUidValues(uids.users)],
            metaData: {
              users: uids.users,
            },
          })
        } else if (includes(rule.field, ["add_tags", "remove_tags", "set_tags"])) {
          acc.push({
            id: nanoid(10),
            field: rule.field,
            uid: actions?.uid,
            order: rule.order,
            value: splitCommaSeparatedString(rule.value),
          })
        }
      }, actions.params.changes)
    }
    return acc
  }, [])(rules)

  // To keep server order, it sorts only if order is set. Checking the first element is enough.
  if (isNumber(parsedActions[0]?.order)) {
    return mapOmittingOrder(sortByOrder(parsedActions))
  }

  return parsedActions
}

const setOrderInRules = mappedIndex((rule, index) => ({
  ...rule,
  order: index,
}))

const getActionValue = rule => {
  const { field, operator, value } = rule || {}

  if (value && field === "ticket" && ["assignedAppUserId", "organization", "requesterUid"].includes(operator)) {
    return value[operator === "requesterUid" ? "uid" : "id"] || null
  }
  return value
}

export const setServerActionRules = rules => {
  const rulesWithOrder = setOrderInRules(rules)

  const actions = reduce(
    (acc, rule) => {
      const { order } = rule

      if (rule?.field === "SEND_EMAIL") {
        acc.SEND_EMAIL.push({
          order,
          type: "SEND_EMAIL",
          params: {
            to: {
              keys: rule?.value.keys.map(key => key?.uid?.toUpperCase() ?? key),
              emails: pluckUid(rule?.value.emails),
              uids: pluckUid(rule?.value.uids),
            },
            subject: rule?.value.subject,
            body: rule?.value.body,
            allowResponse: rule?.value.allowResponse,
          },
          ...(rule?.uid && { uid: rule.uid }),
        })
      } else {
        const oldChanges = acc.TICKET_CHANGE_VALUE.params.changes

        if (includes(rule.field, ["ticket", "attributes"])) {
          const oldField = find(propEq("field", rule?.field), oldChanges) || {
            order,
            field: rule?.field,
            properties: [],
          }

          const newValue = {
            ...oldField,
            properties: [
              ...oldField.properties,
              {
                property: rule?.operator,
                value: getActionValue(rule),
              },
            ],
          }

          if (rule?.uid) {
            acc.TICKET_CHANGE_VALUE.uid = rule.uid
          }

          acc.TICKET_CHANGE_VALUE.params = {
            changes: [...reject(propEq("field", rule?.field), oldChanges), newValue],
          }
        } else if (rule.field === "SEND_NOTIFICATION") {
          if (rule?.uid) {
            acc.SEND_NOTIFICATION.uid = rule.uid
          }

          acc.SEND_NOTIFICATION.params.channels.push({
            order,
            channelId: rule.value,
            channelType: rule.operator,
          })
        } else if (rule.field === "TICKET_LOG_ENTRY_TIME_TRACKER") {
          if (rule?.uid) {
            acc.TICKET_LOG_ENTRY_TIME_TRACKER.uid = rule.uid
          }

          // There cannot be more than one item of this type
          acc.TICKET_LOG_ENTRY_TIME_TRACKER.order = order
          acc.TICKET_LOG_ENTRY_TIME_TRACKER.params = {
            unit: rule.operator,
            value: parseInt(rule.value, 10),
          }
        } else if (rule.field === "ADD_TICKET_COMMENT") {
          if (rule?.uid) {
            acc.ADD_TICKET_COMMENT.uid = rule.uid
          }

          // There cannot be more than one item of this type
          acc.ADD_TICKET_COMMENT.order = order

          acc.ADD_TICKET_COMMENT.params = {
            body: rule.value.body,
            public: rule.value.public,
          }
        } else if (includes(rule.field, ["add_cc_list", "set_cc_list"])) {
          const oldChanges = acc.TICKET_CHANGE_VALUE.params.changes

          const oldField = find(propEq("field", rule?.field), oldChanges) || {
            order,
            field: rule?.field,
            properties: [],
          }

          const newValue = {
            ...oldField,
            properties: [
              {
                property: "emails",
                value: getEmails(rule.value),
              },
              {
                property: "uids",
                value: getUids(rule.value),
              },
            ],
          }

          if (rule?.uid) {
            acc.TICKET_CHANGE_VALUE.uid = rule.uid
          }

          acc.TICKET_CHANGE_VALUE.params = {
            changes: [...reject(propEq("field", rule?.field), oldChanges), newValue],
          }
        } else if (includes(rule.field, ["add_tags", "remove_tags", "set_tags"])) {
          if (rule?.uid) {
            acc.TICKET_CHANGE_VALUE.uid = rule.uid
          }

          acc.TICKET_CHANGE_VALUE.params = {
            changes: [
              ...oldChanges,
              {
                order,
                field: rule?.field,
                value: joinByComma(rule.value),
              },
            ],
          }
        }
      }
      return acc
    },
    {
      SEND_EMAIL: [],
      SEND_NOTIFICATION: {
        type: "SEND_NOTIFICATION",
        params: {
          channels: [],
        },
      },
      TICKET_CHANGE_VALUE: {
        type: "TICKET_CHANGE_VALUE",
        params: {
          changes: [],
        },
      },
      TICKET_LOG_ENTRY_TIME_TRACKER: {
        type: "TICKET_LOG_ENTRY_TIME_TRACKER",
        params: {},
      },
      ADD_TICKET_COMMENT: {
        type: "ADD_TICKET_COMMENT",
        params: {},
      },
    },
  )(rulesWithOrder)

  return [
    ...(!isEmpty(actions.SEND_EMAIL) ? actions.SEND_EMAIL : []),
    ...(!isEmpty(actions.TICKET_CHANGE_VALUE.params.changes) ? [actions.TICKET_CHANGE_VALUE] : []),
    ...(!isEmpty(actions.TICKET_LOG_ENTRY_TIME_TRACKER.params) ? [actions.TICKET_LOG_ENTRY_TIME_TRACKER] : []),
    ...(!isEmpty(actions.ADD_TICKET_COMMENT.params) ? [actions.ADD_TICKET_COMMENT] : []),
    ...(!isEmpty(actions.SEND_NOTIFICATION.params.channels) ? [actions.SEND_NOTIFICATION] : []),
  ]
}

const joinValue = ({ value, key = "id" }) => {
  if (Array.isArray(value)) {
    return map(prop(key), value).join(",")
  }
  return value[key]
}

const prepareConditionValueForServer = ({ value, operator, field }) => {
  if (value && ["assignee", "requester", "location", "organization"].includes(field)) {
    return joinValue({ value, ...("requester" === field && { key: "uid" }) })
  }

  return value
}

const prepareConditionsGroupForSever = ({ conditions, removeDeleted = false }) => {
  return reduce(
    (acc, condition) => {
      if (!removeDeleted || condition.field !== "deleted") {
        acc.push({
          ...omitRuleFieldsForServer(condition),
          value: prepareConditionValueForServer(condition),
        })
      }
      return acc
    },
    [],
    conditions,
  )
}

export const prepareConditionsForServer = ({ conditions, copy, system }) => {
  const { all: allConditions, any: anyConditions } = conditions || {}

  const removeDeletedFromAll = copy && system && allConditions?.length > 1
  return {
    all: allConditions
      ? prepareConditionsGroupForSever({ conditions: allConditions, removeDeleted: removeDeletedFromAll })
      : null,
    any: allConditions ? prepareConditionsGroupForSever({ conditions: anyConditions }) : null,
  }
}

export const prepareActionsForServer = actions => (actions ? sanitizeRules(setServerActionRules(actions)) : null)
