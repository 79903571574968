const getInitialState = () => ({
  path: [],
})

const setPath = (state, { path }) => ({
  ...state,
  path,
})

export default function checklists(state = getInitialState(), action) {
  switch (action.type) {
    case "SET_CHECKLISTS_PATH":
      return setPath(state, action)
    default:
      return state
  }
}
