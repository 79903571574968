import { memo } from "react"
import PropTypes from "prop-types"
import { RadioGroup } from "@ninjaone/components"
import { contentFieldPropTypes } from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import { localized } from "js/includes/common/utils"

const appendDisabledPropToOptions = (options, disabled) => options.map(option => ({ ...option, disabled }))

const RadioButtonsField = memo(({ onChange, values, options, name, disabled = false, nameToken: labelToken }) => {
  const onOptionChanged = value => onChange(name, value)
  return (
    <RadioGroup
      ariaLabel={localized(labelToken)}
      options={appendDisabledPropToOptions(options, disabled)}
      onValueChange={onOptionChanged}
      value={values[name]}
    />
  )
})

RadioButtonsField.propTypes = {
  ...contentFieldPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
      description: PropTypes.string,
    }),
  ),
}

export default RadioButtonsField
