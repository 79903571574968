/** @jsxImportSource @emotion/react */
import { Component } from "react"
import { connect } from "react-redux"
import { prop } from "ramda"
import { Form, FormGroup, Col, ControlLabel } from "react-bootstrap"
import Select from "react-select"
import { css } from "@emotion/react"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import Modal from "js/includes/components/Modal"
import {
  addMapping as _addMapping,
  updateMapping as _updateMapping,
  closeModal as _closeModal,
  setNodeRole as _setNodeRole,
  setConfigurationType as _setConfigurationType,
  setOnCreate as _setOnCreate,
  setOnDelete as _setOnDelete,
} from "js/state/actions/psa/ConnectWise/mappings/configurations/nodeRoleConfigurationTypeMappings"
import { sortListByName } from "js/includes/configuration/psa/PsaSettingsEditor"
import { getNodeRoleValue, getNodeRoleOptions } from "js/includes/configuration/psa/ConnectWise/settings/common"

const labelStyle = css`
  text-align: left;
`

class NodeRoleConfigurationTypeMappingModal extends Component {
  state = {
    nodeRoleRequiredError: "",
    configurationTypeRequiredError: "",
    onCreateRequiredError: "",
    onDeleteRequiredError: "",
  }

  validate = () => {
    const { currentMapping } = this.props
    const nodeRoleRequiredError = !currentMapping.nodeRoleId && localized("Required")
    const configurationTypeRequiredError = !currentMapping.configurationTypeId && localized("Required")
    if (nodeRoleRequiredError || configurationTypeRequiredError) {
      this.setState({
        nodeRoleRequiredError,
        configurationTypeRequiredError,
      })
      return false
    }
    return true
  }

  onSubmit = async e => {
    e.preventDefault()
    e.stopPropagation()

    const { mode, addMapping, updateMapping, closeModal, currentMapping } = this.props
    if (this.validate()) {
      if (mode === "ADD") {
        await addMapping(currentMapping)
      } else {
        await updateMapping(currentMapping)
      }
      closeModal()
    }
  }

  onChangeNodeRole = e => {
    this.setState({ nodeRoleRequiredError: "" })
    this.props.setNodeRole(e)
  }

  onChangeConfigurationType = e => {
    this.setState({ configurationTypeRequiredError: "" })
    this.props.setConfigurationType(e)
  }

  onChangeOnCreateStatus = e => {
    this.props.setOnCreate(e)
  }

  onChangeOnDeleteStatus = e => {
    this.props.setOnDelete(e)
  }

  render() {
    const {
      closeModal,
      title,
      currentMapping,
      nodeRoleList,
      configurationTypeList,
      configurationStatusList,
    } = this.props
    const {
      nodeRoleRequiredError,
      configurationTypeRequiredError,
      onCreateRequiredError,
      onDeleteRequiredError,
    } = this.state

    return (
      <Form onSubmit={this.onSubmit}>
        <Modal
          submittable
          title={title}
          close={closeModal}
          closeText={localizationKey("Close")}
          saveText={localizationKey("OK")}
          overflow={true}
          showCloseButton={false}
          dialogClassName="wide-modal psa-modal"
        >
          <div className="display-flex flex-column">
            <FormGroup validationState={nodeRoleRequiredError ? "error" : null}>
              <Col componentClass={ControlLabel} xs={3} css={labelStyle}>
                <span>{localized("Device Role")}</span>
                <sup> *</sup>
              </Col>
              <Col xs={9}>
                <Select
                  name="nodeRole"
                  className={nodeRoleRequiredError ? "invalid-single-select" : ""}
                  value={getNodeRoleValue(nodeRoleList, currentMapping.nodeRoleId)}
                  options={getNodeRoleOptions(nodeRoleList)}
                  onChange={this.onChangeNodeRole}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                />
                {nodeRoleRequiredError && <em className="invalid">{nodeRoleRequiredError}</em>}
              </Col>
            </FormGroup>
            <FormGroup validationState={configurationTypeRequiredError ? "error" : null}>
              <Col componentClass={ControlLabel} xs={3} css={labelStyle}>
                <span>{localized("Configuration Type")}</span>
                <sup> *</sup>
              </Col>
              <Col xs={9}>
                <Select
                  name="configurationType"
                  className={configurationTypeRequiredError ? "invalid-single-select" : ""}
                  value={configurationTypeList.find(option => option.id === currentMapping.configurationTypeId)}
                  options={sortListByName(configurationTypeList)}
                  onChange={this.onChangeConfigurationType}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                />
                {configurationTypeRequiredError && <em className="invalid">{configurationTypeRequiredError}</em>}
              </Col>
            </FormGroup>
            <FormGroup validationState={onCreateRequiredError ? "error" : null}>
              <Col componentClass={ControlLabel} xs={3} css={labelStyle}>
                <span>{localized("On Create")}</span>
              </Col>
              <Col xs={9}>
                <Select
                  name="onCreate"
                  value={configurationStatusList.find(option => option.id === currentMapping.onCreateStatusId)}
                  options={sortListByName(configurationStatusList)}
                  onChange={this.onChangeOnCreateStatus}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                />
              </Col>
            </FormGroup>
            <FormGroup validationState={onDeleteRequiredError ? "error" : null}>
              <Col componentClass={ControlLabel} xs={3} css={labelStyle}>
                <span>{localized("On Delete")}</span>
              </Col>
              <Col xs={9}>
                <Select
                  name="onDelete"
                  value={configurationStatusList.find(option => option.id === currentMapping.onDeleteStatusId)}
                  options={sortListByName(configurationStatusList)}
                  onChange={this.onChangeOnDeleteStatus}
                  placeholder={localized("Select...")}
                  getOptionValue={prop("id")}
                  getOptionLabel={prop("name")}
                />
              </Col>
            </FormGroup>
          </div>
        </Modal>
      </Form>
    )
  }
}

export default connect(
  ({ psa }) => ({
    title: psa.ConnectWise.mappings.configurations.nodeRoleConfigurationTypeMappingModalEditorTitle,
    mode: psa.ConnectWise.mappings.configurations.nodeRoleConfigurationTypeMappingModalEditorMode,
    currentMapping: psa.ConnectWise.mappings.configurations.currentNodeRoleConfigurationTypeMapping,
    nodeRoleList: psa.ConnectWise.mappings.nodeRoleList,
    configurationTypeList: psa.ConnectWise.mappings.configurations.configurationTypeList,
    configurationStatusList: psa.ConnectWise.mappings.configurations.configurationStatusList,
  }),
  {
    addMapping: _addMapping,
    updateMapping: _updateMapping,
    closeModal: _closeModal,
    setNodeRole: _setNodeRole,
    setConfigurationType: _setConfigurationType,
    setOnCreate: _setOnCreate,
    setOnDelete: _setOnDelete,
  },
)(NodeRoleConfigurationTypeMappingModal)
