import { localized, localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"
import { getSortedBackupFiles } from "js/includes/common/backup"
import { getVolumesColumns } from "./columns"
import { getVolumesActions, getVolumesSecondaryGlobalActions } from "./actions"

const fetchData = async ({
  currentDir: { name: planName, planId, planDeleted, type: planType, destination },
  separator,
  node,
}) => {
  //TODO: Add pagination result on response
  const sortedImageFiles = await getSortedBackupFiles({ sourceNode: node, planId })
  const content = sortedImageFiles.map(
    ({ fileId, lastRevision, volumeName, volume, volumeArrowImageStats, filePlanId: planNumericId }) => {
      const { id: volumeId, name, size, label, location, fileSystem, partitionStyle } = volume ?? {}
      const { localBytes, cloudBytes, usedBytes, capacityBytes, cloudDeduplicationBytes } = volumeArrowImageStats ?? {}
      const filteredVolumeName = name?.replace(/\\/, "") || "*"
      const defaultLabel = name ? localized("Local Disk") : ""
      const volumeLabel = label || defaultLabel

      const id = volumeId ?? volumeName

      return {
        fileId,
        lastRevision,
        id,
        volumeName,
        volumeLetter: filteredVolumeName === "*" ? "(*)" : filteredVolumeName,
        node,
        planId,
        planNumericId,
        planName,
        planType,
        destination,
        size,
        label,
        location,
        fileSystem,
        partitionStyle,
        type: "volume",
        volume: true,
        folder: true,
        planDeleted,
        name: `(${filteredVolumeName}) ${volumeLabel}`,
        path: planName + separator + id,
        used: usedBytes,
        capacity: capacityBytes,
        localSize: localBytes,
        cloudSize: cloudBytes,
        deduplication: cloudDeduplicationBytes,
        filteredVolumeName,
      }
    },
  )

  return {
    data: content,
  }
}

const onError = (error, { name }) =>
  reportErrorAndShowMessage(
    error,
    localizationKey("There was an error fetching volumes for backup plan {{backupPlanName}}"),
    { backupPlanName: name },
  )

export const getVolumesHandler = ({ currentDir: { type }, node }) => ({
  columns: getVolumesColumns(type),
  actions: getVolumesActions(),
  secondaryGlobalActions: getVolumesSecondaryGlobalActions(node),
  fetchData,
  onError,
})
