import fastDeepEqual from "fast-deep-equal"
import { useEffect } from "react"
import { useQuery } from "urql"

import { useMountedState } from "@ninjaone/utils"

import { useSorted } from "js/includes/common/hooks"
import { arrayToMapWithKey, getAsArray, localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"
import { getTags } from "js/includes/ticketing/graphql"

export const useTags = ({ shouldQuery }) => {
  const [{ error, data: tagsData, fetching: fetchingTags }] = useQuery({
    pause: !shouldQuery,
    query: getTags,
  })

  useEffect(() => {
    if (error) {
      reportErrorAndShowMessage(error, localizationKey("Error fetching tags"))
    }
  }, [error])

  const tags = useSorted(tagsData?.tags || [])
  return {
    tags,
    fetchingTags,
  }
}

const getEmptyValue = ({ isMulti }) => (isMulti ? [] : null)

const getValue = ({ value, isMulti }) => {
  let newValue = value
  if (!value || (isMulti && !Array.isArray(newValue)) || (!isMulti && Array.isArray(newValue))) {
    newValue = getEmptyValue({ isMulti })
  }
  return newValue
}

const getSelectionData = ({ value: _value, isMulti, valueKey }) => {
  const value = getValue({ value: _value, isMulti })
  return {
    value,
    selectionMap: arrayToMapWithKey(valueKey, getAsArray(value)),
    isMulti,
    valueKey,
  }
}

export const useSearchableDropdownValue = ({ value, isMulti, valueKey = "id" }) => {
  const [selectionData, setSelectionData] = useMountedState(() => getSelectionData({ isMulti, valueKey, value }))
  if (
    selectionData.isMulti !== isMulti ||
    selectionData.valueKey !== valueKey ||
    !fastDeepEqual(getValue({ value, isMulti }), selectionData.value)
  ) {
    /*
     * This is in charge of setting isMulti and value that are props of AppUsersAndContactsDropdown.
     * It's important because isMulti and value should change at the same time. Passing, for example,
     * isMulti: true and value: {} breaks that component.
     */
    setSelectionData(getSelectionData({ isMulti, value, valueKey }))
  }

  return {
    isMulti: selectionData.isMulti,
    value: selectionData.value,
    selectionMap: selectionData.selectionMap,
  }
}
