import PropTypes from "prop-types"

import { Columns } from "js/includes/ticketing/TriggerEditor/Columns"
import { LoadingWrapper, ticketAttributesType } from "js/includes/ticketing/TriggerEditor/sections"

export const ColumnsAndSorting = ({ isLoading, columns, sortBy, columnsErrorMessage, onChange, ticketAttributes }) => {
  return (
    <LoadingWrapper {...{ isLoading }}>
      <Columns {...{ columns, columnsErrorMessage, onChange, sortBy, ticketAttributes }} />
    </LoadingWrapper>
  )
}

ColumnsAndSorting.propTypes = {
  isLoading: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      direction: PropTypes.oneOf(["DESC", "ASC"]).isRequired,
    }),
  ).isRequired,
  columnsErrorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  ticketAttributes: ticketAttributesType,
}
