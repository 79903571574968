import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { colors } from "js/includes/common/theme"

const errorStylesHandler = ({ errorMessage, theme, onClick }) => {
  const borderColor = errorMessage ? theme.colorBorderError : theme.colorBorderWeak
  const hoverBorderColor = errorMessage ? theme.colorBorderError : theme.colorBorderStrong

  return `
    border: 1px solid ${borderColor};
    &:hover {
      border-color: ${hoverBorderColor};
    }
  `
}

export const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  border-radius: 4px;
  cursor: pointer;
  flex-direction: column;
  background-color: ${colors.white};
  padding: ${tokens.spacing[2]} ${tokens.spacing[3]};
  min-height: 38px;
  max-height: 120px;
  overflow-y: scroll;
  transition: 100ms;
  position: relative;
  ${errorStylesHandler}
`

export const StyledBody = styled.span`
  margin: 4px 0 8px 0;
`

export const StyledEditorWrapper = styled.div`
  background-color: ${({ isPublicResponse, theme }) =>
    isPublicResponse === false ? theme.colorAlertWarningWeakest : theme.colorBackground};
  border-radius: 4px;
  padding: ${tokens.spacing[2]} ${tokens.spacing[3]};
  min-height: 38px;
  max-height: 120px;
  overflow-y: scroll;
  position: relative;
  cursor: pointer;
  ${errorStylesHandler}
`
