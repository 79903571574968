import fastDeepEquals from "fast-deep-equal"
import { __, compose, evolve, filter, includes, keys, lensProp, map, omit, pluck, reduce, set, where } from "ramda"
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { connect } from "react-redux"
import { useQuery } from "urql"

import { useTheme } from "@emotion/react"
import { ConfirmationModal } from "@ninjaone/components"
import { noop } from "@ninjaone/utils"

import { getSignedUrlFromAttachmentId, getTicketingForms } from "js/includes/common/client"
import { useForm, useMountedState } from "js/includes/common/hooks"
import showModal from "js/includes/common/services/showModal"
import {
  applyMultipleValidations,
  arrayToMapWithKey,
  fetchJson,
  isNilOrEmpty,
  localizationKey,
  localized,
  ninjaReportError,
  reportErrorAndShowMessage,
  showErrorMessage,
  showSuccessMessage,
  sortByFieldNameCaseInsensitive,
  validations,
} from "js/includes/common/utils"
import {
  parseServerActionRules,
  parseServerConditionRules,
  prepareActionsForServer,
  prepareConditionsForServer,
} from "js/includes/components/RuleEditor"
import { automationTypes } from "js/includes/ticketing/common/automations"
import { prepareNewEditorData } from "js/includes/ticketing/editor/shared/utils"
import { fetchTriggerBoards as _fetchTriggerBoards } from "js/state/actions/general/boardTabs"

import { CREATE_ACTION, EDIT_ACTION } from "./constants"
import { useTags } from "./hooks"
import { TriggerModal } from "./TriggerModal"
import {
  buildGetValidations,
  getActiveAttributes,
  getColumnOptions,
  getFields,
  setMissingValidations,
  triggerContainsHoursSinceCondition,
  validateActions,
  validateConditions,
} from "./utils"

const query = `
  query GetAttributeList {
    ticketAttributes { 
      id 
      attributeType
      name 
      active
      content { 
        values { 
          id 
          name 
        } 
      } 
    }
  }
`

const getDefaultColumns = compose(
  pluck(["value"]),
  filter(
    where({
      value: includes(__, [
        "status",
        "id",
        "summary",
        "organization",
        "requester",
        "assignee",
        "type",
        "source",
        "createTime",
        "totalTimeTracked",
      ]),
    }),
  ),
)

const defaultValues = {
  enabled: true,
  name: "",
  description: "",
  conditions: {
    all: [],
    any: [],
  },
  actions: [],
}

const setTouched = map(set(lensProp("touched"), true))

const updateConditionsTouched = evolve({
  all: setTouched,
  any: setTouched,
})

const automationErrorCodeTokens = {
  automation_ticket_assignee_do_not_exists: localizationKey("Ticket actions cannot contain deleted users"),
  ticket_changes_action_user_or_contact_not_found: localizationKey("Ticket actions cannot contain deleted users"),
  email_action_user_or_contact_not_found: localizationKey("Email action cannot contain deleted users"),
  email_action_body_max_length: localizationKey("Email action exceeds the maximum character length"),
}

const BOARD_TYPE = "BOARD"

const TriggerEditorModal = memo(
  ({
    id,
    type = automationTypes.EVENT_BASED,
    copy = false,
    system,
    fetchTriggerBoards,
    onUpdateSuccess,
    unmount,
    statusList,
  }) => {
    const theme = useTheme()
    const shouldFetchForms =
      !system && [automationTypes.EVENT_BASED, automationTypes.TIME_BASED, BOARD_TYPE].includes(type)
    const shouldFetchNotificationChannels = [automationTypes.EVENT_BASED, automationTypes.TIME_BASED].includes(type)
    const [formOptions, setFormOptions] = useMountedState([])
    const [fetchingFormOptions, setFetchingFormOptions] = useMountedState(shouldFetchForms)

    const isBoard = type === BOARD_TYPE
    const isResponseTemplate = type === automationTypes.RESPONSE_TEMPLATE
    const isTimeBased = type === automationTypes.TIME_BASED

    const initialValuesRef = useRef(null)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [organizations, setOrganizations] = useMountedState([])
    const [isPersisting, setIsPersisting] = useMountedState(false)
    const [fetchingTrigger, setFetchingTrigger] = useMountedState(!!id)
    const [notificationChannels, setNotificationChannels] = useMountedState([])
    const [fetchingNotificationChannels, setFetchingNotificationChannels] = useMountedState(
      shouldFetchNotificationChannels,
    )

    const defaultBoardValues = useMemo(
      () => ({
        ...defaultValues,
        columns: getDefaultColumns(getColumnOptions()),
        sortBy: [
          {
            field: "id",
            direction: "DESC",
          },
        ],
      }),
      [],
    )

    const [{ error, data, fetching: fetchingAttributes }] = useQuery({
      query,
      requestPolicy: "network-only",
    })

    useEffect(() => {
      if (error) {
        reportErrorAndShowMessage(error, localizationKey("Error fetching ticket attributes"))
      }
    }, [error])

    const { attributes, getValidations } = useMemo(() => {
      const attributes = getActiveAttributes({ attributes: data?.ticketAttributes })
      return {
        attributes,
        getValidations: buildGetValidations(attributes),
      }
    }, [data?.ticketAttributes])

    const fields = useMemo(() => {
      const systemGroupName = localized("System")
      const systemAllFields = getFields({ type, combinator: "all", statusList, groupName: systemGroupName })
      const systemAnyFields = getFields({ type, combinator: "any", statusList, groupName: systemGroupName })

      const allOptions = [...systemAllFields, ...attributes]
      const anyOptions = [...systemAnyFields, ...attributes]
      return {
        all: {
          options: allOptions,
          optionsMap: arrayToMapWithKey("value", allOptions),
        },
        any: {
          options: anyOptions,
          optionsMap: arrayToMapWithKey("value", anyOptions),
        },
      }
    }, [attributes, statusList, type])

    const { values, onChange, validateForm, validation } = useForm({
      fields: isBoard ? defaultBoardValues : defaultValues,
      validate: {
        name: applyMultipleValidations([validations.required, validations.maxLength(50)]),
        description: validations.maxLength(250),
        ...(!isResponseTemplate && {
          conditions: value => {
            if (!(value.any?.length + value.all?.length)) {
              return { success: false, message: localized("Add at least 1 condition") }
            }
            if (isTimeBased && !triggerContainsHoursSinceCondition(value)) {
              return {
                success: false,
                message: localized("Time-based automations require at least one 'time since' condition to be set"),
              }
            }
            return { success: true, message: "" }
          },
        }),
        ...(isBoard && {
          columns: value => {
            const success = !isNilOrEmpty(value)
            return { success, message: success ? "" : localized("Please add at least one column") }
          },
        }),
        ...(!isBoard && {
          actions: value => {
            if (!value.length) {
              return { success: false, message: localized("Add at least 1 action") }
            }
            return { success: true, message: "" }
          },
        }),
      },
    })

    const { enabled, name, description, conditions, actions, columns, sortBy } = values

    useEffect(() => {
      async function prepareResources() {
        try {
          const response = await fetchJson("/client/list")
          setOrganizations(response)
        } catch (error) {
          ninjaReportError(error)
        }
      }
      prepareResources()
    }, [setOrganizations])

    const fetchForms = useCallback(async () => {
      setFetchingFormOptions(true)
      try {
        const forms = await getTicketingForms()
        const activeFormOptions = reduce(
          (acc, form) => {
            const { id, name, active } = form
            if (active) {
              acc.push({ value: id, labelText: name, label: name })
            }
            return acc
          },
          [],
          forms,
        )
        setFormOptions(sortByFieldNameCaseInsensitive("label", "ASC", activeFormOptions))
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error fetching forms"))
      } finally {
        setFetchingFormOptions(false)
      }
    }, [setFormOptions, setFetchingFormOptions])

    useEffect(() => {
      if (shouldFetchForms) {
        fetchForms()
      }
    }, [fetchForms, shouldFetchForms])

    useEffect(() => {
      async function fetchTrigger() {
        try {
          setFetchingTrigger(true)
          const response = await fetchJson(`/ticketing/trigger/${id}`)
          const { conditions: _conditions, actions: _actions, attachments: _attachments } = response

          const attachments = await Promise.all(
            _attachments
              .filter(attachment => !["PROCESSING", "FAILURE", "SUSPICIOUS"].includes(attachment.uploadStatus))
              .map(async upload => {
                let signedUrl
                try {
                  signedUrl = (await getSignedUrlFromAttachmentId(upload.id)).signedUrl
                } catch (error) {
                  ninjaReportError(error)
                }
                return { ...upload, metadata: { ...upload.metadata, __src: signedUrl } }
              }),
          )

          const serverValues = {
            ...response,
            ...(copy && {
              name: `${response.name ?? ""} (${localized("Copy")})`,
            }),
            conditions: {
              all: _conditions.all ? parseServerConditionRules(_conditions.all) : null,
              any: _conditions.any ? parseServerConditionRules(_conditions.any) : null,
            },
            actions: _actions ? parseServerActionRules(_actions, attachments) : null,
          }

          onChange(serverValues)
          initialValuesRef.current = serverValues
        } catch (error) {
          showErrorMessage(localized("Failed"))
          ninjaReportError(error)
        } finally {
          setFetchingTrigger(false)
        }
      }

      id && !initialValuesRef.current && fetchTrigger()
    }, [id, onChange, copy, setFetchingTrigger])

    const fetchNotificationChannels = useCallback(async () => {
      try {
        setFetchingNotificationChannels(true)
        const response = await fetchJson("/channel/list/brief")
        setNotificationChannels(sortByFieldNameCaseInsensitive("name", "ASC", response))
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error fetching notification channels"))
      } finally {
        setFetchingNotificationChannels(false)
      }
    }, [setFetchingNotificationChannels, setNotificationChannels])

    useEffect(() => {
      if (shouldFetchNotificationChannels) {
        fetchNotificationChannels()
      }
    }, [shouldFetchNotificationChannels, fetchNotificationChannels])

    function addMissingValidationsToConditions(conditions) {
      return keys(conditions).reduce((acc, key) => {
        acc[key] = setMissingValidations({ list: conditions[key], getValidations })
        return acc
      }, {})
    }

    function addMissingValidationsToActions(actions) {
      return setMissingValidations({ list: actions, getValidations })
    }

    /**
     * Validates conditions and actions.
     *
     * Before validating, it sets missing validations in actions and conditions that do not have the validation field.
     * Elements will have missing validations when an automation is updated without touching elements.
     *
     * @returns true if actions and conditions are valid
     */
    function validateActionsAndConditions() {
      const updatedActions = addMissingValidationsToActions(setTouched(actions))
      const actionsAreValid = validateActions(updatedActions)
      onChange("actions", updatedActions)

      let conditionsAreValid = true
      if (!isResponseTemplate) {
        const updatedConditions = addMissingValidationsToConditions(updateConditionsTouched(conditions))
        conditionsAreValid = validateConditions(updatedConditions)
        onChange("conditions", updatedConditions)
      }

      return actionsAreValid && conditionsAreValid
    }

    const returnPlaceholderErrorMessage = error => {
      switch (error.resultCode) {
        case "email_action_comment_placeholder_invalid":
          return localizationKey("Invalid placeholder found at email body")
        case "email_action_num_placeholder_comments_invalid":
          return localizationKey("No more than one placeholder comment is allowed")
        case "action_comment_placeholder_not_allowed":
          return localizationKey("Placeholder used in wrong field")
        default:
          const defaultErrorMessage = isBoard
            ? localizationKey("Error saving ticket board")
            : localizationKey("Error saving automation")

          return automationErrorCodeTokens[error?.resultCode] || defaultErrorMessage
      }
    }

    async function handleSave() {
      if (isPersisting) return

      !isFormSubmitted && setIsFormSubmitted(true)

      if (!validateForm()) return

      try {
        if (!system || copy) {
          const actionsAndConditionsAreValid = validateActionsAndConditions()

          if (!actionsAndConditionsAreValid) return
        }

        setIsPersisting(true)

        const creating = !id || copy

        const preparedUploads = []
        const preparedActions = actions.map(action => {
          if (action.field !== "SEND_EMAIL") {
            return action
          }
          const { htmlBody: body, uploads } = prepareNewEditorData(
            {
              ...action.value,
              htmlBody: action.value.body,
            },
            theme,
          )
          preparedUploads.push(...(uploads ?? []))
          return { ...action, value: { ...action.value, body } }
        })

        await fetchJson(`/ticketing/trigger/${creating ? "" : id}`, {
          options: {
            method: creating ? "POST" : "PUT",
            body: JSON.stringify({
              enabled,
              name,
              description,
              type,
              conditions: prepareConditionsForServer({ conditions, copy, system }),
              actions: prepareActionsForServer(preparedActions),
              uploads: preparedUploads.map(upload => ({
                ...omit(["preview", "uploadStatus"], upload),
                metadata: omit(["__src"], upload.metadata),
              })),
              ...(isBoard && { columns, sortBy }),
            }),
          },
        })

        if (isBoard) {
          //NOTE: Comment for now until we implement new endpoint that will bring ticket count
          //id ? updateTriggerBoardTab(response) : addTriggerBoardTab(response)
          fetchTriggerBoards()
        }

        showSuccessMessage(localized("Success"))
        onUpdateSuccess?.()
        unmount()
      } catch (error) {
        reportErrorAndShowMessage(error, returnPlaceholderErrorMessage(error))
      } finally {
        setIsPersisting(false)
      }
    }

    async function handleClose({ unmount }) {
      const initialValues = initialValuesRef.current ?? (isBoard ? defaultBoardValues : defaultValues)

      if (!fastDeepEquals(initialValues, values)) {
        return showModal(
          <ConfirmationModal
            titleToken={localizationKey("You have unsaved changes")}
            descriptionToken={localizationKey("Are you sure you wish to proceed?")}
            actionToken={localizationKey("Continue")}
            type="danger"
            closeToken={localizationKey("Cancel")}
            onConfirm={({ unmount: _unmount }) => {
              _unmount()
              unmount()
            }}
            unmount={noop}
          />,
        )
      }

      unmount()
    }

    function onQueryChange(condition, combinator) {
      onChange("conditions", { ...conditions, [combinator]: condition })
    }

    const { tags, fetchingTags } = useTags({
      shouldQuery: !system || !!copy,
    })

    const isLoading =
      fetchingFormOptions || fetchingTrigger || fetchingAttributes || fetchingTags || fetchingNotificationChannels

    return (
      <TriggerModal
        {...{
          action: id ? EDIT_ACTION : CREATE_ACTION,
          isLoading,
          unmount,
          handleClose,
          values,
          onChange,
          validation,
          isPersisting,
          handleSave,
          system,
          copy,
          ticketAttributes: data?.ticketAttributes,
          onQueryChange,
          attributes,
          fields,
          organizations,
          type,
          getValidations,
          formOptions,
          isFormSubmitted,
          tags,
          notificationChannels,
        }}
      />
    )
  },
)

export default connect(({ ticketing }) => ({ statusList: ticketing.status.list }), {
  fetchTriggerBoards: _fetchTriggerBoards,
})(TriggerEditorModal)
