import { memo } from "react"
import tokens from "@ninjaone/tokens"
import { localized } from "js/includes/common/utils"
import NumericInput from "js/includes/configuration/integrations/psa/common/components/NumericInput"
import { contentFieldPropTypes } from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import { moneyPrecision } from "js/includes/configuration/integrations/psa/psaProducts/productForm/formCommons"
import { Box } from "js/includes/components/Styled"

const MoneyField = memo(
  ({
    onChange,
    values,
    validation,
    disabled = false,
    name,
    nameToken: labelToken,
    placeholderToken,
    mandatory,
    currency = "USD",
  }) => {
    const inputId = `psa-product-${name}`
    const handleChange = value => {
      onChange(name, value)
    }
    const errorMessage = validation.message[name]

    return (
      <Box marginBottom={tokens.spacing[3]}>
        <NumericInput
          {...{
            id: inputId,
            labelToken,
            disabled,
            currency,
            errorMessage,
            mandatory,
            onChange: handleChange,
            value: values[name],
            decimalPlaces: moneyPrecision,
            placeholder: localized(placeholderToken),
          }}
        />
      </Box>
    )
  },
)

MoneyField.propType = contentFieldPropTypes

export default MoneyField
