export const getSearchableDropdownTagRenderer = ({ getOptionLabel, optionIdKey = "id" }) => {
  return ({ option, renderTagOptionName, renderTagDeleteAction, tagLabelValue, TagWrapper }) => {
    const label = getOptionLabel({ option })
    return (
      <TagWrapper key={option[optionIdKey]} data-testid="input-tag">
        {renderTagOptionName(<span title={label}>{label}</span>)}
        {renderTagDeleteAction()}
      </TagWrapper>
    )
  }
}
