import { isNil } from "ramda"
import { memo, useMemo } from "react"

import { defaultToEmpty, localizationKey, localized } from "js/includes/common/utils"
import {
  commonPropTypes,
  isFieldDisabled,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import {
  getQuantityValue,
  getShouldDisableQuantity,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/utils"
import {
  productQuantityTypeMapper,
  productQuantityTypes,
} from "js/includes/customerDashboard/finance/sections/Agreements/productEditorModals/formCommons"
import { maxQuantityValue } from "js/includes/configuration/integrations/psa/psaProducts/productForm/formCommons"

import NumericField from "./NumericField"
import TextInput from "./TextInput"

const getQuantityData = ({ productInfo, quantity, showDynamicQuantityValue, editDynamicQuantity }) => {
  const { type } = productInfo
  const productQuantityType = productQuantityTypeMapper[type]
  if (productQuantityType === productQuantityTypes.DYNAMIC) {
    return {
      value:
        (showDynamicQuantityValue && !isNil(quantity)) || editDynamicQuantity
          ? quantity ?? ""
          : localized("Dynamically calculated"),
      shouldDisable: !editDynamicQuantity && getShouldDisableQuantity(productQuantityType),
    }
  }

  return {
    value: defaultToEmpty(getQuantityValue(productQuantityType, quantity)),
    shouldDisable: getShouldDisableQuantity(productQuantityType),
  }
}

const inputId = "ninja-psa-quantity"

const Quantity = memo(
  ({
    onChange,
    values,
    validation,
    enabledFields,
    fieldLabelTokens,
    productInfo,
    useDynamicQuantity,
    showDynamicQuantityValue,
    editDynamicQuantity,
  }) => {
    const { _values, disableQuantity } = useMemo(() => {
      if (!useDynamicQuantity || !productInfo) {
        return {
          _values: { quantity: values.quantity },
          disableQuantity: false,
        }
      }

      const { value: quantityValue, shouldDisable } = getQuantityData({
        productInfo,
        quantity: values.quantity,
        showDynamicQuantityValue,
        editDynamicQuantity,
      })
      return {
        _values: { quantity: quantityValue },
        disableQuantity: shouldDisable,
      }
    }, [productInfo, values.quantity, useDynamicQuantity, showDynamicQuantityValue, editDynamicQuantity])

    return disableQuantity ? (
      <TextInput
        {...{
          id: inputId,
          nameToken: fieldLabelTokens?.quantity || localizationKey("Quantity"),
          name: "quantity",
          onChange,
          values: _values,
          validation,
          mandatory: true,
          disabled: true,
          maxLength: 250,
        }}
      />
    ) : (
      // TODO use NumericInput
      <NumericField
        {...{
          id: inputId,
          onChange,
          values: _values,
          validation,
          disabled: isFieldDisabled({ name: "quantity", enabledFields }),
          name: "quantity",
          nameToken: fieldLabelTokens?.quantity || localizationKey("Quantity"),
          step: 1,
          min: 0, //Will be validated by the form. This is to prevent the stepper from being red because the initial value is 0
          max: maxQuantityValue,
          mandatory: true,
        }}
      />
    )
  },
)

Quantity.propTypes = commonPropTypes

export default Quantity
