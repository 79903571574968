import React, { PureComponent } from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import AccountAutoMapTable from "./AccountAutoMapTable"
import Modal from "js/includes/components/Modal"
import Loading from "js/includes/components/Loading"
import {
  saveAutoMapping as _saveAutoMapping,
  cancelAutoMapping as _cancelAutoMapping,
} from "js/state/actions/psa/ConnectWise/mappings/accounts"
import { colors } from "js/includes/common/theme"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const StyledParagraph = styled.p`
  text-align: left;
  color: ${colors.ninjaMedium};
`

const StyledDiv = styled.div`
  height: 282px;
  display: flex;
  background-color: ${colors.ninjaWhite};
`

class AccountAutoMapModal extends PureComponent {
  render() {
    const { accountAutoMapPreview, accountMapModalLoading, cancelAutoMapping, saveAutoMapping } = this.props

    return (
      <Modal
        title={localizationKey("Auto mapping results")}
        saveText={localizationKey("Auto Map")}
        closeText={localizationKey("Cancel")}
        save={saveAutoMapping}
        close={cancelAutoMapping}
        dialogClassName="psa-modal"
      >
        {accountMapModalLoading ? (
          <Loading />
        ) : (
          <div className="p-l p-r">
            <StyledParagraph>
              <span>{accountAutoMapPreview.length}</span> {localized("organization(s) will be mapped.")}
            </StyledParagraph>
            <StyledDiv className="panel-bordered">
              <AccountAutoMapTable
                {...{
                  organizations: accountAutoMapPreview,
                }}
              />
            </StyledDiv>
          </div>
        )}
      </Modal>
    )
  }
}

export default connect(
  ({ psa }) => ({
    accountAutoMapPreview: psa.ConnectWise.mappings.accounts.accountAutoMapPreview,
    accountMapModalLoading: psa.ConnectWise.mappings.accounts.accountMapModalLoading,
  }),
  {
    cancelAutoMapping: _cancelAutoMapping,
    saveAutoMapping: _saveAutoMapping,
  },
)(AccountAutoMapModal)
