import { append, pluck, reduce, without } from "ramda"
import { useMemo } from "react"

import styled from "@emotion/styled"
import { Body, IconButton, SearchableSelect, Select } from "@ninjaone/components"
import { Label } from "@ninjaone/components/src/Form/Label"
import { GripLinesIcon, RemoveIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"

import { arrayToMapWithKey, localized, sortByFieldNameCaseInsensitive } from "js/includes/common/utils"
import DragableList from "js/includes/components/DragableList"
import { Box, Flex } from "js/includes/components/Styled"

import { getColumnOptions } from "./utils"

const ElementContainer = styled(Flex)`
  align-items: center;
  padding: 0 ${tokens.spacing[3]};
  min-height: 40px;
  background-color: ${({ theme }) => theme.colorBackground};
  border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  border-radius: ${tokens.spacing[1]};
  cursor: move;
  ${({ isLastItem }) => {
    return `
      ${!isLastItem ? `margin-bottom: ${tokens.spacing[2]};` : ""}
    `
  }}
`

const DragSectionContainer = styled(Flex)`
  flex-direction: column;
  padding: ${tokens.spacing[2]} ${tokens.spacing[2]};
  background-color: ${({ theme }) => theme.colorBackgroundSubtle};
  border-radius: ${tokens.spacing[1]};
  min-height: 322px;
`

const getDraggableElements = (systemOptionsMap, attributeOptionsMap, columns) =>
  reduce(
    (draggableElements, columnId) => {
      const option = systemOptionsMap[columnId] || attributeOptionsMap[columnId]
      if (option) {
        draggableElements.push(option)
      }
      return draggableElements
    },
    [],
    columns,
  )

const GROUP_KEY = "group"

const getUnusedColumns = ({ options, columns, groupName }) =>
  reduce(
    (acc, option) => {
      if (!columns.includes(option.value)) {
        acc.push({ ...option, [GROUP_KEY]: groupName })
      }
      return acc
    },
    [],
    options,
  )

export const Columns = ({ columns, ticketAttributes = [], sortBy, onChange, columnsErrorMessage }) => {
  const sortByValue = sortBy[0]
  const sortByField = sortByValue.field
  const sortByDirection = sortByValue.direction

  const { systemOptions, systemOptionsMap, sortDirectionOptions } = useMemo(() => {
    const systemOptions = getColumnOptions({ labelKey: "labelText" })
    return {
      systemOptions,
      systemOptionsMap: arrayToMapWithKey("value", systemOptions),
      sortDirectionOptions: [
        {
          value: "ASC",
          labelText: localized("Ascending"),
        },
        {
          value: "DESC",
          labelText: localized("Descending"),
        },
      ],
    }
  }, [])

  const { activeAttributeOptions, allAttributeOptionsMap } = useMemo(() => {
    const data = reduce(
      (acc, elem) => {
        const option = {
          labelText: elem.name,
          value: `attribute:${elem.id}`,
        }
        if (elem.active) {
          acc.activeOptions.push(option)
        }
        acc.allOptionsMap[option.value] = option
        return acc
      },
      {
        activeOptions: [],
        allOptionsMap: {},
      },
      ticketAttributes,
    )
    return {
      activeAttributeOptions: sortByFieldNameCaseInsensitive("label", "ASC")(data.activeOptions),
      allAttributeOptionsMap: data.allOptionsMap,
    }
  }, [ticketAttributes])

  const columnOptions = [
    ...getUnusedColumns({ columns, groupName: localized("System"), options: systemOptions }),
    ...getUnusedColumns({ columns, groupName: localized("Fields"), options: activeAttributeOptions }),
  ]

  return (
    <>
      <Box marginBottom={tokens.spacing[2]}>
        <Select
          labelId="sort-by-select"
          labelText={localized("Sort by")}
          options={systemOptions}
          value={sortByField}
          onChange={field => onChange("sortBy", [{ ...sortByValue, field }])}
        />
      </Box>
      <Box marginBottom={tokens.spacing[2]}>
        <Select
          labelId="sort-direction-select"
          labelText={localized("Sort direction")}
          value={sortByDirection}
          options={sortDirectionOptions}
          onChange={direction => onChange("sortBy", [{ ...sortByValue, direction }])}
        />
      </Box>
      <Box marginBottom={tokens.spacing[2]}>
        <Flex justifyContent="space-between">
          <Label labelText={localized("Columns")} required />
          <Body type="bodyXs">
            {columns.length === 1
              ? localized("1 column selected out of {{total}}", { total: columnOptions.length })
              : localized("{{count}} columns selected out of {{total}}", {
                  count: columns.length,
                  total: columnOptions.length,
                })}
          </Body>
        </Flex>
        <SearchableSelect
          ariaLabel={localized("Columns")}
          required
          hideClearSelectionButton
          options={columnOptions}
          groupKey={GROUP_KEY}
          errorMessage={columnsErrorMessage}
          value="__NONE__"
          placeholderText={
            columns.length === 1
              ? localized("1 column selected")
              : localized("{{count}} columns selected", { count: columns.length })
          }
          onChange={column => onChange("columns", append(column, columns))}
        />
      </Box>
      <DragSectionContainer>
        <Box marginBottom={tokens.spacing[2]}>
          <Body italic type="bodyXs" fontWeight={tokens.typography.fontWeight.regular} color="colorTextWeakest">
            {localized("Drag columns to reorder")}
          </Body>
        </Box>
        <DragableList
          keyProp="value"
          elements={getDraggableElements(systemOptionsMap, allAttributeOptionsMap, columns)}
          onChange={values => onChange("columns", values ? pluck("value", values) : [])}
          onRemoveElement={value => onChange("columns", without([value], columns))}
          renderElement={(section, removeSection, isDragging, index) => {
            return (
              <ElementContainer isLastItem={index === columns.length - 1}>
                <Flex width="20px" justifyContent="center">
                  <GripLinesIcon />
                </Flex>
                <Box width="100%" paddingLeft={tokens.spacing[2]}>
                  <Body>{section.labelText}</Body>
                </Box>
                <IconButton onClick={e => removeSection(e, section.value)}>
                  <RemoveIcon />
                </IconButton>
              </ElementContainer>
            )
          }}
        />
      </DragSectionContainer>
    </>
  )
}
