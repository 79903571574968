import React from "react"
import { connect } from "react-redux"
import Modal from "js/includes/components/Modal"
import { localized, localizationKey, sortByFieldNameCaseInsensitive, isFeatureEnabled } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { useForm } from "js/includes/common/hooks"
import { validations } from "js/includes/common/utils/validations"
import { isNil } from "ramda"

const selectCommonProps = {
  width: "100%",
  minHeight: "32px",
  borderRadius: "2px",
  rowHeight: 30,
  searchableKey: "label",
  valueSelectorKey: "value",
  openOnFocus: true,
}

const AutotaskAccountMappingModal = ({
  unmount,
  mapping,
  accountList,
  nodeRoleMappings,
  companyFilterMapTypes,
  saveAccountOrganizationMapping,
}) => {
  const { values, validation, validateForm, onChange } = useForm({
    fields: {
      account: !isNil(mapping.accountId)
        ? {
            id: mapping.accountId,
            name: mapping.accountName,
          }
        : {},
      deviceRoleMapping: !isNil(mapping.installedProductCategoryMappingId)
        ? {
            id: mapping.installedProductCategoryMappingId,
            name: nodeRoleMappings.find(m => m.id === mapping.installedProductCategoryMappingId).name,
          }
        : {},
    },
    validate: {
      account: validations.required,
    },
  })

  return (
    <Modal
      overflow
      title={localizationKey("Account Mapping")}
      saveText={localizationKey("OK")}
      closeText={localizationKey("Cancel")}
      dialogClassName="psa-modal"
      close={() => unmount()}
      save={() => {
        if (validateForm()) {
          saveAccountOrganizationMapping({
            ...mapping,
            accountId: values.account.id,
            accountName: values.account.name,
            installedProductCategoryMappingId: values.deviceRoleMapping?.id ?? null,
          })

          unmount()
        }
      }}
    >
      <Box textAlign="left" display="flex" margin={[0, 4, 4, 4]} flexDirection="column">
        <Box>
          <h4>{localized("Autotask Account")}</h4>

          <SearchableDropDown
            {...{
              ...selectCommonProps,
              pageSize: 500,
              value: values.account,
              searchableKey: "name",
              valueSelectorKey: "id",
              validationState: validation.success.account === false ? "error" : null,
              onSelect: selection => {
                onChange("account", selection)
              },
              ...(isFeatureEnabled("autotask_big_data")
                ? {
                    endpoint: "/psaat/v2/accountsPaginated",
                    searchParams: ({ query }) => ({
                      ...(query && { accountNameParam: query }),
                    }),
                    lastItemKey: "id",
                    responseParser: payload => payload?.accountList ?? [],
                  }
                : {
                    options: sortByFieldNameCaseInsensitive("name", "ASC", accountList),
                  }),
            }}
          />
        </Box>

        <Box paddingTop="20px">
          <h4>{localized("Ninja Device Role Mapping")}</h4>

          <SearchableDropDown
            {...{
              ...selectCommonProps,
              options: sortByFieldNameCaseInsensitive("name", "ASC", nodeRoleMappings),
              value: values.deviceRoleMapping,
              searchableKey: "name",
              valueSelectorKey: "id",
              validationState: validation.success.deviceRoleMapping === false ? "error" : null,
              onSelect: selection => {
                onChange("deviceRoleMapping", selection)
              },
            }}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default connect(({ psa }) => ({
  accountList: psa.Autotask.settings.accountList,
}))(AutotaskAccountMappingModal)
