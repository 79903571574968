import { $getRoot } from "lexical"
import { find, isEmpty, omit } from "ramda"
import { memo, useCallback, useMemo, useRef } from "react"

import styled from "@emotion/styled"
import { $generateHtmlFromNodes } from "@lexical/html"
import { Checkbox, Input, Modal } from "@ninjaone/components"
import InputActions from "@ninjaone/components/src/Form/InputActions"
import { Label } from "@ninjaone/components/src/Form/Label"
import tokens from "@ninjaone/tokens"

import { useForm, useMountedState } from "js/includes/common/hooks"
import showModal from "js/includes/common/services/showModal"
import { isFeatureEnabled, localizationKey, localized, validations } from "js/includes/common/utils"
import { useEditor } from "js/includes/components/RichTextEditor/hooks/useEditor"
import { additionalEmailDefaultOptions } from "js/includes/components/RuleEditor/utils"
import { Box } from "js/includes/components/Styled"
import { Placeholders } from "js/includes/ticketing/TriggerEditor/components/Placeholders"
import { StyledEditorWrapper } from "js/includes/ticketing/TriggerEditor/components/Styled"
import { UserEmailEditor } from "js/includes/ticketing/TriggerEditor/components/UserEmailEditor"
import { getUsersDeletedValidation, mapExistingIdValues } from "js/includes/ticketing/TriggerEditor/utils"
import { findNew } from "js/includes/ticketing/commonMethods"
import { WYSIWYGEditor as WYSIWYGEditorLegacy } from "js/includes/ticketing/editor/shared/components"
import {
  buildTempAttachment,
  WYSIWYGEditor,
  WYSIWYGEditorReadOnly,
} from "js/includes/ticketing/editor/shared/components/WYSIWYGEditor"
import { extractTextContentFromHtml, isEditorNotEmpty, uploadFile } from "js/includes/ticketing/editor/shared/utils"
import { getCurrentUserOption } from "js/includes/ticketing/shared/components/AppUsersAndContactsDropdown"

const StyledSubject = styled.span`
  font-weight: 600;
  margin-top: 8px;
`

const EDITOR_CONTENT_ID = "body-editor"
const TO_LABEL_ID = "to-label"

const EditorModal = memo(
  ({
    value,
    handleOnChange,
    editorInsertHtml,
    showCurrentUserOption,
    isWYSIWYGV3FeatureEnabled,
    unmount,
    metaData,
  }) => {
    const [pendingUploads, setPendingUploads] = useMountedState({})
    const currentUserOption = useMemo(() => getCurrentUserOption(), [])
    const additionalDefaultOptions = useMemo(additionalEmailDefaultOptions, [])

    const initialBodyRef = useRef(value?.body)

    const { values, onChange, validation, validateForm } = useForm({
      fields: {
        uploads: value?.uploads ?? [],
        to: {
          emails: value?.emails ?? [],
          uids: value?.uids ?? [],
          keys:
            value?.keys?.map(key =>
              [currentUserOption, ...additionalDefaultOptions].find(
                option => key?.uid?.toLowerCase() === option?.uid?.toLowerCase(),
              ),
            ) ?? [],
        },
        subject: value?.subject ?? "",
        body: initialBodyRef.current ?? "",
        text: extractTextContentFromHtml(value?.body),
        allowResponse: value?.allowResponse ?? false,
      },
      validate: {
        to: ({ uids, keys, emails }) => {
          return getUsersDeletedValidation({
            metaData: metaData?.toUsers,
            metadataIdKey: "uid",
            valueToArrayConverter: valueArray => mapExistingIdValues({ objectList: valueArray }),
          })([...uids, ...keys, ...emails])
        },
        subject: validations.required,
        body: (htmlBody, { text: body, uploads }) => {
          const success = isEditorNotEmpty({ body, htmlBody, uploads })
          return { success, message: success ? "" : localized("Required") }
        },
      },
    })

    function handleSave() {
      if (validateForm()) {
        editorInsertHtml(values.body)
        handleOnChange(
          omit(["to"], {
            ...values,
            ...values.to,
          }),
        )
        unmount()
      }
    }

    function handleToChange(selected) {
      const email = find(findNew, selected)
      if (email) {
        // Make sure is an email
        const { success } = validations.email(email.displayName)
        if (!success) return
      }

      const defaultValue = { keys: [], uids: [], emails: [] }

      const newToValues = selected.reduce((acc, option) => {
        if (option.isNew) {
          acc.emails = [...acc.emails, option]
        } else if (option.isCurrentUser || option.isDefaultOption) {
          acc.keys = [...acc.keys, option]
        } else {
          acc.uids = [...acc.uids, option]
        }
        return acc
      }, defaultValue)

      onChange("to", newToValues)
    }

    const onChangeEvent = useCallback(
      (editorState, editor) => {
        editorState.read(() => {
          const root = $getRoot()
          const text = root.getTextContent()
          onChange({ text, body: $generateHtmlFromNodes(editor) })
        })
      },
      [onChange],
    )

    const onUploadImage = useCallback(
      async file => {
        const attachment = buildTempAttachment({ file, inline: true })
        setPendingUploads(prev => ({ ...prev, [attachment.tempId]: true }))
        try {
          const { resourceId } = await uploadFile({ file })
          const completeAttachment = {
            ...attachment,
            resourceId,
            metadata: { ...attachment.metadata, contentId: resourceId },
          }
          onChange({ uploads: [...values.uploads, completeAttachment] })
          return completeAttachment
        } finally {
          setPendingUploads(prev => omit([attachment.tempId], prev))
        }
      },
      [values.uploads, onChange, setPendingUploads],
    )

    return (
      <Modal
        size="lg"
        titleGroup={{
          titleText: localized("Edit field"),
        }}
        unmount={unmount}
        buttons={[
          {
            type: "save",
            labelToken: localizationKey("Apply"),
            onClick: handleSave,
            disabled: !isEmpty(pendingUploads),
          },
        ]}
      >
        <Box marginBottom={tokens.spacing[2]}>
          <Label labelText={localized("Recipient")} required id={TO_LABEL_ID} forInputElement={false} />
          <UserEmailEditor
            showCurrentUserOption={showCurrentUserOption}
            value={[...values.to.keys, ...values.to.emails, ...values.to.uids]}
            handleOnChange={handleToChange}
            additionalDefaultOptions={additionalDefaultOptions}
            ariaAttributes={{ "aria-labelledby": TO_LABEL_ID }}
            errorMessage={validation.message.to}
            searchPlaceholderToken={localizationKey("Select recipient")}
          />
        </Box>
        <Box marginBottom={tokens.spacing[2]}>
          <Input
            labelText={localized("Subject")}
            placeholder={localized("Enter subject")}
            required
            value={values.subject}
            maxLength={100}
            onChange={e => onChange("subject", e.target.value)}
            errorMessage={validation.message.subject}
          />
        </Box>
        <Box marginBottom={tokens.spacing[2]}>
          <Label labelText={localized("Body text")} required labelFor={EDITOR_CONTENT_ID} />
          {isWYSIWYGV3FeatureEnabled ? (
            <WYSIWYGEditor
              editorContentId={EDITOR_CONTENT_ID}
              htmlParserPluginProps={{ initialHTML: initialBodyRef.current }}
              richTextPluginProps={{
                placeholderToken: localizationKey("Enter body text"),
              }}
              onChangePluginProps={{
                onChange: onChangeEvent,
              }}
              validationProps={{
                errorMessage: validation.message.body,
              }}
              imagePluginProps={{
                onUploadImage,
                initialInlineAttachments: value?.uploads ?? [],
              }}
              attachmentPluginProps={{ excluded: true }}
              htmlInjectorPluginProps={{ included: true }}
            />
          ) : (
            <WYSIWYGEditorLegacy
              editorData={{
                htmlBody: values.body,
              }}
              allowAttachments={false}
              allowInlineImages={false}
              hasError={validation.success.body === false}
              onChange={({ html, text }) => onChange("body", text.trim().length ? html : null)}
            />
          )}
        </Box>
        <Box marginBottom={tokens.spacing[2]}>
          <Placeholders extended withCurrentUserPlaceholders={showCurrentUserOption} withEmailPlaceholders />
        </Box>
        <Checkbox
          label={localized("Allow email response by recipient")}
          name="allowResponse"
          checked={values.allowResponse}
          onChange={() => onChange("allowResponse", !values.allowResponse)}
        />
      </Modal>
    )
  },
)

export const EmailEditor = memo(
  ({ value, values, handleOnChange, showCurrentUserOption = true, metaData, errorMessage, ariaLabel }) => {
    const [editor, { editorInsertHtml }] = useEditor()

    const containerId = ariaLabel
    const subject = value?.subject ?? localized("Subject")
    const htmlBody = useMemo(() => value?.body ?? localized("Body"), [value?.body])
    const isWYSIWYGV3FeatureEnabled = isFeatureEnabled("wysiwyg_v3")

    return (
      <StyledEditorWrapper
        id={containerId}
        errorMessage={errorMessage}
        aria-label={ariaLabel}
        onClickCapture={e => {
          e.stopPropagation()
          showModal(
            <EditorModal
              {...{
                editorInsertHtml,
                value,
                values,
                handleOnChange,
                showCurrentUserOption,
                isWYSIWYGV3FeatureEnabled,
                metaData,
              }}
            />,
            { withProvider: true },
          )
        }}
      >
        <StyledSubject className="break-word">{subject}</StyledSubject>
        {isWYSIWYGV3FeatureEnabled ? (
          <WYSIWYGEditorReadOnly
            attachmentProps={{ attachments: value?.uploads }}
            htmlParserPluginProps={{ initialHTML: htmlBody, updateOnChange: true }}
          />
        ) : (
          <WYSIWYGEditorLegacy readOnly editor={editor} editorData={{ htmlBody }} />
        )}
        <InputActions ariaErrorId={containerId} disableClear error={errorMessage} />
      </StyledEditorWrapper>
    )
  },
)
