import { compose, includes, pluck, propEq } from "ramda"
import { sizer, sizerNumber } from "@ninjaone/utils"

import { betweenInclusive, isBetweenDates, isNumber } from "@ninjaone/webapp/src/js/includes/common/utils"

import DateRangeSelectFilter from "./Filters/DateRangeSelectFilter"
import InputWithUnitDropdownFilter from "./Filters/InputWithUnitDropdownFilter"
import MultiSelectFilter from "./Filters/MultiSelectFilter"
import NumberRangeFilter from "./Filters/NumberRangeFilter"
import RadioGroupFilter from "./Filters/RadioGroupFilter"
import SearchableSelectFilter from "./Filters/SearchableSelectFilter"
import SingleSelectFilter from "./Filters/SingleSelectFilter"
import DateRangePickerFilter from "./Filters/DateRangePickerFilter"
import DateRangePickerSelectFilter from "./Filters/DateRangePickerSelectFilter"
import SingleValueFilter from "./Filters/SingleValueFilter"

export const cellTypes = {
  ANCHOR: "Anchor",
  BUTTON_LINK: "ButtonLink",
  ICON: "Icon",
  TEXT: "Text",
  SWITCH: "Switch",
  TAGS: "Tags",
  LAZY_RENDERER: "LazyRenderer",
  STICKY_POPOVER: "StickyPopover",
  DATE: "Date",
  CURRENCY: "Currency",
  NUMBER: "Number",
  PROGRESS: "Progress",
}

export const filterTypes = {
  SINGLE_SELECT: "SingleSelect",
  MULTISELECT: "MultiSelect",
  SEARCHABLE_SELECT: "SearchableSelect",
  INPUT_WITH_UNIT_DROPDOWN: "InputWithUnitDropdown",
  RADIO_GROUP: "RadioGroup",
  DATE_RANGE_SELECT: "DateRangeSelect",
  DATE_RANGE_PICKER: "DateRangePicker",
  DATE_RANGE_PICKER_SELECT: "DateRangePickerSelect",
  NUMBER_RANGE: "NumberRange",
  SINGLE_VALUE: "SingleValue",
}

export const additionalTableSettingsTypes = {
  SWITCH: "Switch",
}

export const rowStatusTypes = {
  DISABLED: "disabled",
  ERROR: "error",
}

export const filterOptions = {
  [filterTypes.SINGLE_SELECT]: {
    filter: ({ name, row, value }) => {
      if (Array.isArray(row[name])) {
        return row[name].includes(value)
      }
      return propEq(name, value, row)
    },
    Component: SingleSelectFilter,
  },
  [filterTypes.MULTISELECT]: {
    filter: ({ name, row, value }) => value.includes(row[name]),
    Component: MultiSelectFilter,
  },
  [filterTypes.SEARCHABLE_SELECT]: {
    filter: ({ name, row, value, componentProps: { valueSelectorKey = "value" } }) =>
      compose(includes(row[name]), pluck(valueSelectorKey))(value),
    Component: SearchableSelectFilter,
  },
  [filterTypes.INPUT_WITH_UNIT_DROPDOWN]: {
    filter: ({ name, row, value }) => row[name] >= (value.inputValue ?? 0),
    Component: InputWithUnitDropdownFilter,
  },
  [filterTypes.RADIO_GROUP]: {
    filter: ({ name, row, value }) => row[name] >= (value.selected ?? 0),
    Component: RadioGroupFilter,
  },
  [filterTypes.DATE_RANGE_SELECT]: {
    filter: ({ name, row, value }) => {
      const { from, to } = value
      return from && to && isBetweenDates(row[name], from, to)
    },
    Component: DateRangeSelectFilter,
  },
  [filterTypes.DATE_RANGE_PICKER]: {
    filter: ({ name, row, value }) => {
      const { from, to } = value
      return from && to && isBetweenDates(row[name], from, to)
    },
    Component: DateRangePickerFilter,
  },
  [filterTypes.DATE_RANGE_PICKER_SELECT]: {
    filter: ({ name, row, value }) => {
      const { from, to } = value
      return from && to && isBetweenDates(row[name], from, to)
    },
    Component: DateRangePickerSelectFilter,
  },
  [filterTypes.NUMBER_RANGE]: {
    filter: ({ name, row, value }) => {
      const { from, to } = value
      return isNumber(from) && isNumber(to) && betweenInclusive(from, to)(row[name])
    },
    Component: NumberRangeFilter,
  },
  [filterTypes.SINGLE_VALUE]: {
    filter: ({ name, row, value }) => row[name] === value,
    Component: SingleValueFilter,
  },
}

export const getBooleanAccessorVal = bool => (bool ? "true" : "false")

export const runAfterDataTableRenders = callback => {
  // push to callback queue so callback runs after final render executes
  setTimeout(callback, 0)
}

export const getIsSortedDescFromPreviousValue = previousValue => previousValue === false

export const forceColumnWidth = width => `
  min-width: ${width} !important;
  max-width: ${width} !important;
  width: ${width} !important;
`

export const getHasMultiplePages = ({
  enableLazyLoadRows,
  totalRowCount,
  pageSizeLimit,
  pageCount,
  showSelected,
  rowsSelectedCount,
}) => {
  if (showSelected) {
    return rowsSelectedCount > pageSizeLimit
  }
  return enableLazyLoadRows ? Math.ceil(totalRowCount / pageSizeLimit) > 1 : pageCount && pageCount > 1
}

export const getTimeDiffInMinutes = eventDate => {
  const currentDate = new Date()
  const diffInMs = currentDate.getTime() - eventDate.getTime()
  const diffInMinutes = Math.round(diffInMs / 60000)

  return diffInMinutes
}

export function getSwitchSize(size) {
  switch (size) {
    case "sm":
      return { width: sizerNumber(8), height: sizerNumber(4) }
    case "md":
      return { width: sizerNumber(10), height: sizerNumber(5) }
    case "lg":
      return { width: sizerNumber(12), height: sizerNumber(6) }
    default:
      throw new Error(`Unknown size: ${size}`)
  }
}

export const getSwitchContainerStyles = ({ width, color }) => `
  display: grid;
  grid-template-columns: ${width}px minmax(min-content, auto);
  grid-column-gap: ${sizer(2)};
  align-items: center;
  color: ${color};
`
export const getSelectAllCount = ({
  enableLazyLoadRows,
  totalRowCount,
  loadedRowsWithDisabled,
  selectableRowsCount,
  rows,
  fetchData,
  totalCount,
}) => {
  if (enableLazyLoadRows) return totalRowCount
  if (fetchData) return totalCount
  if (loadedRowsWithDisabled) return selectableRowsCount
  return rows.length
}

export const getDisabledRows = ({ rows, page, hasMultiplePages, getCustomRowProps, loadedRowsWithDisabled }) => {
  let selectedRowsCount = 0
  let selectableRowsCount = 0
  let selectedPageRowsCount = 0
  let selectablePageRowsCount = 0

  if (loadedRowsWithDisabled) {
    rows.forEach(row => {
      const { disabled } = getCustomRowProps(row.original)
      row.isSelected && selectedRowsCount++
      !disabled && selectableRowsCount++
    })
    if (hasMultiplePages) {
      page.forEach(row => {
        const { disabled } = getCustomRowProps(row.original)
        row.isSelected && selectedPageRowsCount++
        !disabled && selectablePageRowsCount++
      })
    }
  }

  const isAllSelectableRowsSelected = selectableRowsCount === selectedRowsCount
  const isAllSelectablePageRowsSelected = selectablePageRowsCount === selectedPageRowsCount
  const disableSelectAll = loadedRowsWithDisabled && selectableRowsCount === 0

  return {
    isAllSelectableRowsSelected,
    isAllSelectablePageRowsSelected,
    disableSelectAll,
    selectableRowsCount,
    selectablePageRowsCount,
  }
}
