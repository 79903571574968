import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Tree, { TreeNode } from "rc-tree"
import { clone, intersection, isEmpty, uniq, without, concat } from "ramda"
import { Button } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { isWindowsDevice, localizationKey, localized, memoize } from "js/includes/common/utils"
import {
  switcherIcon,
  getIcon as icon,
  saveNewFolder,
  enforceTrailingSlashesInPath,
} from "js/includes/components/Browsers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import OutsideClickHandler from "react-outside-click-handler"
import { Box } from "js/includes/components/Styled"
import {
  position,
  border,
  layout,
  flexbox,
  space,
  color,
  typography,
  shouldForwardProp,
} from "js/includes/components/Styled/system"
import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import showModal from "js/includes/common/services/showModal"
import { WrappedNewFolderModal } from "./WrappedNewFolderModal"

export const StyledTree = styled(Tree, { shouldForwardProp })`
  ${space}
  ${position}
  ${border}
  ${flexbox}
  ${layout}
  ${color}
  ${typography}
`

const StyledLinkButton = styled(Button)`
  padding: ${sizer(3)} 0;
  &:hover {
    background: none;
  }
`

class FileFolderBrowserTree extends PureComponent {
  static propTypes = {
    onCheck: PropTypes.func,
    onSelect: PropTypes.func,
    separator: PropTypes.string,
    checkable: PropTypes.bool,
    selectable: PropTypes.bool,
    checkedKeys: PropTypes.array,
    selectedKeys: PropTypes.array,
    expandedKeys: PropTypes.array,
    onGetFolderContent: PropTypes.func.isRequired,
    onGetRootDirectories: PropTypes.func.isRequired,
    deviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    limit: PropTypes.number,
    height: PropTypes.number,
    nodeClass: PropTypes.string,
    canCreateNewFolder: PropTypes.bool,
  }

  static defaultProps = {
    separator: "/",
    checkable: false,
    selectable: false,
    checkedKeys: [],
    selectedKeys: [],
    expandedKeys: [],
    limit: 1000,
    height: 500,
  }

  state = {
    message: "",
    treeData: [],
    checkedKeys: { checked: [], halfChecked: [] },
    selectedKeys: [],
    expandedKeys: [],
    loading: false,
  }

  componentDidMount() {
    this._isMounted = true
    this.initializeTreeData()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deviceId !== this.props.deviceId) {
      this.initializeTreeData()
    }
  }

  getParentPaths = path => {
    const { separator } = this.props
    let result = []
    const pathParts = path.split(separator)
    while (pathParts.length > 1) {
      pathParts.pop() // remove the last element
      const parentPath = pathParts.join(separator) + (pathParts.length === 1 ? separator : "") // use separator at the end just for root
      result.unshift(parentPath) // add the path to the start of the array
    }
    return result
  }

  getHalfCheckedKeys = checkedKeys => {
    let result = []
    checkedKeys.forEach(element => {
      const parentPaths = this.getParentPaths(element)
      while (parentPaths.length > 0) {
        const parentPath = parentPaths.pop() // remove the last element
        if (!result.includes(parentPath)) {
          // do not add parent path if it already exists
          result.push(parentPath)
        }
      }
    })
    return result
  }

  initializeTreeData = async () => {
    this.setState({
      message: localized("Loading files from device"),
      loading: true,
    })

    const {
      nodeClass,
      checkedKeys: initialCheckedKeys,
      expandedKeys,
      selectedKeys,
      onGetRootDirectories,
      onGetFolderContent,
    } = this.props

    const treeData = (await onGetRootDirectories()) || []
    let initialExpandedKeys = [...expandedKeys, ...(isWindowsDevice(nodeClass) ? ["C:/"] : [])]

    if (this._isMounted) {
      let checkedKeys = {
        checked: initialCheckedKeys,
        halfChecked: this.getHalfCheckedKeys(initialCheckedKeys),
      }
      await Promise.all(
        initialExpandedKeys.map(async eventKey => {
          const node = treeData.find(node => node.key === eventKey)
          if (node) {
            const children = await onGetFolderContent(node, node.key)

            this.loadCheckedKeysForNewChildren({ checkedKeys, nodeClass, eventKey, children })

            this.updateTreeData({
              treeData,
              children,
              eventKey,
              reloadChildren: true,
            })
          }
        }),
      )

      this.setState({
        treeData,
        checkedKeys,
        selectedKeys: selectedKeys,
        expandedKeys: initialExpandedKeys,
        message: treeData.length ? "" : localized("Directory listing unavailable."),
        loading: false,
      })
    }
  }

  updateTreeData = ({ treeData, children, eventKey, isLoadMoreAction, reloadChildren }) => {
    treeData.forEach(node => {
      if (eventKey.includes(node.key)) {
        if (node.children) {
          if (node.key === eventKey) {
            if (isLoadMoreAction) {
              node.children = [...node.children, ...children]
            } else if (reloadChildren) {
              node.children = children
            }
          } else {
            this.updateTreeData({
              treeData: node.children,
              children,
              eventKey,
              isLoadMoreAction,
              reloadChildren,
            })
          }
        } else {
          node.children = children
        }
      }
    })
  }

  loadCheckedKeysForNewChildren = ({ checkedKeys, nodeClass, eventKey, children = [] }) => {
    const newChildrenKeys = children.map(c => c.key)

    if (isWindowsDevice(nodeClass)) {
      checkedKeys.checked = checkedKeys.checked.map(item => {
        const matchedKey = newChildrenKeys.find(k => k.toLowerCase() === item.toLowerCase()) ?? item
        return matchedKey
      })

      checkedKeys.halfChecked = checkedKeys.halfChecked.map(item => {
        const matchedKey = newChildrenKeys.find(k => k.toLowerCase() === item.toLowerCase()) ?? item
        return matchedKey
      })
    }

    if (checkedKeys.checked.find(c => c === eventKey)) {
      checkedKeys.checked = [...checkedKeys.checked, ...newChildrenKeys]
    }
  }

  loadData = async (node, reloadChildren) => {
    const {
      props: { eventKey, offset },
    } = node
    const { limit, nodeClass } = this.props
    const treeData = [...this.state.treeData]
    const children = await this.props.onGetFolderContent(node, eventKey, offset, limit)

    let checkedKeys = clone(this.state.checkedKeys)

    this.loadCheckedKeysForNewChildren({ checkedKeys, nodeClass, eventKey, children })

    this.updateTreeData({
      treeData,
      children,
      eventKey,
      isLoadMoreAction: !!offset,
      reloadChildren,
    })

    this.setState({
      treeData,
      checkedKeys,
    })
  }

  recursivelyGetChildrenKeys = node => {
    let result = [node.key]
    if (node.children) {
      node.children.forEach(child => {
        result = result.concat(this.recursivelyGetChildrenKeys(child))
      })
    }
    return result
  }

  isHalfChecked = (nodeChildren = [], checkedKeys = [], halfCheckedKeys = []) => {
    // Extract keys from children
    const childrenKeys = nodeChildren.map(n => n.key)

    // Find the intersection of childrenKeys and halfCheckedKeys
    const halfCheckedIntersectionResult = intersection(childrenKeys, halfCheckedKeys)

    if (halfCheckedIntersectionResult.length > 0) {
      // if there is at least half checked node, that means the parent node should also be half checked
      return true
    }

    // Find the intersection of childrenKeys and checkedKeys
    const intersectionResult = intersection(childrenKeys, checkedKeys)

    // Check if there are elements in the intersection
    // and if the intersection size is less than childrenKeys size,
    // this means that at least one child is selected, but not all
    return intersectionResult.length > 0 && intersectionResult.length !== childrenKeys.length
  }

  propagateCheckStatus = (checkedKeys, nodes = [], info, parentPaths = []) => {
    const path = parentPaths.shift()
    if (path) {
      // if we have a path, it is not the selected node
      const { children } = nodes.find(n => n.key === path) ?? {}
      // we need to know children status to calculate actual check status, so call it recursively
      const childResult = this.propagateCheckStatus(checkedKeys, children, info, parentPaths)
      // if children is half checked
      if (childResult?.halfChecked) {
        if (!checkedKeys.halfChecked.includes(path)) {
          // do not push this path if it is already added
          checkedKeys.halfChecked.push(path)
        }
        // remove this path from checked array, because it is now half checked
        checkedKeys.checked = without([path], checkedKeys.checked)
        // propagate half checked status to all parents
        return childResult
      } else {
        // children is not half checked, it means that it can be checked or unchecked
        // so, we have to verify if this current path should be half checked
        const halfChecked = this.isHalfChecked(children, checkedKeys.checked, checkedKeys.halfChecked)
        if (halfChecked) {
          if (!checkedKeys.halfChecked.includes(path)) {
            // do not push this path if it is already added
            checkedKeys.halfChecked.push(path)
          }
          // remove this path from checked array, because it is now half checked
          checkedKeys.checked = without([path], checkedKeys.checked)
        } else {
          // current path is not half checked, so it means that all its children are checked or unchecked
          // with that, the value of this path will be the same as its child
          if (info.checked) {
            if (!checkedKeys.checked.includes(path)) {
              checkedKeys.checked.push(path)
            }
          } else {
            checkedKeys.checked = without([path], checkedKeys.checked)
          }
          // remove this path from halfChecked array, because it can be only checked or unchecked
          checkedKeys.halfChecked = without([path], checkedKeys.halfChecked)
        }
        return { halfChecked }
      }
    } else {
      // we reached the selected node, propagate check status to each child recursively
      const childrenKeys = this.recursivelyGetChildrenKeys(info.node)
      if (info.checked) {
        checkedKeys.checked = uniq(concat(checkedKeys.checked, childrenKeys))
      } else {
        checkedKeys.checked = without(childrenKeys, checkedKeys.checked)
      }
      // remove any half checked child that was selected
      checkedKeys.halfChecked = without(childrenKeys, checkedKeys.halfChecked)
    }
  }

  handleOnCheck = (treeCheckedKeys, info) => {
    const { treeData } = this.state
    const { onCheck, separator } = this.props

    let checkedKeys = clone(treeCheckedKeys)

    const parentPaths = this.getParentPaths(info.node.key)

    this.propagateCheckStatus(checkedKeys, treeData, info, parentPaths)

    this.setState(
      {
        checkedKeys,
      },
      () => {
        const topLevelFolders = checkedKeys.checked.filter((key, index, self) => {
          const parentFolder = self.find(
            otherKey => key !== otherKey && key.startsWith(otherKey + (otherKey.endsWith(separator) ? "" : separator)),
          )
          return !parentFolder
        })

        onCheck?.(topLevelFolders, info, treeData)
      },
    )
  }

  onSelect = (selectedKeys, info) => {
    if (info.node.props.isLoadMoreAction) {
      return this.loadData(info.node)
    }

    const { treeData } = this.state
    const { onSelect } = this.props
    this.setState(
      {
        selectedKeys,
        selectedInfo: info,
      },
      () => {
        onSelect && onSelect(selectedKeys, info, treeData)
      },
    )
  }

  onExpand = (expandedKeys, info) => {
    const { expanded, node } = info
    const [children] = node.props.children || []

    this.setState(
      {
        expandedKeys,
      },
      () => {
        expanded && !children && this.loadData(node)
      },
    )
  }

  onOutsideClick = () => {
    const { clearable, onClear } = this.props
    const { selectedKeys } = this.state

    if (!clearable) return

    if (!isEmpty(selectedKeys)) {
      this.setState({
        selectedKeys: [],
        selectedInfo: {},
      })
      onClear()
    }
  }

  onSaveNewFolder = async folderName => {
    const {
      props: { deviceId, separator },
      state: { selectedKeys, selectedInfo },
      loadData,
    } = this
    const path = enforceTrailingSlashesInPath(selectedKeys[0], separator)
    const errorResponse = await saveNewFolder({
      nodeId: deviceId,
      folderName,
      path,
    })

    if (errorResponse) return errorResponse

    loadData(selectedInfo.node, true)
  }

  getTreeNodes = memoize(treeData => {
    const { limit } = this.props
    return treeData.map(
      ({ key, type, title, folder, children, isLeaf, parentKey, disabled, disableCheckbox, ...props }) => {
        const hasMoreContent = children && children.length > 1 && children.length % limit === 0

        return (
          <TreeNode
            {...{
              ...props,
              key,
              type,
              icon,
              title,
              isLeaf,
              folder,
              children,
              disabled,
              parentKey,
              switcherIcon,
              disableCheckbox,
              className: `${disabled ? "node-disabled" : ""} ${disableCheckbox ? "checked-disabled" : ""}`,
            }}
          >
            {children && this.getTreeNodes(children)}

            {hasMoreContent && (
              <TreeNode
                {...{
                  key: key,
                  parentKey,
                  offset: children.length + 1,
                  title: localized("Load More"),
                  isLoadMoreAction: true,
                  icon: () => <FontAwesomeIcon icon={faPlus} />,
                }}
              />
            )}
          </TreeNode>
        )
      },
    )
  })

  render() {
    const {
      props: { checkable, selectable, height, nodeClass, canCreateNewFolder },
      state: { message, checkedKeys, selectedKeys, selectedInfo, expandedKeys, treeData, loading },
      handleOnCheck,
      onSelect,
      onExpand,
      getTreeNodes,
      onOutsideClick,
      onSaveNewFolder,
    } = this

    const treeNodes = getTreeNodes(treeData)

    return (
      <Box className="file-folder-browser-tree-container">
        {canCreateNewFolder && (
          <StyledLinkButton
            variant="tertiary"
            onClick={() => showModal(<WrappedNewFolderModal {...{ nodeClass, save: onSaveNewFolder }} />)}
            labelToken={localizationKey("New Folder")}
            disabled={!selectedInfo?.selected}
          />
        )}
        {treeData.length ? (
          <OutsideClickHandler {...{ onOutsideClick }}>
            <StyledTree
              showIcon
              {...{
                height,
                onCheck: handleOnCheck,
                onSelect,
                onExpand,
                checkable,
                selectable,
                checkedKeys,
                selectedKeys,
                expandedKeys,
                switcherIcon,
                className: "file-folder-browser-tree",
                checkStrictly: true,
              }}
            >
              {treeNodes}
            </StyledTree>
          </OutsideClickHandler>
        ) : (
          <Box paddingLeft={3} paddingTop={1} backgroundColor="white" {...{ height }}>
            {message && <span>{message}</span>}
            {loading && <FontAwesomeIcon spin icon={faSpinner} />}
          </Box>
        )}
      </Box>
    )
  }
}

export default FileFolderBrowserTree
