import { Link as NinjaLink } from "@ninjaone/components"
import { NINJAONE_ZENDESK_BASE_URL } from "js/includes/common/utils"
import ZendeskSsoForm from "./Zendesk/ZendeskSsoForm"

const Link = ({ href, target = "_blank", children, increaseContrast, increaseSpacing, textDecoration }) => {
  const isZendeskLink = href?.startsWith(NINJAONE_ZENDESK_BASE_URL)

  return isZendeskLink ? (
    <ZendeskSsoForm link={href} {...{ increaseContrast, increaseSpacing, textDecoration }}>
      {children}
    </ZendeskSsoForm>
  ) : (
    <NinjaLink
      onClick={e => e.stopPropagation()}
      href={href}
      target={target}
      rel="nonopener noreferrer"
      {...{ increaseContrast, textDecoration }}
    >
      {children}
    </NinjaLink>
  )
}

export default Link
