import { localizationKey, localized } from "js/includes/common/utils"
import { CREATE_ACTION, EDIT_ACTION } from "js/includes/ticketing/TriggerEditor/constants"
import { automationTypes } from "js/includes/ticketing/common/automations"

const nonSystemBoardDescriptionToken = localizationKey(
  "Customize the conditions, columns, and sorting criteria to control what tickets and information will be displayed in this board.",
)
const systemBoardDescriptionToken = localizationKey(
  "Customize the columns and sorting criteria to control what tickets and information will be displayed in this board.",
)

const eventOrTimeBasedDescriptionToken = localizationKey(
  "Configure ticket condition triggers to execute predefined actions, ensuring faster, more consistent ticket updates, responses, and resolutions.",
)

const responseTemplateDescriptionToken = localizationKey(
  "Set pre-defined actions to provide faster, more consistent ticket updates, responses, and resolutions.",
)

const saveToken = localizationKey("Save")

const createToken = localizationKey("Create")

const tokensByAction = {
  [automationTypes.EVENT_BASED]: {
    [EDIT_ACTION]: {
      saveButton: saveToken,
      description: eventOrTimeBasedDescriptionToken,
      title: localizationKey("Edit event-based automation"),
    },
    [CREATE_ACTION]: {
      saveButton: createToken,
      description: eventOrTimeBasedDescriptionToken,
      title: localizationKey("Add event-based automation"),
    },
  },
  [automationTypes.TIME_BASED]: {
    [EDIT_ACTION]: {
      saveButton: saveToken,
      description: eventOrTimeBasedDescriptionToken,
      title: localizationKey("Edit time-based automation"),
    },
    [CREATE_ACTION]: {
      saveButton: createToken,
      description: eventOrTimeBasedDescriptionToken,
      title: localizationKey("Add time-based automation"),
    },
  },
  [automationTypes.RESPONSE_TEMPLATE]: {
    [EDIT_ACTION]: {
      saveButton: saveToken,
      description: responseTemplateDescriptionToken,
      title: localizationKey("Edit response template"),
    },
    [CREATE_ACTION]: {
      saveButton: createToken,
      description: responseTemplateDescriptionToken,
      title: localizationKey("Add response template"),
    },
  },
  BOARD: {
    [EDIT_ACTION]: {
      saveButton: saveToken,
      title: localizationKey("Edit ticket board"),
    },
    [CREATE_ACTION]: {
      saveButton: createToken,
      title: localizationKey("Add ticket board"),
    },
  },
}

export const getTriggerModalLabels = ({ system, type, action }) => {
  const tokens = tokensByAction[type][action]
  const completeTokens = {
    ...tokens,
    ...(type === "BOARD" && {
      description: system ? systemBoardDescriptionToken : nonSystemBoardDescriptionToken,
    }),
  }

  return {
    titleGroup: {
      titleText: localized(completeTokens.title),
      descriptionText: localized(completeTokens.description),
    },
    saveButtonToken: completeTokens.saveButton,
  }
}

export const getSectionsData = ({ type, system, copy }) => {
  return {
    ...(type === "BOARD" && {
      general: {
        props: {
          displayDisabled: false,
          disabled: !!system && !copy,
        },
      },
      columnsAndSorting: {
        visible: true,
      },
      conditions: {
        visible: !system,
      },
    }),
    ...(type === automationTypes.RESPONSE_TEMPLATE && {
      general: {
        props: {
          displayDisabled: true,
          disabledTooltip: localized("Disabled templates will not be included in response template options."),
        },
      },
      actions: {
        visible: true,
      },
    }),
    ...((type === automationTypes.EVENT_BASED || type === automationTypes.TIME_BASED) && {
      general: {
        props: {
          displayDisabled: true,
          disabledTooltip: localized("Disabled automations will not be applied to newly created or updated tickets."),
        },
      },
      actions: {
        visible: true,
      },
      conditions: {
        visible: true,
      },
    }),
  }
}
