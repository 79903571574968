import PropTypes from "prop-types"
import { sizer } from "@ninjaone/utils"

export const commonPropTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  enabledFields: PropTypes.objectOf(PropTypes.bool),
  fieldLabelTokens: PropTypes.objectOf(PropTypes.string),
  fieldTooltipInfoTokens: PropTypes.objectOf(PropTypes.string),
  useDynamicQuantity: PropTypes.bool,
  showDynamicQuantityValue: PropTypes.bool,
}
export const contentPropTypes = {
  ...commonPropTypes,
}

export const contentFieldPropTypes = {
  ...commonPropTypes,
  name: PropTypes.string.isRequired,
  nameToken: PropTypes.string,
  mandatory: PropTypes.bool,
}

export const optionsType = PropTypes.arrayOf(
  PropTypes.shape({
    labelText: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
)

export const twoColumnsMargin = sizer(3)

export const isFieldDisabled = ({ name, enabledFields }) => !!enabledFields && !enabledFields[name]
