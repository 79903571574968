import { memo } from "react"

import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import {
  commonPropTypes,
  isFieldDisabled,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import { getLicensingOptions } from "js/includes/configuration/integrations/psa/psaProducts/productForm/formCommons"

import DropdownField from "./DropdownField"

const Term = memo(({ onChange, values, validation, enabledFields }) => {
  return (
    <DropdownField
      {...{
        onChange,
        values,
        validation,
        disabled: isFieldDisabled({ name: "licensingType", enabledFields }),
        options: getLicensingOptions(),
        name: "licensingType",
        nameToken: localizationKey("Licensing"),
      }}
    />
  )
})

Term.propTypes = commonPropTypes

export default Term
