import React, { Fragment } from "react"
import Loading from "js/includes/components/Loading"
import ConnectWiseAccountCriteria from "./ConnectWiseAccountCriteria"
import ConnectWiseAccountMapping from "./ConnectWiseAccountMapping"

export default function ConnectWiseAccountSettings(props) {
  return (
    <div className="psa-cw-account display-flex flex-column flex-full">
      {props.loading ? (
        <Loading className="p-l" />
      ) : (
        <Fragment>
          <ConnectWiseAccountCriteria />
          <ConnectWiseAccountMapping />
        </Fragment>
      )}
    </div>
  )
}
