import { memo } from "react"
import { map } from "ramda"
import { localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils"
import { isFieldDisabled } from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"

import DropdownField from "./DropdownField"

const name = "groupIds"

const Group = memo(({ onChange, values, validation, enabledFields, options, searchesMap }) => {
  return (
    <DropdownField
      {...{
        name,
        options,
        onChange,
        values,
        validation,
        isMulti: true,
        disabled: isFieldDisabled({ name, enabledFields }),
        nameToken: localizationKey("Group"),
        getValue: () =>
          map(groupId => {
            return searchesMap[groupId]?.option || { labelText: localized("N/A"), value: groupId }
          }, values[name]),
      }}
    />
  )
})

export default Group
