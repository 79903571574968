import { insert, map } from "ramda"
import { useMemo } from "react"

import { SearchableSelect } from "@ninjaone/components"

import { clipboardWriteText, localized } from "js/includes/common/utils"

/* eslint-disable no-template-curly-in-string */
const ticketSubjectPlaceholder = "${ticket.subject}"
const ticketDescriptionPlaceholder = "${ticket.description}"

const basicPlaceholders = [
  "${ticket.id}",
  "${ticket.createdDate}",
  "${ticket.device.displayName}",
  "${ticket.device.url}",
  ticketSubjectPlaceholder,
  "${ticket.status}",
  "${ticket.severity}",
  "${ticket.priority}",
  "${ticket.type}",
  "${ticket.tags}",
  "${ticket.organization.name}",
  "${ticket.organization.url}",
  "${ticket.assignee.email}",
  "${ticket.assignee.name}",
  "${ticket.assignee.firstName}",
  "${ticket.assignee.lastName}",
  "${ticket.assignee.phone}",
  "${ticket.requester.email}",
  "${ticket.requester.name}",
  "${ticket.requester.firstName}",
  "${ticket.requester.lastName}",
  "${ticket.requester.phone}",
  "${ticket.requester.organization.name}",
  "${ticket.url}",
]

const currentUserPlaceholders = [
  "${currentUser.email}",
  "${currentUser.name}",
  "${currentUser.firstName}",
  "${currentUser.lastName}",
  "${currentUser.phone}",
  "${currentUser.organization.name}",
]

const emailOnlyPlaceholders = [
  "${ticket.comments.all}",
  "${ticket.comments.latest20}",
  "${ticket.comments.latest10}",
  "${ticket.comments.latestPrivate}",
  "${ticket.comments.latestPublic}",
  "${ticket.comments.latest}",
]

const extendedPlaceholders = insert(
  basicPlaceholders.indexOf(ticketSubjectPlaceholder) + 1,
  ticketDescriptionPlaceholder,
  basicPlaceholders,
)

const mapPlaceholderOptions = map(option => ({
  value: option,
  labelText: option,
}))

const basicPlaceholderOptions = mapPlaceholderOptions(basicPlaceholders)
const currentUserPlaceholderOptions = mapPlaceholderOptions(currentUserPlaceholders)
const extendedPlaceholderOptions = mapPlaceholderOptions(extendedPlaceholders)
const emailOnlyPlaceholderOptions = mapPlaceholderOptions(emailOnlyPlaceholders)

export const Placeholders = ({
  extended = false,
  withCurrentUserPlaceholders = true,
  withEmailPlaceholders = false,
}) => {
  const placeholderOptions = useMemo(() => {
    return [
      ...(extended ? extendedPlaceholderOptions : basicPlaceholderOptions),
      ...(withCurrentUserPlaceholders ? currentUserPlaceholderOptions : []),
      ...(withEmailPlaceholders ? emailOnlyPlaceholderOptions : []),
    ]
  }, [extended, withCurrentUserPlaceholders, withEmailPlaceholders])

  return (
    <SearchableSelect
      labelText={localized("Placeholders")}
      options={placeholderOptions}
      value="__NONE__"
      onChange={placeholder => clipboardWriteText(placeholder)}
    />
  )
}
