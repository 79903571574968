import { SET_ANDROID_CRITICAL_APPS, SET_MDM_NODE_ROLES_ADE_DATA } from "js/state/actions/mdm/mdmConfiguration"

const initialState = {
  criticalApps: null,
  nodeRolesList: [],
  isAdvancedInstallerEnabled: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANDROID_CRITICAL_APPS:
      return {
        ...state,
        criticalApps: action.payload,
      }
    case SET_MDM_NODE_ROLES_ADE_DATA:
      return {
        ...state,
        nodeRolesList: action.payload.nodeRolesList,
        isAdvancedInstallerEnabled: action.payload.isAdvancedInstallerEnabled,
      }
    default:
      return state
  }
}

export default reducer
