import { PureComponent } from "react"
import { connect } from "react-redux"
import Indicator from "js/includes/components/Indicator"
import EditorPortalModal from "js/includes/components/EditorPortalModal"
import ProductMappingTable from "./ProductMappingTable"
import ProductMappingModal from "./ProductMappingModal"
import { showProductMappingModal as _showProductMappingModal } from "js/state/actions/psa/ConnectWise/mappings/products/products"
import { getTempId } from "js/state/actions/psa/ConnectWise/mappings/common"
import { Flex } from "js/includes/components/Styled"

class ProductMapping extends PureComponent {
  onAdd = () => {
    const { showProductMappingModal, productMappings } = this.props
    showProductMappingModal("ADD", getTempId(productMappings))
  }

  render() {
    const { loading, productMappingModalVisibility } = this.props
    return (
      <Flex flex={1} height="100%">
        {loading ? (
          <Indicator />
        ) : (
          <>
            <ProductMappingTable addNewMapping={this.onAdd} />
            <EditorPortalModal {...{ show: productMappingModalVisibility, className: "inmodal" }}>
              <ProductMappingModal />
            </EditorPortalModal>
          </>
        )}
      </Flex>
    )
  }
}

export default connect(
  ({ psa }) => ({
    productMappingModalVisibility: psa.ConnectWise.mappings.products.productMappingModalVisibility,
    productMappings: psa.ConnectWise.mappings.products.productMappings,
    loading: psa.editorLoading,
  }),
  {
    showProductMappingModal: _showProductMappingModal,
  },
)(ProductMapping)
