import PropTypes from "prop-types"

import { Body, Input, Modal } from "@ninjaone/components"
import InputActions from "@ninjaone/components/src/Form/InputActions"
import tokens from "@ninjaone/tokens"

import { useForm } from "js/includes/common/hooks"
import showModal from "js/includes/common/services/showModal"
import { localizationKey, localized, validations } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { Placeholders } from "js/includes/ticketing/TriggerEditor/components/Placeholders"
import { StyledContainer } from "js/includes/ticketing/TriggerEditor/components/Styled"

const EditorModal = ({ value, handleOnChange, unmount }) => {
  const { values, validation, validateForm, onChange } = useForm({
    fields: {
      subject: value ?? "",
    },
    validate: {
      subject: validations.required,
    },
  })

  function handleSave() {
    if (validateForm()) {
      handleOnChange(values.subject)
      unmount()
    }
  }

  return (
    <Modal
      titleGroup={{
        titleText: localized("Edit field"),
      }}
      unmount={unmount}
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Apply"),
          onClick: handleSave,
        },
      ]}
    >
      <Box marginBottom={tokens.spacing[2]}>
        <Input
          labelText={localized("Subject")}
          placeholder={localized("Enter subject")}
          required
          value={values.subject}
          maxLength={200}
          onChange={e => onChange("subject", e.target.value)}
          errorMessage={validation.message.subject}
        />
      </Box>
      <Placeholders />
    </Modal>
  )
}

export const TicketSubjectEditor = props => {
  const { handleOnChange, ariaLabel, value, errorMessage } = props
  const containerId = ariaLabel

  const showEditorModal = () => showModal(<EditorModal {...{ handleOnChange, value }} />)

  return (
    <StyledContainer id={containerId} ariaLabel={ariaLabel} onClick={showEditorModal} errorMessage={errorMessage}>
      <Body textWrap>{value ?? <>&nbsp;</>}</Body>
      <InputActions ariaErrorId={containerId} disableClear error={errorMessage} />
    </StyledContainer>
  )
}

TicketSubjectEditor.propTypes = {
  value: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  ariaLabel: PropTypes.string,
}
