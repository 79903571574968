import { validations } from "js/includes/common/utils"
import { AppUsersAndContactsDropdown } from "js/includes/ticketing/shared/components/AppUsersAndContactsDropdown"

export const UserEmailEditor = ({ value, handleOnChange, errorMessage, ariaLabel, ariaAttributes, ...rest }) => {
  return (
    <AppUsersAndContactsDropdown
      isMulti
      isCreatable
      shouldCreate={value => validations.email(value).success}
      value={value ?? []}
      onSelect={handleOnChange}
      {...(errorMessage && {
        errorMessage,
        validationState: "error",
      })}
      ariaAttributes={{
        ...(ariaLabel && { "aria-label": ariaLabel }),
        ...ariaAttributes,
      }}
      useSelectStyling
      keepInView={false}
      {...rest}
    />
  )
}
