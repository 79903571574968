const getInitialState = () => ({
  patchName: "",
})

const setPatchNameFilter = (state, { patchName }) => ({
  patchName,
})

export default function patchingVulnerabilities(state = getInitialState(), action) {
  switch (action.type) {
    case "SET_PATCHING_FILTER":
      return setPatchNameFilter(state, action)
    default:
      return state
  }
}
