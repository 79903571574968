import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export const MOVE_RANK_UP = {
  id: "move-rank-up",
  token: localizationKey("Up"),
}

export const MOVE_RANK_DOWN = {
  id: "move-rank-down",
  token: localizationKey("Down"),
}
