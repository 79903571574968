import { localized } from "js/includes/common/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { getSearchableDropdownTagRenderer } from "js/includes/ticketing/TriggerEditor/components/utils"
import { useSearchableDropdownValue } from "js/includes/ticketing/TriggerEditor/hooks"

const OPTION_LABEL_KEY = "name"
const OPTION_ID_KEY = "id"

const getDeletedLabel = ({ valuesMap, option }) => {
  return valuesMap[option?.[OPTION_ID_KEY]]?.deleted ? ` (${localized("Deleted")})` : ""
}

export const OrganizationEditor = ({
  isMulti: _isMulti = true,
  value: _value,
  handleOnChange,
  errorMessage,
  ariaLabel,
}) => {
  const { value, selectionMap, isMulti } = useSearchableDropdownValue({ isMulti: _isMulti, value: _value })

  const handleOnSelect = selected => {
    handleOnChange(selected)
  }

  const getOptionLabel = ({ option }) => {
    return `${option?.[OPTION_LABEL_KEY] || ""}${getDeletedLabel({ valuesMap: selectionMap, option })}`
  }

  return (
    <SearchableDropDown
      isMulti={isMulti}
      keepDropdownInView
      pageSize={50}
      endpoint="/query/organizations"
      valueSelectorKey={OPTION_ID_KEY}
      searchableKey={OPTION_LABEL_KEY}
      value={value}
      onSelect={handleOnSelect}
      getOptionLabel={() => getOptionLabel({ option: value })}
      tagRenderer={getSearchableDropdownTagRenderer({ getOptionLabel })}
      keepInView={false}
      useSelectStyling
      ariaAttributes={{
        "aria-label": ariaLabel,
      }}
      {...(errorMessage && {
        errorMessage,
        validationState: "error",
      })}
    />
  )
}
