import { IconButton } from "@ninjaone/components"
import { RemoveIcon } from "@ninjaone/icons"

const CloseRuleButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} size="lg">
      <RemoveIcon />
    </IconButton>
  )
}

export default CloseRuleButton
