import { localized, localizedF } from "@ninjaone/webapp/src/js/includes/common/utils"

import {
  FontCaseLightIcon,
  H1LightIcon,
  H2LightIcon,
  H3LightIcon,
  H4LightIcon,
  ListUlLightIcon,
  ListOlLightIcon,
  MessageQuoteLightIcon,
  CodeLightIcon,
} from "@ninjaone/icons"

export function getBlockTypeOptions() {
  return {
    paragraph: {
      label: localized("Paragraph"),
      Icon: FontCaseLightIcon,
    },
    h1: {
      label: localized("Header 1"),
      Icon: H1LightIcon,
    },
    h2: {
      label: localized("Header 2"),
      Icon: H2LightIcon,
    },
    h3: {
      label: localized("Header 3"),
      Icon: H3LightIcon,
    },
    h4: {
      label: localized("Header 4"),
      Icon: H4LightIcon,
    },
    bullet: {
      label: localized("Bulleted List"),
      Icon: ListUlLightIcon,
    },
    number: {
      label: localized("Numbered List"),
      Icon: ListOlLightIcon,
    },
    quote: {
      label: localized("Quote"),
      Icon: MessageQuoteLightIcon,
    },
    code: {
      label: localized("Code Block"),
      Icon: CodeLightIcon,
    },
  }
}

export const FONT_SIZE_OPTIONS = [
  ["12px", localizedF("12px")],
  ["14px", localizedF("14px")],
  ["16px", localizedF("16px")],
  ["18px", localizedF("18px")],
  ["20px", localizedF("20px")],
  ["22px", localizedF("22px")],
]
