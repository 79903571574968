import { memo, createContext } from "react"
import { Flex } from "js/includes/components/Styled"
import { sizer } from "@ninjaone/utils"

export const SyncGroupContext = createContext([])

export default memo(function ConnectWiseSyncSelectGroupWrapper({ children, value, onChange }) {
  return (
    <SyncGroupContext.Provider
      value={{
        selectedGroups: value,
        setSelectedGroups: onChange,
      }}
    >
      <Flex flexDirection="column" overflowY="scroll" gridGap={sizer(8)} paddingBottom={sizer(8)}>
        {children}
      </Flex>
    </SyncGroupContext.Provider>
  )
})
