import {
  filter,
  compose,
  find,
  map,
  reduce,
  pathOr,
  reject,
  isEmpty,
  pluck,
  assoc,
  min as _min,
  max as _max,
  prop,
  flatten,
  last,
} from "ramda"

const volatileDataFilter = filter(d => d && d.volatileData)
const calculateCpuSpeed = datapoints => {
  if (!datapoints?.length) return 0
  const volatileData = compose(
    last,
    filter(item => item?.usedMHz > 0),
    flatten,
    map(prop("volatileData")),
  )(datapoints)
  if (!volatileData) return 0
  const { usedMHz, usedPct } = volatileData
  const percentage = usedPct / 100
  return percentage ? ((usedMHz * 100) / percentage).toFixed(1) : 0
}

export const cpuDataParser = data => {
  if (!data) return null
  const { datapoints } = data
  const cpuSpeed = calculateCpuSpeed(datapoints)

  const _parsedData = compose(
    reduce(
      (acc, d) => {
        const { volatileData, monTime } = d
        const [usage] = volatileData
        const { usedPct } = usage
        const [usedTotal, parsedData, maxUsedPercent, minUsedPercent] = acc
        const percentage = usedPct / 100
        const newMaxUsedPercent = _max(percentage, maxUsedPercent)
        const newMinUsedPercent = _min(percentage, minUsedPercent)

        return [
          usedTotal + usedPct / 100,
          [...parsedData, { performance: [usage.usedMHz, 0], percentage, monTime }],
          newMaxUsedPercent,
          newMinUsedPercent,
          usedPct / 100,
        ]
      },
      [0, [], 0, 100, 0],
    ),
    volatileDataFilter,
  )(datapoints)

  const [sum, parsedData, max, min, latest] = _parsedData
  const percentageAverage = sum / parsedData.length || 0

  return {
    percentage: percentageAverage.toFixed(1),
    parsedData,
    max,
    min,
    latest,
    cpuSpeed,
  }
}

export const memoryDataParser = (data, memoryData) => {
  if (!data || !memoryData) return null
  const { datapoints } = data
  const memory = memoryData.datapoints[0].data

  const _parsedData = compose(
    reduce(
      (acc, d) => {
        const { volatileData, monTime } = d
        const [usage] = volatileData
        const { usedHostBytes } = usage
        const [usedTotal, parsedData, maxUsedMemory, minUsedMemory] = acc

        const newMaxUsedMemory = _max(usedHostBytes, maxUsedMemory)
        const newMinUsedMemory = _min(usedHostBytes, minUsedMemory)

        return [
          usedTotal + usedHostBytes,
          [...parsedData, { performance: [usedHostBytes, 0], monTime }],
          newMaxUsedMemory,
          newMinUsedMemory,
          usedHostBytes,
        ]
      },
      [0, [], 0, memory.memorySize, 0],
    ),
    volatileDataFilter,
  )(datapoints)

  const [sum, parsedData, max, min, latest] = _parsedData
  const averageUsedBytes = sum / parsedData.length || 0

  return {
    averageUsedBytes,
    parsedData,
    max,
    min,
    latest,
  }
}

const parseDataStore = (datapoints, vmfs_uuid) => {
  if (!datapoints) return []
  const _parsedData = compose(
    reduce(
      (acc, d) => {
        const { volatileData, monTime } = d
        const dataSpec = find(d => d.vmfs_uuid === vmfs_uuid, volatileData) || {}
        const [readSpeed, writeSpeed, parsedData, usedParsedData] = acc

        const { readSpeedKBps, writeSpeedKBps, used } = dataSpec

        const readWriteData = {
          readSpeed: readSpeedKBps,
          writeSpeed: writeSpeedKBps,
          monTime,
        }

        const usedData = {
          usedStorage: [used, 0],
          monTime,
        }

        return [
          readSpeed + dataSpec.readSpeedKBps,
          writeSpeed + dataSpec.writeSpeedKBps,
          [...parsedData, readWriteData],
          [...usedParsedData, usedData],
          dataSpec.freeSpace,
          dataSpec.used,
        ]
      },
      [0, 0, [], [], 0, 0],
    ),
    volatileDataFilter,
  )(datapoints)

  const [readSpeedTotal, writeSpeedTotal, parsedData, usedParsedData, freeSpace, usedSpace] = _parsedData
  const readSpeed = readSpeedTotal / parsedData.length || 0
  const writeSpeed = writeSpeedTotal / parsedData.length || 0

  return {
    parsedData,
    usedParsedData,
    readSpeed,
    writeSpeed,
    freeSpace,
    usedSpace,
  }
}

export const datastoreDataParser = data => {
  if (!data) return []
  const { datapoints } = data

  const [datastoreInfo] = datapoints
  const { staticData } = datastoreInfo

  return map(datastore => {
    const data = parseDataStore(datapoints, datastore.vmfs_uuid)

    return {
      ...datastore,
      ...data,
    }
  }, staticData)
}

export const healthSensorsDataParser = healthSensorData => {
  if (!healthSensorData) return []
  const { datapoints } = healthSensorData
  const [sensorDatapoints] = datapoints
  const { data } = sensorDatapoints
  return data
}

const parseIntefaceData = (datapoints, device) => {
  const data = reduce(
    (acc, datapoint) => {
      const { volatileData, monTime } = datapoint
      if (volatileData) {
        const point = find(i => i.device === device, volatileData)

        if (point && point.device === device) {
          const { sendBps, receiveBps } = point
          const [send, receive, parsedData, maxSent, maxReceived, minSent, minReceived] = acc

          const currentMaxSent = _max(maxSent, sendBps)
          const currentMaxReceived = _max(maxReceived, receiveBps)
          const currentMinSent = _min(minSent, sendBps)
          const currentMinReceived = _min(minReceived, sendBps)

          return [
            send + sendBps,
            receive + receiveBps,
            [...parsedData, { sendBps: [sendBps, 0], receiveBps: [receiveBps, 0], monTime }],
            currentMaxSent,
            currentMaxReceived,
            currentMinSent,
            currentMinReceived,
            sendBps,
            receiveBps,
          ]
        }

        return acc
      }
      return acc
    },
    [0, 0, [], 0, 0, 0, 0, 0, 0],
    datapoints,
  )

  const [
    sendTotal,
    receiveTotal,
    parsedData,
    maxSent,
    maxReceived,
    minSent,
    minReceived,
    latestSent,
    latestReceived,
  ] = data
  const sendSpeed = sendTotal / parsedData.length || 0
  const receiveSpeed = receiveTotal / parsedData.length || 0

  return {
    sendSpeed,
    receiveSpeed,
    parsedData,
    maxSent,
    maxReceived,
    minSent,
    minReceived,
    latestSent,
    latestReceived,
  }
}

export const networkDataParser = networkData => {
  if (!networkData) return []
  const { datapoints } = networkData
  const [interfaces] = datapoints
  const { staticData } = interfaces

  return map(interfaceData => {
    const { device } = interfaceData
    const data = parseIntefaceData(datapoints, device)
    return {
      ...interfaceData,
      ...data,
    }
  }, staticData)
}

export const licenceDataParser = licenseData => {
  if (!licenseData) return []
  const { datapoints } = licenseData
  return reduce(
    (acc, d) => {
      const { data } = d
      const licenses = map(value => value.licenseKey, data)
      return [...acc, ...licenses]
    },
    [],
    datapoints,
  )
}

export const snapshotDataParser = snapshotData => {
  const data = pathOr([], ["datapoints", 0, "data"], snapshotData)
  const parentIds = compose(reject(isEmpty), pluck("parent_id"))(data)
  return data.map(snapshot => assoc("children", parentIds.includes(snapshot.id), snapshot))
}
