const initialState = {
  loading: false,
  data: null,
}

export default function connection(state = initialState, action) {
  switch (action.type) {
    case "SET_FRESHSERVICE_LOADING_DATA":
      return { ...state, loading: action.payload }
    case "SET_FRESHSERVICE_CONNECTION":
      return { ...state, data: action.payload }
    case "RESET_FRESHSERVICE_CONNECTION":
      return initialState
    default:
      return state
  }
}
