import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import visibility from "js/state/reducers/common/visibility"
import loading from "js/state/reducers/common/loading"
import connectionTestResult from "./connectionTestResult"
import ConnectWise from "./ConnectWise/rootReducer"
import Autotask from "./Autotask/rootReducer"
import Accelo from "./Accelo/rootReducer"
import GenericWebhook from "./GenericWebhook/rootReducer"
import ServiceNow from "./ServiceNow/rootReducer"
import Freshservice from "./Freshservice/rootReducer"
import Zendesk from "./Zendesk/rootReducer"
import ticketTemplates from "./ticketTemplates"
import ticketSetupValues from "./ticketSetupValues"
import defaultTicketTemplateId from "./defaultTicketTemplateId"
import ticketTemplateEditorValues from "./ticketTemplateEditorValues"
import ticketTemplate from "./ticketTemplate"

const psaReducer = combineReducers({
  Autotask,
  ConnectWise,
  Accelo,
  ServiceNow,
  Freshservice,
  Zendesk,
  GenericWebhook,
  connectionTestResult,
  defaultTicketTemplateId,
  ticketTemplate,
  ticketTemplateEditorValues,
  ticketTemplates,
  ticketSetupValues,
  editorLoading: createNamedWrapperReducer(loading, "PSASettingsEditor", false),
  editorSaving: createNamedWrapperReducer(loading, "PSASettingsEditorSaving", false),
  connectionModalVisibility: createNamedWrapperReducer(visibility, "PSAConnectionModal", false),
  connectionModalLoading: createNamedWrapperReducer(loading, "PSAConnectionModal", false),
  defaultTicketTemplateModalVisibility: createNamedWrapperReducer(visibility, "PSADefaultTicketTemplateModal", false),
  ticketTemplateModalVisibility: createNamedWrapperReducer(visibility, "PSATicketTemplateModal", false),
  ticketTemplateModalLoading: createNamedWrapperReducer(loading, "PSATicketTemplateModal", false),
})

export default function rootReducer(state, action) {
  if (action.type === "DELETE_PSA") {
    window.psaConnect.set("psaType", null)
    state = undefined
  }

  return psaReducer(state, action)
}
