import { useCallback } from "react"

import {
  AppUsersAndContactsDropdown,
  valueSelectorKey as usersDropdownSelectorKey,
} from "js/includes/ticketing/shared/components/AppUsersAndContactsDropdown"
import { useSearchableDropdownValue } from "js/includes/ticketing/TriggerEditor/hooks"

export const UserEditor = ({
  value: _value,
  handleOnChange,
  valueSelectorKey = usersDropdownSelectorKey,
  metaData,
  isMulti: _isMulti,
  errorMessage,
  ariaLabel,
  ...rest
}) => {
  const { value, isMulti } = useSearchableDropdownValue({ isMulti: _isMulti, value: _value })

  const handleOnSelect = useCallback(
    selected => {
      if (Array.isArray(selected)) {
        handleOnChange(!selected.length ? null : selected)
      } else {
        handleOnChange(!selected ? null : selected)
      }
    },
    [handleOnChange],
  )

  return (
    <AppUsersAndContactsDropdown
      // TODO remove key when dropdown does not crash when going from {isMulti: false, value: {}} to {isMulti: true, value: []}
      key={`${isMulti}`}
      value={value}
      onSelect={handleOnSelect}
      isMulti={isMulti}
      useSelectStyling
      {...(errorMessage && {
        errorMessage,
        validationState: "error",
      })}
      ariaAttributes={{
        "aria-label": ariaLabel,
      }}
      keepInView={false}
      {...rest}
    />
  )
}
