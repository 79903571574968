import { useMemo } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import { Root } from "@radix-ui/react-radio-group"
import { v4 as uuidv4 } from "uuid"
import tokens from "@ninjaone/tokens"
import RadioOption from "./RadioOption"
import { variants } from "./constants"
import RadioCardOption from "./RadioCardOption"
import { shouldIgnoreProps } from "./utils"
import { Label } from "../Form/Label"

const StyledRoot = styled(Root, {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  display: flex;
  gap: ${tokens.spacing[3]};
  flex-direction: ${({ orientation }) => (orientation === "horizontal" ? "row" : "column")};

  ${({ labelText }) => labelText && `margin-top: ${tokens.spacing[3]}`}
`
function RadioGroup({
  ariaLabel,
  defaultValue,
  labelText,
  onValueChange,
  options,
  orientation = "vertical",
  required,
  size = "md",
  tooltipText,
  value: controlledValue,
  variant = "default",
}) {
  const ariaId = useMemo(() => uuidv4(), [])
  const descriptionMode = options.find(option => option?.description)
  const ariaProps = labelText ? { "aria-labelledby": ariaId } : { "aria-label": ariaLabel }

  const disabledAll = options.every(option => option.disabled)

  return (
    <>
      {labelText && (
        <Label id={ariaId} forInputElement={false} {...{ disabled: disabledAll, labelText, required, tooltipText }} />
      )}

      <StyledRoot
        {...{
          labelText,
          orientation,
          defaultValue,
          ...ariaProps,
          "aria-required": !disabledAll && required,
          onValueChange,
          ...(controlledValue && { value: controlledValue }),
        }}
      >
        {options.map(option => {
          const { disabled, value, description, label, extraContentRenderer, image } = option

          const commonOptionProps = {
            value,
            label,
            disabled: disabledAll || disabled,
            key: value,
            description,
            descriptionMode,
            extraContentRenderer,
          }

          switch (variant) {
            case variants.CARD:
              return <RadioCardOption {...{ ...commonOptionProps, image, size }} />

            default:
              return <RadioOption {...commonOptionProps} />
          }
        })}
      </StyledRoot>
    </>
  )
}

export default RadioGroup

RadioGroup.propTypes = {
  /**
   * The text for the label of the radio button group.
   */
  labelText: PropTypes.string,
  /**
   * The ARIA label for accessibility.
   */
  ariaLabel: PropTypes.string,
  /**
   * The default value of the radio button.
   */
  defaultValue: PropTypes.string,
  /**
   * The controlled value of the radio item to check.
   */
  value: PropTypes.string,
  /**
   * Callback function to be called when the value of the radio button changes.
   */
  onValueChange: PropTypes.func,
  /**
   * The orientation of the radio buttons. Must be either "horizontal" or "vertical".
   */
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  /**
   * An array of options for the radio button group.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      description: PropTypes.string,
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      extraContentRenderer: PropTypes.func,
      image: PropTypes.string,
    }),
  ).isRequired,
  /**
   * The component style variant.
   */
  variant: PropTypes.oneOf(["default", "card"]),
  /**
   * The size of the card variant.
   */
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  /**
   * The required state for the component.
   */
  required: PropTypes.bool,
  /**
   * The text for the Tooltip in the Label of the the component.
   */
  tooltipText: PropTypes.string,
}
