/** @jsxImportSource @emotion/react */
import { connect } from "react-redux"
import { Form, FormGroup, Col, ControlLabel, FormControl } from "react-bootstrap"
import { css } from "@emotion/react"
import Select from "react-select"
import { filter, propEq } from "ramda"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import {
  addProductMapping as _addProductMapping,
  updateProductMapping as _updateProductMapping,
  closeProductMappingModal as _closeProductMappingModal,
  setProductMappingName as _setProductMappingName,
  changeProductMapping as _changeProductMapping,
} from "js/state/actions/psa/ConnectWise/mappings/products/products"
import { useForm } from "js/includes/common/hooks"
import { StyledModal } from "js/includes/components/Styled/Form"

import NodeRoleProductMappings from "./NodeRoleProductMappings"

const labelStyle = css`
  text-align: left;
`

const ProductMappingModal = ({
  currentProductMapping,
  productMappings,
  addProductMapping,
  updateProductMapping,
  closeProductMappingModal,
  setProductMappingName,
  changeProductMapping,
  mode,
}) => {
  const {
    values: { name },
    validation,
    onChange,
    validateForm,
  } = useForm({
    fields: {
      name: currentProductMapping.name,
    },
    validate: {
      name: validateName,
    },
  })

  const mappingOptions = [
    { value: "MULTIPLE_HIT", label: localized("Map device to all matching mappings") },
    { value: "SINGLE_HIT", label: localized("Map device to first mapping only") },
  ]

  function handleInput(event) {
    setProductMappingName(event.target.value)
    onChange("name", event.target.value)
  }

  async function handleSave(e) {
    e.preventDefault()
    e.stopPropagation()
    if (validateForm()) {
      if (mode === "ADD") {
        await addProductMapping(currentProductMapping)
      } else {
        await updateProductMapping(currentProductMapping)
      }
      closeProductMappingModal()
    }
  }

  function validateName(name) {
    const notPresent = !name.trim() && localized("Required")
    const tooLong = name.length > 50 && localized("Max length for name is 50 chars")
    const taken =
      nameTaken(productMappings, name.trim(), currentProductMapping.id) &&
      localized("Product mapping name already taken")
    return {
      success: !notPresent && !tooLong && !taken,
      message: notPresent || tooLong || taken,
    }
  }

  function nameTaken(productMappings, name, id) {
    const pm = productMappings.find(pm => pm.name === name)
    return pm && pm.id !== id
  }

  function handleMapTypeChange(option) {
    const { value } = option
    changeProductMapping({
      ...currentProductMapping,
      productMappingType: value,
    })
  }

  function getMappingValue() {
    return filter(propEq("value", currentProductMapping.productMappingType), mappingOptions)
  }

  return (
    <Form onSubmit={handleSave}>
      <StyledModal
        submittable
        title={localizationKey("Product Mapping")}
        close={closeProductMappingModal}
        closeText={localizationKey("Close")}
        saveText={localizationKey("OK")}
        showCloseButton={false}
        dialogClassName="widest-modal psa-modal"
        height="580px !important"
        maxHeight="580px !important"
      >
        <div className="display-flex flex-column">
          <FormGroup validationState={validation.message.name ? "error" : null}>
            <Col componentClass={ControlLabel} xs={4} css={labelStyle}>
              <span>{localized("Name")}</span>
              <sup> *</sup>
            </Col>
            <Col xs={8}>
              <FormControl
                autoFocus={true}
                type="text"
                placeholder={localized("Name")}
                value={name}
                onChange={handleInput}
                maxLength="50"
              />
              {validation.message.name && <em className="invalid">{validation.message.name}</em>}
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} xs={4} css={labelStyle}>
              <span>{localized("Mapping Type")}</span>
            </Col>
            <Col xs={8}>
              <Select
                id="mappingTypeSelect"
                options={mappingOptions}
                onChange={handleMapTypeChange}
                value={getMappingValue()}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} xs={12} css={labelStyle}>
              {localized("Mappings")}
            </Col>
            <Col xs={12}>
              <NodeRoleProductMappings />
            </Col>
          </FormGroup>
        </div>
      </StyledModal>
    </Form>
  )
}

export default connect(
  ({ psa }) => ({
    mode: psa.ConnectWise.mappings.products.productModalEditorMode,
    productMappings: psa.ConnectWise.mappings.products.productMappings,
    currentProductMapping: psa.ConnectWise.mappings.products.currentProductMapping,
  }),
  {
    addProductMapping: _addProductMapping,
    updateProductMapping: _updateProductMapping,
    closeProductMappingModal: _closeProductMappingModal,
    setProductMappingName: _setProductMappingName,
    changeProductMapping: _changeProductMapping,
  },
)(ProductMappingModal)
