import PropTypes from "prop-types"
import { memo } from "react"

import tokens from "@ninjaone/tokens"
import { Text, InfoTooltip } from "@ninjaone/components"

import { localized } from "js/includes/common/utils"
import { StyledSpan } from "js/includes/components/Styled"

const FieldLabel = memo(({ token, mandatory = false, infoTooltipToken }) => (
  <StyledSpan display="inline-block" marginBottom={tokens.spacing[1]}>
    <Text color="colorTextStrong" type="body">
      {localized(token)}
      {mandatory && <span>*</span>}
    </Text>
    {infoTooltipToken && <InfoTooltip token={infoTooltipToken} />}
  </StyledSpan>
))

FieldLabel.propTypes = {
  token: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
}

export default FieldLabel
