import { memo } from "react"
import PropTypes from "prop-types"
import { map, prop } from "ramda"
import tokens from "@ninjaone/tokens"
import { Select } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"
import {
  contentFieldPropTypes,
  optionsType,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import { Box } from "js/includes/components/Styled"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

import FieldLabel from "./FieldLabel"

const DropdownField = memo(
  ({
    onChange,
    values,
    validation,
    disabled = false,
    options,
    name,
    nameToken,
    isMulti = false,
    marginTop = tokens.spacing[3],
    mandatory = false,
    getValue,
  }) => {
    const labelId = `psa-product-${name}-label`
    const errorMessage = validation?.message?.[name]
    const value = getValue?.() ?? values[name]

    const onSelect = option => {
      if (isMulti) {
        return onChange(name, map(prop("value"))(option))
      }

      onChange(name, option)
    }

    const select = isMulti ? (
      <Box>
        {nameToken && <FieldLabel token={nameToken} mandatory={mandatory} />}
        <SearchableDropDown
          openOnFocus
          width="100%"
          useSelectStyling
          searchableKey="labelText"
          options={options}
          keepInView={false}
          isMulti={isMulti}
          keepDropdownInView={true}
          value={value}
          onSelect={onSelect}
          disabled={disabled}
          validationState={!!errorMessage ? "error" : ""}
          ariaAttributes={{ "aria-labelledby": labelId }}
        />
      </Box>
    ) : (
      <Select
        labelId={labelId}
        options={options}
        value={value || null}
        onChange={onSelect}
        disabled={disabled}
        errorMessage={errorMessage}
        labelText={`${localized(nameToken)}${mandatory ? "*" : ""}`}
        triggerAriaLabel={localized(nameToken)}
      />
    )

    return <Box marginTop={marginTop}>{select}</Box>
  },
)

DropdownField.propTypes = {
  ...contentFieldPropTypes,
  isMulti: PropTypes.bool,
  name: PropTypes.string.isRequired,
  nameToken: PropTypes.string.isRequired,
  marginBottom: PropTypes.number,
  options: optionsType.isRequired,
  getValue: PropTypes.func,
}

export default DropdownField
