import { PureComponent } from "react"
import { reduce, any, equals, pluck, map, omit } from "ramda"
import { connect } from "react-redux"
import { sort as _sort } from "js/state/actions/common/tableSorting"
import {
  autoMap as _autoMap,
  openAccountOrganizationModal as _openAccountOrganizationModal,
  resetMapping as _resetMapping,
  refetchCWAccountOptions as _refetchCWAccountOptions,
  setSelectedAccountMappings as _setSelectedAccountMappings,
} from "js/state/actions/psa/ConnectWise/mappings/accounts"
import { clearCWAccountOrganizationSites as _clearCWAccountOrganizationSites } from "js/state/actions/psa/ConnectWise/mappings/accounts/locations"
import Portal from "js/includes/components/Portal"
import AccountMappingTable from "./AccountMappingTable"
import AccountMappingModal from "./AccountMappingModal"
import AccountAutoMapModal from "./AccountAutoMapModal"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { localized, localizationKey, sortTableList } from "js/includes/common/utils"

const parseOrgData = reduce((acc, org) => {
  const { locationSiteMappings } = org
  const areLocationsMapped = any(equals(true), pluck("mapped", locationSiteMappings))
  let previousMappingClientName = null

  const mappedList = areLocationsMapped
    ? map(locMapping => {
        const {
          productMappingName: orgProductMappingName,
          configurationTypeMappingName: orgConfigurationTypeMappingName,
        } = org

        const hideFirstColumn = org.clientName === previousMappingClientName
        previousMappingClientName = org.clientName

        return {
          ...org,
          ...omit(["clientId"], locMapping),
          data: org,
          areLocationsMapped,
          orgProductMappingName,
          orgConfigurationTypeMappingName,
          hideFirstColumn,
        }
      }, locationSiteMappings)
    : [
        {
          ...org,
          productMappingType: "NONE",
          configurationTypeMappingType: "NONE",
          data: org,
          areLocationsMapped,
        },
      ]

  return [...acc, ...mappedList]
}, [])

class AccountMapping extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      organizationData: [],
    }
  }

  componentDidMount() {
    this.props.clearCWAccountOrganizationSites()
    this.sortTableData()
  }

  sortTableData = () => {
    const { sortBy, sortDirection, accountOrganizationMappings } = this.props
    const parsedData = parseOrgData(accountOrganizationMappings)

    this.setState({
      organizationData: sortTableList(parsedData, sortBy, sortDirection),
    })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.sortBy !== this.props.sortBy ||
      prevProps.sortDirection !== this.props.sortDirection ||
      prevProps.accountOrganizationMappings !== this.props.accountOrganizationMappings
    ) {
      this.sortTableData()
    }
  }

  render() {
    const {
      accountModalVisibility,
      accountAutoMapModalVisibility,
      autoMap,
      openAccountOrganizationModal,
      refetchCWAccountOptions,
      resetMapping,
      sort,
      sortBy,
      sortDirection,
      setSelectedAccountMappings,
      selectedAccountMappings,
    } = this.props

    const { organizationData } = this.state

    async function confirmResetMapping() {
      const buttonPressed = await ShowMessageDialog({
        icon: { icon: faEdit, type: "critical" },
        title: localizationKey(
          "All ConnectWise Company-mapping data for the selected Organizations will be permanently deleted",
        ),
        message: localizationKey("Are you sure you want to delete this data."),
        buttons: [
          { id: "CANCEL", label: localizationKey("Cancel") },
          { id: "CLEAR_MAPPING", label: localizationKey("Clear"), type: "critical" },
        ],
      })
      if (buttonPressed === "CLEAR_MAPPING") {
        resetMapping()
      }
    }

    return (
      <div id="psa-cw-account-mapping">
        <div className="title display-flex">
          <h4 className="p-l">{localized("Company Mapping")}</h4>
        </div>
        <div className="display-flex flex-full">
          <AccountMappingTable
            {...{
              sort,
              sortBy,
              sortDirection,
              organizationData,
              openAccountOrganizationModal,
              setSelectedAccountMappings,
              selectedAccountMappings,
              autoMap,
              confirmResetMapping,
              refetchCWAccountOptions,
            }}
          />
        </div>
        {accountAutoMapModalVisibility && (
          <Portal>
            <AccountAutoMapModal />
          </Portal>
        )}
        {accountModalVisibility && (
          <Portal>
            <AccountMappingModal />
          </Portal>
        )}
      </div>
    )
  }
}

export default connect(
  ({ psa }) => ({
    accountAutoMapPreview: psa.ConnectWise.mappings.accounts.accountAutoMapPreview,
    accountOrganizationMappings: psa.ConnectWise.mappings.accounts.accountOrganizationMappings,
    sortBy: psa.ConnectWise.mappings.accounts.accountOrganizationMappingSort.sortBy,
    sortDirection: psa.ConnectWise.mappings.accounts.accountOrganizationMappingSort.sortDirection,
    accountModalVisibility: psa.ConnectWise.mappings.accounts.accountOrganizationMappingModalVisibility,
    accountAutoMapModalVisibility: psa.ConnectWise.mappings.accounts.accountAutoMapModalVisibility,
    accountMapModalLoading: psa.ConnectWise.mappings.accounts.accountMapModalLoading,
    selectedAccountMappings: psa.ConnectWise.mappings.accounts.selectedAccountMappings,
  }),
  {
    autoMap: _autoMap,
    openAccountOrganizationModal: _openAccountOrganizationModal,
    refetchCWAccountOptions: _refetchCWAccountOptions,
    resetMapping: _resetMapping,
    sort: _sort("CWAccountOrganizationMappingSort"),
    setSelectedAccountMappings: _setSelectedAccountMappings,
    clearCWAccountOrganizationSites: _clearCWAccountOrganizationSites,
  },
)(AccountMapping)
