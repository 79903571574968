import PropTypes from "prop-types"

import { Input, Switch, TextArea } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"

import { localized } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"

import { LoadingWrapper } from "./LoadingWrapper"

const INPUT_MAX_LENGTH = 50
const TEXT_AREA_MAX_LENGTH = 250

export const General = ({
  isLoading,
  values,
  validation,
  onChange,
  disabled,
  displayDisabled = false,
  disabledTooltip,
}) => {
  return (
    <LoadingWrapper {...{ isLoading }}>
      {displayDisabled && (
        <Box marginBottom={tokens.spacing[2]}>
          <Switch
            tooltip={disabledTooltip}
            checked={!!values.enabled}
            onChange={checked => onChange("enabled", checked)}
            labelText={values.enabled ? localized("Enabled") : localized("Disabled")}
          />
        </Box>
      )}

      <Box marginBottom={tokens.spacing[2]}>
        <Input
          {...{
            labelText: localized("Name"),
            placeholder: localized("Enter name"),
            required: true,
            errorMessage: validation.message.name || "",
            value: values.name,
            onChange: e => onChange("name", e.target.value),
            maxLength: INPUT_MAX_LENGTH,
            disabled,
          }}
        />
      </Box>

      <Box marginBottom={tokens.spacing[2]}>
        <TextArea
          {...{
            labelText: localized("Description"),
            placeholder: localized("Enter description"),
            value: values.description || "",
            onChange: e => onChange("description", e.target.value),
            maxLength: TEXT_AREA_MAX_LENGTH,
            resize: "none",
            disabled,
          }}
        />
      </Box>
    </LoadingWrapper>
  )
}

General.propTypes = {
  isLoading: PropTypes.bool,
  displayDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  validation: PropTypes.shape({
    success: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
  }),
  disabledTooltip: PropTypes.string,
}
