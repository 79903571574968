import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { localized } from "js/includes/common/utils"
import deviceDataSyncAttributeActions from "js/state/actions/psa/ConnectWise/deviceSyncAttributes"
import Loading from "js/includes/components/Loading"
import { configurationQuestions, requiredSyncFields } from "./defaultAttributes"
import ConnectWiseSyncSelectGroup from "./ConnectWiseSyncSelectGroup"
import ConnectWiseSyncSelectGroupWrapper from "./ConnectWiseSyncSelectGroupWrapper"

class DeviceDataSyncAttributes extends PureComponent {
  render() {
    const { deviceDataSyncAttributes, onDeviceSyncAttributesChange, editorLoading } = this.props
    return editorLoading ? (
      <Loading />
    ) : (
      <ConnectWiseSyncSelectGroupWrapper value={deviceDataSyncAttributes} onChange={onDeviceSyncAttributesChange}>
        <ConnectWiseSyncSelectGroup titleText={localized("Configuration Attributes")} items={requiredSyncFields()} />
        <ConnectWiseSyncSelectGroup titleText={localized("Configuration Questions")} items={configurationQuestions()} />
      </ConnectWiseSyncSelectGroupWrapper>
    )
  }
}

export default connect(
  ({
    psa: {
      ConnectWise: {
        settings: { deviceDataSyncAttributes },
      },
      editorLoading,
    },
  }) => ({
    deviceDataSyncAttributes,
    editorLoading,
  }),
  dispatch => deviceDataSyncAttributeActions(dispatch),
)(DeviceDataSyncAttributes)
