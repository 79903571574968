import React, { PureComponent } from "react"
import { connect } from "react-redux"
import Table from "js/includes/components/Table"
import { noRowsRenderer, headerRenderer } from "js/includes/common/utils/react-virtualized"
import { localized, localizationKey, localizeMappingName } from "js/includes/common/utils"
import {
  updateMapping as _updateMapping,
  removeMapping as _removeMapping,
  setCurrentMapping as _setCurrentMapping,
  showModal as _showModal,
} from "js/state/actions/psa/ConnectWise/mappings/configurations/nodeRoleConfigurationTypeMappings"
import { getNodeRoleConfigurationTypeMappingList } from "js/state/selectors/psa/ConnectWise/mappings/configurations"
import { sort as _sort } from "js/state/actions/common/tableSorting"
import { faTrash } from "@fortawesome/pro-solid-svg-icons"
import ShowMessageDialog from "js/includes/components/MessageDialog"

class NodeRoleConfigurationTypeMappingsTable extends PureComponent {
  onEdit = (e, data) => {
    e.stopPropagation()
    e.preventDefault()
    this.props.setCurrentMapping(data)
    this.props.showModal(localizationKey("Edit Mapping"), "EDIT", data.id)
  }

  onDelete = async (e, id) => {
    e.stopPropagation()
    e.preventDefault()
    const buttonPressed = await ShowMessageDialog({
      icon: { icon: faTrash, type: "critical" },
      title: localizationKey("Delete Mapping"),
      message: localizationKey("Are you sure you want to delete this mapping?"),
      buttons: [
        { id: true, label: localizationKey("Yes"), type: "critical" },
        { id: false, label: localizationKey("No") },
      ],
    })
    if (buttonPressed) {
      await this.props.removeMapping(id)
    }
  }

  render() {
    const {
      props: { mappings, sort, sortBy, sortDirection, nodeRoleList },
    } = this

    return (
      <Table
        {...{
          list: mappings,
          sort,
          sortBy,
          sortDirection,
          onRowClick: e => {
            const { event, rowData } = e
            this.onEdit(event, rowData)
          },
          noRowsRenderer: noRowsRenderer(localizationKey("No Mappings"), false, "text-align-left col-xs-3"),
          columns: [
            {
              headerRenderer,
              dataKey: "nodeRoleName",
              label: localizationKey("Device Role"),
              width: 1,
              flexGrow: 1,
              cellRenderer: ({ rowData }) => {
                const role = nodeRoleList.find(role => role.id === rowData.nodeRoleId)
                const { custom, name } = role
                return custom ? name : localizeMappingName(name)
              },
            },
            {
              headerRenderer,
              dataKey: "configurationTypeName",
              label: localizationKey("Configuration Type"),
              width: 1,
              flexGrow: 1,
            },
            {
              headerRenderer,
              dataKey: "onCreateStatusName",
              label: localizationKey("On Create"),
              width: 1,
              flexGrow: 0.5,
            },
            {
              headerRenderer,
              dataKey: "onDeleteStatusName",
              label: localizationKey("On Delete"),
              width: 1,
              flexGrow: 0.5,
            },
            {
              dataKey: "",
              width: 1,
              flexGrow: 0.5,
              cellRenderer: ({ rowData }) => (
                <div className="action-links text-align-left">
                  <button
                    className="btn-link"
                    onClick={e => {
                      this.onEdit(e, rowData)
                    }}
                  >
                    {localized("Edit")}
                  </button>
                  <button
                    className="btn-link text-danger"
                    onClick={e => {
                      this.onDelete(e, rowData.id)
                    }}
                  >
                    {localized("Delete")}
                  </button>
                </div>
              ),
            },
          ],
        }}
      />
    )
  }
}

export default connect(
  ({ psa }) => ({
    mappings: getNodeRoleConfigurationTypeMappingList(psa.ConnectWise.mappings),
    sortBy: psa.ConnectWise.mappings.configurations.nodeRoleConfigurationTypeMappingsSort.sortBy,
    sortDirection: psa.ConnectWise.mappings.configurations.nodeRoleConfigurationTypeMappingsSort.sortDirection,
    nodeRoleList: psa.ConnectWise.mappings.nodeRoleList,
  }),
  {
    sort: _sort("CWNodeRoleConfigurationTypeMappingsSort"),
    updateMapping: _updateMapping,
    removeMapping: _removeMapping,
    setCurrentMapping: _setCurrentMapping,
    showModal: _showModal,
  },
)(NodeRoleConfigurationTypeMappingsTable)
