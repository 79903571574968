import React from "react"
import { connect } from "react-redux"
import { reject } from "ramda"
import { Box } from "js/includes/components/Styled"
import { Checkbox } from "js/includes/components/NinjaReactICheck"
import { setDeviceDataSyncAttributes } from "js/state/actions/psa/Autotask/settings"
import { StyledSpan } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"

const addAttribute = (attribute, list) => [...list, attribute]
const removeAttribute = (attribute, list) => reject(a => a === attribute, list)

const AutotaskSyncAttributes = ({ deviceDataSyncAttributes, setDeviceDataSyncAttributes, syncAttributes }) => {
  const allSelected = deviceDataSyncAttributes?.length === syncAttributes.length

  return (
    <Box marginLeft={4} overflowY="auto">
      <Box marginBottom={4}>
        <Checkbox
          checked={allSelected}
          checkboxClass="icheckbox_square-blue"
          onChange={() => {
            const attributes = allSelected ? [] : syncAttributes
            setDeviceDataSyncAttributes(attributes)
          }}
        />
        <StyledSpan marginLeft={2} fontWeight={600} color="black">
          {allSelected ? localized("general.unselectAll") : localized("general.selectAll")}
        </StyledSpan>
      </Box>

      {syncAttributes.map(attribute => {
        const checked = deviceDataSyncAttributes.includes(attribute)

        // TODO: Dynamic Token
        return (
          <Box key={attribute} marginBottom={4}>
            <Checkbox
              checkboxClass="icheckbox_square-blue"
              onChange={() => {
                const attributes = checked
                  ? removeAttribute(attribute, deviceDataSyncAttributes)
                  : addAttribute(attribute, deviceDataSyncAttributes)

                setDeviceDataSyncAttributes(attributes)
              }}
              {...{ checked }}
            />

            <StyledSpan marginLeft={2} color="ninjaMedium">
              {localized(`configuration.psa.${attribute}`)}
            </StyledSpan>
          </Box>
        )
      })}
    </Box>
  )
}

export default connect(
  ({ psa }) => ({
    deviceDataSyncAttributes: psa.Autotask.settings.deviceDataSyncAttributes,
    syncAttributes: psa.Autotask.settings.availableDeviceDataSyncAttributes,
  }),
  {
    setDeviceDataSyncAttributes,
  },
)(AutotaskSyncAttributes)
