import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { values } from "ramda"
import { noRowsRenderer, headerRenderer } from "js/includes/common/utils/react-virtualized"
import {
  setCurrentConfigurationTypeMapping as _setCurrentConfigurationTypeMapping,
  showConfigurationTypeMappingModal as _showConfigurationTypeMappingModal,
  removeConfigurationTypeMapping as _removeConfigurationTypeMapping,
  copyConfigurationTypeMapping as _copyConfigurationTypeMapping,
} from "js/state/actions/psa/ConnectWise/mappings/configurations/configurations"
import { getConfigurationTypeMappingList } from "js/state/selectors/psa/ConnectWise/mappings/configurations"
import { sort as _sort } from "js/state/actions/common/tableSorting"
import { faTrash } from "@fortawesome/pro-solid-svg-icons"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import SelectableKeyboardShortcutsTable from "js/includes/components/SelectableKeyboardShortcutsTable"
import { getTempId } from "js/state/actions/psa/ConnectWise/mappings/common"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

import { ADD_MAPPING, EDIT_MAPPING, DELETE_MAPPING, COPY_MAPPING } from "./actions"

const columns = () => [
  {
    headerRenderer,
    dataKey: "name",
    label: localizationKey("Name"),
    headerClassName: "col-xs-4",
    width: 1,
    flexGrow: 1,
  },
]

class ConfigurationTypeMappingTable extends PureComponent {
  state = {
    selectedRows: [],
  }

  DEFAULT_HEIGHT = 35

  onEdit = data => {
    this.props.setCurrentConfigurationTypeMapping(data)
    this.props.showConfigurationTypeMappingModal("EDIT", data.id)
  }

  onDelete = async id => {
    const buttonPressed = await ShowMessageDialog({
      icon: { icon: faTrash, type: "critical" },
      title: localizationKey("Delete Configuration Type Mapping"),
      message: localizationKey("Are you sure you want to delete this configuration type mapping?"),
      buttons: [
        { id: "YES", label: localizationKey("Yes"), type: "critical" },
        { id: "NO", label: localizationKey("No") },
      ],
    })
    if (buttonPressed === "YES") {
      await this.props.removeConfigurationTypeMapping(id)
    }
  }

  onCopy = data => {
    const { copyConfigurationTypeMapping, configurationTypeMappings, setCurrentConfigurationTypeMapping } = this.props
    const payload = {
      ...data,
      name: `${data.name} (${localized("Copy")})`,
      id: getTempId(configurationTypeMappings),
    }

    setCurrentConfigurationTypeMapping(payload)
    copyConfigurationTypeMapping(payload)
  }

  getActions = () => {
    const { selectedRows } = this.state

    return [ADD_MAPPING, ...(selectedRows.length === 1 ? [EDIT_MAPPING, DELETE_MAPPING, COPY_MAPPING] : [])]
  }

  onAction = action => {
    const { selectedRows } = this.state
    const { id } = action

    const [selectedRow] = values(selectedRows.rows)

    switch (id) {
      case ADD_MAPPING.id:
        return this.props.addNewMapping()
      case EDIT_MAPPING.id:
        return this.onEdit(selectedRow)
      case DELETE_MAPPING.id:
        return this.onDelete(selectedRow.id)
      case COPY_MAPPING.id:
        return this.onCopy(selectedRow)
      default:
        throw new Error(`Invalid action: ${action.id}`)
    }
  }

  render() {
    const { configurationTypeMappings, sort, sortBy, sortDirection } = this.props
    return (
      <SelectableKeyboardShortcutsTable
        {...{
          sort,
          sortBy,
          sortDirection,
          columns,
          data: configurationTypeMappings,
          searchable: ["name"],
          actions: this.getActions(),
          onAction: this.onAction,
          headerHeight: this.DEFAULT_HEIGHT,
          rowRenderer: this.rowRenderer,
          rowHeight: this.DEFAULT_HEIGHT,
          onCheck: ({ selected }) => this.setState({ selectedRows: selected }),
          noRowsRenderer: noRowsRenderer(localizationKey("No Mappings")),
          onRowClick: ({ rowData }) => {
            this.onEdit(rowData)
          },
        }}
      />
    )
  }
}

export default connect(
  ({ psa }) => ({
    configurationTypeMappings: getConfigurationTypeMappingList(psa.ConnectWise.mappings),
    sortBy: psa.ConnectWise.mappings.configurations.configurationTypeMappingSort.sortBy,
    sortDirection: psa.ConnectWise.mappings.configurations.configurationTypeMappingSort.sortDirection,
  }),
  {
    sort: _sort("CWConfigurationTypeMappingSort"),
    setCurrentConfigurationTypeMapping: _setCurrentConfigurationTypeMapping,
    showConfigurationTypeMappingModal: _showConfigurationTypeMappingModal,
    removeConfigurationTypeMapping: _removeConfigurationTypeMapping,
    copyConfigurationTypeMapping: _copyConfigurationTypeMapping,
  },
)(ConfigurationTypeMappingTable)
