const initialState = {
  fetched: false,
  resetPasswordViaLink: false,
  acceptInvitationViaLink: false,
  allowedIPSubnets: [],
}

export default function divisionSecurity(state = initialState, action) {
  switch (action.type) {
    case "SET_DIVISION_SECURITY_CONFIG":
      return {
        ...state,
        fetched: true,
        ...action.payload,
      }
    default:
      return state
  }
}
