import { PureComponent } from "react"
import { connect } from "react-redux"
import Loading from "js/includes/components/Loading"
import EditorPortalModal from "js/includes/components/EditorPortalModal"
import ConfigurationTypeMappingTable from "./ConfigurationTypeMappingTable"
import ConfigurationTypeMappingModal from "./ConfigurationTypeMappingModal"
import { showConfigurationTypeMappingModal as _showConfigurationTypeMappingModal } from "js/state/actions/psa/ConnectWise/mappings/configurations/configurations"
import { getTempId } from "js/state/actions/psa/ConnectWise/mappings/common"

class ConfigurationTypeMapping extends PureComponent {
  onAdd = () => {
    const { showConfigurationTypeMappingModal, configurationTypeMappings } = this.props
    showConfigurationTypeMappingModal("ADD", getTempId(configurationTypeMappings))
  }

  render() {
    const { loading, configurationTypeMappingModalVisibility } = this.props
    return (
      <div className="display-flex flex-column flex-full">
        {loading ? (
          <Loading />
        ) : (
          <>
            <ConfigurationTypeMappingTable addNewMapping={this.onAdd} />
            <EditorPortalModal {...{ show: configurationTypeMappingModalVisibility, className: "inmodal" }}>
              <ConfigurationTypeMappingModal />
            </EditorPortalModal>
          </>
        )}
      </div>
    )
  }
}

export default connect(
  ({ psa }) => ({
    configurationTypeMappingModalVisibility:
      psa.ConnectWise.mappings.configurations.configurationTypeMappingModalVisibility,
    configurationTypeMappings: psa.ConnectWise.mappings.configurations.configurationTypeMappings,
    loading: psa.editorLoading,
  }),
  {
    showConfigurationTypeMappingModal: _showConfigurationTypeMappingModal,
  },
)(ConfigurationTypeMapping)
