import React from "react"
import { reject } from "ramda"
import showModal from "js/includes/common/services/showModal"
import AutotaskAccountAutoMapModal from "./AutotaskAccountAutoMapModal"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export const AUTOMAP = {
  id: "automap",
  token: localizationKey("Auto Map"),
  type: "autotask",
  position: "right",
  action: async () => {
    showModal(<AutotaskAccountAutoMapModal />, { withProvider: true })
  },
}

export const CLEAR_MAPPING = {
  id: "clear-mapping",
  token: localizationKey("Clear Mapping"),
  type: "autotask",
  action: async (
    selected,
    accountOrganizationMappings,
    saveAccountOrganizationMappings,
    saveLocationMappings,
    locationMappings,
    dispatchCallback,
  ) => {
    saveAccountOrganizationMappings(
      accountOrganizationMappings.map(mapping =>
        selected.rows[mapping.clientId]
          ? {
              ...mapping,
              accountId: null,
              accountName: null,
              installedProductCategoryMappingId: null,
              installedProductCategoryMappingName: null,
            }
          : mapping,
      ),
    )

    saveLocationMappings(reject(({ clientId }) => !!selected.rows[clientId], locationMappings))
    dispatchCallback({ type: "CLEAR_SELECTION" })
  },
}
