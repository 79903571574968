import React, { PureComponent } from "react"
import { FormGroup, Col } from "react-bootstrap"
import { connect } from "react-redux"
import { localized } from "js/includes/common/utils"
import { Checkbox } from "js/includes/components/NinjaReactICheck"
import { cwHelpDeskOptionsChangeActionCreator } from "js/state/actions/psa/ConnectWise/helpdesk"

class ConnectwiseHelpDesk extends PureComponent {
  handleContactChange = () => {
    const {
      cwHelpDeskSettings: { createContact },
    } = this.props
    this.props.setCreateContact(!createContact)
  }

  render() {
    const {
      cwHelpDeskSettings: { createContact },
    } = this.props
    return (
      <FormGroup>
        <div className="m-l-xs">
          <label className="m-l-xs">
            {" "}
            {localized(
              "Options for the customization of Connectwise ticket creation from help desk submit from the Systray icon",
            )}{" "}
          </label>
        </div>
        <div className="m-l-xs">
          <Col className="display-flex align-items-center form-row-height">
            <Checkbox
              checkboxClass="icheckbox_square-blue"
              increaseArea="20%"
              onChange={this.handleContactChange}
              checked={createContact}
            />
            <span className="m-l-md">{localized("Create contact for email addresses not already in Connectwise")}</span>
          </Col>
        </div>
      </FormGroup>
    )
  }
}

const mapStateToProps = state => {
  return {
    cwHelpDeskSettings: state.psa.ConnectWise.cwHelpDeskSettings,
  }
}

export default connect(mapStateToProps, cwHelpDeskOptionsChangeActionCreator)(ConnectwiseHelpDesk)
