import { memo } from "react"
import tokens from "@ninjaone/tokens"
import { localized } from "js/includes/common/utils"
import { isFieldDisabled } from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"
import { managedDevicesGroupOptions } from "js/includes/configuration/integrations/psa/psaProducts/productForm/formCommons"
import { Box } from "js/includes/components/Styled"

import RadioButtonsField from "./RadioButtonsField"

const AnyOrAllGroups = memo(({ onChange, values, validation, enabledFields }) => {
  return (
    <Box marginTop={tokens.spacing[3]}>
      <RadioButtonsField
        {...{
          onChange,
          values,
          validation,
          disabled: isFieldDisabled({ name: "allGroups", enabledFields }),
          name: "allGroups",
          options: [
            {
              value: managedDevicesGroupOptions.any,
              label: localized("Any group"),
              description: localized("Rules will be applied to devices in any of the selected groups."),
            },
            {
              value: managedDevicesGroupOptions.all,
              label: localized("All groups"),
              description: localized("Rules will be applied to devices in all of the selected groups."),
            },
          ],
        }}
      />
    </Box>
  )
})

export default AnyOrAllGroups
