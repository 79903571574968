import { memo } from "react"
import PropTypes from "prop-types"
import tokens from "@ninjaone/tokens"
import { Stepper } from "@ninjaone/components"
import { Box } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"
import { MAX_INTEGER_VALUE } from "js/includes/common/_constants"
import { contentFieldPropTypes } from "js/includes/configuration/integrations/psa/psaProducts/productForm/content/contentCommons"

const NumericField = memo(
  ({
    onChange,
    values,
    validation,
    disabled = false,
    name,
    nameToken: labelToken,
    step,
    min,
    max = MAX_INTEGER_VALUE,
    mandatory,
  }) => {
    const errorMessage = validation.message[name]
    const labelText = localized(labelToken)

    return (
      <Box marginTop={tokens.spacing[3]}>
        <Stepper
          step={step}
          min={min}
          max={max}
          required={mandatory}
          ariaLabel={labelText}
          labelText={labelText}
          value={values[name]}
          disabled={disabled}
          error={errorMessage}
          onChange={val => onChange({ [name]: val > max ? max : val })}
        />
      </Box>
    )
  },
)

NumericField.propTypes = {
  ...contentFieldPropTypes,
  step: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
}

export default NumericField
