import { Tab, Tabs } from "react-bootstrap"
import styled from "@emotion/styled"
import { localized } from "js/includes/common/utils"
import ConfigurationTypeMapping from "./ConfigurationTypeMapping"
import DeviceDataSyncAttributes from "./DeviceDataSyncAttributes"

const StyledTabs = styled(Tabs)`
  .tab-content {
    overflow: hidden;
  }
`

export default function ConnectWiseConfigurationTypes() {
  return (
    <StyledTabs
      animation={false}
      defaultActiveKey="mappings"
      id="cw-configuration-tabs"
      className="custom-nav-tabs p-l p-r"
    >
      <Tab eventKey="mappings" title={localized("Mappings")}>
        <ConfigurationTypeMapping />
      </Tab>
      <Tab eventKey="sync" title={localized("Sync")}>
        <DeviceDataSyncAttributes />
      </Tab>
    </StyledTabs>
  )
}
