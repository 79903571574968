import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { noRowsRenderer, headerRenderer } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import Table from "js/includes/components/Table"
import Tooltip from "js/includes/components/Tooltip"
import showModal from "js/includes/common/services/showModal"
import AutotaskLocationMappingModal from "./AutotaskLocationMappingModal"
import { saveLocationMappings } from "js/state/actions/psa/Autotask/settings"
import { updateByKey } from "js/includes/common/utils/ssrAndWebUtils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const cellRenderer = ({ cellData }) =>
  cellData ? (
    <Tooltip output={cellData} className="word-wrap">
      <span className="text-ellipsis">{cellData}</span>
    </Tooltip>
  ) : (
    "-"
  )

class AutotaskLocationMapping extends PureComponent {
  state = {
    sortBy: "configurationTypeName",
    sortDirection: "DESC",
  }

  columns = [
    {
      cellRenderer,
      headerRenderer,
      dataKey: "clientName",
      width: 1,
      flexGrow: 1,
      label: localizationKey("Ninja Organization"),
    },
    {
      cellRenderer,
      headerRenderer,
      dataKey: "locationName",
      width: 1,
      flexGrow: 1,
      label: localizationKey("Ninja Location"),
    },
    {
      cellRenderer,
      headerRenderer,
      dataKey: "autotaskAccountName",
      width: 1,
      flexGrow: 1,
      label: localizationKey("Autotask Company"),
    },
    {
      cellRenderer,
      headerRenderer,
      dataKey: "autotaskLocationName",
      width: 1,
      flexGrow: 1,
      label: localizationKey("Autotask Location"),
    },
  ]

  render() {
    const {
      props: { locationMappings = [], saveLocationMappings },
      state: { sortBy, sortDirection },
      columns,
    } = this

    return (
      <Box display="flex" flex={1} marginLeft={3}>
        <Table
          {...{
            columns,
            sortBy,
            sortDirection,
            list: locationMappings,
            rowHeight: 50,
            headerHeight: 45,
            noRowsRenderer: noRowsRenderer(localizationKey("No Mappings"), false, "no-padding  m-l-xs"),
            sort: ({ sortBy, sortDirection }) => this.setState({ sortBy, sortDirection }),
            onRowClick: ({ rowData, index }) => {
              showModal(
                <AutotaskLocationMappingModal
                  mapping={rowData}
                  saveMapping={locationMapping => {
                    const updatedLocationMappings = updateByKey(locationMapping, locationMappings, "locationId")
                    saveLocationMappings(updatedLocationMappings)
                  }}
                />,
              )
            },
          }}
        />
      </Box>
    )
  }
}

export default connect(
  ({ psa }) => ({
    locationMappings: psa.Autotask.settings.locationMappings,
  }),
  { saveLocationMappings },
)(AutotaskLocationMapping)
