import PropTypes from "prop-types"

import { AlertMessage } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"

import { localizationKey } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import CommonConditions from "js/includes/ticketing/TriggerEditor/Conditions"

import { LoadingWrapper } from "./LoadingWrapper"
import {
  attributesType,
  conditionsType,
  fieldsType,
  formOptionsType,
  organizationsType,
  tagsType,
  triggerType,
} from "./propTypes"

const translationTokens = {
  removeRule: {
    labelToken: localizationKey("Remove condition"),
  },
  addRule: {
    labelToken: localizationKey("Add condition"),
  },
}

export const Conditions = ({
  isLoading,
  onQueryChange,
  conditions,
  attributes,
  fields,
  organizations,
  type,
  getValidations,
  formOptions,
  isFormSubmitted,
  tags,
  validationMessage,
}) => {
  const { all: allConditions, any: anyConditions } = conditions

  return (
    <LoadingWrapper {...{ isLoading }}>
      <Flex flexDirection="column" height="100%" justifyContent="space-between" padding="0 1px">
        <Flex flexDirection="column" gap={tokens.spacing[4]}>
          <CommonConditions
            {...{
              query: allConditions,
              combinator: "all",
              onQueryChange: condition => onQueryChange(condition, "all"),
              attributes,
              fields: fields.all,
              organizations,
              type,
              getValidations,
              formOptions,
              translationTokens,
              tags,
              displayErrors: isFormSubmitted,
            }}
          />
          <CommonConditions
            {...{
              query: anyConditions,
              combinator: "any",
              onQueryChange: condition => onQueryChange(condition, "any"),
              attributes,
              fields: fields.any,
              organizations,
              type,
              getValidations,
              formOptions,
              translationTokens,
              tags,
              displayErrors: isFormSubmitted,
            }}
          />
        </Flex>
        {validationMessage && isFormSubmitted && <AlertMessage variant="danger">{validationMessage}</AlertMessage>}
      </Flex>
    </LoadingWrapper>
  )
}

Conditions.propTypes = {
  isLoading: PropTypes.bool,
  onQueryChange: PropTypes.func.isRequired,
  attributes: attributesType.isRequired,
  conditions: conditionsType.isRequired,
  fields: fieldsType.isRequired,
  organizations: organizationsType.isRequired,
  type: triggerType.isRequired,
  getValidations: PropTypes.func.isRequired,
  formOptions: formOptionsType.isRequired,
  isFormSubmitted: PropTypes.bool,
  tags: tagsType,
  validationMessage: PropTypes.string,
}
