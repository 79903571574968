import React, { PureComponent } from "react"
import { connect } from "react-redux"
import SelectableKeyboardShortcutsTable from "js/includes/components/SelectableKeyboardShortcutsTable"
import { compose, map, filter, sortBy, prop, keys, find } from "ramda"
import { noRowsRenderer, headerRenderer } from "js/includes/common/utils/react-virtualized"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import {
  updateMapping as _updateMapping,
  removeMapping as _removeMapping,
  setCurrentMapping as _setCurrentMapping,
  showModal as _showModal,
} from "js/state/actions/psa/ConnectWise/mappings/products/nodeRoleProductMappings"
import { faTrash } from "@fortawesome/pro-solid-svg-icons"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import Tooltip from "js/includes/components/Tooltip"
import {
  localizeSingleRole,
  defaultToNameNotAvailable,
} from "js/includes/configuration/psa/ConnectWise/settings/common"
import { MOVE_RANK_UP, MOVE_RANK_DOWN } from "./actions"

const sortByRanking = sortBy(prop("ranking"))

class NodeRoleProductMappingsTable extends PureComponent {
  onEdit = (e, data) => {
    e.stopPropagation()
    e.preventDefault()
    this.props.setCurrentMapping(data)
    this.props.showModal(localizationKey("Edit Mapping"), "EDIT", data.id)
  }

  onDelete = async (e, id) => {
    e.stopPropagation()
    e.preventDefault()
    const buttonPressed = await ShowMessageDialog({
      icon: { icon: faTrash, type: "critical" },
      title: localizationKey("Delete Mapping"),
      message: localizationKey("Are you sure you want to delete this mapping?"),
      buttons: [
        { id: true, label: localizationKey("Yes"), type: "critical" },
        { id: false, label: localizationKey("No") },
      ],
    })
    if (buttonPressed) {
      await this.props.removeMapping(id)
    }
  }

  renderDeviceWithColumn = rowData => {
    const { groupList, nodeRoleList } = this.props
    const groups = compose(
      map(g => g.name),
      filter(g => rowData.groups.includes(g.id)),
    )(groupList)

    const role = compose(
      localizeSingleRole,
      find(role => role.id === rowData.nodeRoleId),
    )(nodeRoleList)

    const { name } = role

    return (
      <div className="display-flex flex-row">
        <p className="no-margin">{name}</p>
        {groups.length ? (
          <div>
            <Tooltip output={`${localized("In Groups:")} ${groups.join(", ")}`} />
          </div>
        ) : (
          ""
        )}
      </div>
    )
  }

  render() {
    const {
      props: { mappings, selectMappingRowId, moveRankUp, moveRankDown, selectedMapping },
    } = this

    const sortedMapping = sortByRanking(mappings)

    return (
      <div id="product-mappings-table" className="display-flex flex-full">
        <SelectableKeyboardShortcutsTable
          {...{
            data: sortedMapping,
            sortBy: "ranking",
            sortDirection: "ASC",
            singleRowCheck: true,
            filterText: false,
            noRowsRenderer: noRowsRenderer(localizationKey("No Mappings"), false, "text-align-left col-xs-4"),
            actions: selectedMapping ? [MOVE_RANK_UP, MOVE_RANK_DOWN] : [],
            onAction: ({ id }) => {
              switch (id) {
                case MOVE_RANK_UP.id:
                  moveRankUp()
                  break
                case MOVE_RANK_DOWN.id:
                  moveRankDown()
                  break
                default:
                  throw new Error("Invalid action")
              }
            },
            onCheck: ({ selected }) => {
              const { rows } = selected
              const [id] = keys(rows)
              selectMappingRowId(parseInt(id, 10))
            },
            columns: () => [
              {
                dataKey: "nodeRoleName",
                label: localizationKey("Device(s) with Role"),
                width: 1,
                flexGrow: 1,
                disableSort: true,
                headerRenderer,
                cellRenderer: ({ rowData }) => this.renderDeviceWithColumn(rowData),
              },
              {
                headerRenderer,
                dataKey: "productName",
                label: localizationKey("Map To Product / Agreement Type"),
                width: 1,
                flexGrow: 1,
                disableSort: true,
                cellRenderer: ({ rowData }) =>
                  `${defaultToNameNotAvailable(rowData.productName)} / ${rowData.agreementTypeName}`,
              },
              {
                dataKey: "",
                width: 1,
                flexGrow: 0.5,
                cellRenderer: ({ rowData }) => (
                  <div className="action-links">
                    <button
                      className="btn-link"
                      onClick={e => {
                        this.onEdit(e, rowData)
                      }}
                    >
                      {localized("Edit")}
                    </button>
                    <button
                      className="btn-link text-danger"
                      onClick={e => {
                        this.onDelete(e, rowData.id)
                      }}
                    >
                      {localized("Delete")}
                    </button>
                  </div>
                ),
              },
            ],
          }}
        />
      </div>
    )
  }
}

export default connect(
  ({ psa, deviceSearch }) => ({
    mappings: psa.ConnectWise.mappings.products.currentProductMapping.nodeRoleProductMappings,
    groupList: deviceSearch.groups,
    nodeRoleList: psa.ConnectWise.mappings.nodeRoleList,
  }),
  {
    updateMapping: _updateMapping,
    removeMapping: _removeMapping,
    setCurrentMapping: _setCurrentMapping,
    showModal: _showModal,
  },
)(NodeRoleProductMappingsTable)
