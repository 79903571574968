import { memo, useContext, useEffect, useMemo, useState } from "react"
import { equals, ifElse, curry, all, includes, pluck, reject, append, compose, prop } from "ramda"
import { Checkbox, Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"
import ConnectWiseSyncSelectItem from "./ConnectWiseSyncSelectItem"
import { SyncGroupContext } from "./ConnectWiseSyncSelectGroupWrapper"

const checkAllItemsAreSelected = curry((listToCheck, listToCheckAgainst) =>
  all(item => includes(item, listToCheckAgainst))(listToCheck),
)

const selectAllNames = compose(pluck("name"), reject(prop("required")))

const mergeLists = curry((list1, list2) => {
  return list1.reduce((acc, curr) => {
    if (!acc.includes(curr)) {
      acc.push(curr)
    }
    return acc
  }, list2)
})

export default memo(function ConnectWiseSyncSelectGroup({ items = [], titleText }) {
  const { selectedGroups, setSelectedGroups } = useContext(SyncGroupContext)

  const itemOptions = useMemo(() => selectAllNames(items), [items])
  const mergeWithSelected = mergeLists(selectedGroups)

  const [selectAllChecked, setSelectAllChecked] = useState(checkAllItemsAreSelected(itemOptions, selectedGroups))

  const setAllChecked = () => setSelectedGroups(mergeWithSelected(itemOptions))
  const setAllUnchecked = () => setSelectedGroups(reject(item => itemOptions.includes(item), selectedGroups))

  const handleChecked = name => () => {
    setSelectedGroups(ifElse(includes(name), reject(equals(name)), append(name))(selectedGroups))
  }

  useEffect(() => {
    const allSelected = checkAllItemsAreSelected(itemOptions, selectedGroups)
    setSelectAllChecked(allSelected)
  }, [selectedGroups, itemOptions])

  return (
    <Flex>
      <Box width={sizer(60)}>
        <Text color="colorTextStrong" size="sm" bold>
          {titleText}
        </Text>
      </Box>
      <Flex flex={1} flexDirection="column" gridGap={sizer(3)}>
        <Flex gridGap={sizer(3)}>
          <Checkbox
            checked={selectAllChecked}
            onChange={({ isChecked }) => {
              setSelectAllChecked(isChecked)
              ifElse(equals(true), setAllChecked, setAllUnchecked)(isChecked)
            }}
          />
          <Text size="sm" lineHeight={1.1} color="colorTextStrong">
            {localized("Select All")}
          </Text>
        </Flex>
        <Flex flexDirection="column" gridGap={sizer(3)} paddingLeft={sizer(10)}>
          {items?.map(({ name, titleText, descriptionText, required }) => {
            return (
              <ConnectWiseSyncSelectItem
                key={name}
                name={name}
                disabled={required}
                checked={selectedGroups.includes(name) || !!required}
                titleText={titleText}
                descriptionText={descriptionText}
                onChange={handleChecked(name)}
              />
            )
          })}
        </Flex>
      </Flex>
    </Flex>
  )
})
