import moment from "moment"
import { compose, defaultTo, last, map, omit } from "ramda"
import { memo } from "react"
import CreatableSelect from "react-select/creatable"

import { DatePicker, Input, SearchableSelect } from "@ninjaone/components"

import { getSeconds, isEKey, isNotNilOrEmpty, localized } from "js/includes/common/utils"
import { flattenOptions } from "js/includes/components/RuleEditor/utils"

const omitLabel = omit(["label"])
const omitLabelOnLastElement = compose(omitLabel, last, defaultTo([]))
const omitLabelsOnList = compose(map(omitLabel), defaultTo([]))

const NAInput = ({ ariaLabel }) => <Input disabled ariaLabel={ariaLabel} value={localized("N/A")} />

const ValueEditor = memo(
  ({ operator, value, handleOnChange, type, inputType, values, metaData, ariaLabel, errorMessage }) => {
    if (["present", "not_present", "changed", "in", "not_in"].includes(operator)) return <NAInput {...{ ariaLabel }} />

    if (typeof type === "function") return type({ value, values, handleOnChange, metaData, errorMessage, ariaLabel })

    switch (type) {
      case "select": {
        return (
          <SearchableSelect
            ariaLabel={ariaLabel}
            options={values}
            value={value}
            onChange={handleOnChange}
            errorMessage={errorMessage}
            hideClearSelectionButton
          />
        )
      }
      case "multi-select": {
        const valueArray = isNotNilOrEmpty(value?.trim()) ? value.split(",") : []
        return (
          <SearchableSelect
            ariaLabel={ariaLabel}
            options={values}
            value={valueArray}
            isMulti
            onChange={selected => handleOnChange(selected.join(","))}
            errorMessage={errorMessage}
            hideClearSelectionButton
          />
        )
      }

      case "multi-creatable-select": {
        const flattenedOptions = flattenOptions(values)

        function handleChange(upcomingChange, { action }) {
          switch (action) {
            case "select-option":
            case "create-option":
              handleOnChange([...value, omitLabelOnLastElement(upcomingChange)])
              break
            case "remove-value":
              handleOnChange(omitLabelsOnList(upcomingChange))
              break
            case "clear":
              handleOnChange([])
              break
            default:
              break
          }
        }

        return (
          <CreatableSelect
            aria-label={ariaLabel}
            menuPlacement="auto"
            className="flex-full"
            isMulti
            options={values}
            value={value.map(v => ({
              value: v.value,
              label: v.__isNew__ ? v.value : flattenedOptions.find(o => o.value === v.value)?.label,
              __isNew__: v.__isNew__,
            }))}
            onChange={handleChange}
            menuPortalTarget={document.body}
            getOptionLabel={({ label, labelText }) => label || labelText}
            styles={{
              menuPortal: styles => ({
                ...styles,
                zIndex: 9999,
              }),
            }}
          />
        )
      }
      case "radio":
        return (
          <span aria-label={ariaLabel}>
            {values.map(({ name, label }) => (
              <label key={name}>
                <input
                  type="radio"
                  value={name}
                  checked={value === name}
                  onChange={e => handleOnChange(e.target.value)}
                />
                {label}
              </label>
            ))}
          </span>
        )

      case "date":
        return (
          <DatePicker
            ariaLabel={ariaLabel}
            disableClear
            disabledDays
            placeholder={localized("Select a date")}
            selectedDays={value ? moment.unix(value).toDate() : null}
            onDayChange={({ date }) => {
              const datetime = moment(date)
              handleOnChange(getSeconds(datetime.valueOf()))
            }}
            fullWidth
            inputProps={{ readOnly: true }}
            errorMessage={errorMessage}
          />
        )

      default:
        return (
          <Input
            ariaLabel={ariaLabel}
            type={inputType || "text"}
            value={value}
            onChange={e => handleOnChange(e.target.value)}
            onKeyDown={event => {
              if (inputType === "number" && isEKey(event)) {
                event.preventDefault()
              }
            }}
            errorMessage={errorMessage}
          />
        )
    }
  },
)

export default ValueEditor
