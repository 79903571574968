import { memo, useCallback, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { map, assoc } from "ramda"
import { createCommand, COMMAND_PRIORITY_LOW, CLEAR_EDITOR_COMMAND } from "lexical"
import { mergeRegister } from "@lexical/utils"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"

import tokens from "@ninjaone/tokens"
import { localizationKey, isNilOrEmpty } from "@ninjaone/webapp/src/js/includes/common/utils"

import FileAttachment from "../../FileAttachment"

export const ALLOW_ATTACHMENT_PLUGIN_COMMAND = createCommand("ALLOW_ATTACHMENT_PLUGIN_COMMAND")
export const INSERT_ATTACHMENT_BEGAN_COMMAND = createCommand("INSERT_ATTACHMENT_BEGAN_COMMAND")
export const INSERT_ATTACHMENT_COMPLETED_COMMAND = createCommand("INSERT_ATTACHMENT_COMPLETED_COMMAND")
export const REMOVE_ATTACHMENT_COMMAND = createCommand("REMOVE_ATTACHMENT_COMMAND")

const StyledContainer = styled.div`
  padding: ${tokens.spacing[4]};
  padding-top: 0;
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  border-bottom-left-radius: ${tokens.borderRadius[1]};
  border-bottom-right-radius: ${tokens.borderRadius[1]};
`

const StyledLayout = styled.div`
  display: flex;
  overflow: auto;
  gap: ${tokens.spacing[2]};
`

const assocProgress = map(assoc("progress", 100))

function useProgress(initialProgress) {
  const [step, setStep] = useState(0.5)
  const [progress, setProgress] = useState(initialProgress ?? 0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress === 100) {
        clearInterval(interval)
      } else {
        const nextProgress = progress + step
        const currentProgress = Math.round((Math.atan(nextProgress) / (Math.PI / 2)) * 100 * 1000) / 1000

        setProgress(currentProgress)

        // Stop before 100% to give the impression that the file is still being uploaded
        if (currentProgress >= 90) {
          clearInterval(interval)
        } else if (currentProgress >= 70) {
          setStep(0.1)
        }
      }
    }, 100)

    return () => clearInterval(interval)
  }, [progress, step])

  return [progress, setProgress]
}

const AnimatedAttachment = memo(
  ({
    cidKey,
    attachment,
    setAttachments,
    onAddAttachment,
    onPreviewAttachment,
    onDownloadAttachment,
    onRemoveAttachment,
  }) => {
    const [editor] = useLexicalComposerContext()
    const [progress, setProgress] = useProgress(attachment.progress)

    const handleAddAttachment = useCallback(
      // Contains file and response after upload
      payload => {
        if (payload.id === attachment.id) {
          // Avoid setting the progress to 100 on complete as this will cause the progress bar to disappear at whatever current progress it's in.
          // This will artificially give the impression that the progress bar served it's purpose.
          // This is common practice among progress bars, as it's difficult to truly calculate progress.
          setTimeout(() => setProgress(99), 1000)
          setTimeout(() => setProgress(100), 2000)

          onAddAttachment?.(payload)
          setAttachments(prevState =>
            prevState.map(a => (a.id === payload.id ? { ...a, [cidKey]: payload.response[cidKey] } : a)),
          )
        }
      },
      [setProgress, cidKey, attachment, setAttachments, onAddAttachment],
    )

    const handleRemoveAttachment = useCallback(
      payload => {
        onRemoveAttachment?.(payload)
        const attachmentKey = payload[cidKey] ? cidKey : "id"
        setAttachments(prevState => prevState.filter(a => a[attachmentKey] !== payload[attachmentKey]))
      },
      [cidKey, setAttachments, onRemoveAttachment],
    )

    useEffect(() => {
      return mergeRegister(
        editor.registerCommand(INSERT_ATTACHMENT_COMPLETED_COMMAND, handleAddAttachment, COMMAND_PRIORITY_LOW),
        editor.registerCommand(REMOVE_ATTACHMENT_COMMAND, handleRemoveAttachment, COMMAND_PRIORITY_LOW),
      )
    }, [editor, handleAddAttachment, handleRemoveAttachment])

    return (
      <FileAttachment
        key={attachment.id}
        file={attachment.file}
        progress={progress}
        actions={[
          ...(onDownloadAttachment
            ? [
                {
                  labelToken: localizationKey("Download"),
                  action: onDownloadAttachment,
                },
              ]
            : []),
          ...(onPreviewAttachment
            ? [
                {
                  labelToken: localizationKey("Preview"),
                  action: onPreviewAttachment,
                  splitAfter: true,
                },
              ]
            : []),
          {
            labelToken: localizationKey("Delete"),
            action: () => handleRemoveAttachment(attachment),
            isRed: true,
          },
        ]}
      />
    )
  },
)

/**
 * Must be defined as the LAST plugin since it renders at the bottom
 */
export const AttachmentPlugin = ({
  cidKey,
  initialAttachments = [],
  onAddAttachment,
  onPreviewAttachment,
  onDownloadAttachment,
  onRemoveAttachment,
}) => {
  const [editor] = useLexicalComposerContext()
  const [attachments, setAttachments] = useState(assocProgress(initialAttachments))

  useEffect(() => {
    editor.dispatchCommand(ALLOW_ATTACHMENT_PLUGIN_COMMAND, true)
    return mergeRegister(
      editor.registerCommand(
        INSERT_ATTACHMENT_BEGAN_COMMAND,
        payload => setAttachments(prevState => [{ id: payload.id, file: payload.file }, ...prevState]),
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(CLEAR_EDITOR_COMMAND, () => setAttachments([]), COMMAND_PRIORITY_LOW),
    )
  }, [editor])

  if (isNilOrEmpty(attachments)) return null

  return (
    <StyledContainer>
      <StyledLayout>
        {attachments.map(attachment => (
          <AnimatedAttachment
            {...{
              key: attachment.id,
              cidKey,
              attachment,
              setAttachments,
              onAddAttachment,
              onPreviewAttachment,
              onDownloadAttachment,
              onRemoveAttachment,
            }}
          />
        ))}
      </StyledLayout>
    </StyledContainer>
  )
}
