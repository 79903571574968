import React, { PureComponent } from "react"
import styled from "@emotion/styled"
import LocationsSiteAutoMapTable from "./LocationsSiteAutoMapTable"
import Modal from "js/includes/components/Modal"
import Loading from "js/includes/components/Loading"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { colors } from "js/includes/common/theme"
import {
  saveLocationSiteAutoMapping as _saveLocationSiteAutoMapping,
  cancelLocationSiteAutoMapping as _cancelLocationSiteAutoMapping,
} from "js/state/actions/psa/ConnectWise/mappings/accounts/locations"
import { connect } from "react-redux"

const StyledParagraph = styled.p`
  text-align: left;
  color: ${colors.ninjaMedium};
`

const StyledDiv = styled.div`
  height: 282px;
  display: flex;
  background-color: ${colors.ninjaWhite};
`

class LocationsSiteAutoMapModal extends PureComponent {
  handleCancel = () => {
    const { cancelLocationSiteAutoMapping, unmount } = this.props
    cancelLocationSiteAutoMapping && cancelLocationSiteAutoMapping()
    unmount && unmount()
  }

  handleSave = () => {
    const { saveLocationSiteAutoMapping, unmount } = this.props
    saveLocationSiteAutoMapping && saveLocationSiteAutoMapping()
    unmount && unmount()
  }

  render() {
    const { possibleLocationMappings, possibleLocationMappingsModalLoading } = this.props
    return (
      <Modal
        title={localizationKey("Auto mapping results")}
        saveText={localizationKey("Auto Map")}
        closeText={localizationKey("Cancel")}
        disabledSaveBtn={!possibleLocationMappings.length}
        save={this.handleSave}
        close={this.handleCancel}
        dialogClassName="psa-modal"
      >
        {possibleLocationMappingsModalLoading ? (
          <Loading />
        ) : (
          <div className="p-l p-r">
            <StyledParagraph>
              <span>{possibleLocationMappings.length}</span> {localized("location(s) will be mapped")}
            </StyledParagraph>
            <StyledDiv className="panel-bordered">
              <LocationsSiteAutoMapTable
                {...{
                  possibleLocationMappings,
                }}
              />
            </StyledDiv>
          </div>
        )}
      </Modal>
    )
  }
}

export default connect(
  ({ psa }) => ({
    possibleLocationMappings: psa.ConnectWise.mappings.accounts.accountOrganizationModal.possibleLocationMappings,
    possibleLocationMappingsModalLoading: psa.ConnectWise.mappings.accounts.possibleLocationMappingsModalLoading,
  }),
  {
    cancelLocationSiteAutoMapping: _cancelLocationSiteAutoMapping,
    saveLocationSiteAutoMapping: _saveLocationSiteAutoMapping,
  },
)(LocationsSiteAutoMapModal)
