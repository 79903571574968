import { compose, join, reduce } from "ramda"

import { SearchableSelect } from "@ninjaone/components"

import { getAsArray, isNilOrEmpty, localized, sortByFieldNameCaseInsensitive } from "js/includes/common/utils"
import { getSelectedValue, getValuesMap } from "js/includes/ticketing/TriggerEditor/utils"

const OPTION_ID_KEY = "value"
const OPTION_LABEL_KEY = "labelText"
const getSelectedArrayValues = compose(join(","))

const metaDataMapper = ({ name, id, active }) => ({ [OPTION_LABEL_KEY]: name, [OPTION_ID_KEY]: id, active })

const valueMapper = ({ value }) => value

const buildOptions = ({ metaData, formOptions, selected, valuesMap }) => {
  if (isNilOrEmpty(metaData) || isNilOrEmpty(selected)) {
    return formOptions
  }

  const selectedArray = getAsArray(selected)
  const isAnInactiveOptionSelected = selectedArray.some(id => valuesMap[id]?.active === false)
  if (!isAnInactiveOptionSelected) {
    return formOptions
  }

  const inactiveText = localized("Inactive")
  const inactiveOptions = reduce(
    (acc, formId) => {
      const option = valuesMap[formId]
      if (option?.active === false) {
        acc.push({
          ...option,
          [OPTION_LABEL_KEY]: `${option[OPTION_LABEL_KEY]} (${inactiveText})`,
        })
      }
      return acc
    },
    [],
    selectedArray,
  )
  return sortByFieldNameCaseInsensitive(OPTION_LABEL_KEY, "ASC", [...formOptions, ...inactiveOptions])
}

export const FormEditor = ({
  isMulti = true,
  value,
  handleOnChange,
  metaData,
  formOptions,
  errorMessage,
  ariaLabel,
}) => {
  const valuesMap = getValuesMap({ metaData, options: formOptions, metaDataMapper, idKey: OPTION_ID_KEY })
  const selected = getSelectedValue({
    value,
    isMulti,
    valuesMap,
    idKey: OPTION_ID_KEY,
    labelKey: OPTION_LABEL_KEY,
    valueMapper,
  })
  const options = buildOptions({ formOptions, metaData, selected, valuesMap })

  const handleOnSelect = selected => {
    handleOnChange(Array.isArray(selected) ? getSelectedArrayValues(selected) : selected)
  }

  return (
    <SearchableSelect
      ariaLabel={ariaLabel}
      options={options}
      value={selected || "__NONE__"}
      onChange={handleOnSelect}
      isMulti={isMulti}
      errorMessage={errorMessage}
      hideClearSelectionButton
    />
  )
}
